import React from 'react'
import { AppBar, Box, Container, Grid, Paper, styled, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import banner22 from '../../images/Mort1.jpg';
import PropTypes from 'prop-types';
import { AlignVerticalBottom, CalculateOutlined, CurrencyExchange, Receipt } from '@mui/icons-material';
import Mortgage from './Mortgage';
import Footer from '../Footer';
import Buydown from './Buydown';
import ROI from './ROI';

const StyledBox = styled(Box)(({ theme }) => ({
    padding: 24,
    [theme.breakpoints.down("sm")]: {
        padding: "24px 0px 0px 0px"
    }

}));

const MortPaper = styled(Paper)(({ theme, isMatch }) => ({
    borderRadius: 0,
    height: isMatch ? 250 : '500px',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: isMatch ? '4em' : 'none'
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <StyledBox p={3} >
                    <Typography>{children}</Typography>
                </StyledBox>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const ScrollableTabsButtonAuto = styled(Tabs)(({ theme }) => ({
    overflowX: 'auto',
    '& .MuiTabs-flexContainer': {
        display: 'contents',
        '& .MuiTab-root': {
            minWidth: 0,
            marginRight: theme.spacing(1),
        },
    },

}));



const MortContainer = () => {
    const [value, setValue] = React.useState(0);
    const isMatch = useMediaQuery('(max-width:960px )');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>
            {value === 0 ?
                <MortPaper isMatch={isMatch}>
                    <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            sx={{
                                fontSize: isMatch ? '30px' : '64px',
                                fontWeight: 500,
                                color: 'white',
                            }}
                        >
                            Mortgage calculator
                        </Typography>
                    </Grid>
                </MortPaper>
                :
                <MortPaper isMatch={isMatch}>
                    {
                        value === 1 ?
                            <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography
                                    sx={{
                                        fontSize: isMatch ? '30px' : '64px',
                                        fontWeight: 500,
                                        color: 'white',
                                    }}
                                >
                                    Buydown calculator
                                </Typography>
                            </Grid>
                            :
                            <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography
                                    sx={{
                                        fontSize: isMatch ? '30px' : '64px',
                                        fontWeight: 500,
                                        color: 'white',
                                    }}
                                >
                                    Return on Investment
                                </Typography>
                            </Grid>
                    }

                </MortPaper>
            }
            <div>
                <AppBar position="static" sx={{ backgroundColor: 'white' }}>
                    <Container maxWidth="md">
                        {isMatch ?
                            <ScrollableTabsButtonAuto
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Mortage calculator" icon={<CalculateOutlined />} {...a11yProps(0)} />
                                <Tab label="Buydown calculator" icon={<AlignVerticalBottom />} {...a11yProps(1)} />
                                <Tab label="Return on Investment" icon={<CurrencyExchange />} {...a11yProps(2)} />
                            </ScrollableTabsButtonAuto>
                            :
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Mortage calculator" icon={<CalculateOutlined />} {...a11yProps(0)} />
                                <Tab label="Buydown calculator" icon={<AlignVerticalBottom />} {...a11yProps(1)} />
                                <Tab label="Return on Investment" icon={<CurrencyExchange />} {...a11yProps(2)} />
                            </Tabs>}
                    </Container>
                </AppBar>
                <Container maxWidth="xl" >
                    <TabPanel value={value} index={0}>
                        <Paper elevation={5} sx={{ mt: isMatch ? '1em' : '2em', mb: '2em' }}>
                            <Mortgage />
                        </Paper>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Paper elevation={5} sx={{ mt: isMatch ? '1em' : '2em', mb: '2em' }}>
                            <Buydown />
                        </Paper>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Paper elevation={5} sx={{ mt: isMatch ? '1em' : '2em', mb: '2em' }}>
                            <ROI />
                        </Paper>
                    </TabPanel>
                </Container>

            </div>
            <Footer />
        </>
    )
}

export default MortContainer