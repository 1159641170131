import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography, } from '@mui/material';
import sanityClient from '../client';
import { useParams } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from '@sanity/block-content-to-react'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import Divider from '@mui/material/Divider'
import Footer from '../components/Footer';
import { useNavigate, Link } from 'react-router-dom';

const builder = imageUrlBuilder(sanityClient);



function urlFor(source) {
    return builder.image(source);
}

const MobBlogDetail = () => {
    const [allPost, setAllPost] = useState([]);
    const { address } = useParams();
    const [latestPost, setLatestPost] = useState([]);
    const ScrollTop = useRef();

    var size = 3;

    const overrides = {
        highlight: props => <p style={{
            margin: '20px 0 30px',
            borderLeft: '5px solid',
            paddingLeft: '20px',
            borderColor: 'indianred'
        }} {...props} />,
    }


    const BlockRenderer = (props) => {
        const { style = 'normal' } = props.node
        if (style === 'blockquote') {
            return (
                <body
                    style={{
                        margin: '20px 0 30px',
                        borderLeft: '4px solid',
                        paddingLeft: '20px',
                        borderColor: 'indianred',
                        fontSize: '15px',
                        background: 'white',
                    }}
                >
                    {props.children}
                </body>
            )

        }
        if (style === 'h6') {
            return (
                <body
                    style={{
                        fontSize: '1.1rem',
                        background: 'white',
                        lineHeight: 1,
                        fontWeight: 400,
                    }}
                >
                    {props.children}
                </body>
            )

        }
        return BlockContent.defaultSerializers.types.block(props)
    }


    // const serializers = {
    //     types: {
    //         code: (props) => (
    //             <SyntaxHighlighter
    //                 language={props.node.language}
    //                 style={coldarkDark}
    //                 showLineNumbers
    //                 lineNumberStyle={{
    //                     padding: "0 5px 0 0",
    //                     fontSize: 14,
    //                     borderRight: "1.5px solid darkgray",
    //                     marginRight: "10px",
    //                 }}
    //             >
    //                 {props.node.code}
    //             </SyntaxHighlighter>
    //         ),
    //         block: props =>
    //             overrides[props.node.style]
    //                 ? overrides[props.node.style]({ children: props.children })
    //                 : BlockContent.defaultSerializers.types.block(props),

    //     }
    // };

    const handleScroll = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }



    useEffect(() => {
        sanityClient
            .fetch(
                `*[address.current == "${address}"]{
            title,
            mainImage{
                asset->{
                    _id,
                    url
                },
                       
            },
           body,
           date,
           authorname,
           description,
           categories {
               category[] -> {
                   name,
                   content
               }
           }
        }`,
            )
            .then((data) => setAllPost(data[0]))
            .catch(console.error);
    }, [address]);
    console.log('allposts', allPost)


    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "post"]{
            title,
            address,
            mainImage{
                asset->{
                    _id,
                    url
                },               
            },
            description,
            date,
            authorname,
            releaseDate,
            authorimage,
        }`
            )
            .then((data) => setLatestPost(data))
            .catch(console.error);
    }, []);
    console.log("latestposts", latestPost.slice(0, size))


    // if (!allPost && !latestPost )  return <div><Loading /></div> 





    return (
        <>

            <Box display={{ xs: 'block', sm: 'none' }} m={1} style={{ margin: 0, backgroundColor: "white" }}>

                <Container style={{ marginTop: "2%" }} ref={ScrollTop}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '25px', fontWeight: '500' }} >{allPost.title}</Typography>
                            <Typography variant="body2" color="text.secondary" display="block" gutterBottom >
                                <DateRangeRoundedIcon sx={{ fontSize: 20 }} /> {allPost.date}
                            </Typography>
                        </Grid>
                        {allPost.mainImage && allPost.mainImage.asset && (
                            <Grid item xs={12}>
                                <img
                                    src={allPost.mainImage.asset.url}
                                    width="100%"
                                    height={200}
                                    alt={allPost.address}
                                    style={{ borderRadius: '10px' }}
                                />
                            </Grid>
                        )}
                        {/* <Grid container alignItems="center" >
                    <Grid item sm>
                        <IconButton variant="contained">
                            <FacebookIcon sx={{ fontSize: 40 }}/>
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <IconButton variant="contained">
                            <FacebookIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <IconButton variant="contained">
                            <FacebookIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    </Grid>
                    </Grid> */}
                        {/* <Grid item xs={12} align="inherit">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'initial',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup size="small" aria-label="small button group">
                             <IconButton sx={{fontSize: 10}}> <Facebook style={{width: 40, height: 40}}/></IconButton>
                             <IconButton sx={{fontSize: 10}}> <Twitter style={{width: 40, height: 40}}/></IconButton>
                             <IconButton sx={{fontSize: 10}}> <LinkedInIcon style={{width: 40, height: 40}}/></IconButton>
                             
                            </ButtonGroup>
                        </Box>
                    </Grid> */}
                        <Grid item xs={12}>
                            <BlockContent blocks={allPost.body} projectId="nk6jutwv" dataset="production" imageOptions={{ w: 350, h: 240, fit: 'max' }} serializers={{ types: { block: BlockRenderer } }} />
                        </Grid>
                        <Grid item xs={12} align="initial">
                            <Divider variant="middle" />
                        </Grid>
                        {/* <Grid item xs={12} style={{ marginTop: '1em' }}>
                            <Card >

                                <Grid item xs={12} style={{ marginLeft: '1em' }}>
                                    <Typography variant="h6">Author </Typography>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} align="center" style={{ margin: '1em 10em 0em 10em' }}>
                                        <div style={{border: '1px solid #f3f3f3', display: 'inline-block' , borderRadius: '50000px' , padding: '11px', }}>
                                        {
                                            allPost.mainImage && allPost.mainImage.asset && (
                                                <Avatar alt="authorimage" sx={{ width: 60, height: 60, }} src={allPost.mainImage.asset.url} />
                                            )
                                        }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <Typography variant="body1" display="block" gutterBottom style={{ marginLeft: '0.2em' }}>
                                            {allPost.authorname}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <Container md>
                                            <Typography variant="body1" display="block" gutterBottom style={{ marginLeft: '0.2em' }}>
                                                {allPost.description}
                                            </Typography>
                                        </Container>

                                    </Grid>
                                    <Grid item xs={12} align="center">

                                    </Grid>
                                    
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} align="initial">
                            <Divider variant="middle" style={{ marginTop: '1em' }}/>
                        </Grid> */}
                        <Grid item xs={12} style={{ margin: '1em 0em 2em 0em' }}>

                            <Box style={{ background: 'rgb(224 224 224 / 23%)', borderRadius: '10px' }}>
                                <Grid item xs={12} style={{ background: 'transparent' }}>
                                    <Typography variant="h6" style={{ marginLeft: '1em' }}>Latest Post </Typography>
                                </Grid>
                                {latestPost
                                    .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                                    .slice(0, size)
                                    .map((item) => (
                                        <Card key={item.address.current}
                                            sx={{
                                                display: 'flex',
                                                border: 'none',
                                                boxShadow: 'none',
                                                background: 'transparent'
                                            }}
                                        >
                                            <CardMedia
                                                // style={{  }}
                                                // onClick={Scroll}
                                                component="img"
                                                sx={{ padding: '1em 0em 0em 1em', width: 110, height: 82, borderRadius: '10px', }}
                                                image={urlFor(item.mainImage).url()}
                                                alt={item.address}

                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Link style={{ color: 'black', textDecoration: 'none' }} to={`/Blog/${item.address.current}`} onClick={handleScroll} >
                                                        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                                                            {item.title}
                                                        </Typography>
                                                    </Link>

                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        <DateRangeRoundedIcon sx={{ fontSize: 20 }} /> {item.date}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Card>
                                    ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>


            </Box >
            <div>
                <Footer />
            </div>
        </>
    )
};

export default MobBlogDetail;
