import React, { useState, useEffect } from 'react'
import { Paper, Typography, Container, Grid, Button, TextField, Box, TextareaAutosize, InputLabel, IconButton, CircularProgress, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import {  PhotoCamera } from '@mui/icons-material';
import firebase from '../firebase/firebase';

const StyledForm = styled('form')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: 'auto',
  margin: 'auto',
  maxWidth: 1000,
  paddingBottom: 20,
}));


const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: '1em'
}));


const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: 'auto',
  margin: 'auto',
  maxWidth: 1000,
  paddingBottom: 20,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: 'auto',
  margin: 'auto',
  maxWidth: 1000,
  paddingBottom: 20,
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};



const TestimonalsData = (props) => {

  const initialValues = {
    name: '',
    reviewDate: '',
    feedback: '',
    platform: '',
    rating: '',
    houseImage: '',
    clientType: '',
  }
  // const [name, setName] = useState("");
  // const [reviewDate, setReviewDate] = useState("");
  // const [feedback, setFeedBack] = useState("");
  // const [platform, setPlatForm] = useState("");
  // const [rating, setRating] = useState("");
  // const [image, setImage] = useState([]);
  var [values, setValues] = useState(initialValues);
  const [position, setPosition] = useState('')
  const [progress, setProgress] = useState(10);
  const [houseImage, setHouseImage] = useState([]);
  var fireDB = firebase.database().ref();

  // const TodayDate = new Date().toISOString().split('T')[0]

  useEffect(() => {
    if (props.currentId === '') {
      setValues({ ...initialValues })
      setHouseImage([...initialValues.houseImage || ''])

    }
    else {
      setValues({
        ...props.propertyObject[props.currentId]
      })
      setHouseImage([...props.propertyObject[props.currentId].houseImage || ''])
    }
  }, [props.currentId, props.propertyObject])


  const handleInputChange = (e) => {
    var { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleImage = async (e) => {
    if (e.target.files) {
      async function task() {
        const image = e.target.files[0];
        let folderName = 'HouseImages';
        const id = new Date().getTime();
        var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
        var uploadTask = storageRef.put(image);
        uploadTask.on('state_changed', function (snapshot) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        }, function (error) {
          console.log(error);
        },
          async filename => {
            const downloadURL = await firebase
              .storage()
              .ref("HouseImages")
              .child(id + image.name)
              .getDownloadURL();
            setHouseImage([downloadURL]);
          }
        )
      }
      task();
    }
  }

  const deleteImage = (ImageUrl, setter) => {
    let image = firebase.storage().refFromURL(ImageUrl);
    image.delete().then(function () {
      if (setter === setHouseImage) {
        let newHouseImage = houseImage.filter(item => item !== ImageUrl);
        setHouseImage(newHouseImage)
        setPosition('')
        if (props.currentId !== '') {
          fireDB.child(`Testimonials/${props.currentId}`).update({ houseImage: newHouseImage })
        }
        console.log('image deleted', houseImage, newHouseImage);
      }
    })
  }

  const handleFormsubmit = (e) => {
    e.preventDefault();
    props.add({ ...values, houseImage })
    setHouseImage('')
  }

  return (
    <>
      <StyledForm noValidate autoComplete="off" onSubmit={handleFormsubmit} style={{ marginTop: '1em' }}>
        <StyledDiv>
          <StyledPaper>
            <Container>
              {/* <Typography variant="h5"> Testimonials</Typography> */}
              <Grid container spacing={1}>
                <StyledGrid xs={6} align="start">
                  <TextField
                    label="Name"
                    id="filled-size-small"
                    variant="outlined"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </StyledGrid>
                <StyledGrid xs={6}>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      // sx={{ width: 150 }}
                      label="Review Date"
                      name="reviewDate"
                      value={reviewDate}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}
                  <TextField
                    label="Review Date"
                    defaultValue="mm/dd/yyyy"
                    id="filled-size-small"
                    variant="outlined"
                    name="reviewDate"
                    value={values.reviewDate}
                    onChange={handleInputChange}
                  />
                </StyledGrid>
                <StyledGrid xs={6}>
                   <FormControl  fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Platform</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.platform} name="platform"
                      onChange={handleInputChange}
                      label="Platform"
                      variant="outlined"
                    >
                      <MenuItem value={'Facebook'}>Facebook</MenuItem>
                      <MenuItem value={'Zillow'}>Zillow</MenuItem>
                      <MenuItem value={'Google'}>Google</MenuItem>
                      <MenuItem value={'Both'}>Both</MenuItem>
                    </Select>
                  </FormControl>
                </StyledGrid>
                <StyledGrid xs={6}>
                   <TextField
                    type="number"
                    label="Rating"
                    id="filled-size-small"
                    variant="outlined"
                    name="rating"
                    value={values.rating}
                    onChange={handleInputChange}
                  />
                </StyledGrid>
                <StyledGrid xs={6} align="start">
                  <Typography variant="h6"> Image</Typography>
                  <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file"
                    onChange={handleImage} />
                  <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  <div >
                    <Box width="100%" mr={1}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress variant="determinate" value={progress} />
                        </Box>

                      </Box>

                    </Box>
                  </div>
                  <div>
                    {(() => {
                      if (houseImage) {
                        return (houseImage.map((url, index) => (
                          <div key={index}>
                            <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                            <Button variant="contained" onClick={() => { deleteImage(url, setHouseImage); setPosition(index) }}>
                              Delete
                            </Button>
                            {position === index &&
                              <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                            }
                          </div>
                        )))
                      }
                    })()}
                  </div>
                </StyledGrid>
                <StyledGrid xs={6}>
                  <FormControl style={{ marginTop: '1em' }} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Client Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.clientType} name="clientType"
                      onChange={handleInputChange}
                      label="Client Type"
                      variant="outlined"
                    >
                      <MenuItem value={'Buyer'}>Buyer</MenuItem>
                      <MenuItem value={'Seller'}>Seller</MenuItem>
                    </Select>
                  </FormControl>
                </StyledGrid>

                <StyledGrid xs={12}>
                  <Typography variant="h6" align="start">Feedback</Typography>
                  <TextareaAutosize
                    style={{ width: '100%' }}
                    aria-label="minimum height"
                    minRows={10}
                    placeholder="Feedback"
                    value={values.feedback}
                    name="feedback"
                    onChange={handleInputChange}
                    fullWidth
                  />
                </StyledGrid>
              </Grid>
            </Container>
          </StyledPaper>
        </StyledDiv>
        <Button variant="contained" color="inherit" disableElevation type="submit" >
          {props.currentId === '' ? "Save" : "Update"}
        </Button>
      </StyledForm>
    </>
  )
}

export default TestimonalsData