import React from 'react';
import { withPropertyConsumer } from '../context';
import defaultmage from '../images/room-1.jpeg';
import './AvilableHome.css';
import './Availability.css';
import './NavBar/NavBar.css';
import { ButtonBase, Card, Container, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Room from '@material-ui/icons/Room';
import AttachMoney from '@material-ui/icons/AttachMoney';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import NumberFormat from 'react-number-format';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperD: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  papermob: {
    padding: 10,
    margin: 'auto',
  },
  image: {
    width: 128,
    height: 80
  },
  imagemob: {
    width: 80,
    height: 80
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  imgmob: {
    margin: 'auto',
    display: 'block',
    maxHeight: '80px',
    borderRadius: "5px",
    overflow: "hidden",
    height: "100%",
    cursor: 'default',
  },
  gridCenter: {
    alignItems: "center"
  },
  GridSpace: {
    padding: "0px 30px"
  },
  cardGrid: {
    marginTop: "8px",
  },
  textmob1: {
    color: "#3d4152",
    marginTop: "1px",
    fontSize: "1rem",
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 0
  },
  textmob2: {
    width: "100%",
    color: "#7e808c",
    fontSize: ".83rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
  textmob3: {
    color: "#f69022",
    fontSize: ".85rem"
  },
  textAC: {
    color: "#138e22",
    fontSize: ".85rem"
  },
  textPen: {
    color: "#FF5722",
    fontSize: ".85rem"
  },
  textClos: {
    color: "#ff0000",
    fontSize: ".85rem"
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: 900,
    borderRadius: '800px'

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 420,
    fontSize: '15px',
    width: 100,

  },

  searchroot: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 400,

    [theme.breakpoints.down("xs")]: {
      width: "auto",
      borderRadius: '4px'
    },
    // margin: 'auto',
    borderRadius: "30px 0px 0px 30px",
  },
  search_lable: {
    fontWeight: 900,
    fontSize: '15px',
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '80px',
      textOverflow: "ellipsis"
    },
  },
  search_input: {
    fontWeight: 500,

  },
  divpadding: {
    paddingBottom: 10,
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down("md")]: {
      minWidth: 140
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 100
    },
  },

  innerpaper: {
    width: 'fit-content',
    margin: "auto",
    padding: "0px 10px",
  },
  filterPaper: {
    borderRadius: "10px"
  },
  root2: {
    display: 'flex',
    background: "#2979ff",
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer"
  },
  root3: {
    display: 'flex',

    background: 'linear-gradient(45deg, #4fbadafc, #3a85d2cf)',
    borderRadius: 0,
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer"
  },
  details: {
    paddingBottom: 8
  },
  texttwo: {
    fontSize: "1.2rem"
  }
}));


function Citys(props) {
  const classes = useStyles();
  const propertyname = props.propertyname.toLowerCase();
  const communityname = props.community.toLowerCase();

  const refreshPage = () => {
    window.location.reload(false);
  }
  // let avilableproperty = props.Properties;
  // avilableproperty = avilableproperty.filter(house => house.community == props.community)

  var pIname = propertyname || communityname;
  var pname = pIname.split("by")[0];
  var ppname = pname.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');


  return (
    <>
      <Grid item key={props} xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <Link underline='none' to={`New-constructions/${props.slug}`} onClick={refreshPage} style={{ textDecoration: 'none' }} >
            <Paper className={classes.papermob} elevation={0} style={{ background: "#fafafa" }}>
              <Grid container spacing={1} style={{ alignItems: "center" }}>
                <Grid item>
                  <ButtonBase className={classes.imagemob}>
                    <img className={classes.imgmob} alt="complex" src={props.url || defaultmage} />
                    {props.Tag === 'Recommended' && <div className="top-Right_Rec_mob_avb">Recommended</div>}
                  </ButtonBase>
                </Grid>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs style={{ cursor: 'text' }}>
                    <Typography gutterBottom variant="subtitle1" className={classes.textmob1}>
                      {ppname}
                    </Typography>
                    {/* <Typography variant="body2" gutterBottom className={classes.textmob2}>
                 By {props.builder}
                </Typography> */}
                    <Grid item >
                      <Grid item xs container direction="row" style={{ alignItems: "center" }}>
                        <Grid item >
                          <Typography className={classes.textmob3}>
                            <Room color="secondarytext" style={{ width: ".85em", height: ".85em" }} /> {props.city}
                          </Typography>
                        </Grid>
                        <Grid item xs container style={{ justifyContent: "center" }}>
                          <Grid item >
                            <Typography className={classes.textmob3}>
                              <AttachMoney color="secondarytext" style={{ width: ".85em", height: ".85em" }} />
                            </Typography>
                          </Grid >
                          <Grid item >
                            <Typography className={classes.textmob3}>
                              {(() => {
                                if (!props.startprice) {
                                  return (
                                    <>
                                      Not released
                                    </>)
                                }
                                else {
                                  return (
                                    <div>
                                      <NumberFormat value={props.startprice} displayType={'text'} thousandSeparator={true} />
                                    </div>)
                                }
                              })()}
                            </Typography>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid item >
                      <Typography variant="subtitle1">
                        <Link underline='none' to={`/New-constructions/${props.slug}`}  onClick={refreshPage} style={{ textDecoration: 'none', color: "rgb(49 11 255 / 42%)" }} >
                          <ChevronRightRounded />Know More
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Paper>
          </Link>
        </div>


      </Grid>
      {/* ))} */}

    </>
  )
}


const CitysSub = ({ context, props }) => {
  const classes = useStyles();
  const { city } = props;
  const {allHomes} = context;

  let avilableproperty = allHomes;
  avilableproperty = allHomes.filter(house => house.city === props.city)
  // avilableproperty = avilableproperty.filter(house => house.Tag === "Recommended")
  avilableproperty = avilableproperty.filter(house => house.status === "Currently Available")
  avilableproperty = avilableproperty.filter(house => house.propertyname !== props.propertyname)
  // console.log("cittttt", props.city)



  return (
    <div>
      <Container className={classes.cardGrid} maxWidth="md">
        {avilableproperty.length > 0 &&
          <div className={classes.hold}  >
            <div className={classes.details}>
              <Card className={classes.root2} elevation={5}>
                <Typography component="h5" variant="h5" className={classes.texttwo}>
                  Recommended based on your search
                </Typography>
              </Card>
            </div>
          </div>}
        <Grid container spacing={2}>
          {avilableproperty.map((card) => (
            <Citys community={card.community} builder={card.builders}
              startprice={card.startprice} Tag={card.Tag} slug={card.slug}
              propertyname={card.propertyname} city={card.city} url={card.url} />
          ))}
        </Grid>
      </Container>


    </div>

  )
}
export default withPropertyConsumer(CitysSub);
















