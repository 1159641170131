import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { Container, Grid, Card, Skeleton, Stack, Collapse, Button, CardActionArea, CardMedia, Box } from '@mui/material'
import ReactPlayer from 'react-player';
import { PropertyContext } from '../../context'
import { useMediaQuery } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));




const Youtube = () => {

    const classes = useStyles();
    const context = useContext(PropertyContext);
    const { listings, getData } = context;
    const [openImage, setOpenImage] = React.useState(false);
    const isMatch = useMediaQuery('(max-width:600px )');
    const match = window.location.pathname.split('/')[1] === 'StagingsbyDeepti' ? true : false;

    // const options = {
    //     icons: {
    //         downloadIcon: null,
    //     },
    //     buttons: {
    //         showDownloadButton: false
    //     }
    // }

    let listingshouse = listings
    listingshouse = [
        ...listingshouse.filter(house => house.ActiveStatus === "AdminOnly"),
        ...listingshouse.filter(house => house.ActiveStatus === "Active"),
        ...listingshouse.filter(house => house.ActiveStatus === "Pending"),
        ...listingshouse.filter(house => house.ActiveStatus === "Sold")
    ]
    
    const handleClickImage = () => {
        setOpenImage(!openImage);
    };

   
    useEffect(() => {
        if (listingshouse.length === 0) {
            getData()
        }

    }, [])

    return (
        <div>
            {
                isMatch ?
                    <Container className={classes.root} >
                        {
                            listingshouse.length === 0 ?
                                (
                                    <Stack spacing={1} mt={3} mb={3}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                                        {/* For other variants, adjust the size with `width` and `height` */}
                                        <Skeleton variant="circular" width={40} height={40} />
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            width='auto'
                                            height='300px'
                                        />
                                    </Stack>
                                )
                                :
                                <>
                                    {(match === true) ?
                                        <ImageList className={classes.imageList} cols={1.5}>
                                            {listingshouse
                                                .filter(house => house.StagingGallery === "Yes")
                                                .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                                .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                .map((item) => (
                                                    <ImageListItem key={item.MlsId} style={{ width: "auto", height: '100%' }}>
                                                        <ReactPlayer width={250} height={250} url={item.Youtubevideo} controls={true} />
                                                    </ImageListItem>
                                                ))}
                                        </ImageList>
                                        :
                                        <Grid container spacing={2}>
                                            {
                                                listingshouse
                                                    .filter(house => house.StagingGallery === "Yes")
                                                    .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                                    .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                    .slice(0, 6)
                                                    .map((item) => (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <Card key={item.MlsId} style={{ width: '100%', height: '195px', }}>
                                                                <ReactPlayer width="100%" height="100%" url={item.Youtubevideo} controls={true} />
                                                            </Card>
                                                        </Grid>
                                                    ))
                                            }
                                            {
                                                openImage ? null :
                                                    <>
                                                        {listingshouse
                                                            .filter(house => house.StagingGallery === "Yes")
                                                            .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                                            .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                            .slice(6, 7)
                                                            .map((image) => {
                                                                return (
                                                                    <Grid item xs={12} sm={6} md={4}>
                                                                        <div>
                                                                            <Card key={image.MlsId} style={{ width: '100%', height: '195px', }} >
                                                                                <CardActionArea>
                                                                                    <CardMedia
                                                                                        component="img"
                                                                                        image={image.featuredUrl || image.url}
                                                                                        alt="Image"
                                                                                        sx={{ filter: 'brightness(0.5)' }}
                                                                                    />
                                                                                    <Box
                                                                                        sx={{
                                                                                            position: 'absolute',
                                                                                            top: '50%',
                                                                                            left: '50%',
                                                                                            transform: 'translate(-50%, -50%)',
                                                                                        }}
                                                                                    >
                                                                                        <Button variant="outlined" onClick={handleClickImage} sx={{
                                                                                            color: '#fff',
                                                                                            fontSize: 'larger',
                                                                                            '&.MuiButton-outlined': {
                                                                                                borderColor: '#fff',
                                                                                                color: '#fff',
                                                                                                '&:hover': {
                                                                                                    cursor: 'pointer',
                                                                                                    transform: 'scale(1.05)',
                                                                                                    borderColor: '#fff',
                                                                                                    color: '#fff',
                                                                                                },
                                                                                            }
                                                                                        }}>
                                                                                            Load More
                                                                                        </Button>
                                                                                    </Box>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </div>
                                                                    </Grid>
                                                                )
                                                            })}
                                                    </>
                                            }
                                            <Grid container direction="row" spacing={2} sx={{ m: '1em 0em 1em 1em' }} justifyContent="start" alignItems="start">
                                                <Collapse in={openImage} timeout="auto" unmountOnExit sx={{ width: '100%' }} >
                                                    <Grid container spacing={2}>
                                                        {
                                                            listingshouse
                                                                .filter(house => house.StagingGallery === "Yes")
                                                                .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                                                .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                                .slice(6)
                                                                .map((image) => {
                                                                    return (
                                                                        <Grid item xs={12} sm={6} md={4}>
                                                                            <div>
                                                                                <Card key={image.MlsId} style={{ width: '100%', height: '195px', }} >
                                                                                    <ReactPlayer width="100%" height="100%" url={image.Youtubevideo} controls={true} />
                                                                                </Card>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                })}
                                                    </Grid>
                                                </Collapse>
                                            </Grid>
                                        </Grid>
                                    }
                                </>
                        }
                    </Container>
                    :
                    <Container maxWidth="Lg" >
                        {
                            listingshouse.length === 0 ?
                                (<>
                                    <Stack direction="row" spacing={1} >
                                        <Stack>
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                variant="rectangular"
                                                width='400px'
                                                height='300px'
                                            />
                                        </Stack>
                                        <Stack  >
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                variant="rectangular"
                                                width='400px'
                                                height='300px'
                                            />
                                        </Stack>
                                        <Stack  >
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                variant="rectangular"
                                                width='400px'
                                                height='300px'
                                            />
                                        </Stack>
                                    </Stack>
                                </>
                                )
                                :
                                <Grid container spacing={2}>
                                    {
                                        listingshouse
                                            .filter(house => house.StagingGallery === "Yes")
                                            .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                            .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                            .slice(0, 6)
                                            .map((image) => {
                                                return (
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <div>
                                                            <Card key={image.MlsId} style={{ width: '100%', height: '240px', }} >
                                                                <ReactPlayer width="100%" height="100%" url={image.Youtubevideo} controls={true} />
                                                            </Card>
                                                        </div>
                                                    </Grid>
                                                )
                                            })}
                                    {
                                        openImage ? null :
                                            <>
                                                {listingshouse
                                                    .filter(house => house.StagingGallery === "Yes")
                                                    .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                                    .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                    .slice(6, 7)
                                                    .map((image) => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <div>
                                                                    <Card key={image.MlsId} style={{ width: '100%', height: '240px', }} >
                                                                        <CardActionArea>
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={image.featuredUrl || image.url}
                                                                                alt="Image"
                                                                                sx={{ filter: 'brightness(0.5)' }}
                                                                            />
                                                                            <Box
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translate(-50%, -50%)',
                                                                                }}
                                                                            >
                                                                                <Button variant="outlined" onClick={handleClickImage} sx={{
                                                                                    color: '#fff',
                                                                                    fontSize: 'larger',
                                                                                    '&.MuiButton-outlined': {
                                                                                        borderColor: '#fff',
                                                                                        color: '#fff',
                                                                                        '&:hover': {
                                                                                            cursor: 'pointer',
                                                                                            transform: 'scale(1.05)',
                                                                                            borderColor: '#fff',
                                                                                            color: '#fff',
                                                                                        },
                                                                                    }
                                                                                }}>
                                                                                    Load More
                                                                                </Button>
                                                                            </Box>
                                                                        </CardActionArea>
                                                                    </Card>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })}
                                            </>
                                    }
                                    <Grid container direction="row" spacing={2} sx={{ m: '1em 0em 1em 1em' }} justifyContent="start" alignItems="start">
                                        <Collapse in={openImage} timeout="auto" unmountOnExit sx={{ width: '100%' }} >
                                            <Grid container spacing={2}>
                                                {
                                                    listingshouse
                                                        .filter(house => house.StagingGallery === "Yes")
                                                        .filter(house => house.Youtubevideo !== undefined && house.Youtubevideo !== "")
                                                        .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                        .slice(6)
                                                        .map((image) => {
                                                            return (
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <div>
                                                                        <Card key={image.MlsId} style={{ width: '100%', height: '240px', }} >
                                                                            <ReactPlayer width="100%" height="100%" url={image.Youtubevideo} controls={true} />
                                                                        </Card>
                                                                    </div>
                                                                </Grid>
                                                            )
                                                        })}
                                            </Grid>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                        }

                    </Container>
            }
        </div >


    )
}

export default Youtube





