import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { FormControl, Stack } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const BuyerContact = (props) => {
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [date, setDate] = useState()
    const [open1, setOpen1] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const {
        Open,
        Close,
    } = props

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClickError = () => {
        setOpenError(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });

    };



    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }
    const handleEmailSubmit = e => {
        e.preventDefault();
        setProgress(true)
        setLoading(true)
        emailjs.sendForm(
            'service_b9v5hfs',
            'template_h41a2rl',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
                setProgress(false);
                setLoading(false);
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
                setLoading(false);
                setProgress(false);
            });

        e.target.reset()
    }

    return (
        <div>
            <Dialog open={Open} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper" >
                <DialogTitle id="form-dialog-title">Book Your Consultation Call</DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleEmailSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    required
                                    id="Name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="Phone"
                                    name="phone"
                                    label="Phone"
                                    fullWidth
                                    onChange={handleChange}
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                            <TextField
                                type="address"
                                required
                                variant="outlined"
                                fullWidth
                                id="address"
                                label="Address"
                                name="address"
                                autoComplete="address"
                                className="text_contact"
                                value={`${Address}`}
                                onChange={handleChange}
                            />
                        </Grid> */}
                            <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="Consultation Date"
                                            name="date"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} name="date" variant='outlined' />}
                                        />
                                    </LocalizationProvider>
                            </Grid>
                            <Grid item >
                                <TextField
                                    variant="outlined"
                                    id="message"
                                    name="message"
                                    label="Message"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                    multiline
                                    rows={5}
                                />
                            </Grid>
                        </Grid>
                        {/* {progress && <LinearProgress style={{ marginTop: "10px" }} />} */}

                        <DialogActions>
                            <Button onClick={Close} color="primary" >
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={loading}
                                onClick={handleClick({
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                })}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={open}
                            onClose={handleClose}
                            autoHideDuration={3000}
                            key={vertical + horizontal}>
                            <Alert onClose={Close} severity="success">
                                Thank you for getting in touch! We appreciate you contacting us. One of our team members will get back in touch with you soon regarding your consultation call with Deepti. Have a great day!
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openError} autoHideDuration={1000} onClose={Close}>
                            <Alert onClose={Close} severity="error">
                                Please try again!
                            </Alert>
                        </Snackbar>
                    </form >
                </DialogContent>
            </Dialog>

        </div >
    )
}
