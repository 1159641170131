import React, { useEffect } from 'react';
// import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import './Email.css'
// import deepti from '../images/Deepti.png'
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Card } from '@material-ui/core';
import { CardContent, Grid, Typography, Box, OutlinedInput, Button, ButtonGroup, CardActions } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha, styled } from '@mui/material/styles';
import styles from '../components/DatePicker/DatePicker.module.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  addDays,
  addMonths,
  differenceInMonths,
  format,
  isSameDay,
  lastDayOfMonth,
  startOfMonth
} from "date-fns";
// import CallRoundedIcon from '@material-ui/icons/CallRounded';

const ValidationTextField = styled(TextField)({
  '& input:valid + fieldset': {
    borderColor: 'green',
    borderWidth: 2,
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2,
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 6,
    padding: '4px !important', // override inline-style
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  icon: {
    marginTop: 50,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  block: {
    display: 'none',
  },
  root: {
    display: 'flex',
    '& > *': {

      margin: theme.spacing(1),
    },
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function DatePicker({ endDate, selectDate, getSelectedDay, color, labelFormat }) {

  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const firstSection = { marginLeft: '-10px' };
  const startDate = new Date();
  const lastDate = addDays(startDate, endDate || 90);
  const primaryColor = color || 'rgb(54, 105, 238)';
  const selectedStyle = { fontWeight: "bold", width: "50px", height: "55px", borderRadius: `2px`, color: 'white', background: '#0d76d6' };
  const selectedStyle2 = { fontWeight: "bold", width: "50px", height: "55px", borderRadius: `2px`, color: 'black', background: 'lightgrey' };
  const buttonColor = { background: primaryColor };
  const labelColor = { color: '' };


  const getStyles = (day) => {
    if (isSameDay(day, selectedDate)) {
      return (selectedStyle);
    }
    return null
  };

  const getId = (day) => {
    if (isSameDay(day, selectedDate)) {
      return ('selected')
    } else {
      return ("")
    }
  };

  function renderDays() {
    const dayFormat = "E";
    const dateFormat = "d";
    const months = [];
    let days = [];
    for (let i = 0; i <= differenceInMonths(lastDate, startDate); i++) {
      let start, end;
      const month = startOfMonth(addMonths(startDate, i));
      start = i === 0 ? Number(format(startDate, dateFormat)) - 1 : 0;
      end = i === differenceInMonths(lastDate, startDate) ? Number(format(lastDate, "d")) : Number(format(lastDayOfMonth(month), "d"));
      for (let j = start; j < end; j++) {
        days.push(
          <div id={`${getId(addDays(startDate, j))}`}
            className={styles.dateDayItem}
            style={getStyles(addDays(month, j))}
            key={addDays(month, j)}
            onClick={() => onDateClick(addDays(month, j))}
          >
            <div className={styles.dayLabel}>
              {format(addDays(month, j), dayFormat)}
            </div>
            <div className={styles.dateLabel}>
              {format(addDays(month, j), dateFormat)}
            </div>
          </div>
        );
      }
      months.push(
        <div className={styles.monthContainer} key={month}>
          <span className={styles.monthYearLabel} style={labelColor}>
            {format(month, labelFormat || "MMMM yyyy")}
          </span>
          <div className={styles.daysContainer} style={i === 0 ? firstSection : null}>
            {days}
          </div>
        </div>
      );
      days = [];
    }
    return <div id={"container"} className={styles.dateListScrollable}>{months}</div>;
  }

  const onDateClick = day => {
    setSelectedDate(day);
    if (getSelectedDay) {
      getSelectedDay(day);
    }
  };

  useEffect(() => {
    if (getSelectedDay) {
      if (selectDate) {
        getSelectedDay(selectDate);
      } else {
        getSelectedDay(startDate);
      }
    }
  }, []);

  return (
    <div style={{ width: '120%' }}>
      {renderDays()}
    </div>
  )
}

export default function EmailForm(props) {

  const classes = useStyles();
  const [date, setDate] = React.useState(new Date());
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const buttonColor = { background: primaryColor };

  const handleNext = () => {
    const e = document.getElementById('container');
    const width = e ? e.getBoundingClientRect().width : null;
    e.scrollLeft += width - 60;
  }

  const handlePrevious = () => {
    const e = document.getElementById('container');
    const width = e ? e.getBoundingClientRect().width : null;
    e.scrollLeft -= width - 60;
  }

  const selectedDay = (val) => {
    console.log(val)
  };
  const today = new Date()


  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleScroll = () => {
    window.scroll({ behavior: 'smooth', bottom: 0 })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }

  const handleEmailSubmit = e => {
    e.preventDefault();

    setLoading(true)
    emailjs.sendForm(
      'service_b9v5hfs',
      'template_m7yxtzs',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setLoading(false)
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setLoading(false)
      });

    e.target.reset()
  }
  const match = window.location.pathname.split('/')[1] === 'ListingsbyDeepti' ? true : false;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card className="container" elevation={5} rounded >
        <div className="body_area" >
          <h5>Looking to sell your house !
          </h5>
          <form className={classes.form}
            onSubmit={handleEmailSubmit}>
            <TextField
              type="email"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              className="text_contact"
              // value={values.email}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              className="text_contact"
              // value={values.name}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="number"
              label="Number"
              name="number"
              type="number"
              autoComplete="number"
              className="text_contact"
              // value={values.number}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"

              fullWidth
              id="outlined-multiline-static"
              label="Message"
              name="message"
              multiline
              rows={4}
              autoComplete="message"
              className="text_contact"
              // value={values.message}
              onChange={handleChange}
            />
            <TextField
              required
              autoFocus
              id="property"
              name="property"
              label="property"
              fullWidth
              value={`${props.propertyname}`}
              onChange={handleChange}
              className={classes.block}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              disabled={loading}
            // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            >
              Send
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Email has been sent to admin team. They will reach out to you for scheduling in 1-4 hours.
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                Please try again!
              </Alert>
            </Snackbar>
          </form >
        </div>


        {/* <Grid xs={12} align="start" >
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            style={{
              color: '#f69022',
              fontWeight: '500'
            }}>
            Request a Tour Today!
          </Typography>
        </Grid>
        <form className={classes.form}
          onSubmit={handleEmailSubmit}>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <LocationOnIcon style={{ color: 'black' }} />
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: 'white', fontSize: '15px', fontWeight: '700' }}>Community Location</Typography>
              <Typography style={{ fontSize: '13px' }} gutterBottom>{props.address}</Typography>
              <input type='hidden' value={props.address} name="address" />
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" color='primary' style={{ borderRadius: '2px' }} onClick={handleScroll} >Map</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: '1em' }}>
            <Grid item xs="auto">
              <PhoneIcon style={{ color: 'black' }} />
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: 'white', fontSize: '15px', fontWeight: '700' }}>425-588-8470</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: '1em' }}>
            <Grid item xs="auto">
              <AccessTimeFilledIcon style={{ color: 'black' }} />
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: 'white', fontSize: '15px', fontWeight: '700' }}>Hours</Typography>
              <Typography style={{ fontSize: '13px' }} gutterBottom>Everyday 10:00 AM - 9:00 PM</Typography>
            </Grid>

          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& > *': {
                m: 1,
              },
            }}
          >
            <ButtonGroup variant="contained" size="small" aria-label="large button group">
            <ValidationTextField sx={{ width: 150 }} type='button' value="1-on-1" />
            <ValidationTextField type='button' value="Buyer consultation" />
            <Button color="success" sx={{ width: 150 }}>1-on-1</Button>
            <Button color='success' variant="outlined">Buyer consultation</Button>
          </ButtonGroup>
          </Box>
          <div className={styles.container}>
            <div className={styles.buttonWrapper}>
              <Button color='primary' className={styles.button} onClick={handlePrevious}><ChevronLeftIcon /></Button>
            </div>
            <Container maxwidth="md">
            <DatePicker
              getSelectedDay={selectedDay}
              selectDate={today}
              labelFormat={"MMMM"}
              color={"#374e8c"}
            />
            </Container>
            
            <div className={styles.buttonWrapper}>
              <Button color='primary' className={styles.button}  onClick={handleNext} ><ChevronRightIcon /></Button>
            </div>
          </div>
          <Grid container spacing={1} style={{marginTop: '1em'}}>
            <Grid
              item
              xs='auto'
              style={{
                marginTop: '3.5em',
                marginLeft: '1.3em',
                width: '35px',
                height: '35px',
                borderRadius: '50%',
                border: 'none',
                background: 'aliceblue',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >

              <Button
                style={{ color: '#f68f4a',  marginLeft: '-1.8em', marginTop: '-1em'  }}
                onClick={handlePrevious}
              >
                <ChevronLeftIcon style={{fontSize: '2.5em'}}/>
              </Button>

            </Grid>
            <Grid item xs={6}>
              <DatePicker
                getSelectedDay={selectedDay}
                selectDate={today}
                labelFormat={"MMMM"}
                color={"#374e8c"}
              />
            </Grid>
            <Grid item xs='auto'
              style={{
                marginTop: '3.5em',
                marginLeft: '2.3em',
                borderRadius: '50%',
                width: '35px',
                height: '35px',
                border: 'none',
                background: 'aliceblue',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >

              <Button
                style={{ color: '#f68f4a',  marginLeft: '-1.8em', marginTop: '-1em'  }}
                onClick={handleNext}>
                <ChevronRightIcon style={{fontSize: '2.5em'}} />
              </Button>


            </Grid>

          </Grid>

          <Button
            type="submit"
            style={{ backgroundColor: '#f50057', marginTop: '2em' }}
            variant="contained"
            size="large"
            fullWidth
            disabled={loading}
          >
            Request a tour
          </Button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Email has been sent to admin team. They will reach out to you for scheduling in 1-4 hours.
            </Alert>
          </Snackbar>
          <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              Please try again!
            </Alert>
          </Snackbar>
        </form> */}

      </Card>
    </Container>
  );
}


