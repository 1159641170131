import React from 'react'
import { Box, Button, Card, CardMedia, Container, CssBaseline, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, OutlinedInput, Paper, Select, styled, TextField, Typography, useMediaQuery } from '@mui/material'
import banner22 from '../images/Bannermob13.png';
import { PhotoCamera } from '@material-ui/icons';
import shortid from 'shortid';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CustomBanner = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 34,
    paddingTop: 60
}))

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'grey',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'grey',
        },
    },

})


const RentalInputs = () => {
    const isMatch = useMediaQuery('(max-width:600px )');
    const [startPrice, setStartPrice] = React.useState([]);
    const [endPrice, setEndPrice] = React.useState([]);
    const [imageFile, setImageFile] = React.useState(null);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [uploadedImage, setUploadedImage] = React.useState([]);

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setImageFile(file);
    //     setUploadedImage(null);
    //     setUploadProgress(0); // Reset progress when selecting a new file

    //     // Start the upload process when a file is selected
    //     if (file) {
    //         uploadImage(file);
    //     }
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Assuming you're only allowing single file uploads
        setImageFile(file);
        setUploadedImage(null);
        setUploadProgress(0);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                const fileName = file.name;
                const fileType = file.type;
                const uniqueIdentifier = shortid.generate(); // Generate a unique identifier as per your requirement
                const fileData = [[uniqueIdentifier, fileName, fileType]];
                const formattedImageValue = JSON.stringify(fileData);
                uploadImage(formattedImageValue);
            };
            reader.readAsDataURL(file);
        }
    };

    const uploadImage = (file) => {
        // Simulate upload progress
        const uploadInterval = setInterval(() => {
            setUploadProgress((prevProgress) => (prevProgress < 95 ? prevProgress + 5 : prevProgress));
        }, 500);

        // Simulate upload process (replace this with actual upload logic)
        setTimeout(() => {
            clearInterval(uploadInterval);
            setUploadProgress(100);
            setUploadedImage(file);
        }, 3000);
    };
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };
    const handleChangeStartPrice = (event) => {
        const {
            target: { value },
        } = event;
        setStartPrice(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeEndPrice = (event) => {
        const {
            target: { value },
        } = event;
        setEndPrice(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    return (
        <>
            <Box sx={{ margin: 0, background: "white" }}>
                <CustomBanner >
                    <Container sx={{ marginTop: "10px", }} maxWidth="md">
                        <div className="BAnner_text_Nav">
                            <h3>Rental Information </h3>
                        </div>
                    </Container>
                </CustomBanner >
                <Box sx={{ margin: 0, background: "white" }}>
                    <Container component="main" maxWidth={isMatch ? 'xs' : 'md'} sx={{ p: isMatch ? '10px' : '1em' }}>
                        <CssBaseline />
                        <Card sx={{ padding: isMatch ? '10px' : '14px' }}>
                            <Grid xs={12}>
                                <Typography sx={{ fontSize: '1.8em', fontWeight: 500 }}>Deepti Gupta Real Estate</Typography>
                            </Grid>
                            <Divider sx={{ marginTop: '10px' }} />
                            <Grid xs={12} sx={{ marginTop: '10px' }}>
                                <Typography color="primary" sx={{ fontSize: '1em', fontWeight: 400 }}>Please fill in the following contact details as it will help us to serve you better.</Typography>
                            </Grid>
                            <form action='https://docs.google.com/forms/u/0/d/e/1FAIpQLSdva7cc_IRmYaTiJmaCu8LVOn9jYvn6wUV3iOfz5e76Nr4T-Q/formResponse'>
                                <Grid container spacing={1}>
                                    <Grid item xs={isMatch ? 12 : 6} >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Legal First Name"
                                            name='entry.72105073'
                                            autoComplete="name"
                                            className="text_contact"
                                            fontColor="black"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="name"
                                            label="Legal Middle Name"
                                            name='entry.104347865'
                                            autoComplete="name"
                                            className="text_contact"

                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Legal Last Name"
                                            name='entry.692747134'
                                            autoComplete="name"
                                            className="text_contact"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} style={{ marginTop: '1em' }} >
                                        <CustomTextField
                                            required
                                            variant="outlined"
                                            id="email"
                                            name='emailAddress'
                                            label="Email ID"
                                            type="email"
                                            className="text_contact"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="number"
                                            label="Mobile Number"
                                            name='entry.1271919662'
                                            type="number"
                                            autoComplete="number"
                                            className="text_contact"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 12} >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Rental Address"
                                            name='entry.1099950163'
                                            autoComplete="name"
                                            className="text_contact"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 12}  >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Home Owner's Address"
                                            name='entry.449919287'
                                            autoComplete="name"
                                            className="text_contact"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 12}  >
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            rows={4}
                                            required
                                            fullWidth
                                            id="name"
                                            label="Lease Terms"
                                            name='entry.1975982279'
                                            autoComplete="name"
                                            className="text_contact"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid container spacing={1} sx={{ background: 'lightgrey', margin: '10px -2px', borderRadius: '5px' }}>
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <Typography>Price Range</Typography>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-simple-select">Starting Price</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='entry.1848342336'
                                                value={startPrice}
                                                onChange={handleChangeStartPrice}
                                                input={<OutlinedInput label="Starting Price" />}
                                                MenuProps={MenuProps}
                                            >
                                                {Price.map((item, index) => (
                                                    <MenuItem key={index} value={item}  >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} sx={{ mt: isMatch ? 'null' : '1.5em' }}>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-simple-select">Ending Price</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='entry.161772822'
                                                value={endPrice}
                                                onChange={handleChangeEndPrice}
                                                input={<OutlinedInput label="Ending Price" />}
                                                MenuProps={MenuProps}
                                            >
                                                {Price.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: '1em' }}>
                                    <Typography>Upload Rental Home Image</Typography>
                                    <input id="fileInput" type="file" accept="image/*"  multiple onChange={handleFileChange} style={{ display: 'none' }} />
                                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={openFileDialog}>
                                        <PhotoCamera />
                                    </IconButton>
                                    <CustomTextField
                                        label="Upload your file"
                                        variant="outlined"
                                        fullWidth
                                        name='entry.146605135'
                                        value={uploadedImage}
                                    />
                                </Grid>
                                {/* <Grid item xs={isMatch ? 12 : 6} sx={{ mt: '1em' }}>
                                    {uploadProgress > 0 && uploadProgress < 100 && <LinearProgress variant="determinate" value={uploadProgress} />}
                                    {uploadedImage && (
                                        <Card>
                                            <CardMedia component="img" image={URL.createObjectURL(uploadedImage)} alt="Uploaded" width="100px" height="80px" />
                                        </Card>
                                    )}
                                </Grid> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            </form>
                        </Card>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default RentalInputs


const Price = [
    '$ 100,000',
    '$ 200,000',
    '$ 300,000',
    '$ 400,000',
    '$ 500,000',
    '$ 600,000',
    '$ 700,000',
    '$ 800,000',
    '$ 900,000',
    '$ 1,000,000',
    '$ 1,100,000',
    '$ 1,200,000',
    '$ 1,300,000',
    '$ 1,400,000',
    '$ 1,500,000',
    '$ 1,600,000',
    '$ 1,700,000',
    '$ 1,800,000',
    '$ 1,900,000',
    '$ 2,000,000',
    '$ 2,100,000',
    '$ 2,200,000',
    '$ 2,300,000',
    '$ 2,400,000',
    '$ 2,500,000',
    '$ 2,600,000',
    '$ 2,700,000',
    '$ 2,800,000',
    '$ 2,900,000',
    '$ 3,000,000',
    '$ 3,100,000',
    '$ 3,200,000',
    '$ 3,300,000',
    '$ 3,400,000',
    '$ 3,500,000',
    '$ 3,600,000',
    '$ 3,700,000',
    '$ 3,800,000',
    '$ 3,900,000',
    '$ 4,000,000',
    '$ 4,100,000',
    '$ 4,200,000',
    '$ 4,300,000',
    '$ 4,400,000',
    '$ 4,500,000',
    '$ 4,600,000',
    '$ 4,700,000',
    '$ 4,800,000',
    '$ 4,900,000',
    '$ 5,000,000',
]
