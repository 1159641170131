import React, { useEffect } from 'react';
import PropertyFilter from './PropertyFilter';
import PropertyList from './PropertyList';
import { withPropertyConsumer } from '../context';
import Loading from './Loading';
import './FeaturedListing.css';
import Footer from '../components/Footer';
import Filter from './Mobile/Filter.js';
import Box from '@material-ui/core/Box';
import AvaliableCards from './Cards/AvaliableCards';
import PopularCommunities from './Cards/PopularCommunities';
import Stack from '@mui/material/Stack';
import ReactPaginate from "react-paginate";
import './Pagination.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMediaQuery } from '@mui/material';
import UpcomingCard from './Mobile/UpcomingCard';
import WishListed from './Cards/WishListed';


function PropertyContainer({ context }) {
  const { loading, sortedProperty, allHomes, handlePageSelect, page, max, offset } = context;
  const [currentPage, setCurrentPage] = React.useState(0)
  const isMatch = useMediaQuery('(max-width:600px )')
  const popularCommunity = allHomes.filter(item => item.builders !== "Lennar").filter(item => item.HighlightTag === "Highlight");

  let sortLennar = sortedProperty.filter(item => item.Sold !== "Hide").filter(item => item.builders !== "Lennar")
  let Recommended = [
    ...sortLennar.filter(house => house.status === "Currently Available").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
    ...sortLennar.filter(house => house.status === "Coming Soon").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
    ...sortLennar.filter(house => house.status === "Currently Sold Out").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
    ...sortLennar.filter(house => house.status === "Sold Out").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
  ]
  // Recommended = [
  //   ...Recommended.filter(house => house.Tag === "Recommended"),
  //   ...Recommended.filter(house => house.status !== "Recommended"),
  //   // ...Recommended.filter(house => house.status === "Currently Sold Out"),
  //   // ...Recommended.filter(house => house.status === "Sold Out")
  // ]
  let TotalCommunity = Recommended.length;
  let sortedCommunity = 15;


  // const totalPages = Math.ceil(TotalCommunity / max);
  const totalPages = Math.ceil(Recommended.length / max);
  const totalPages2 = Math.ceil(TotalCommunity / sortedCommunity);
  console.log('tp', totalPages)
  const numberOfCommunities = currentPage * max;
  const numberOfCommunities2 = currentPage * sortedCommunity;
  const numberOfCommunities3 = numberOfCommunities2 - sortedCommunity;

  const CurrentCommunities = Recommended
    .slice(
      numberOfCommunities,
      numberOfCommunities + max
    )

  const handlePageChange = (page) => {
    setCurrentPage(page.selected)
    window.scrollTo({
      top: 10,
      behavior: 'smooth'
    });

  }

  // console.log("available", sortedProperty.filter(house  => house.status === "Currently Available"))

  if (loading) {
    return <Loading />;
  }
  return (
    <div style={{ "margin": "0" }} >
      {/* <div Style="min-width:1347px"> */}
      <div style={{ "margin": "0" }}>
        {/* <Banner/> */}
        <Box display={{ xs: 'block', sm: 'none' }}  >
          {/* <Navbar/> */}
          <Filter Property={allHomes} />
          {/* <AvaliableCards /> */}
          {/* <UpcomingCard/> */}
          <PopularCommunities Popular={popularCommunity} />
        </Box>
        <Box display={{ xs: 'none', sm: 'block' }} m={1} style={{ "margin": "0" }} >
          {/* <Banner/> */}
          <PropertyFilter Property={allHomes} />
          {/* <AvaliableCards /> */}
          {/* <WishListed CurrentData={CurrentCommunities}/> */}
          <PopularCommunities Popular={popularCommunity} />
        </Box>
        <div >
          <PropertyList Property={sortedProperty} CurrentData={CurrentCommunities} />
          <Stack spacing={2} sx={{ p: isMatch ? '1em 2em 1em 0em' : '1em' }}>


            <ReactPaginate
              previousLabel={<KeyboardArrowLeftIcon />}
              nextLabel={<KeyboardArrowRightIcon />}
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName={"navigationButtons"}
              previousLinkClassName={"previousButton"}
              nextLinkClassName={"nextButton"}
              //   disabledClassName={"navigationDisabled"}
              activeClassName={"navigationActive"}
              marginPagesDisplayed={isMatch ? 1 : 3}
              pageRangeDisplayed={isMatch ? 1 : 3}
            />



          </Stack >


        </div>

        <Footer />
      </div>
    </div>
  );
}
export default withPropertyConsumer(PropertyContainer)
