import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import emailjs from 'emailjs-com';
import MuiAlert from '@material-ui/lab/Alert';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Container from '@material-ui/core/Container';
import { Card } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ButtonBase, Typography, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { PhoneAndroid } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  block: {
    display: 'none',
  },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function DailogForm(props) {

  const classes = useStyles();
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [date, setDate] = useState()
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    Open,
    Close,
    property,
    Facing,
    AvName
  } = props



  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }
  const handleEmailSubmit = e => {
    e.preventDefault();
    setProgress(true)
    setLoading(true)
    emailjs.sendForm(
      'gmail-A',
      'template_n577qqi',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setProgress(false);
        setLoading(false);
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setLoading(false);
        setProgress(false);
      });

    e.target.reset()
  }

  return (
    <div>
      <Dialog open={Open} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper" >
        <DialogTitle id="form-dialog-title">Book Your Tour</DialogTitle>

        <DialogContent dividers>
          {/* <Typography style={{ fontSize: 15, fontWeight: 500 }}>
            Please fill ou details and detailed message.Our Team wil get back to you in 24 hours.
          </Typography> */}
          <DialogContentText style={{ fontSize: 15, fontWeight: 500 }}>
            Our Team will get back to you in 24 hours. Please call or message Deepti on
            {/* <PhoneAndroid color="primary"  /> */}
            <Chip style={{ fontSize: '14px' }} icon={<PhoneAndroid />} label="425-588-8470" variant="outlined" color="primary" />
            &nbsp;for quick response.
          </DialogContentText>
          {AvName ?
            <DialogContentText style={{ fontSize: 15, color: "#ff0000b8", fontWeight: 400 }}>
              Property Name / Lot : {property + ',' + AvName}
            </DialogContentText>
            :
            <DialogContentText style={{ fontSize: 15, color: "#ff0000b8", fontWeight: 400 }}>
              Property Name / Lot : {property}
            </DialogContentText>
          }
          <form onSubmit={handleEmailSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  variant="outlined"
                  required
                  id="Name"
                  name="name"
                  label="Name"
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  id="Phone"
                  name="phone"
                  label="Phone"
                  fullWidth
                  onChange={handleChange}
                />

              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  id="address"
                  name="address"
                  label="Address"
                  type="address"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="outlined"
                    format="MM/dd/yyyy"
                    fullWidth
                    label="Date"
                    name="tour"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item >
                <TextField
                  id="message"
                  variant="outlined"
                  name="message"
                  label="Message"
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleChange}
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  autoFocus
                  id="property"
                  name="property"
                  label="property"
                  fullWidth
                  value={`${AvName ? property + ',' + AvName + ',' + Facing :  property }`}
                  onChange={handleChange}
                  className={classes.block}
                />
              </Grid>
            </Grid>
            {progress && <LinearProgress style={{ marginTop: "10px" }} />}
            <Snackbar open={open} onClose={Close}>
              <Alert onClose={Close} severity="success">
                Email has been sent to admin team. They will reach out to you shortly with the scheduling details. Thank you.
              </Alert>
            </Snackbar>
            <Snackbar open={openError} onClose={Close}>
              <Alert onClose={Close} severity="error">
                Please try again!
              </Alert>
            </Snackbar>
            <DialogActions>
              <Button onClick={Close} color="primary">
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={loading}
              >
                Submit
              </Button>
            </DialogActions>
          </form >
        </DialogContent>
      </Dialog>
    </div>
  );
}