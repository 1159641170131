import React from 'react';
import House from './House';
import House2 from './House2';
// import FeaturedListing from './FeaturedListing';
// import Alert from '@material-ui/lab/Alert';
import image from '../images/no-data6.png';
import Box from '@material-ui/core/Box';
import './Filter.css';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import './HouseMobile.css'
import Container from '@material-ui/core/Container';

import './House.css';
import Address from '../contact/Address';
import BookConsultation from './Cards/BookConsultation';
import UpcomingCard from './Mobile/UpcomingCard';

// import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
    root: {
        width: "100%",
        borderRadius: "10px"
    },
    media: {
        height: 180,
    },
});
// const useStyles = makeStyles((theme) => ({
//    contain:{

//    }
//   }));


export default function PropertyList({ Property, CurrentData }) {
    const classes = useStyles();

    let allProperty = CurrentData;

   
    // const sortedProperties = allProperty.sort((a, b) => {
    //     const statusOrder = {
    //       'Currently Available': 0,
    //       'Coming Soon': 1,
    //       'Currently Sold Out': 2,
    //       'Sold Out': 3,
    //     };
    //     return statusOrder[a.status] - statusOrder[b.status];
    //   });


    if (allProperty.length === 0) {
        return (
            <>
                <div className="no_property">
                    <img width="100%" height="400" src={image} alt="No data" />
                </div>
                {/* <Alert severity="warning"  variant="filled">No property found! <strong>Try with other keywods.</strong></Alert>  */}

                {/* <h1> no property found </h1> */}
                {/* <FeaturedListing/> */}
            </>
        )
    }

    return (
        <div>
            <Box display={{ xs: 'none', md: 'block' }} m={1} >
                <Container style={{ padding: '0px 50px 40px 60px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>

                            {allProperty.map(item => {
                                return <House key={item.id} home={item} />
                            })}

                        </Grid>
                        <Grid item xs={3}>
                            <BookConsultation />
                            {/* <Address /> */}
                        </Grid>
                    </Grid>
                </Container>



                {/* <Container className={classes.cardGrid} maxWidth="md">
                    {Property.filter(item => item.builders !== "Lennar").map(item => {
                        return <House key={item.id} home={item} />
                    })}
                </Container> */}


            </Box>

            <Box display={{ xs: 'block', md: 'none' }} m={1}>
                {/* <House2 key={item.id} home={item}/> */}

                <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <UpcomingCard />
                    </Grid> */}

                    {allProperty.map((item) => (
                        <Grid item key={item} xs={12} sm={6} md={4}>

                            <House2 key={item.id} home={item} />
                        </Grid>

                    ))}
                    <Grid item xs={12} sm={6} md={4}>
                        <BookConsultation />
                        {/* <Address /> */}
                    </Grid>
                </Grid>

            </Box>


        </div >
    );
}






