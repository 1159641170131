import React, { useContext } from 'react'
import { Box, Breadcrumbs, Button, Card, CardMedia, Container, Grid, Paper, Tabs, tabsClasses, Link, Typography, useTheme, styled, Chip, useMediaQuery, Stack, Divider, List, ListItemButton, Collapse, ListItemText, CardContent, CardHeader, CardActions } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { PropertyContext } from '../context';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import SwipeableViews from 'react-swipeable-views';
import whiteImage from '../images/Image_not_available.png';
import { ExpandLess, ExpandMore, NavigateNext } from '@mui/icons-material';
import Footer from '../components/Footer';
import RelatedDeals from './RelatedDeals';


const CustomChip = styled(Chip)(({ theme, isMatch }) => ({
    order: 0,
    padding: 0,
    verticalAlign: 'middle',
    boxSizing: 'border-box',
    zIndex: 11,
    left: isMatch ? 'none' : '50.7%',
    top: isMatch ? '12em' : '11em',
    position: 'absolute',
    color: '#fff',
    paddingLeft: '3px',
    paddingRight: '3px',
    borderRadius: 0,
    fontWeight: 600,
    fontSize: '10px',
    background: '#4BB4B4',
    marginTop: '10px'
}))

const CustomChip1 = styled(Chip)(({ theme, isMatch }) => ({
    order: 0,
    padding: 0,
    verticalAlign: 'middle',
    boxSizing: 'border-box',
    zIndex: 11,
    marginTop: '10px',
    color: '#fff',
    paddingLeft: '3px',
    paddingRight: '3px',
    borderRadius: 0,
    fontWeight: 600,
    fontSize: '10px',
    background: '#4BB4B4',
}))

const StyledBox = styled(Box)(({ theme }) => ({
    lineHeight: 1.5,
    textTransform: 'none',
    whiteSpace: 'normal',
    color: '#fff',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

const CustomBox = styled(Box)(({ theme }) => ({
    width: '40px',
    height: '42px',
    zIndex: 11,
    fontSize: '12px',
    background: '#f50057',
}))

const CustomBox1 = styled(Box)(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: '0px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '20px solid #f50057',
}))

const CustomBox2 = styled(Box)(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: '0px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '20px solid #f50057',
}))


const ImageSlide = ({ url, isMatch }) => {
    const { gallery } = url
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [value, setValue] = React.useState(0);

    console.log("url test", url)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }
    return (
        <>
            <Card elevation={isMatch ? 3 : 5} sx={{ width: isMatch ? '96%' : '520px', mt: '1em', height: isMatch ? 'auto' : 'none' }}>
                <div >
                    <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >{(() => {
                                if (gallery)
                                    return gallery.map((item, index) => (
                                        <div key={gallery}>
                                            {Math.abs(activeStep - index) <= 20 ? (
                                                <img style={{
                                                    height: isMatch ? '320px' : "430px",
                                                    display: 'block',
                                                    overflow: 'hidden',
                                                    width: isMatch ? '100%' : '100%',
                                                }} src={item} alt={item} />
                                            ) : null}

                                        </div>
                                    ))
                                else {
                                    return <div >
                                        <img style={{
                                            height: "430px",
                                            display: 'block',
                                            overflow: 'hidden',
                                            width: '100%',
                                        }} src={whiteImage} alt="Image" />

                                    </div>
                                }

                            })()}

                            </SwipeableViews>
                        </SRLWrapper>


                    </SimpleReactLightbox>
                </div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="visible arrows tabs example"
                    textColor="inherit"
                    indicatorColor='none'
                    sx={{
                        width: 'auto',
                        backgroundColor: 'rgb(224,224,224)',
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                            color: '#000'
                        },
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {(() => {
                            if (gallery)
                                return gallery.map((item, index) => (
                                    <div key={gallery}>
                                        <Paper
                                            key={index}
                                            elevation={isMatch ? 2 : 3}
                                            style={{
                                                margin: '0.5rem',
                                                width: '60px',
                                                height: '60px',
                                                cursor: 'pointer',
                                                border: index === activeStep ? '2px solid blue' : 'none',
                                            }}
                                            onClick={() => handleStepChange(index)}
                                        >
                                            <img src={item} alt={`Thumbnail ${index}`} width="100%" />
                                        </Paper>
                                    </div>
                                ))
                            else {
                                return <div >
                                    <img style={{
                                        width: '60px',
                                        height: '60px',
                                        display: 'block',
                                        overflow: 'hidden',
                                    }} src={whiteImage} alt="Image" />

                                </div>
                            }

                        })()}


                    </div>
                </Tabs>
            </Card>
        </>
    )
}


const Dealsdp = () => {
    const context = useContext(PropertyContext);
    const { homedeals, DealsData } = context;
    const { dealSlug } = useParams();
    const isMatch = useMediaQuery('(max-width:480px)');
    const history = useNavigate();
    const SingleDeal = homedeals.filter(item => item.dealSlug === dealSlug);
    const SingleDeal1 = SingleDeal.map(item => item.categories);
    const [open, setOpen] = React.useState(true);
    const [open1, setOpen1] = React.useState(true);

    let soldListings = homedeals;

    soldListings = [
        ...soldListings.filter(house => house.dealSlug !== dealSlug).filter(house => house.categories === `${SingleDeal1}`).filter((item) => item.IsVisible === "Yes"),
    ]
    console.log('category', SingleDeal1)
    const handleClick = () => {
        setOpen(!open);
    };

    const handleClick1 = () => {
        setOpen1(!open1);
    };

    React.useEffect(() => {
        if (homedeals.length === 0) {
            DealsData()
        }

    }, [])

    console.log('deal', SingleDeal.map((item) => item))

    if (homedeals.length === 0) {
        return <Loading />
    }


    return (
        <SimpleReactLightbox>
            <Box display={{ xs: isMatch ? 'block' : 'none', sm: isMatch ? 'none' : 'block' }} m={1}  >
                <Container maxWidth={isMatch ? 'md' : 'lg'} >
                    {
                        SingleDeal && SingleDeal.map((item) => {
                            return (
                                <>
                                    {
                                        isMatch ?
                                            <div style={{ paddingBottom: "20px" }}>
                                                <Card sx={{ mt: "5em", pl: '1em', borderRadius: "20px" }} elevation={0} >
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                                            {(item.rating) === '' ? null : <CustomChip label={`${item.rating} % off`} isMatch={isMatch} />}
                                                            <Typography sx={{
                                                                color: '#4b4b4be0',
                                                                fontWeight: 500,
                                                                fontSize: '16px',
                                                                mt: '2.5em'
                                                            }}>{item.name}</Typography>
                                                            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" sx={{ mt: '5px' }}>
                                                                <Link underline='none' color="inherit" href='/' >
                                                                    Home
                                                                </Link>
                                                                <Link underline='none' color="inherit" href='/Deals'>
                                                                    Deals
                                                                </Link>
                                                                <Typography color="textPrimary">{item.name}</Typography>
                                                            </Breadcrumbs>
                                                            <Stack display="flex" flexDirection="row" sx={{ mt: '5px' }}>
                                                                <Stack display="flex" flexDirection="column" justifyContent="start" alignItems="start" >
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '15px',
                                                                        color: '#388e3c'
                                                                    }}>Price</Typography>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '16px',
                                                                    }}>$ {item.startprice}</Typography>
                                                                </Stack>
                                                                <Stack display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ ml: '3em' }} >
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '15px',
                                                                        color: '#f50057'
                                                                    }}>Final Price</Typography>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '16px',
                                                                    }}>$ {item.finalprice}  {(item.shipping) === "Free" ? '+ Free Shipping' : item.shipping}</Typography>
                                                                </Stack>

                                                            </Stack>
                                                            {(item.shipping) === '' ? null :
                                                                <Stack display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ mt: '1em' }}>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '15px',
                                                                        color: '#02367d',
                                                                    }}>Shipping</Typography>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '16px',
                                                                    }}> {item.shipping}</Typography>
                                                                </Stack>
                                                            }
                                                            <ImageSlide url={item} style={{ borderRadius: "20px 0px 0px 20px", height: isMatch ? 150 : 300 }} isMatch={isMatch} />
                                                        </Grid>
                                                        <Grid xs={12} sx={{ mt: '2em' }}>
                                                            <Divider sx={{ width: '538px' }} />
                                                            <Stack display="flex" flexDirection="row" justifyContent="start" alignItems="start">
                                                                <Typography sx={{
                                                                    fontSize: '16px', // Change font size
                                                                    fontWeight: '500',
                                                                    pt: '10px',

                                                                }}
                                                                >
                                                                    Category :
                                                                </Typography>
                                                                <Typography sx={{ fontSize: '15px', color: '#0000008a', pt: '10px', pl: '10px' }}>
                                                                    {item.categories}
                                                                </Typography>
                                                            </Stack>
                                                            <Typography sx={{
                                                                fontSize: '15px', // Change font size
                                                                fontWeight: '500',
                                                                pt: '10px'
                                                            }}
                                                            >
                                                                Highlights
                                                            </Typography>
                                                            <ul>
                                                                <Typography sx={{ fontSize: '14px', color: '#0000008a', pt: '10px' }}>
                                                                    {(item.details.split(';')).map((item, index) => {
                                                                        return (<li key={index}>{item}</li>)
                                                                    })}
                                                                </Typography>
                                                            </ul>
                                                            <Link underline='none' href={`${item.websitelink}`} target="_blank" rel="noopener">
                                                                <Button
                                                                    variant='contained'
                                                                    color='info'
                                                                    sx={{ width: '100%',p: '1em', m: '2em 0em',fontSize: '14px', fontWeight: 500, }}>
                                                                    Get this Deal
                                                                </Button>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                                <Paper elevation={5} sx={{ m: '1em', borderRadius: '10px' }}>
                                                    <List
                                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                    >
                                                        <ListItemButton onClick={handleClick}>
                                                            <ListItemText sx={{
                                                                '& .MuiListItemText-primary': {
                                                                    fontSize: '18px', // Change font size
                                                                    fontWeight: '500',
                                                                }
                                                            }}
                                                                primary="Product Details" />
                                                            {open ? <ExpandLess fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} /> : <ExpandMore fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} />}
                                                        </ListItemButton>
                                                        <Divider sx={{ m: 1 }} />
                                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                                            <Grid container spacing={1} sx={{ p: '0em 2em' }}>
                                                                <Grid xs={12} sx={{ mt: '10px' }}>
                                                                    <Typography sx={{ fontSize: '14px', color: '#0000008a', pt: '10px' }}>
                                                                        {item.description}
                                                                    </Typography>
                                                                    <ul>
                                                                        <Typography sx={{ fontSize: '14px', pt: '10px' }}>
                                                                            {(item.productInfo.split(';')).map((item, index) => {
                                                                                return (<li style={{ marginTop: '10px' }} key={index}>{item}</li>)
                                                                            })}
                                                                        </Typography>
                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                        </Collapse>
                                                        <ListItemButton onClick={handleClick1} sx={{ mt: '10px' }}>
                                                            <ListItemText sx={{
                                                                '& .MuiListItemText-primary': {
                                                                    fontSize: '18px', // Change font size
                                                                    fontWeight: '500',
                                                                }
                                                            }}
                                                                primary="More from this category"
                                                                secondary={`Category - ${item.categories}`} />
                                                            {open ? <ExpandLess fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} /> : <ExpandMore fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} />}
                                                        </ListItemButton>
                                                        <Divider sx={{ m: 1 }} />
                                                        <Collapse in={open1} timeout="auto" unmountOnExit>
                                                            <Box display={{ xs: isMatch ? 'block' : 'none', sm: isMatch ? 'none' : 'block' }} m={1}  >
                                                                <Grid container spacing={1} sx={{ display: 'flex', overflowX: 'auto' }}>
                                                                    {
                                                                        soldListings.map((item) => {
                                                                            return (
                                                                                <Grid item key={item.name} xs={12} sm={6} md={6} sx={{ mt: '1em', mb: '1em' }}>
                                                                                    <Card sx={{
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        paddingBottom: 0,
                                                                                        borderRadius: "10px"
                                                                                    }}>

                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            alt={item.name}
                                                                                            sx={{
                                                                                                height: '150px',
                                                                                                backgroundSize: 'cover',
                                                                                                backgroundRepeat: 'norepeat',
                                                                                                backgroundPosition: 'center center',
                                                                                                transition: 'transform 0.3s, opacity 0.3s',
                                                                                            }}
                                                                                            image={item.houseImage}
                                                                                            src={item.categories}
                                                                                        />
                                                                                        {(item.rating) === '' ? null : <div style={{ position: 'absolute', }}><CustomChip1 label={`${item.rating} % off`} /></div>}
                                                                                        <CardContent>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography sx={{ fontSize: '13px', color: '#373F50', fontWeight: 500 }}>
                                                                                                    {item.name}
                                                                                                </Typography>
                                                                                                <Divider sx={{ mt: '10px' }} />
                                                                                                <Stack spacing={0.5} display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ p: '10px' }}>
                                                                                                    <Typography sx={{ fontSize: '13px', }}>
                                                                                                        Price: $ {item.startprice}
                                                                                                    </Typography>
                                                                                                    {(item.rating) === '' ? null : <Typography sx={{ fontSize: '13px', }}>
                                                                                                        Discount:  Reflected in sale price
                                                                                                    </Typography>}
                                                                                                    {(item.shipping) === '' ? null : <Typography sx={{ fontSize: '13px', }}>
                                                                                                        Shipping:  {item.shipping}
                                                                                                    </Typography>}
                                                                                                    <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
                                                                                                        Final Price:  $ {item.finalprice}  {(item.shipping) === "Free" ? '+ Free Shipping' : item.shipping}
                                                                                                    </Typography>
                                                                                                </Stack>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                        {(item.IsNew) === "Recommended" ?
                                                                                            <div style={{ position: 'absolute', right: '15px' }}>
                                                                                                <CustomBox>
                                                                                                    <StyledBox component="span" >
                                                                                                        New
                                                                                                    </StyledBox>
                                                                                                </CustomBox>
                                                                                                <Box sx={{ width: '100%', display: 'flex', }}>
                                                                                                    <CustomBox1></CustomBox1>
                                                                                                    <CustomBox2></CustomBox2>
                                                                                                </Box>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        <CardActions sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                                            <Button size="large" variant='contained' color="success" sx={{ mr: '1em' }} onClick={() => history(`/Deals/${item.dealSlug}`)}>Get Deal</Button>
                                                                                        </CardActions>
                                                                                    </Card>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Box>
                                                        </Collapse>
                                                    </List>
                                                </Paper>
                                            </div>
                                            :
                                            <div style={{ paddingBottom: "20px" }}>
                                                <Card sx={{ mt: "3em", pl: '1em', borderRadius: "20px" }} elevation={0} >
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={isMatch ? 12 : 6} sx={{ p: '1em 6em' }}>
                                                            <ImageSlide url={item} style={{ borderRadius: "20px 0px 0px 20px", height: isMatch ? 150 : 300 }} isMatch={isMatch} />
                                                        </Grid>
                                                        <Grid item xs={isMatch ? 12 : 6} display="flex" flexDirection="column" justifyContent="start" alignItems="start" >
                                                            {(item.rating) === '' ? null : <CustomChip label={`${item.rating} % off`} />}
                                                            <Typography sx={{
                                                                color: '#4b4b4be0',
                                                                fontWeight: 500,
                                                                fontSize: '20px',
                                                                mt: '2.5em'
                                                            }}>{item.name}</Typography>
                                                            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" sx={{ mt: '10px' }}>
                                                                <Link underline='none' color="inherit" href='/' >
                                                                    Home
                                                                </Link>
                                                                <Link underline='none' color="inherit" href='/Deals'>
                                                                    Deals
                                                                </Link>
                                                                <Typography color="textPrimary">{item.name}</Typography>
                                                            </Breadcrumbs>
                                                            <Stack display="flex" flexDirection="row">
                                                                <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '15px',
                                                                        mt: '10px',
                                                                        color: '#388e3c'
                                                                    }}>Price</Typography>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '18px',
                                                                    }}>$ {item.startprice}</Typography>
                                                                </Stack>
                                                                <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pl: '5em', mt: '9px' }}>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '15px',
                                                                        color: '#f50057'
                                                                    }}>Final Price</Typography>
                                                                    <Typography sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '18px',
                                                                    }}>$ {item.finalprice}  {(item.shipping) === "Free" ? '+ Free Shipping' : item.shipping}</Typography>
                                                                </Stack>
                                                                {(item.shipping) === '' ? null :
                                                                    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pl: '5em', mt: '9px' }}>
                                                                        <Typography sx={{
                                                                            fontWeight: 500,
                                                                            fontSize: '15px',
                                                                            color: '#02367d'
                                                                        }}>Shipping</Typography>
                                                                        <Typography sx={{
                                                                            fontWeight: 500,
                                                                            fontSize: '18px',
                                                                        }}> {item.shipping}</Typography>
                                                                    </Stack>
                                                                }

                                                            </Stack>

                                                            <Grid xs={12} sx={{ mt: '10px' }}>
                                                                <Divider sx={{ width: '538px' }} />
                                                                <Stack display="flex" flexDirection="row" justifyContent="start" alignItems="start">
                                                                    <Typography sx={{
                                                                        fontSize: '16px', // Change font size
                                                                        fontWeight: '500',
                                                                        pt: '10px',

                                                                    }}
                                                                    >
                                                                        Category :
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: '16px', color: '#0000008a', pt: '10px', pl: '10px' }}>
                                                                        {item.categories}
                                                                    </Typography>
                                                                </Stack>
                                                                <Typography sx={{
                                                                    fontSize: '16px', // Change font size
                                                                    fontWeight: '500',
                                                                    pt: '10px'
                                                                }}
                                                                >
                                                                    Highlights
                                                                </Typography>
                                                                <ul>
                                                                    <Typography sx={{ fontSize: '14px', color: '#0000008a', pt: '10px' }}>
                                                                        {(item.details.split(';')).map((item, index) => {
                                                                            return (<li key={index}>{item}</li>)
                                                                        })}
                                                                    </Typography>
                                                                </ul>
                                                            </Grid>
                                                            <Grid xs={12} sx={{ mt: '10px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <Link underline='none' href={`${item.websitelink}`} target="_blank" rel="noopener">
                                                                    <Button
                                                                        variant='contained'
                                                                        color='info'
                                                                        sx={{ p: '1em', m: '1em', fontSize: '1em', fontWeight: 500, }}>
                                                                        Get this Deal
                                                                    </Button>
                                                                </Link>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                                <Paper elevation={5} sx={{ m: '3em 1em', borderRadius: '10px' }}>
                                                    <List
                                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                    >
                                                        <ListItemButton onClick={handleClick}>
                                                            <ListItemText sx={{
                                                                '& .MuiListItemText-primary': {
                                                                    fontSize: '18px', // Change font size
                                                                    fontWeight: '500',
                                                                }
                                                            }}
                                                                primary="Product Details" />
                                                            {open ? <ExpandLess fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} /> : <ExpandMore fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} />}
                                                        </ListItemButton>
                                                        <Divider sx={{ m: 1 }} />
                                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                                            <Grid container spacing={1} sx={{ p: '0em 2em' }}>
                                                                <Grid xs={9} sx={{ mt: '10px' }}>
                                                                    <Typography sx={{ fontSize: '15px', color: '#0000008a', pt: '10px' }}>
                                                                        {item.description}
                                                                    </Typography>
                                                                    <ul>
                                                                        <Typography sx={{ fontSize: '15px', pt: '10px' }}>
                                                                            {(item.productInfo.split(';')).map((item, index) => {
                                                                                return (<li style={{ marginTop: '10px' }} key={index}>{item}</li>)
                                                                            })}
                                                                        </Typography>
                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                        </Collapse>
                                                        <ListItemButton onClick={handleClick1} sx={{ mt: '10px' }}>
                                                            <ListItemText sx={{
                                                                '& .MuiListItemText-primary': {
                                                                    fontSize: '18px', // Change font size
                                                                    fontWeight: '500',
                                                                }
                                                            }}
                                                                primary="More from this category"
                                                                secondary={`Category - ${item.categories}`} />
                                                            {open ? <ExpandLess fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} /> : <ExpandMore fontSize="large" sx={{ color: 'rgb(255 141 0)', fontSize: '18px', fontWeight: 500 }} />}
                                                        </ListItemButton>
                                                        <Divider sx={{ m: 1 }} />
                                                        <Collapse in={open1} timeout="auto" unmountOnExit>
                                                            <Box display={{ xs: 'none', sm: 'block' }} m={1}  >
                                                                <Container maxWidth="md" style={{ paddingBottom: 30 }}>
                                                                    <Grid container spacing={4}>
                                                                        {
                                                                            soldListings.map((item) => {
                                                                                return (
                                                                                    <Grid item key={item.name} xs={12} sm={6} md={6}>
                                                                                        <Card sx={{
                                                                                            width: '80%',
                                                                                            height: '100%',
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            paddingBottom: 0,
                                                                                            borderRadius: "10px"
                                                                                        }}>

                                                                                            <CardMedia
                                                                                                component="img"
                                                                                                alt={item.name}
                                                                                                sx={{
                                                                                                    height: '250px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'norepeat',
                                                                                                    backgroundPosition: 'center center',
                                                                                                    transition: 'transform 0.3s, opacity 0.3s',
                                                                                                }}
                                                                                                image={item.houseImage}
                                                                                                src={item.categories}
                                                                                            />
                                                                                            {(item.rating) === '' ? null : <div style={{ position: 'absolute', }}><CustomChip1 label={`${item.rating} % off`} /></div>}
                                                                                            {/* <Grid container spacing1>
                                                                                        <Grid item xs={7}>
                                                                                           
                                                                                        </Grid>
                                                                                        
                                                                                    </Grid> */}
                                                                                            <CardContent>
                                                                                                <Grid item xs={12}>
                                                                                                    <Typography sx={{ fontSize: '16px', color: '#373F50', fontWeight: 500 }}>
                                                                                                        {item.name}
                                                                                                    </Typography>
                                                                                                    <Divider sx={{ mt: '10px' }} />
                                                                                                    <Stack spacing={0.5} display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ p: '10px' }}>
                                                                                                        <Typography sx={{ fontSize: '13px', }}>
                                                                                                            Price: $ {item.startprice}
                                                                                                        </Typography>
                                                                                                        {(item.rating) === '' ? null : <Typography sx={{ fontSize: '13px', }}>
                                                                                                            Discount:  Reflected in sale price
                                                                                                        </Typography>}
                                                                                                        {(item.shipping) === '' ? null : <Typography sx={{ fontSize: '13px', }}>
                                                                                                            Shipping:  {item.shipping}
                                                                                                        </Typography>}
                                                                                                        <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
                                                                                                            Final Price:  $ {item.finalprice}  {(item.shipping) === "Free" ? '+ Free Shipping' : item.shipping}
                                                                                                        </Typography>
                                                                                                    </Stack>
                                                                                                </Grid>
                                                                                            </CardContent>
                                                                                            {(item.IsNew) === "Recommended" ?
                                                                                                <div style={{ position: 'absolute', marginLeft: '20em' }}>
                                                                                                    <CustomBox>
                                                                                                        <StyledBox component="span" >
                                                                                                            New
                                                                                                        </StyledBox>
                                                                                                    </CustomBox>
                                                                                                    <Box sx={{ width: '100%', display: 'flex', }}>
                                                                                                        <CustomBox1></CustomBox1>
                                                                                                        <CustomBox2></CustomBox2>
                                                                                                    </Box>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            <CardActions sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                                                <Button size="large" variant='contained' color="success" sx={{ mr: '1em' }} onClick={() => history(`/Deals/${item.dealSlug}`)}>Get Deal</Button>
                                                                                            </CardActions>
                                                                                        </Card>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Container>
                                                            </Box>
                                                        </Collapse>
                                                    </List>
                                                </Paper>
                                            </div>
                                    }

                                </>
                            )
                        })
                    }

                </Container>

            </Box>
            <Footer />
        </SimpleReactLightbox>

    )
}

export default Dealsdp

const split = ["Firm and Comfortable: The cushions you sit on are nice and firm. You'll never have to worry about sinking in.The more you sit in it the better. Perfect For Small Spaces: Small space reversible sectional sofa, Make the most of your apartment or studio space;upstairs loft and more. Versatile Sectional Sofa: Sectional sofa with an extra wide chaise lounge for maximum comfort,When friends come by, you can place the ottoman on either side of the sofa at random"]