import React, { useContext } from 'react'
import { Button, Card, CardMedia, Container, Divider, Grid, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import banner22 from '../images/banner2.jpg';
import { IconButton, MenuItem, Select, } from '@material-ui/core';
import { LocationOn, Search } from '@mui/icons-material';
import { PropertyContext } from '../context';
import { Box } from '@mui/system';
import Loading from '../components/Loading';
import NumberFormat from 'react-number-format';
import '../components/House.css'
import UpcomingContact from '../contact/UpcomingContact';
import Footer from '../components/Footer';

const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 25,
    paddingTop: 150
}));



function Row(props) {
    const { card, isMatch } = props;
    const [open1, setOpen1] = React.useState(false);

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleClickOpen = () => {
        setOpen1(true);
        // setOpen1(true);
    };

    return (
        <Box sx={{ p: isMatch ? '1px 9px' : null, ml: '1em', mr: '0.3em' }}>
            <Box sx={{ m: '10px', display: 'flex', flexDirection: 'end', justifyContent: 'flex-end' }}>
                <Button variant='contained' onClick={handleClickOpen} >Know More</Button>
                <UpcomingContact Open={open1} Close={handleClose1} Property={card.city} propertyName={card.propertyName} />
            </Box>

        </Box>
    );
}

const UpcomingPro = () => {
    const context = useContext(PropertyContext);
    const { featuredListings, FeaturedListings } = context;
    const [searchTerm, setSearchTerm] = React.useState("");
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const City = featuredListings.map(item => item.city);
    const cities = [...new Set(City)];
    // console.log('Cities',[...new Set(City)])


    const filteredCity = React.useMemo(() => {
        if (searchTerm.length > 0) {
            return featuredListings.filter(item => item.city === searchTerm)
        }
        return featuredListings
    }, [searchTerm, featuredListings])

    React.useEffect(() => {
        if (featuredListings.length === 0) {
            FeaturedListings()
        }

    }, [])

    if (featuredListings.length === 0) {
        return <Loading />
    }


    return (
        <>
            <StyledPaper>
                <Grid align="center" style={{ marginBottom: '3em' }}>
                    {/* <Typography variant="h2" sx={{ color: 'white', fontWeight: '500', mb: '1em' }} gutterBottom>Upcoming Projects {new Date().getFullYear()}</Typography> */}
                    <Paper
                        sx={{
                            paddingTop: '2px 10px',
                            display: 'flex',
                            alignItems: 'center',
                            width: isMatch ? 320 : 400,
                            margin: 'auto',
                            borderRadius: '30px',
                            mb: '10em'
                        }}>
                        <Select
                            disableUnderline
                            color=""
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            name="Soldmergecity"
                            style={{
                                marginLeft: '1em',
                                flex: 1,
                                fontWeight: 420,
                                fontSize: '15px',
                                width: 100,
                            }}
                            defaultValue="none"
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="none"
                                style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                    letterSpacing: '0.00938em',
                                }}
                            >
                                Search by city
                            </MenuItem>
                            <MenuItem value="All"
                                style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                    letterSpacing: '0.00938em',
                                }}
                                onClick={() => setSearchTerm("")}
                            >
                                All
                            </MenuItem>
                            {cities.map((item, index) => (
                                <MenuItem key={index} value={item}
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 15,
                                        letterSpacing: '0.00938em',
                                    }}
                                    onClick={() => setSearchTerm(item)} >
                                    {item + ", " + 'WA'}
                                </MenuItem>
                            ))}
                        </Select>
                        <IconButton aria-label="search"
                        >
                            <Search />
                        </IconButton>
                    </Paper>
                </Grid>
            </StyledPaper>
            <Box display={{ xs: 'block', md: 'block' }} m={1} >
                <Container maxWidth="xl">
                    <Grid container spacing={1}>
                        {
                            featuredListings &&
                            filteredCity.map((item, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} key={index} align="start" sx={{ p: isMatch ? '3px' : '5px' }}>
                                        <Card sx={{ mt: isMatch ? '10px' : '15px', mb: '1em', borderRadius: '10px', maxWidth: isMatch ? 400 : null }}>
                                            <CardMedia
                                                component="img"
                                                alt={item.address}
                                                width={isMatch ? '250' : "auto"}
                                                height={isMatch ? '300' : "400"}
                                                sx={{
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'norepeat',
                                                    backgroundPosition: 'center center',
                                                }}
                                                image={item.featuredImage}
                                                src="indiangrocerystores"
                                            // onClick={() => history(`/${item.categorySlug}/${agent.agentSlug}`)}
                                            // onClick1={handleClick}
                                            />
                                            <Grid item xs={12} sx={{ background: 'lightgrey', m: isMatch ? '15px' : '2em 1em', borderRadius: '5px', p: '5px' }}>
                                                <Stack direction="row" spacing={isMatch ? 5 : 16} sx={{
                                                    ml: isMatch ? '1.5em' : '1.3em',
                                                    pt: '5px',
                                                    // pb: '5px'
                                                }}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: isMatch ? '1rem' : '17px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                        }}
                                                    >
                                                        {item.propertyName}
                                                    </Typography>
                                                    <div>
                                                        <p id="price_1">
                                                            <div className="starting">
                                                                Price
                                                            </div>
                                                            <strong id="price_from">
                                                                <NumberFormat value={item.listingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </Stack>
                                                <Stack direction="row" spacing={0} sx={{
                                                    ml: isMatch ? '1.5em' : '1.3em',
                                                    pt: '5px',
                                                    // pb: '5px'
                                                }}>
                                                    <Typography
                                                        sx={{
                                                            color: '#565656c9',
                                                            fontWeight: 500,
                                                            fontSize: isMatch ? '1rem' : '15px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                        }}
                                                    >
                                                        Community by {item.builder}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" spacing={0} sx={{
                                                    ml: isMatch ? '1.5em' : '1.3em',
                                                    pt: '5px',
                                                    // pb: '1em'
                                                }}>
                                                    <LocationOn sx={{ color: '#ff0055' }} />
                                                    <Typography
                                                        sx={{

                                                            fontWeight: 400,
                                                            fontSize: isMatch ? '13px' : '15px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                        {item.city}
                                                    </Typography>
                                                </Stack>
                                                <Divider sx={{ m: isMatch ? '1em 0em' : '1em' }} />
                                                <Stack direction="row" spacing={0} sx={{
                                                    ml: isMatch ? '1.5em' : '1.3em',
                                                    pt: '5px',
                                                    pb: '1em'
                                                }}>
                                                    <Typography
                                                        sx={{
                                                            ml: '2px',
                                                            fontWeight: 400,
                                                            fontSize: isMatch ? '13px' : '15px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                        {item.short}
                                                    </Typography>
                                                </Stack>
                                                
                                            </Grid>
                                            
                                            {/* <Stack direction="row" spacing={0} sx={{
                                                    ml: isMatch ? '1.5em' : '1.3em',
                                                    pt: '5px',
                                                    pb: '1em'
                                                }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '.775rem',
                                                            color: '#999',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        Listing provided by nwmls
                                                    </Typography>
                                                </Stack> */}
                                            <Row card={item} isMatch={isMatch} />
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Container>
            </Box>
            <div>
                <Footer />
            </div>
        </>
    )
}

export default UpcomingPro