import React from 'react'
import { Avatar, Box, Button, Card, Checkbox, Chip, Collapse, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, Stack, styled, TextareaAutosize, TextField, Typography, useMediaQuery } from '@mui/material';
import { CalendarMonth, Clear, ClearAllOutlined, Delete, Download, Edit, FileUploadOutlined, LocationOn, SaveAltOutlined, Update, UploadFileOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import ScoopPannel from '../admin/ScoopPannel';
import firebase from '../firebase/firebase'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

const TodayDate = new Date().toISOString().split('T')[0];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey1 = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};



const Textarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    padding: '12px',
    borderRadius: '12px 12px 0 12px',
    color: `${theme.palette.mode === 'dark' ? grey1[300] : grey1[900]}`,
    background: `${theme.palette.mode === 'dark' ? grey1[900] : '#fff'}`,
    border: `1px solid ${theme.palette.mode === 'dark' ? grey1[700] : grey1[200]}`,
    boxShadow: `0px 2px 2px ${theme.palette.mode === 'dark' ? grey1[900] : grey1[50]}`,
    '&:hover': {
        borderColor: `${blue[400]}`,
    },
    '&:focus': {
        outline: 0,
        borderColor: `${blue[400]}`,
        boxShadow: `10 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]}`,
    },
    '&:focus-visible': {
        outline: 0,
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const getFileColor = (fileType) => {
    switch (fileType) {
        case 'pdf':
            return '#ff5722'; // Orange for PDF
        case 'doc':
            return '#2196f3'; // Blue for DOC
        case 'xls':
            return '#4caf50'; // Green for XLSX
        case 'jpeg':
        case 'png':
            return '#e91e63'; // Pink for JPEG and PNG
        default:
            return '#000000'; // Default color for unknown types
    }
};

const CustomButton = styled(Button)(({ theme, fileType }) => ({
    width: '100%',
    color: theme.palette.getContrastText(getFileColor(fileType)),
    backgroundColor: getFileColor(fileType),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    margin: '5px',
}));





function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


function EditPannel(props) {
    var fireDB = firebase.database().ref();
    const [fileName, setFileName] = React.useState([]);
    const [file, setFile] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [allValues, setAllValues] = React.useState({
        newName: '',
        title: '',
        description: '',
        selectedDate: TodayDate,
        file: ''
    });

    // console.log('team', allValues)
    React.useEffect(() => {
        setAllValues({
            ...props.scoop[props.currentId]
        })
        setFile([...props.scoop[props.currentId].file || ''])
    }, [props.currentId, props.scoop])
    console.log("file", [...props.scoop[props.currentId].file || ''])
    console.log("fileName", file)

    function getFileNameFromStorageUrl(storageUrl) {
        if (!storageUrl) {
            return null; // Return null or another appropriate value for an empty URL
        }
        try {
            var str1 = new URL(storageUrl).pathname.split('/').pop();
            var str2 = (str1.includes("_pdffile_")) ? str1.split("_pdffile_")[1] : str1// Get the last part (the filename)
            return str2;
        } catch (error) {
            console.error("Error extracting filename:", error);
            return null; // Return null or another appropriate value in case of an error
        }

    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAllValues({ ...allValues, [name]: value });
    };

    const handleClearFileName = (key, indexToDelete) => {
        let data = [...props.scoop[key].file || []];

        const deleteImage = (index) => {
            if (index < data.length) {
                const element = data[index];

                // If the current index matches the indexToDelete, delete this file
                if (index === indexToDelete) {
                    return firebase
                        .storage()
                        .refFromURL(element)
                        .delete()
                        .then(() => {
                            console.log('Image deleted');
                            data.splice(indexToDelete, 1); // Remove the deleted file from the array
                            return deleteImage(index + 1); // Recursively delete the next image
                        })
                        .catch((error) => {
                            console.error('An error occurred while deleting the image', error);
                            return Promise.reject(error); // Reject the promise on error
                        });
                } else {
                    // If the current index does not match the indexToDelete, skip to the next index
                    return deleteImage(index + 1);
                }
            } else {
                // All images are deleted or skipped, now update the file property in the database
                const propertyPath = `properties/${props.UpdateScoop}`;
                return fireDB
                    .child(`${propertyPath}/scoop/${key}/file`)
                    .set(data) // Update the file array in the database
                    .then(() => {
                        setFileName('');
                        console.log('File property updated in the database');
                    })
                    .catch((error) => {
                        console.error('Error while updating file property:', error);
                        return Promise.reject(error); // Reject the promise on error
                    });
            }
        };

        deleteImage(0)
            .then(() => {
                console.log('File at index', indexToDelete, 'removed successfully.');
            })
            .catch((error) => {
                console.error('Error during the process:', error);
            });
    };
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };



    const handleFileChange = async (e) => {
        const newFiles = e.target.files;
        if (newFiles) {
            const uploadPromises = Array.from(newFiles).map(async (file) => {
                return new Promise((resolve, reject) => {
                    let folderName = 'PDF files';
                    const id = shortid.generate();
                    const storageRef = firebase.storage().ref(`${folderName}/${id + "_pdffile_" + file.name}`);
                    const uploadTask = storageRef.put(file);

                    uploadTask.on('state_changed', (snapshot) => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setProgress(progress);
                    }, (error) => {
                        console.error(error);
                        reject(error);
                    }, async () => {
                        const downloadURL = await storageRef.getDownloadURL();
                        resolve({ downloadURL, filename: file.name });
                    });
                });
            });

            try {
                const results = await Promise.all(uploadPromises);
                const newDownloadURLs = results.map((result) => result.downloadURL);
                const newFileNames = results.map((result) => result.filename);

                setFile((prevFiles) => [...prevFiles, ...newDownloadURLs]);
                setFileName((prevFileNames) => [...prevFileNames, ...newFileNames]);
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }
    };

    const handleUpload = (event) => {
        event.preventDefault();
        const { newName, title, description, selectedDate, } = allValues;

        if (newName && title && description && selectedDate) {
            // Create a data object with all values
            const data = {
                newName: newName,
                title: title,
                description: description,
                selectedDate: selectedDate,
                file: file, // Save the download URL
            };

            const propertyPath = `properties/${props.UpdateScoop}`;
            // Create a reference to the Firebase database location

            fireDB.child(`${propertyPath}/scoop/${props.currentId}`).update(data);
        };
    }


    return (
        <div>
            <Grid container spacing={1} sx={{ p: props.isMatch ? '0em 3em 0em 10em' : '1em' }} display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={props.isMatch ? 12 : 6} display="flex" justifyContent="start" alignItems="center">
                    <FormControl sx={{ width: 230 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Name</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={allValues.newName}
                            name="newName"
                            onChange={handleInputChange}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value="Amit">Amit</MenuItem>
                            <MenuItem value="Becky">Becky</MenuItem>
                            <MenuItem value="Pradeep">Pradeep</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={props.isMatch ? 12 : 6} display="flex" justifyContent="center" alignItems="center">
                    <TextField
                        label="Date"
                        type="date"
                        name="selectedDate"
                        defaultValue={TodayDate}
                        value={allValues.selectedDate}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Date"
                                name="date"
                                value={allValues.selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} name="date" variant='outlined' />}
                                fullWidth
                            />
                        </LocalizationProvider> */}
                </Grid>
                <Grid item xs={props.isMatch ? 12 : 12} display="flex" justifyContent="center" alignItems="center">
                    <TextField
                        label="Title"
                        name="title"
                        value={allValues.title}
                        onChange={handleInputChange}
                        fullWidth />
                </Grid>
                <Grid item xs={props.isMatch ? 12 : 12} display="flex" justifyContent="center" alignItems="center">
                    <Textarea
                        label="Description"
                        name="description"
                        minRows={3}
                        placeholder="Description"
                        value={allValues.description}
                        onChange={handleInputChange}
                        sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={props.isMatch ? 8 : 9} display="flex" justifyContent="start" alignItems="center">
                    <FormControl sx={{ m: 1, width: props.isMatch ? 250 : 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Files</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={fileName === "" ? file : fileName}
                            onChange={handleFileChange}
                            input={<OutlinedInput label="Files" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {file.map((name, index) => (
                                <MenuItem key={name} value={name}>
                                    <ListItemText primary={getFileNameFromStorageUrl(name)} />
                                    <IconButton onClick={() => { handleClearFileName(props.currentId, index) }}>
                                        <Delete />
                                    </IconButton>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <Select>
                        {
                            file.map((item) => {
                                return (
                                    <MenuItem>
                                        {item}
                                        <Button onClick={() => { handleClearFileName(props.currentId) }}>
                                            Delete
                                        </Button>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select> */}

                    {/* <TextField
                        label="Upload your file"
                        variant="outlined"
                        fullWidth
                        value={fileName.join(', ')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {fileName === '' ? null :
                                        <IconButton onClick={() => { handleClearFileName(props.currentId) }}>
                                            {(!props.fileName) === true ? <Clear /> : <Delete />}
                                        </IconButton>
                                    }
                                </InputAdornment>
                            ),
                        }}
                    /> */}

                    <input
                        id="fileInput"
                        type="file"
                        accept="*/*"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </Grid>
                <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                    {
                        (props.isMatch) === true ?
                            <IconButton onClick={openFileDialog}
                                sx={{
                                    backgroundColor: '#2e7d32',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#2e7d32',
                                        color: '#fff', // Change the background color on hover
                                    },
                                }}>
                                <FileUploadOutlined />
                            </IconButton>
                            :
                            <Button variant="contained" color="success" onClick={openFileDialog} startIcon={<FileUploadOutlined />}>
                                Browse
                            </Button>
                    }
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel variant="determinate" value={progress} />
                </Grid>
                {/* <Grid item xs={4} sx={{ mt: '1em' }}>
                    <Button variant="contained" color="error" onClick={props.handleClose}>
                        Cancel
                    </Button>
                </Grid> */}
                <Grid item xs={12} display="flex" justifyContent="end" sx={{ mt: '1em' }}>
                    <Button variant="contained" color="primary" onClick={handleUpload} endIcon={<Update />}>
                        Update
                    </Button>
                </Grid>

            </Grid>
        </div>
    )
}


function Floor(props) {
    var str = props.floorUrl
    var str1 = new URL(str).pathname.split('/').pop();
    var str2 = (str.includes("_pdffile_")) ? str1.split("_pdffile_")[1] : str1
    var str3 = str2
    // var str4 = str3.split('?')[0]
    // console.log("floor", str)
    console.log("id", str2)



    const downloadFile = () => {
        // You can replace this URL with the actual URL of your PDF file
        const pdfUrl = str; // Replace with the actual URL of your PDF file
        const fileName = str2; // Replace with the desired filename

        // Use the js-file-download library to initiate the download
        const link = document.createElement('a');
        link.href = pdfUrl; // Opens the PDF in a new tab/window
        link.target = '_blank';
        link.download = fileName;

        // Trigger a click event on the link to initiate the download
        link.click();
    };

    function getFileExtension(filename) {
        // Split the filename based on the dot
        const parts = filename.split('.');

        // Take the last part of the array as the file extension
        const extension = parts[parts.length - 1];
        console.log("id2", extension.toLowerCase())
        return extension.toLowerCase();
        // Convert to lowercase for consistency
    }

    const fileExtension = getFileExtension(str2);
    console.log("id3", fileExtension)
    return (
            <CustomButton variant="contained" size="small"
                sx={{
                    // backgroundColor: '#ff4560',
                    backgroundColor: fileExtension === 'mp4' ? '#ff4560' : fileExtension === 'docx' ? '#2979ff' : fileExtension === 'xls' ? '#4caf50' : fileExtension === 'png' ? '#4caf50' : fileExtension === 'pdf' ? '#ff9700' : '#e91e63',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: fileExtension === 'mp4' ? '#ff4560' : fileExtension === 'docx' ? '#2979ff' : fileExtension === 'xls' ? '#4caf50' : fileExtension === 'png' ? '#4caf50' : fileExtension === 'pdf' ? '#ff9700' : '#e91e63', // Change the background color on hover
                    },
                }}
                endIcon={<Download />}
                onClick={downloadFile}
            >
                <Typography  variant="caption" display="block" gutterBottom >
                    {str2}
                </Typography>
            </CustomButton>
    )

}

const formatDate = (dateString) => {
    const dateParts = dateString.split('-'); // Split the date string into parts
    if (dateParts.length === 3) {
        const [year, month, day] = dateParts;
        const formattedDate = `${day}-${month}-${year}`; // Rearrange the parts in the desired format
        return formattedDate;
    } else {
        return 'Invalid date format'; // Handle invalid date format
    }
};

function ScoopCard(props) {
    const isMatch = useMediaQuery('(max-width:960px )')
    const [open, setOpen] = React.useState(false);
    var [currentId, setCurrentId] = React.useState('');
    const {
        ScoopData,
        scoop,
        propertyname,
        UpdateScoop,
        user,
        allowedEmail
    } = props;

    // const singleID = Object.keys(scoop).filter((id) => scoop[id].title === ScoopData.title)


    const handleClickOpen = (id) => {
        setOpen(true);
        setCurrentId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>

            <Card sx={{
                m: isMatch ? '1em 0em' : '2em 0em',
                position: 'relative',
                overflow: 'hidden',
                // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                // transition: 'transform 0.3s, opacity 0.3s',
                // '&:hover': {
                //     transform: 'scale(1.05)',
                //     boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
                // },
            }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Stack sx={{ width: '100%', p: '16px 16px 6px 16px' }} direction="row" spacing={0}>
                            <Box sx={{ display: 'flex' }}>
                                <Avatar variant="rounded" sx={{ width: 50, height: 50, bgcolor: '#ff9c00' }}>{scoop[ScoopData].newName.charAt(0)}</Avatar>
                            </Box>
                            <Stack sx={{ width: '100%', ml: '1em' }}>
                                <Stack direction="row" spacing={2} justifyContent="space-between">
                                    <Typography fontWeight={500} sx={{ fontSize: 16, color: '#0288d1', }}>{scoop[ScoopData].newName}</Typography>
                                    <Typography fontWeight={500} sx={{ fontSize: 14, color: '#9e9e9e', mt: '5px', textAlign: 'right' }}><CalendarMonth sx={{ color: grey[500] }} /> {formatDate(scoop[ScoopData].selectedDate)}</Typography>
                                </Stack>
                                <Divider />
                                {
                                    isMatch ?
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <Typography sx={{ fontSize: 14, mt: '5px' }} fontWeight={500}>{scoop[ScoopData].title}</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '1em', pt: '5px', }}>
                                                {
                                                    user && user.email === allowedEmail ?
                                                        <IconButton sx={{
                                                            backgroundColor: '#2ac49b',
                                                            height: '35px',
                                                            width: '35px',
                                                            color: '#fff',
                                                            '&:hover': {
                                                                backgroundColor: '#2ac49b',
                                                                color: '#fff', // Change the background color on hover
                                                            },
                                                        }}>
                                                            <Edit sx={{ fontSize: 17 }} onClick={() => handleClickOpen(ScoopData)} />
                                                            {/* <Typography sx={{ fontSize: 14 }} fontWeight={500}>Edit</Typography> */}
                                                        </IconButton>

                                                        :
                                                        null}
                                            </Box>
                                        </Stack>
                                        :
                                        null
                                }
                                {/* <CalendarMonth sx={{ color: grey[500] }} /> {scoop[ScoopData].selectedDate} */}
                                {isMatch ? null : <Typography sx={{ fontSize: 14, mt: '5px' }} fontWeight={500}>{scoop[ScoopData].title}</Typography>}
                            </Stack>
                            {isMatch ? null : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '1em' }}>
                                {
                                    user && user.email === allowedEmail ?
                                        <IconButton sx={{
                                            backgroundColor: '#2ac49b',
                                            height: '40px',
                                            width: '40px',
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#2ac49b',
                                                color: '#fff', // Change the background color on hover
                                            },
                                        }}>
                                            <Edit sx={{ fontSize: 17 }} onClick={() => handleClickOpen(ScoopData)} />
                                            {/* <Typography sx={{ fontSize: 14 }} fontWeight={500}>Edit</Typography> */}
                                        </IconButton>
                                        :
                                        null}
                            </Box>}
                        </Stack>
                        <Stack sx={{ p: '0px 0px 8px 16px' }}>
                            <Typography variant="body1" color="text.secondary">
                                {scoop[ScoopData].description}
                            </Typography>
                        </Stack>
                        {/* {
                            str === '' ? null :
                                <Grid item xs={12} display="flex" justifyContent="end" sx={{ p: 1 }}
                                >
                                    <Button variant="contained" color="success" size="small" endIcon={<Download />} onClick={downloadFile}>
                                        {str4}
                                    </Button>
                                </Grid>
                        } */}

                    </Grid>

                    {(() => {
                        if (Array.isArray(scoop[ScoopData].file))
                            if (scoop[ScoopData].file.length === 1)
                                return <Grid container spacing={2} sx={{ p: '1em' }}>
                                    {scoop[ScoopData].file.map((data) => (
                                        <Grid item xs={12} sm={6} md={4} lg={6} display="flex" justifyContent="end" sx={{ p: 1 }}>
                                            <Floor floorUrl={data} />
                                        </Grid>)
                                    )}
                                </Grid>
                            else
                                return <Grid container spacing={2} sx={{ p: '1em' }}>
                                    {scoop[ScoopData].file.map((data) => (
                                        <Grid item xs={12} sm={6} md={4} lg={6} sx={{ p: 1, width: '100%' }}>  <Floor floorUrl={data} /></Grid>
                                    )
                                    )}
                                </Grid>
                    })()}


                    <Grid item xs={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit sx={{ p: isMatch ? "0em" : "0em 3em" }}>
                            <Grid item xs={12} >
                                <Divider sx={{ m: '2em 1em' }} />
                            </Grid>
                            <Grid item xs={12} >
                                <Card sx={{
                                    m: '1em',
                                    p: '8px',
                                    backgroundColor: '#00000061',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'baseline'
                                }}>
                                    <Typography sx={{ color: '#fff', }} id="disabled-slider" gutterBottom variant="h7">
                                        {`Edit Scoop ${scoop[ScoopData].title}`}
                                    </Typography>
                                    <IconButton onClick={handleClose}
                                        sx={{
                                            backgroundColor: '#1565c0',
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#1565c0',
                                                color: '#fff', // Change the background color on hover
                                            }
                                        }}
                                    >
                                        <Clear sx={{ color: '#fff', fontSize: 17 }} />
                                    </IconButton>
                                </Card>
                                {isMatch ?
                                    <Grid item xs={8} display="flex" justifyContent="center" sx={{ p: 1 }}>
                                        <EditPannel
                                            scoop={scoop}
                                            currentId={currentId}
                                            ScoopData={ScoopData}
                                            UpdateScoop={UpdateScoop}
                                            handleClose={handleClose}
                                            fileName={scoop[ScoopData].file}
                                            isMatch={isMatch}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={isMatch ? 8 : 12} display="flex" justifyContent="end" sx={{ p: 1 }}>
                                        <EditPannel
                                            scoop={scoop}
                                            currentId={currentId}
                                            ScoopData={ScoopData}
                                            UpdateScoop={UpdateScoop}
                                            handleClose={handleClose}
                                            fileName={scoop[ScoopData].file}
                                            isMatch={isMatch}
                                        />
                                    </Grid>
                                }
                            </Grid>

                            {/* {(() => {
                                if (Array.isArray(scoop[ScoopData].file)) {
                                    if (scoop[ScoopData].file.length === 1)
                                        return <div >
                                            {scoop[ScoopData].file.map((data) => (
                                                <Grid item xs={12} display="flex" justifyContent="end" sx={{ p: 1 }}>
                                                    <EditPannel
                                                        scoop={scoop}
                                                        currentId={currentId}
                                                        ScoopData={ScoopData}
                                                        UpdateScoop={UpdateScoop}
                                                        handleClose={handleClose}
                                                        fileName={data}
                                                        isMatch={isMatch}
                                                    />
                                                </Grid>)
                                            )}
                                        </div>
                                    else
                                        return <div >
                                            {scoop[ScoopData].file.map((data) => (
                                                <Grid item xs={12} display="flex" justifyContent="end" sx={{ p: 1 }}>
                                                    <EditPannel
                                                        scoop={scoop}
                                                        currentId={currentId}
                                                        ScoopData={ScoopData}
                                                        UpdateScoop={UpdateScoop}
                                                        handleClose={handleClose}
                                                        fileName={data}
                                                        isMatch={isMatch}
                                                    />
                                                </Grid>)
                                            )}
                                        </div>
                                }
                                else {
                                    return <div >
                                        <Grid item xs={12} display="flex" justifyContent="end" sx={{ p: 1 }}>
                                            <EditPannel
                                                scoop={scoop}
                                                currentId={currentId}
                                                ScoopData={ScoopData}
                                                UpdateScoop={UpdateScoop}
                                                handleClose={handleClose}
                                                fileName={scoop[ScoopData].file}
                                                isMatch={isMatch} />
                                        </Grid>
                                    </div>
                                }

                            })()} */}
                            <Divider sx={{ m: isMatch ? '1em' : '2em 1em' }} />
                        </Collapse>
                    </Grid>
                </Grid>

            </Card >
        </div >
    )
}

export default ScoopCard