import React, { useState, useEffect } from 'react';
import defaultmage from '../images/Image_not_available.png';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import './HouseMobile.css'
import Button from '@material-ui/core/Button';
import './House.css';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import firebase from '../firebase/firebase';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { useMediaQuery } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Moment from 'moment';
import { IconButton, Stack, } from '@mui/material';
import { FavoriteRounded } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderRadius: "10px"
  },
  media: {
    height: 180,
  },
});


const Item = styled(Paper)(({ theme }) => ({
  color: 'darkgreen',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 12,
  borderRadius: '4px',
  borderColor: 'darkgreen'
}));

const Item2 = styled(Paper)(({ theme }) => ({
  color: 'brown',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 12,
  borderRadius: '4px',
  borderColor: 'brown'
}));

const Item3 = styled(Paper)(({ theme }) => ({
  color: 'red',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 12,
  borderRadius: '4px',
  borderColor: 'red',
}));


export default function House2({ home }) {
  const classes = useStyles();
  const { propertyname,
    slug,
    status,
    url,
    address,
    startprice,
    endprice,
    builders,
    kitchens,
    bathrooms,
    bedrooms,
    Tag,
    parking,
    featuredUrl,
    MlsId,
    ActiveStatus,
    ListingPrice,
    PlotSize,
    ListedDate,
    SoldDate,
    ClosedDate,
    MutualDate,
    SellerName,
    wishlist
  } = home;


  const [user, setUser] = useState(null)


  const match = window.location.pathname.split('/')[1] === 'Our-Listings' ? true : false;
  // const isMatch = useMediaQuery('(max-width:600px)')
  var pIname = propertyname.toLowerCase()
  var pname = pIname.split("by")[0];
  var ppname = pname.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  const mutualDate = Moment(MutualDate).format('DD-MM-YYYY');
  const closedDate = Moment(ClosedDate).format('DD-MM-YYYY');

  React.useEffect(() => {
    // Listen for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const allowedEmail = 'dguptahomes.web@gmail.com';
  const email = user && user.email;
  const wishlistData = wishlist !== null && typeof wishlist === 'object'
    ? Object.keys(wishlist).map((id) => wishlist[id])
    : null;

  const Favourite = React.useMemo(() => {
    // Check if wishlistData is not null and has items
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);

      if (isWishlisted) {
        return (
          <IconButton>
            <FavoriteRounded color="error" sx={{ fontSize: '1em' }} />
          </IconButton>
        );
      } else {
        return null
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      return null
    }
  }, [wishlistData, email,]);


  return (
    <div>
      <Card className={classes.root} elevation={4} spacing={5}>
        <CardActionArea>
          {
            (match === true ?
              <Link underline='none' component={RouterLink} to={`/Our-Listings/${MlsId}`} >
                <CardMedia
                  className={classes.media}
                  image={featuredUrl || url || defaultmage} alt="single image"
                  title="Live from space album cover"
                />
                {ActiveStatus == 'Active' && <div className="top-left_avai_mob2">Active</div>}
                {ActiveStatus == 'Just Sold' && <div className="top-left_c_Sold_mob">Just Sold</div>}
                {ActiveStatus == 'Pending' && <div className="top-left_coming_mob2">Pending</div>}
                {ActiveStatus == 'AdminOnly' && <div className="top-left_AdminOnlymob">Admin Only</div>}
                {ActiveStatus == 'Sold'
                  &&
                  <div >
                    <div className="top-left_ls_price_mob">
                      Listed Price - <NumberFormat value={ListingPrice} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                    </div>
                    <div className="top-left_sold_price_mob">
                      Sold Price - <NumberFormat value={startprice} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                    </div>
                  </div>
                }

                {Tag == 'Recommended' && <div className="top-Right_pericesqfeet">Recommended</div>}
              </Link>
              :
              <Link underline='none' component={RouterLink} to={`/New-constructions/${slug}`} >
                <CardMedia
                  className={classes.media}
                  image={featuredUrl || url || defaultmage} alt="single image"
                  title="Live from space album cover"
                />
                {status == 'Sold Out' && <div className="top-left_sold_mob">Sold Out</div>}
                {status == 'Currently Available' && <div className="top-left_avai_mob">Currently Available</div>}
                {status == 'Coming Soon' && <div className="top-left_coming_mob">Coming Soon</div>}
                {status == 'Currently Sold Out' && <div className="top-left_c_Sold_mob">Currently Sold Out</div>}
                {Tag == 'Recommended' &&
                  <><div className="top-Right_Rec_mob">Recommended</div>
                    <Stack display="flex" justifyContent="end" alignItems="end"
                      sx={{ position: 'absolute', top: '30px', right: '5px' }}>
                      {Favourite}
                    </Stack>
                  </>
                }
                {Tag == 'Recommended' ?
                  null
                  :
                  <Stack display="flex" justifyContent="end" alignItems="end"
                    sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                    {Favourite}
                  </Stack>}
              </Link>
            )
          }

          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
            Lizard
          </Typography> */}

            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  {
                    (match === true ?
                      <div>
                        <Typography className="mobhouse_title">
                          <h2>{address}</h2>
                        </Typography>
                        <Typography className="mobhouse_add">
                          <h3>MLS ID : {MlsId}</h3>
                        </Typography>
                      </div>
                      :
                      <div>
                        <Typography className="mobhouse_title">
                          <h2>{ppname}</h2>
                        </Typography>
                        <Typography className="mobhouse_add">
                          <h3>{address}</h3>
                        </Typography>
                      </div>)
                  }

                  {/* <Typography variant="body2" color="textSecondary">
                  ID: 1030114
                </Typography> */}
                </Grid>
                <Grid item>
                  <Typography className="mobhouse_hi" >
                    <h3>Highlights</h3>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <div className="priceContainermob">
                    {(() => {
                      if (status == 'Sold Out') {
                        return (
                          <p id="price_2">
                            <strong id="price_from2">
                              SOLD OUT
                            </strong>
                          </p>)
                      }
                      if (status == 'Currently Sold Out') {
                        return (
                          <p id="price_2">
                            <strong id="price_from2">
                              CURRENTLY SOLD OUT
                            </strong>
                          </p>)
                      }
                      if (ActiveStatus == 'Sold') {
                        return (
                          <div>
                            <p id="price_2">
                              <div className="starting">
                                Sold Price 
                              </div>
                              <strong id="price_from">
                                <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>
                        )
                      }
                      if (ActiveStatus == 'Pending') {
                        return (
                          <div>
                            <p id="price_2">
                              <div className="starting">
                                Listed Price 
                              </div>
                              <strong id="price_from">
                                <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>
                        )
                      }
                      if (ActiveStatus == 'Active') {
                        return (
                          <div>
                            <p id="price_2">
                              <div className="starting">
                                Listed Price 
                              </div>
                              <strong id="price_from">
                                <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>
                        )
                      }
                      if (ActiveStatus == 'AdminOnly') {
                        return (
                          <div>
                            <p id="price_2">
                              <div className="starting">
                                Listed Price 
                              </div>
                              <strong id="price_from">
                                <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>
                        )
                      }
                      if (!startprice) {
                        return (
                          <p id="price_2">
                            {(
                              match === true ?
                                <div></div>
                                :
                                <strong id="price_from2">
                                  Not Available
                                </strong>
                            )}
                          </p>)
                      }
                      else if (!endprice) {
                        return (
                          <div>
                            <p id="price_2">
                              <div className="starting">
                                Starting
                              </div>
                              <strong id="price_from2">
                                <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>)
                      }
                      else {
                        return (<div>
                          <p id="price_2">
                            {/* from  */}
                            <strong id="price_from2">
                              <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              {' - '}
                              <NumberFormat value={endprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </strong>
                          </p>
                        </div>)
                      }
                    })()}
                  </div>
                  <p id="price_divider"></p>
                </Typography>
                {
                  (ActiveStatus == 'Sold') ?
                    <div>
                      <p id="priceSoldDate">
                        <div className="starting">
                          Sold Date 
                        </div>
                        <strong id="price_from">
                          {SoldDate}
                        </strong>
                      </p>
                    </div>
                    :
                    <div>
                      {
                        (match === true ?
                          <div>
                            <p id="prices">
                              <div className="starting">
                                Listed Date 
                              </div>
                              <strong id="price_from">
                                {ListedDate}
                              </strong>
                            </p>
                          </div>
                          :
                          null
                        )
                      }
                    </div>

                }
              </Grid>
            </Grid>
            <Typography className="mobhouse_dis">
              {(
                match === true ?
                  <p>
                    Plot Size : {PlotSize ? PlotSize : "NA"} | Bed Rooms : {bedrooms ? bedrooms : "NA"} | Bath Rooms : {bathrooms ? bathrooms : "NA"}  | Parking : {parking ? parking : "NA"}
                  </p>
                  :
                  <p>
                    Builder : {builders ? builders : "NA"} | Bed Rooms : {bedrooms ? bedrooms : "NA"} | Bath Rooms : {bathrooms ? bathrooms : "NA"} | Kitchens : {kitchens ? kitchens : "NA"} | Parking : {parking ? parking : "NA"}

                  </p>
              )}
            </Typography>
            {
              user && user.email === allowedEmail && (match === true) ?
                <Grid
                  container spacing={1}
                  style={{ marginTop: '10px' }}
                >
                  <Grid item xs={6}>
                    <Item variant="outlined" square={true}>Seller Name : {SellerName}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    {
                      (ActiveStatus === "Active" || ActiveStatus === "AdminOnly") ?
                        null
                        :
                        <Item2 variant="outlined" square={true}>Mutual Date : {mutualDate}</Item2>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {
                      (ActiveStatus === "Sold") ?
                        <Item3 variant="outlined" square={true}>Closed Date : {closedDate}</Item3>
                        :
                        null
                    }
                  </Grid>
                </Grid>

                :
                null
              // <Grid
              //   container spacing={0}
              //   style={{ marginTop: '15px' }}
              // >
              //    <Grid item xs={6}>
              //     <Typography
              //       style={{
              //         fontSize: '.775rem',
              //         color: '#999',
              //         overflow: 'hidden',
              //         whiteSpace: 'nowrap',
              //         textOverflow: 'ellipsis',
              //       }}
              //     >
              //       Listing provided by nwmls
              //     </Typography>
              //   </Grid>
              // </Grid>

            }
            {
              (match === true) ?
                <Grid
                  container spacing={0}
                  style={{ marginTop: '15px' }}
                >
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontSize: '.775rem',
                        color: '#999',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Listing provided by nwmls
                    </Typography>
                  </Grid>
                </Grid>
                :
                null
            }
          </CardContent>
        </CardActionArea>
        <div className="border_top">
          <div className="ButtonContain">
            {
              (
                match === true ?
                  <Link underline='none' component={RouterLink} to={`/Our-Listings/${MlsId}`} >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Know More
                    </Button>
                  </Link>
                  :
                  <Link underline='none' component={RouterLink} to={`/New-constructions/${slug}`} >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Know More
                    </Button>
                  </Link>
              )}
          </div>
        </div>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </div>

  )
}

House2.propTypes = {
  home: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    url: PropTypes.arrayOf(PropTypes.string).isRequired,
    startprice: PropTypes.number.isRequired,
    endprice: PropTypes.number.isRequired,
  })
}

