import React, { useEffect, useState, useRef } from 'react';
import { Box, Card, CardMedia, CardContent, Container, Typography, Grid, useMediaQuery } from '@mui/material';
import sanityClient from '../client';
import { useParams } from "react-router-dom";
import MobBlogDetail from './MobBlogDetail';
import { makeStyles } from '@material-ui/styles';
import SimpleReactLightbox from 'simple-react-lightbox';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import imageUrlBuilder from "@sanity/image-url";
import { useNavigate, Link } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';
import Divider from '@mui/material/Divider';
import Footer from '../components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '5em',
    fontWeight: 600,
    lineHeight: 1,
    color: '#4b4b4be0',
    marginBottom: '35px',
    display: 'flex',
  },
}))

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}


const BlogDetail = () => {
  const classes = useStyles();
  const [allPost, setAllPost] = useState([]);
  const [latestPost, setLatestPost] = useState([]);
  const isMatch = useMediaQuery('(max-width:600px )')
  const { address } = useParams();
  const ScrollTop = useRef();

  var size = 5;


  const handleScroll = () => {
    window.scroll({ behavior: 'smooth', top: 0 })
  }


  const BlockRenderer = (props) => {
    const { style = 'normal' } = props.node

    if (style === 'normal') {
      return (
        <p
          style={{
            fontSize: '1.3em',
            background: 'white',
            lineHeight: 1.6,
            fontWeight: 400,
          }}
        >
          {props.children}
        </p>
      )

    }
    if (style === 'h5') {
      return (
        <body
          style={{
            fontSize: '20px',
            background: 'white',
            lineHeight: 2,
          }}
        >
          {props.children}
        </body>
      )

    }
    if (style === 'h6') {
      return (
        <body
          style={{
            fontSize: '1.3rem',
            background: 'white',
            lineHeight: 1.6,
            fontWeight: 400,
          }}
        >
          {props.children}
        </body>
      )

    }
    if (style === 'blockquote') {
      return (
        <body
          style={{
            margin: '20px 0 30px',
            borderLeft: '4px solid',
            paddingLeft: '20px',
            borderColor: 'indianred',
            color: '#4b4b4be0',
            fontSize: '18px',
            background: 'white',
          }}
        >
          {props.children}
        </body>
      )

    }

    return BlockContent.defaultSerializers.types.block(props)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[address.current == "${address}"]{
        title,
        mainImage{
            asset->{
                _id,
                url
            },
                   
        },
       body,
       date,
       authorname,
       description,
       categories {
           category[] -> {
               name,
               content
           }
       }
    }`,
      )
      .then((data) => setAllPost(data[0]))
      .catch(console.error);
  }, [address]);
  // console.log('allposts', allPost)


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        address,
        mainImage{
            asset->{
                _id,
                url
            },               
        },
        description,
        date,
        authorname,
        releaseDate,
        authorimage,
    }`
      )
      .then((data) => setLatestPost(data))
      .catch(console.error);
  }, []);
  // console.log("latestposts", latestPost.slice(0, size))




  return (
    <>
      {isMatch ? <MobBlogDetail /> :
        <div>
          <SimpleReactLightbox>
            <Box display={{ xs: 'none', sm: 'block' }} m={1}>
              <Container maxWidth="Lg" style={{ padding: "0px 50px 30px 50px" }}>
                <Card style={{ padding: " 0px 20px 20px", borderRadius: "20px" }} elevation={0} varient="outlined">
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        style={{
                          display: 'flex',
                          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                          fontWeight: 500,
                          fontSize: '2em',
                          // color: '#4b4b4be0',
                          marginTop: '0.5em',
                          lineHeight: 1
                        }}
                      >
                        {allPost.title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary" display="block" gutterBottom >
                        <DateRangeRoundedIcon sx={{ fontSize: 23 }} /> {allPost.date}
                      </Typography>
                    </Grid>
                    {allPost.mainImage && allPost.mainImage.asset && (
                      <Grid item xs={12} sm={12} md={12}>
                        <img
                          src={allPost.mainImage.asset.url}
                          width="85%"
                          height={450}
                          alt={allPost.address}
                          style={{ borderRadius: '10px' }}
                        />
                      </Grid>
                    )}
                    <Grid container spacing={1}>
                      <Grid item xs={8} style={{ padding: '20px 0px 0px 20px' }}>
                        <BlockContent blocks={allPost.body} projectId="nk6jutwv" dataset="production" imageOptions={{ w: 800, h: 500 }} serializers={{ types: { block: BlockRenderer } }} />
                        <Divider orientation="vertical" flexItem />
                      </Grid>
                      <Grid item xs={4} style={{ paddingTop: '2em', }}>
                        <Card
                          sx={{
                            // display: 'flex',
                            border: 'none',
                            boxShadow: 'none',
                            background: 'rgb(224 224 224 / 23%)'
                          }}>
                          <Grid xs={12} style={{ padding: '5px 0px 5px 0px', backgroundColor: 'rgb(201 6 75)', borderRadius: '5px' }}>
                            <Typography style={{ color: 'white', fontSize: '1.5rem', fontWeight: 500, marginLeft: '0.8em' }}>Latest Post</Typography>
                          </Grid>

                          {latestPost
                          .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                          .slice(0, size)
                          .map((item) => (
                            <Card key={item.address.current}
                              sx={{
                                display: 'flex',
                                border: 'none',
                                boxShadow: 'none',
                                background: 'transparent'
                              }}
                            >
                              <CardMedia
                                // style={{  }}
                                // onClick={Scroll}
                                component="img"
                                sx={{ padding: '1em 0em 0em 1em', width: 200, height: 100, borderRadius: '10px', }}
                                image={urlFor(item.mainImage).url()}
                                alt={item.address}

                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '0 0 auto' }}>
                                  <Link style={{ color: 'black', textDecoration: 'none' }} to={`/Blog/${item.address.current}`} onClick={handleScroll} >
                                    <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                                      {item.title}
                                    </Typography>
                                  </Link>

                                  <Typography variant="subtitle1" color="text.secondary" component="div">
                                    <DateRangeRoundedIcon sx={{ fontSize: 20 }} /> {item.date}
                                  </Typography>
                                </CardContent>
                              </Box>
                            </Card>
                          ))}
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
                {/* <Card style={{ padding: " 0px 20px 20px", borderRadius: "20px" }} elevation={0} >

              </Card> */}
              </Container>
            </Box>
          </SimpleReactLightbox>
          <div>
            <Footer />
          </div>
        </div>


      }
    </>
  )
}

export default BlogDetail;
