import React from 'react'
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, LinearProgress, Paper, styled, Tab, Tabs, TextareaAutosize, TextField, Typography } from '@mui/material';
import { BurstModeRounded, DescriptionRounded, HomeRounded, InsertPhotoRounded, MonetizationOnRounded, PhotoCamera, } from '@mui/icons-material';
import PropTypes from 'prop-types';
import firebase from '../firebase/firebase';


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


//////////*******styling************/////////////////

const StyledForm = styled('form')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1200,
    paddingBottom: 20,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: '1em'
}));


// const StyledDiv = styled('div')(({ theme }) => ({
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//     display: 'flex',
//     height: 'auto',
//     margin: 'auto',
//     maxWidth: 1000,
//     paddingBottom: 20,
// }));

const StyledPaper = styled(Paper)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1100,
    paddingBottom: 20,
}));

const TodayDate = new Date().toISOString().split('T')[0]

function FeaturedData(props) {

    const initialValues = {
        address: '',
        offerDate: '',
        listingPrice: '',
        MlsId: '',
        city: '',
        featuredImage: '',
        short: '',
        long: '',
        zip: '',
        link: '',
        sqfeetPrice: '',
        builder: '',
        propertyName: '',
    }

    var [values, setValues] = React.useState(initialValues);
    const [value, setValue] = React.useState(0);
    const [position, setPosition] = React.useState('')
    const [progress, setProgress] = React.useState(10);
    const [featuredImage, setfeaturedImage] = React.useState([]);
    var fireDB = firebase.database().ref();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleInputChange = (e) => {
        var { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        });
    }

    React.useEffect(() => {
        if (props.currentId === '') {
            setValues({ ...initialValues })
            setfeaturedImage([...initialValues.featuredImage || ''])

        }
        else {
            setValues({
                ...props.propertyObject[props.currentId]
            })
            setfeaturedImage([...props.propertyObject[props.currentId].featuredImage || ''])
        }
    }, [props.currentId, props.propertyObject])


    const handleFeaturedImage = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Featured Listings';
                const id = new Date().getTime();
                var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
                var uploadTask = storageRef.put(image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {
                        const downloadURL = await firebase
                            .storage()
                            .ref("Featured Listings")
                            .child(id + image.name)
                            .getDownloadURL();
                        setfeaturedImage([downloadURL]);
                    }
                )
            }
            task();
        }
    }

    const deleteFeaturedImage = (ImageUrl, setter) => {
        let image = firebase.storage().refFromURL(ImageUrl);
        image.delete().then(function () {
            if (setter === setfeaturedImage) {
                let newFeaturedImage = featuredImage.filter(item => item !== ImageUrl);
                setfeaturedImage(newFeaturedImage)
                setPosition('')
                if (props.currentId !== '') {
                    fireDB.child(`/${props.currentId}`).update({ featuredImage: newFeaturedImage })
                }
                console.log('image deleted', featuredImage, newFeaturedImage);
            }
        })
    }


    const handleFormsubmit = e => {
        e.preventDefault();
        props.add({ ...values, featuredImage })
        setfeaturedImage('')
    }

    return (
        <>
            <StyledForm noValidate autoComplete="off" onSubmit={handleFormsubmit}>
                <StyledPaper>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        // sx={{ width: '50%' }}
                    >
                        <Tab label="General" icon={<HomeRounded />} {...a11yProps(0)} />
                        <Tab label="Price" icon={<MonetizationOnRounded />}{...a11yProps(1)} />
                        <Tab label="Featured" icon={< InsertPhotoRounded />}{...a11yProps(2)} />
                        <Tab label="Gallery" icon={< BurstModeRounded />}{...a11yProps(3)} />
                        <Tab label="Description" icon={< DescriptionRounded />}  {...a11yProps(4)} />

                    </Tabs>
                    <TabPanel value={value} index={0} sx={{ ml: '1em' }}>
                        <Typography variant='h6'sx={{mb: '1em'}}>General</Typography>
                        <Grid container spacing={2} alignItems="flex-end" >
                            <Grid xs={8} align="start" sx={{ pt: '10px'}}>
                                <TextField
                                    label="Property Name"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="propertyName"
                                    value={values.propertyName}
                                    onChange={handleInputChange}
                                    size="medium"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={8} align="start" sx={{ pt: '15px'}}>
                                <TextField
                                    label="Address"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="address"
                                    value={values.address}
                                    onChange={handleInputChange}
                                    size="medium"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={6.5} align="start" sx={{ pt: '10px'}}>
                            <Autocomplete
                                    id="free-solo-demo"
                                    value={values.builder} name="builder"
                                    onSelect={handleInputChange}
                                    options={Builders.map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Builder" margin="normal" variant="outlined"
                                            value={values.builder} name="builder"
                                            onChange={handleInputChange}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={6} align="start" sx={{ pt: '10px'}}>
                                <Autocomplete
                                    id="free-solo-demo"
                                    value={values.city} name="city"
                                    onSelect={handleInputChange}
                                    options={citys.map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="City" margin="normal" variant="outlined"
                                            value={values.city} name="city"
                                            onChange={handleInputChange}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={8} align="start" sx={{ pt: '20px'}}>
                                <TextField
                                    id="date"
                                    label="Listed Date"
                                    type="date"
                                    defaultValue={TodayDate}
                                    variant="outlined"
                                    size="small"
                                    value={values.offerDate}
                                    name="offerDate"
                                    onChange={handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            {/* <StyledGrid xs={8} align="start">
                                <TextField
                                    label="Property Name"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="builder"
                                    value={values.propertyName}
                                    onChange={handleInputChange}
                                    size="medium"
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={8} align="start">
                                <TextField
                                    label="Address"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="address"
                                    value={values.address}
                                    onChange={handleInputChange}
                                    size="medium"
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={8} align="start">
                                <TextField
                                    label="MLS (ID)"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="MlsId"
                                    value={values.MlsId}
                                    onChange={handleInputChange}
                                    size="medium"
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={8} align="start">
                                <TextField
                                    label="Zip"
                                    id="filled-size-small"
                                    variant="outlined"
                                    value={values.zip}
                                    name="zip"
                                    size="medium"
                                    onChange={handleInputChange}
                                />
                            </StyledGrid>
                            <StyledGrid xs={6} align="start">
                                <Autocomplete
                                    id="free-solo-demo"
                                    value={values.city} name="city"
                                    onSelect={handleInputChange}
                                    options={citys.map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="City" margin="normal" variant="outlined"
                                            value={values.city} name="city"
                                            onChange={handleInputChange}
                                        />
                                    )}
                                />
                            </StyledGrid>
                            <StyledGrid xs={8} align="start">
                                <TextField
                                    label="Bulider"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="builder"
                                    value={values.builder}
                                    onChange={handleInputChange}
                                    size="medium"
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={8} align="start">
                                <TextField
                                    id="date"
                                    label="Listed Date"
                                    type="date"
                                    defaultValue={TodayDate}
                                    variant="outlined"
                                    size="small"
                                    value={values.offerDate}
                                    name="offerDate"
                                    onChange={handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </StyledGrid>
                            <StyledGrid xs={12} align="start">
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Redfin Link"
                                    value={values.link}
                                    name="link"
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </StyledGrid> */}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1} >
                        <Typography variant='h6'>Price</Typography>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sx={{ mb: '1em' }}>
                                <MonetizationOnRounded />
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="number"
                                    label="Listed Price"
                                    value={values.listingPrice}
                                    name="listingPrice"
                                    onChange={handleInputChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sx={{ mb: '1em' }}>
                                <MonetizationOnRounded />
                            </Grid>
                            <Grid item sx={{ mt: '2em' }}>
                                <TextField
                                    type="number"
                                    label="Price per Sq.ft"
                                    value={values.sqfeetPrice}
                                    name="sqfeetPrice"
                                    onChange={handleInputChange} />
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <MonetizationOnRounded />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        type="number"
                                        label="Start-Price"
                                        value={values.startprice}
                                        name="startprice"
                                        onChange={handleInputChange} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <MonetizationOnRounded />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        type="number"
                                        label="End-Price"
                                        value={values.startprice}
                                        name="startprice"
                                        onChange={handleInputChange} />
                                </Grid>
                            </Grid> */}

                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid container spacing={1}>
                            <StyledGrid xs={12} align="start">
                                <Typography variant="h6">Featured Image</Typography>
                                <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                    onChange={handleFeaturedImage} />
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera sx={{ mr: 3 }} />
                                    </IconButton>
                                </label>
                                <div >
                                    <Box width="100%" mr={1}>
                                        <LinearProgressWithLabel variant="determinate" value={progress}
                                            sx={{ width: '100%', }} />
                                    </Box>
                                </div>
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <div>
                                    {(() => {
                                        if (featuredImage) {
                                            return (featuredImage.map((url, index) => (
                                                <div key={index}>
                                                    <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                    <Button variant="contained" onClick={() => { deleteFeaturedImage(url, setfeaturedImage); setPosition(index) }}>
                                                        Delete
                                                    </Button>
                                                    {position === index &&
                                                        <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                    }
                                                </div>
                                            )))
                                        }
                                    })()}
                                </div>
                            </StyledGrid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>

                    </TabPanel>
                    <TabPanel value={value} index={4} style={{ width: '100%' }}>
                        <Typography variant='h6'>Description</Typography>
                        <StyledGrid xs={12}>
                            <TextareaAutosize
                                style={{ width: '100%' }}
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Short Description"
                                value={values.short}
                                name="short"
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </StyledGrid>
                        <StyledGrid xs={12}>
                            <TextareaAutosize
                                style={{ width: '100%' }}
                                aria-label="minimum height"
                                minRows={10}
                                placeholder="Long Description"
                                value={values.long}
                                name="long"
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </StyledGrid>
                    </TabPanel>
                </StyledPaper>
                <Button variant="contained" color="inherit" disableElevation type="submit" >
                    {props.currentId === '' ? "Save" : "Update"}
                </Button>
            </StyledForm>
        </>
    )
}






const citys = [
    { title: 'Sammamish' },
    { title: 'Kirkland' },
    { title: 'Lynnwood' },
    { title: 'Lake Stevens' },
    { title: 'Maple Valley' },
    { title: 'Redmond' },
    { title: 'Bellevue' },
    { title: 'Bothell' },
    { title: 'Issaquah' },
    { title: 'Mill Creek' },
    { title: 'Renton' },
    { title: 'Puyallup' },
]



const Builders = [
    { title: 'DR Horton' },
    { title: 'Richmond American Homes' },
    { title: 'Garrette Custom Homes	' },
    { title: 'KB Home' },
    { title: 'Pulte Homes' },
    { title: 'Lennar' },
    { title: 'Toll Brothers Camwest' },
    { title: 'Conner Homes' },
    { title: 'MainVue Homes' },
    { title: 'JK Monarch' },
    { title: 'Century Communities' },
    { title: 'Quadrant Homes' },
    { title: 'Acme Homes' },
    { title: 'Shea Homes' },
    { title: 'Blackwood Builders' },
    { title: 'HC Homes' },
    { title: 'Lungren Homes' },
    { title: 'Murray Franklyn' },
    { title: 'Azure Northwest Homes' },
    { title: 'Sage Homes NW' },
    { title: 'Rush Residential' },
    { title: 'Cornerstone Homes' },
    { title: 'Isola Homes	' },
    { title: 'BDR Homes' },
    { title: 'Westcott Homes' },
    { title: 'Capstone Homes' },
    { title: 'Hardin Construction & Design' },
    { title: 'GProjects' },
    { title: 'BlueFern Development' },
    { title: 'Hinkle Homes' },
    { title: 'John Day Homes' },
    { title: 'Terrene Homes' },
    { title: 'Build Urban' },
    { title: 'High Country Homes' },
    { title: 'Haberzetle Homes' },
    { title: 'Green Canopy Homes' },
    { title: 'Skeers Construction' },
    { title: 'Zetterberg Custom Homes' },
    { title: 'JayMarc Homes' },
    { title: 'Pacific Ridge Homes' },
    { title: 'Lexar Homes' },
    { title: 'Steele Homes' },
    { title: 'Ashworth Homes' },
    { title: 'Dwell Development' },
    { title: 'Mietzner Brothers Properties' },
    { title: 'Macfarlane Homes' },
    { title: 'JMS Homes' },
    { title: 'Scott Donogh Homes' },
    { title: 'Mead Homes' },
    { title: 'Greenbuild Development' },
    { title: 'Influx Development' },
    { title: 'Steve Burnstead' },
    { title: 'Oakridge Homes' },
    { title: 'Ichijo USA' },
    { title: 'Adair Homes' },
    { title: 'Eastside Homes' },
    { title: 'Evergreen Heights' },
    { title: 'Appleton Homes' },
    { title: 'Summit Homes' },
    { title: 'Landed Gentry' },
    { title: 'Domus Homes' },
    { title: 'Glong Construction' },
    { title: 'Gamut360' },
    { title: 'Omega Custom Homes' },
    { title: 'Jabooda Homes' },
    { title: 'Extraordinary Homes' },
    { title: 'Shelter Homes' },
    { title: 'StoryBuilt' },
    { title: 'Classic Concepts' },
    { title: 'M & P Builders' },
    { title: 'Harbour Homes' },
    { title: 'Robinett Homes' },
    { title: 'Hunter Homes' },
    { title: 'William Buchan' },
    { title: 'Great Northwest Homes' },
    { title: 'Design Built Homes' },
    { title: 'Curtis Lang Custom Homes' },
    { title: 'Slusher Homes' },
    { title: 'Wildheart Homes' },
    { title: 'Schneider Homes' },
    { title: 'Roberts Construction' },
    { title: 'Sterling Custom Homes' },
    { title: 'Sager Family Homes' },
    { title: 'Adair Enterprises' },
    { title: 'Hendrickson Construction' },
    { title: 'Buffington Custom Homes' },
    { title: 'Harkey Construction' },
    { title: 'Strandberg Construction' },
    { title: 'BYK Construction' },
    { title: 'Merit Homes' },
    { title: 'K & L Homes' },
    { title: 'Craftsman Group' },
    { title: 'Christopher Homes' },
    { title: 'SNC Development' },
    { title: 'Hawthorne Land Dev' },
    { title: 'KLP' },
    { title: 'Irving Construction' },
    { title: 'Town & Country Homes' },
    { title: 'A & J Precision Homes' },
    { title: 'Dynasty Homes' },
    { title: 'Campbell Homes' },
    { title: 'MN Custom Homes' },
    { title: 'Aho Construction' },
    { title: 'Alliance Properties' },
    { title: 'MacKinnon Design & Development' },
    { title: 'Sapphire Homes' },
    { title: 'Titan Homes NW' },
    { title: 'Wick Homes' },
    { title: 'Polygon Northwest' },
    { title: 'DJ & DJ Contracting' },
    { title: 'Freestone' },
    { title: 'Dunca' },
    { title: 'Kobza Home Builders' },
    { title: 'Eagle Homes' },
    { title: 'Cascade View' },
    { title: 'Village Life' },
    { title: 'RM Homes' },
    { title: 'BJ Builders' },
    { title: 'MGM Construction' },
    { title: 'Fairwinds Development' },
    { title: 'Trailside Homes' },
    { title: 'Waldron Construction' },
    { title: 'Envision Northwest' },
    { title: 'Rick Burnstead Construction' },
    { title: 'Brooks Homes' },
    { title: 'Kohary Builders' },
    { title: 'Rudd Development' },
    { title: 'Element Residential' },
    { title: 'Lubbesmeyer Construction' },
    { title: 'Hansen Construction' },
    { title: 'Hayden Homes' },
    { title: 'Grandview Homes' },
    { title: 'MC Construction' },
  ]

export default FeaturedData