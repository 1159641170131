import React, { useContext } from 'react'
import { Box, Container, Stack, Button, styled, useTheme, Card, Typography, Divider, Paper, Grid, CardMedia, CardActionArea, Table, TableRow, TableContainer, TableCell, Avatar, Link, createTheme, TextField, useMediaQuery, ListItem, Collapse, ListItemText, MobileStepper, Alert, Snackbar, } from '@mui/material'
import { PropertyContext } from '../context';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { Add, Forum, PhoneIphone, Remove } from '@mui/icons-material';
import whiteImage from '../images/Image_not_available.png';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import NumberFormat from 'react-number-format';
import Image from '../images/Deepti.png';
import Sketch from '../images/Plan.jpg';
import DailogForm from '../contact/DailogForm';
import AlexNavbar from './AlexNavbar';
import Footer from '../components/Footer'; import Loading from '../components/Loading';
import ReactPlayer from 'react-player';

function Alert1(props) {
    return <Alert elevation={6} variant="filled" {...props} />
}

function AlertError1(props) {
    return <Alert elevation={6} variant="filled" {...props} />;
}


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const BannerContainer = styled('div')({
//     position: 'relative',
//     width: '100%',
//     height: '300px',
//     background: '#f5f5f5',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
// });

// const BannerImage = styled('img')({
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '200px',
//     height: '200px',
//     borderRadius: '50%',
//     boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
// });

const BannerText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    opacity: 0,
    transition: 'opacity 0.5s ease-in',
}));

// const AnimatedBannerText = ({ children }) => {
//     const [isVisible, setIsVisible] = React.useState(false);

//     React.useEffect(() => {
//         const timer = setTimeout(() => {
//             setIsVisible(true);
//         }, 500);

//         return () => clearTimeout(timer);
//     }, []);

//     return <BannerText style={{ opacity: isVisible ? 1 : 0 }}>{children}</BannerText>;
// };


const Containerimg = styled('img')(({ theme, isMatch }) => ({
    height: isMatch ? "290px" : "730px",
    display: 'block',
    overflow: 'hidden',
    width: '100%',
}));

const CustomTypography = styled(Typography)(({ theme, isMatch }) => ({
    color: 'rgb(255, 255, 255)',
    textShadow: 'rgba(0, 0, 0, 0.5) 0px 10px 15px',
    fontSize: isMatch ? 13 : 18,
    fontWeight: isMatch ? 500 : 700
}));

const CustomTypography1 = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    fontSize: '3em',
    '&::before': {
        content: '""',
        display: 'inline-block',
        width: '234px',
        height: '10px',
        margin: '0px 15px 8px 0px',
        borderTop: '2px solid rgb(238, 238, 238)',
        borderBottom: '2px solid rgb(238, 238, 238)'
    },
    '&::after': {
        content: '""',
        display: 'inline-block',
        width: '234px',
        height: '10px',
        margin: '0px 0px 8px 15px',
        borderTop: '2px solid rgb(238, 238, 238)',
        borderBottom: '2px solid rgb(238, 238, 238)',
        backgroundColor: 'transparent',
    },

}));

const Customtypo = styled(Typography)(({ theme, isMatch }) => ({
    fontSize: isMatch ? 24 : 35,
    lineHeight: 1.2,
    position: 'relative',
    fontWeight: 700,
    '&::after': {
        content: '""',
        display: 'block',
        marginTop: '15px',
        width: 100,
        height: 4,
        backgroundColor: 'rgb(0, 174, 240)',
    }
}));

const CustomRemove = styled(Remove)(({ theme, isMatch }) => ({
    background: "rgb(255 141 0)",
    borderRadius: "1000px",
    color: "white",
    height: "20px",
    width: "20px"
}));

const CustomAdd = styled(Add)(({ theme, isMatch }) => ({
    background: "rgb(255 141 0)",
    borderRadius: "1000px",
    color: "white",
    height: "20px",
    width: "20px"
}));


const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'absolute',
    backgroundColor: 'transparent',
    bottom: 0,
    left: 0,
    width: '95%',
    '& .MuiMobileStepper-dot': {
        width: 14,
        height: 14,
        borderRadius: '50%',
        margin: '0 5px',
        border: '1px solid #bdbdbd',
        transition: 'background-color 0.3s, transform 0.5s ease-in-out',
        transform: 'scale(1)',
    },

    '& .MuiMobileStepper-dotActive': {
        backgroundColor: '#fff',
        transform: 'scale(1)',
    },
}));

const StyledCard = styled(Card)(({ theme, isMatch }) => ({
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: 0,
    overflowWrap: 'break-word',
    backgroundClip: 'border-box',
    border: '0px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '0.75rem',
    overflow: 'visible',
    // padding: isMatch ? 10 : '50px',
    marginTop: '-64px',
    marginBottom: '32px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'saturate(200%) blur(30px)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
}))

// const SlideInText = styled(Typography)(({ theme }) => ({
//     backgroundColor: 'red',
//     height: '4px',
//     width: '100%',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     transform: 'rotate(45deg)', // Adjust the rotation angle as needed
//     transformOrigin: 'top left',
//     '&::before': {
//         content: '""',
//         position: 'absolute',
//         top: 0,
//         left: '-4px',
//         width: '4px',
//         height: 'calc(100% + 4px)',
//         backgroundColor: 'red',
//         transform: 'rotate(-45deg)', // Adjust the opposite angle of the main rotation
//     },
//     '&::after': {
//         content: '""',
//         position: 'absolute',
//         top: '-4px',
//         left: 0,
//         width: 'calc(100% + 4px)',
//         height: '4px',
//         backgroundColor: 'red',
//         transform: 'rotate(-45deg)', // Adjust the opposite angle of the main rotation
//     },
// }));


// const GlowingText = styled('span')(({ theme }) => ({
//     textShadow: theme.typography.glow.textShadow,
//     marginLeft: '10px',
//     // animation: 'blink-animation 1s infinite',
//     // '@keyframes blink-animation': {
//     //     '0%': {
//     //         opacity: 1,
//     //     },
//     //     '50%': {
//     //         opacity: 0,
//     //     },
//     //     '100%': {
//     //         opacity: 1,
//     //     },
//     // }
// }));

// const Slidesection = styled(Stack)(({ theme }) => ({
//     opacity: `${(props) => (props.show ? '1' : '0')}`,
//     transform: `translateY(${(props) => (props.show ? '0' : '100%')})`,
//     transition: 'opacity 0.5s ease, transform 0.5s ease',
// }));

// const theme1 = createTheme({
//     typography: {
//         fontFamily: 'Arial', // Replace with your preferred font
//         glow: {
//             textShadow: '0 0 8px #FF0000', // Customize the glow effect here
//         },
//     },
// });

function Floor(props) {
    const floorUrl = props.floorUrl
    const isMatch = props.isMatch

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }

    return (
        <div item key={floorUrl} style={{ "padding": isMatch ? '5px' : "10px" }} >
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <Card elevation={4} spacing={2} style={{ width: 'auto', height: 'auto' }}>
                        <CardActionArea>
                            <CardMedia className="imageee"
                                component="img"
                                image={floorUrl} alt="imageee"
                                title="Live from space album cover"
                                sx={{ p: isMatch ? '5px' : '15px' }}
                            />
                        </CardActionArea>
                    </Card>
                </SRLWrapper>
            </SimpleReactLightbox>
        </div>
    )

}



function ImageSlide(props) {
    const url = props.url;
    const image = url.slice(0, 3)
    const { address, beds, startprice, size, bathroom, isMatch } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [showSection, setShowSection] = React.useState(false);
    const theme = useTheme();

    const maxSteps = image.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }

    React.useEffect(() => {
        setShowSection(true);
    }, []);


    return (
        <>
            <div tyle={{ position: 'relative', }}>
                <SRLWrapper options={options}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >{(() => {
                        if (image)
                            return image.map((item, index) => (
                                <div key={image}>
                                    {Math.abs(activeStep - index) <= 40 ? (
                                        <Containerimg src={item} alt={item} isMatch={isMatch} />
                                    ) : null}

                                </div>
                            ))
                        else {
                            return <div >
                                <Containerimg src={whiteImage} alt="Image" />
                            </div>
                        }

                    })()}

                    </AutoPlaySwipeableViews>
                    <Stack sx={{ position: 'absolute', width: '95%', top: isMatch ? '20em' : '50em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <StyledMobileStepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={null}
                            backButton={null}
                            sx={{
                                position: 'absolute', bottom: 0, left: 0, width: '95%',
                                backgroundColor: 'transparent',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '10px 0',
                            }}
                            DotProps={{
                                sx: {
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    margin: '0 5px',
                                    transition: 'transform 0.3s',
                                    transform: activeStep === 0 ? 'scale(1.5)' : 'scale(1)',
                                },
                            }}
                        />
                    </Stack>

                </SRLWrapper>
            </div>
            <Box sx={{ position: 'absolute', width: isMatch ? '40%' : '40%', top: isMatch ? '12em' : '40em', right: isMatch ? '0em' : '1em' }}>
                <Stack display="column" spacing={isMatch ? 1 : 2}>
                    <Typography
                        sx={{
                            color: 'rgb(255, 255, 255)',
                            textShadow: 'rgba(0, 0, 0, 0.5) 0px 10px 15px',
                            fontSize: isMatch ? 15 : 25,
                            fontWeight: 700
                        }}
                    >{address}
                    </Typography>
                    <CustomTypography isMatch={isMatch}>Starting from<NumberFormat style={{ marginLeft: '5px' }} value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></CustomTypography>
                    {/* <Stack sx={{ mt: '2em' }} direction="row" divider={<Divider orientation="vertical" flexItem sx={{ backgroundColor: 'rgb(255, 255, 255)' }} />} spacing={2}>
                        <CustomTypography isMatch={isMatch}><NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></CustomTypography>
                        <CustomTypography isMatch={isMatch}>{beds} Beds</CustomTypography>
                        <CustomTypography isMatch={isMatch}>{bathroom} Baths</CustomTypography>
                        <CustomTypography isMatch={isMatch}>{size} Sq Ft</CustomTypography>
                    </Stack> */}
                </Stack>

            </Box>
            {/* <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
            /> */}
            {/* <Tabs
                variant="scrollable"
                value={activeStep}
                onChange={handleStepChange}
                orientation="horizontal"
                scrollButtons
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    '& .MuiTabScrollButton-root': {
                        backgroundColor: '#1565c0',
                        color: 'white',
                    },

                }}
            >
                {url.map((item, index) => {
                    return (
                        <div key={index} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                            <img
                                src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                alt="image"
                                loading='lazy'
                                style={{
                                    height: isMatch ? '85px' : '150px',
                                    cursor: 'pointer',
                                    borderBottom: activeStep === index ? '7px solid #00aef0' : 'none',
                                    opacity: activeStep === index ? '0.5' : '1'
                                }}
                                onClick={() => setActiveStep(index)}
                            />
                        </div>
                    )
                })
                }
            </Tabs> */}
        </>
    )
}



const Projectalex61 = () => {
    const context = useContext(PropertyContext);
    const theme = useTheme();
    const { sortedProperty, allHomes } = context;
    const [openn, setOpenn] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [opene, setOpene] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [open1, setOpen1] = React.useState(true);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [openImage, setOpenImage] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSc3WTKa4S8OKjQh13A__BHYZY3pl56dx7tCZBimwjv_WgNgCA/formResponse?embedded=true';

    const OverviewScrolldiv = React.useRef(null)
    const DescriptionScrolldiv = React.useRef(null)
    const FloorScrolldiv = React.useRef(null)
    const PhotoGalleryScrolldiv = React.useRef(null)
    const VedioGalleryScrolldiv = React.useRef(null)
    const SitemapScrolldiv = React.useRef(null)
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const fullText = 'Lynnwood';
    // Replace with your desired end value

    let SingleCommunity = allHomes.filter(house => house.propertyname === "Newest Lynnwood Townhomes by Mural Homes");
    const url = SingleCommunity.map((item) => item.url);
    // console.log('image', url)
    const endValue = SingleCommunity.map((item) => item.startprice)

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }

    const handleClickDescription = () => {
        setOpen1(!open1);
    };
    const handleClickFloor = () => {
        setOpen2(!open2);
    };

    const handleClickPhoto = () => {
        setOpen3(!open3);
    };

    const handleClicksitemap = () => {
        setOpen4(!open4);
    };

    const handleClickSchool = () => {
        setOpen5(!open5);
    };

    const handleClickVedio = () => {
        setOpen6(!open6);
    };

    const handleClickImage = () => {
        setOpenImage(!openImage);
    };

    const handleOpen = () => {
        setOpenn(true);
    };

    const handleDone = () => {
        setOpenn(false);
    };


    const handleClick = () => {
        setOpen(true);
    };

    const handleClickError = () => {
        setOpenError(true);
    };

    const handleClose = (event, reason) => {
        setOpenError(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        // Submit the form to the Google Form
        const formData = new FormData();
        formData.append('entry.1953929110', email); // Replace 'entry.1234567890' with the actual field ID of the email input in your Google Form
        fetch(googleFormURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                // Handle success response
                // console.log('Form submitted successfully');
                // Show response message to the user

                handleClick(Alert1);
                // Reset the email input field
                setEmail('');
            })
            .catch((error) => {
                // Handle error
                // console.error('Error submitting form:', error);
                // Show error message to the user
                handleClickError(AlertError1);
                setEmail('');
            });
    };



    const OverviewScroll = () => OverviewScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const DescriptionScroll = () => DescriptionScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const FloorScroll = () => FloorScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const PhotoGalleryScroll = () => PhotoGalleryScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const VedioGalleryScroll = () => VedioGalleryScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const SitemapScroll = () => SitemapScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })




    if (SingleCommunity.length === 0) {
        return <Loading />
    }
    // console.log("url test", url)


    return (
        <>
            <Box
            >
                {
                    isMatch ? null
                        :
                        <AlexNavbar
                            OverviewScrolldiv={OverviewScroll}
                            DescriptionScrolldiv={DescriptionScroll}
                            FloorScrolldiv={FloorScroll}
                            PhotoGalleryScrolldiv={PhotoGalleryScroll}
                            VedioGalleryScrolldiv={VedioGalleryScroll}
                            SitemapScrolldiv={SitemapScroll}
                        />
                }
            </Box>
            {
                isMatch ?
                    <StyledCard sx={{ m: '4em 0em 0em 0em' }} isMatch={isMatch}>
                        {
                            SingleCommunity.map((item) => {
                                return (
                                    <ImageSlide
                                        isMatch={isMatch}
                                        url={item.url}
                                        address={item.address}
                                        beds={item.bedrooms}
                                        bathroom={item.bathrooms}
                                        size={item.size}
                                        startprice={item.startprice}
                                        style={{ borderRadius: "20px 0px 0px 20px", height: 400 }} />
                                )
                            })
                        }
                    </StyledCard>
                    :
                    <Box display={{ xs: 'block', sm: 'block' }}  >
                        {
                            SingleCommunity.map((item) => {
                                return (
                                    <StyledCard sx={{ m: '2em 4em 4em 4em' }}>
                                        <ImageSlide
                                            isMatch={isMatch}
                                            url={item.url}
                                            address={item.address}
                                            beds={item.bedrooms}
                                            bathroom={item.bathrooms}
                                            size={item.size}
                                            startprice={item.startprice}
                                            style={{ borderRadius: "20px 0px 0px 20px", height: 400 }} />
                                    </StyledCard>
                                )
                            })
                        }
                    </Box>
            }

            <StyledCard elevation={1} sx={{ m: isMatch ? '2em' : '3em', transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', }} isMatch={isMatch} >
                {
                    SingleCommunity.map((item) => {
                        return (
                            <Grid container spacing={isMatch ? 1 : 3} justifyContent="center" alignItems="center">
                                <Grid item xs={isMatch ? 12 : 6} ref={OverviewScrolldiv}>
                                    <Stack direction="column" divider={<Divider sx={{ backgroundColor: '#000)' }} orientation="horizontal" flexItem />} sx={{ p: isMatch ? '1em' : '2em' }} spacing={isMatch ? 1 : 2}>
                                        <Stack direction="column">
                                            <Typography sx={{ width: isMatch ? '100%' : '70%', fontSize: isMatch ? 20 : 26, fontWeight: 800, color: 'rgb(26, 115, 232)' }}>20102 61st Place West</Typography>
                                            <Typography sx={{ width: isMatch ? '100%' : '70%', fontSize: isMatch ? 18 : 20, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Lynnwood, WA 98036</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography sx={{ fontSize: isMatch ? 14 : 16, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>Open House: </Typography>
                                            <Typography sx={{ fontSize: isMatch ? 13 : 15, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>{item.movein}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography sx={{ fontSize: isMatch ? 14 : 16, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>Open House: </Typography>
                                            <Typography sx={{ fontSize: isMatch ? 13 : 15, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>{item.facing}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={isMatch ? 8 : 16}>
                                            <Typography sx={{ fontSize: isMatch ? 15 : 19, fontWeight: 800, color: 'rgb(26, 115, 232)' }}><NumberFormat value={item.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> +</Typography>
                                            <Typography sx={{ fontSize: isMatch ? 15 : 16, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Status: Active</Typography>
                                        </Stack>
                                        {/* <Stack direction="column" spacing={2}>
                                            <Stack direction="row" spacing={5}>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={1} sx={{ width: '20%' }} justifyContent="center" alignItems="center">
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>3</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Beds</Typography>
                                                </Stack>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={1} sx={{ width: isMatch ? '27%' : '20%' }} justifyContent="center" alignItems="center">
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>3.5</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Baths</Typography>
                                                </Stack>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={1} sx={{ width: isMatch ? '33%' : '22%', ml: isMatch ? '25px' : null }} justifyContent="center" alignItems="center">
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>2</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Stories</Typography>

                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" spacing={5}>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={1} sx={{ width: isMatch ? '30%' : '20%' }} justifyContent="center" alignItems="center">
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>2</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Garage</Typography>
                                                </Stack>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={1} sx={{ width: '20%', ml: isMatch ? 18 : null }} justifyContent="center" alignItems="center">
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>2,345</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Sq Ft</Typography>
                                                </Stack>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={1} sx={{ width: isMatch ? '32%' : '20%', ml: isMatch ? '25px' : null }} justifyContent="center" alignItems="center">
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>1</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Lot</Typography>

                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <Stack direction={isMatch ? "column" : "row"} spacing={isMatch ? 1 : 2} justifyContent="center" alignItems="center" sx={{ width: isMatch ? '20%' : null }}>
                                                    <Typography sx={{ fontSize: isMatch ? 17 : 18, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>2,355</Typography>
                                                    <Typography sx={{ fontSize: isMatch ? 15 : 17, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Lot Sq Ft</Typography>

                                                </Stack>
                                            </Stack>
                                        </Stack> */}
                                        <Stack direction="row" spacing={1}>
                                            <Typography sx={{ fontSize: isMatch ? 15 : 16, fontWeight: 400, color: 'rgb(123, 128, 154)' }}>Community by </Typography>
                                            <Typography sx={{ fontSize: isMatch ? 15 : 16, fontWeight: 700, color: 'rgb(26, 115, 232)' }}>Mural Homes</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 6}>
                                    <Box sx={{ m: isMatch ? 0 : '3em', bgcolor: '#000', minHeight: '450px' }}>
                                        <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={isMatch ? 2 : 3} sx={{ p: isMatch ? '1em' : '5em 0em 0em 1em' }}>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={Image}
                                                sx={{ width: 150, height: 150 }}
                                            />
                                            <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                                                <Typography sx={{ fontSize: isMatch ? '2em' : '2.5em', fontWeight: 400, color: '#fff' }}>Schedule tour with us</Typography>
                                                <Typography sx={{ fontSize: isMatch ? '13px' : '1.1em', fontWeight: 400, color: '#fff', textTransform: 'uppercase' }}>Our Team is Here to Help</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={isMatch ? 5 : 3} sx={{ p: isMatch ? '2em' : '4em 5em 4em 5em' }}>
                                            <Button variant='outlined' sx={{
                                                width: isMatch ? '15em' : '25em',
                                                height: isMatch ? 32 : 50,
                                                fontSize: isMatch ? 15 : '1.1em',
                                                border: '2px solid white', // Set the border color to white
                                                bgcolor: 'transparent',
                                                color: '#fff',// Set the background color to transparent
                                                '&:hover': {
                                                    border: '2px solid white',
                                                    bgcolor: 'transparent', // Set the background color to white on hover
                                                },
                                            }} startIcon={<PhoneIphone />}>
                                                <Link style={{ textDecoration: 'none', color: '#fff' }} href="tel:425-588-8470">Call 425-588-8470</Link>
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary' s
                                                startIcon={<Forum />}
                                                onClick={handleOpen}
                                                sx={{ height: isMatch ? 32 : '50px', fontSize: isMatch ? 12 : '1.1em', width: isMatch ? '19em' : '25em' }}>
                                                Send Message
                                            </Button>
                                            <DailogForm Open={openn} Close={handleDone} property={item.propertyname} />
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sx={{ mt: '2em' }} ref={DescriptionScrolldiv}>
                                    {
                                        isMatch ?
                                            <>
                                                <ListItem button onClick={handleClickDescription} sx={{ background: 'whitesmoke' }}>
                                                    <ListItemText sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: 20
                                                        }
                                                    }} primary="Description" />
                                                    {open1 ? <CustomRemove /> : <CustomAdd />}
                                                </ListItem>
                                                <Collapse in={open1} timeout="auto" unmountOnExit  >
                                                    <Stack justifyContent="center" alignItems="center" sx={{ m: '1em 0em' }}>
                                                        <Container maxWidth="md">
                                                            <Typography sx={{ fontSize: 14 }}>
                                                                Experience Luxury living at Alexander61, an exclusive community of 13 luxurious townhomes by Mural Homes. Designed to
                                                                provide comfort, style, and convenience in one of the most desirable locations in Lynnwood. These homes are flooded with natural
                                                                light and feature luxury finishes, soaring ceilings, oversized windows, and warm vinyl plank floors.
                                                                With a variety of features and upgrades such as dedicated oversized garages, high-efficiency heating and cooling, high-end
                                                                appliances, quartz surfaces, spa retreat bathrooms, and designer kitchens, these homes are designed to meet your modern needs
                                                                with flexible spaces to live and work.
                                                                Our development is conveniently located near grocery stores, shops, restaurants, and entertainment options within a 2-mile radius.
                                                                Families with children will also appreciate the proximity of Edmonds High School. Our community is located in a peaceful and
                                                                serene neighborhood, yet very near to everything, perfect for families, couples, and anyone looking for a relaxing retreat that's still
                                                                conveniently located near all the best amenities. With plenty of outdoor recreational opportunities nearby, including parks,
                                                                restaurants, pubs, Fitness Clubs, Skating & Bowling Arena..
                                                            </Typography>
                                                        </Container>
                                                    </Stack>
                                                </Collapse>
                                            </>
                                            :
                                            <>
                                                <Stack justifyContent="center" alignItems="center">
                                                    <CustomTypography1>Description</CustomTypography1>
                                                </Stack>
                                                <Stack justifyContent="center" alignItems="center" sx={{ mt: '3em' }}>
                                                    <Container maxWidth="lg">
                                                        <Typography sx={{ fontSize: 16 }}>
                                                            Experience Luxury living at Alexander61, an exclusive community of 13 luxurious townhomes by Mural Homes. Designed to
                                                            provide comfort, style, and convenience in one of the most desirable locations in Lynnwood. These homes are flooded with natural
                                                            light and feature luxury finishes, soaring ceilings, oversized windows, and warm vinyl plank floors.
                                                            With a variety of features and upgrades such as dedicated oversized garages, high-efficiency heating and cooling, high-end
                                                            appliances, quartz surfaces, spa retreat bathrooms, and designer kitchens, these homes are designed to meet your modern needs
                                                            with flexible spaces to live and work.
                                                            Our development is conveniently located near grocery stores, shops, restaurants, and entertainment options within a 2-mile radius.
                                                            Families with children will also appreciate the proximity of Edmonds High School. Our community is located in a peaceful and
                                                            serene neighborhood, yet very near to everything, perfect for families, couples, and anyone looking for a relaxing retreat that's still
                                                            conveniently located near all the best amenities. With plenty of outdoor recreational opportunities nearby, including parks,
                                                            restaurants, pubs, Fitness Clubs, Skating & Bowling Arena..
                                                        </Typography>
                                                    </Container>
                                                </Stack>
                                            </>
                                    }

                                </Grid>
                                <Grid xs={12} sx={{ m: isMatch ? null : '2em', }} ref={FloorScrolldiv}>
                                    {
                                        isMatch ?
                                            <>
                                                <ListItem button onClick={handleClickFloor} sx={{ background: 'whitesmoke' }}>
                                                    <ListItemText sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: 20
                                                        }
                                                    }} primary="Floor Plan" />
                                                    {open2 ? <CustomRemove /> : <CustomAdd />}
                                                </ListItem>
                                                <Collapse in={open2} timeout="auto" unmountOnExit  >
                                                    <Grid container direction="row" sx={{ m: '1em 0em' }}>
                                                        {(item.floorUrl).map((data) => (
                                                            <Grid item xs={12}><Floor floorUrl={data} isMatch={isMatch} /></Grid>
                                                        )
                                                        )}
                                                    </Grid>
                                                </Collapse>
                                            </>
                                            :
                                            <>
                                                <Stack justifyContent="center" alignItems="center" >
                                                    <CustomTypography1>Floor Plan</CustomTypography1>
                                                </Stack>
                                                <Grid container direction="row" sx={{ mt: '3em' }} justifyContent="center" alignItems="center">
                                                    {(item.floorUrl).map((data) => (
                                                        <Grid item xs={6}><Floor floorUrl={data} /></Grid>
                                                    )
                                                    )}
                                                </Grid>
                                            </>
                                    }

                                </Grid>
                                <Grid xs={12} sx={{ m: isMatch ? null : '2em', }} ref={PhotoGalleryScrolldiv}>
                                    {
                                        isMatch ?
                                            <>
                                                <ListItem button onClick={handleClickPhoto} sx={{ background: 'whitesmoke' }}>
                                                    <ListItemText sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: 20
                                                        }
                                                    }} primary="Photo Gallery" />
                                                    {open3 ? <CustomRemove /> : <CustomAdd />}
                                                </ListItem>
                                                <Collapse in={open3} timeout="auto" unmountOnExit sx={{ p: '1em' }}>
                                                    <Grid container direction="row" spacing={2} sx={{ m: '1em -1em' }} justifyContent="start" alignItems="start">
                                                        {
                                                            (item.url).slice(0, 6).map((item, index) => {
                                                                return (
                                                                    <Grid key={index} item xs={6} sm={6} md={4}>
                                                                        <SimpleReactLightbox>
                                                                            <SRLWrapper options={options}>
                                                                                <Card
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        height: '170px',
                                                                                        '&:hover': {
                                                                                            cursor: index === 5 && (openImage === false) ? null : 'pointer',
                                                                                            filter: index === 5 && (openImage === false) ? null : 'brightness(0.5)',
                                                                                            transform: index === 5 && (openImage === false) ? null : 'scale(1.05)',
                                                                                        },
                                                                                    }}>
                                                                                    {index === 5 && (openImage === false) ?
                                                                                        <CardActionArea>
                                                                                            <CardMedia
                                                                                                component="img"
                                                                                                width="100%"
                                                                                                height='170px'
                                                                                                image={item}
                                                                                                alt="Image"
                                                                                                sx={{ filter: 'brightness(0.5)', }}
                                                                                            />
                                                                                            <Box
                                                                                                sx={{
                                                                                                    position: 'absolute',
                                                                                                    top: '50%',
                                                                                                    left: '50%',
                                                                                                    transform: 'translate(-50%, -50%)',
                                                                                                }}
                                                                                            >
                                                                                                <Button variant="outlined" onClick={handleClickImage} sx={{
                                                                                                    color: '#fff',
                                                                                                    fontSize: '10px',
                                                                                                    width: '11em',
                                                                                                    '&.MuiButton-outlined': {
                                                                                                        borderColor: '#fff',
                                                                                                        color: '#fff',
                                                                                                        '&:hover': {
                                                                                                            cursor: 'pointer',
                                                                                                            transform: 'scale(1.05)',
                                                                                                            borderColor: '#fff',
                                                                                                            color: '#fff',
                                                                                                        },
                                                                                                    }
                                                                                                }}>
                                                                                                    Load More
                                                                                                </Button>
                                                                                            </Box>
                                                                                        </CardActionArea>
                                                                                        :
                                                                                        <CardActionArea>
                                                                                            <CardMedia
                                                                                                component="img"
                                                                                                width="100%"
                                                                                                height='170px'
                                                                                                image={item}
                                                                                                alt="Image"
                                                                                            />
                                                                                        </CardActionArea>}
                                                                                </Card>
                                                                            </SRLWrapper>
                                                                        </SimpleReactLightbox>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }

                                                    </Grid>
                                                    <Grid container direction="row" spacing={2} >
                                                        {/* {openImage ? null : <Button onClick={handleClickImage} variant="contained" sx={{
                                                            m: '2em',
                                                            width: '8em',
                                                            height: '2em',
                                                            color: '#fff',
                                                            background: '#00aef0',
                                                            fontSize: 15,
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                background: '#00aef0',
                                                                transform: 'scale(1.05)',
                                                                color: '#fff',
                                                            },
                                                        }}>
                                                            Load More
                                                        </Button>} */}
                                                        <Collapse in={openImage} timeout="auto" unmountOnExit sx={{ ml: '1em', width: '100%' }} >
                                                            <Grid container direction="row" spacing={2} sx={{ m: '1em -1em' }} justifyContent="start" alignItems="start">
                                                                {
                                                                    (item.url).slice(9).map((item, index) => {
                                                                        return (
                                                                            <Grid key={index} item xs={6} sm={6} md={4}>
                                                                                <SimpleReactLightbox>
                                                                                    <SRLWrapper options={options}>
                                                                                        <Card
                                                                                            sx={{
                                                                                                width: '100%',
                                                                                                height: '170px',
                                                                                                '&:hover': {
                                                                                                    cursor: 'pointer',
                                                                                                    filter: 'brightness(0.5)',
                                                                                                    transform: 'scale(1.05)',
                                                                                                },
                                                                                            }}>
                                                                                            <img width="100%" height="100%" src={item} alt="image" />
                                                                                        </Card>
                                                                                    </SRLWrapper>
                                                                                </SimpleReactLightbox>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Collapse>
                                                    </Grid>
                                                </Collapse>

                                            </>
                                            :
                                            <>
                                                <Stack justifyContent="center" alignItems="center" >
                                                    <CustomTypography1>Photo Gallery</CustomTypography1>
                                                </Stack>
                                                <Grid container direction="row" spacing={2} sx={{ mt: '3em', m: '1em' }} justifyContent="center" alignItems="center">
                                                    {
                                                        (item.url).slice(0, 6).map((item, index) => {
                                                            return (
                                                                <Grid key={index} item xs={12} sm={6} md={4}>
                                                                    <SimpleReactLightbox>
                                                                        <SRLWrapper options={options}>
                                                                            <Card
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    height: '360px',
                                                                                    '&:hover': {
                                                                                        cursor: index === 5 && (openImage === false) ? null : 'pointer',
                                                                                        filter: index === 5 && (openImage === false) ? null : 'brightness(0.5)',
                                                                                        transform: index === 5 && (openImage === false) ? null : 'scale(1.05)',
                                                                                    },
                                                                                }}>
                                                                                {index === 5 && (openImage === false) ?
                                                                                    <CardActionArea>
                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            width="100%"
                                                                                            height='360px'
                                                                                            image={item}
                                                                                            alt="Image"
                                                                                            sx={{ filter: 'brightness(0.5)', }}
                                                                                        />
                                                                                        <Box
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: '50%',
                                                                                                left: '50%',
                                                                                                transform: 'translate(-50%, -50%)',
                                                                                            }}
                                                                                        >
                                                                                            <Button variant="outlined" onClick={handleClickImage} sx={{
                                                                                                color: '#fff',
                                                                                                fontSize: 'larger',
                                                                                                '&.MuiButton-outlined': {
                                                                                                    borderColor: '#fff',
                                                                                                    color: '#fff',
                                                                                                    '&:hover': {
                                                                                                        cursor: 'pointer',
                                                                                                        transform: 'scale(1.05)',
                                                                                                        borderColor: '#fff',
                                                                                                        color: '#fff',
                                                                                                    },
                                                                                                }
                                                                                            }}>
                                                                                                Load More
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </CardActionArea>
                                                                                    :
                                                                                    <CardActionArea>
                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            width="100%"
                                                                                            height='360px'
                                                                                            image={item}
                                                                                            alt="Image"
                                                                                        />
                                                                                    </CardActionArea>}
                                                                            </Card>
                                                                        </SRLWrapper>
                                                                    </SimpleReactLightbox>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                <Grid container direction="row" spacing={2} sx={{ m: '0em 1em 3em 2em' }} justifyContent="start" alignItems="start">
                                                    {/* {openImage ? null : <Button onClick={handleClickImage} variant="contained" sx={{
                                                        marginLeft: '1em',
                                                        width: '12em',
                                                        height: '3em',
                                                        color: '#fff',
                                                        background: '#00aef0',
                                                        fontSize: 18,
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            background: '#00aef0',
                                                            transform: 'scale(1.05)',
                                                            color: '#fff',
                                                        },
                                                    }}>
                                                        Load More
                                                    </Button>} */}
                                                    <Collapse in={openImage} timeout="auto" unmountOnExit sx={{ width: '100%' }} >
                                                        <Grid container direction="row" spacing={2}>
                                                            {
                                                                (item.url).slice(9).map((item, index) => {
                                                                    return (
                                                                        <Grid key={index} item xs={12} sm={6} md={4}>
                                                                            <SimpleReactLightbox>
                                                                                <SRLWrapper options={options}>
                                                                                    <Card
                                                                                        sx={{
                                                                                            width: '100%',
                                                                                            height: '360px',
                                                                                            '&:hover': {
                                                                                                cursor: 'pointer',
                                                                                                filter: 'brightness(0.5)',
                                                                                                transform: 'scale(1.05)',
                                                                                            },
                                                                                        }}>
                                                                                        <img width="100%" height="100%" src={item} alt="image" />
                                                                                    </Card>
                                                                                </SRLWrapper>
                                                                            </SimpleReactLightbox>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Collapse>
                                                </Grid>
                                            </>
                                    }

                                </Grid >
                                <Grid xs={12} sx={{ m: isMatch ? null : '2em' }} ref={VedioGalleryScrolldiv}>
                                    {
                                        isMatch ?
                                            <>
                                                <ListItem button onClick={handleClickVedio} sx={{ background: 'whitesmoke' }}>
                                                    <ListItemText sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: 20
                                                        }
                                                    }} primary="Video Gallery" />
                                                    {open6 ? <CustomRemove /> : <CustomAdd />}
                                                </ListItem>
                                                <Collapse in={open6} timeout="auto" unmountOnExit  >
                                                    <Container maxWidth="lg" sx={{ m: '1em 0em' }}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Card elevation={5} style={{ width: '100%', height: '250px' }} >
                                                                    <ReactPlayer
                                                                        width='100%'
                                                                        height='100%'
                                                                        url={`${item.video}`}
                                                                        controls={true}
                                                                    />
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Card elevation={5} style={{ width: '100%', height: '450px' }} >
                                                                    <iframe src={`${item.Represented}`}
                                                                        width="100%"
                                                                        height="100%"
                                                                        frameborder="0"
                                                                        allowfullscreen
                                                                        allow="xr-spatial-tracking">
                                                                    </iframe>
                                                                </Card>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </Collapse>
                                            </>
                                            :
                                            <>
                                                <Stack justifyContent="center" alignItems="center" >
                                                    <CustomTypography1>Video Gallery</CustomTypography1>
                                                </Stack>
                                                <Container maxWidth="md" >
                                                    <Stack direction="column" spacing={5} sx={{ mt: '3em' }}>
                                                        <Card elevation={5} style={{ width: '100%', height: '35em', padding: '1em' }} >
                                                            <ReactPlayer width="100%" height="100%" url={`${item.video}`} controls={true} />
                                                        </Card>
                                                        <Card elevation={5} style={{ width: '100%',height: '45em', padding: '1em' }} >
                                                            <iframe src={`${item.Represented}`}
                                                                width="100%"
                                                                height="100%"
                                                                frameborder="0"
                                                                allowfullscreen
                                                                allow="xr-spatial-tracking">
                                                            </iframe>

                                                        </Card>
                                                    </Stack>
                                                </Container>
                                            </>
                                    }
                                </Grid>
                                <Grid xs={12} sx={{ m: isMatch ? null : '2em' }} ref={SitemapScrolldiv}>
                                    {
                                        isMatch ?
                                            <>
                                                <ListItem button onClick={handleClicksitemap} sx={{ background: 'whitesmoke' }}>
                                                    <ListItemText sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: 20
                                                        }
                                                    }} primary="Sitemap" />
                                                    {open4 ? <CustomRemove /> : <CustomAdd />}
                                                </ListItem>
                                                <Collapse in={open4} timeout="auto" unmountOnExit  >
                                                    <Container maxWidth="lg" sx={{ m: '1em 0em' }}>
                                                        {item.siteurl[0] &&
                                                            <SimpleReactLightbox>
                                                                <SRLWrapper options={options}>
                                                                    <Card elevation={5} style={{ width: '100%', height: '250px' }} >
                                                                        <CardMedia
                                                                            style={{ height: "100%", width: "100" }}
                                                                            component="img"
                                                                            image={item.siteurl[0]}
                                                                            title="Paella dish"
                                                                        />
                                                                    </Card>
                                                                </SRLWrapper >
                                                            </SimpleReactLightbox>
                                                        }
                                                    </Container>
                                                </Collapse>
                                            </>
                                            :
                                            <>
                                                <Stack justifyContent="center" alignItems="center" >
                                                    <CustomTypography1>Sitemap</CustomTypography1>
                                                </Stack>
                                                <Container maxWidth="md" sx={{ mt: '3em' }}>
                                                    {item.siteurl[0] &&
                                                        <SimpleReactLightbox>
                                                            <SRLWrapper options={options}>
                                                                <Card elevation={5} style={{ width: '100%' }} >
                                                                    <CardMedia
                                                                        style={{ height: "100%", width: "100" }}
                                                                        component="img"
                                                                        image={item.siteurl[0]}
                                                                        title="Paella dish"
                                                                    />
                                                                </Card>

                                                            </SRLWrapper >
                                                        </SimpleReactLightbox>
                                                    }
                                                </Container>
                                            </>
                                    }

                                </Grid>
                                <Grid xs={12} sx={{ m: isMatch ? null : '2em', mb: isMatch ? '2em' : '3em' }}>
                                    {
                                        isMatch ?
                                            <>
                                                <ListItem button onClick={handleClickSchool} sx={{ background: 'whitesmoke' }}>
                                                    <ListItemText sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: 20
                                                        }
                                                    }} primary="Schools" />
                                                    {open5 ? <CustomRemove /> : <CustomAdd />}
                                                </ListItem>
                                                <Collapse in={open5} timeout="auto" unmountOnExit sx={{ p: '1em' }}>
                                                    <TableContainer component={Paper} style={{ width: '100%', mt: '2em' }}>
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell component="th" sx={{ background: 'rgb(246, 246, 246)', width: '40%' }}>
                                                                    <Typography sx={{ fontSize: isMatch ? 14 : 18, fontWeight: 700 }}>Elementary School</Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: isMatch ? 13 : 17, fontWeight: 400 }}>{item.level1}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" sx={{ background: 'rgb(246, 246, 246)', width: '40%' }}>
                                                                    <Typography sx={{ fontSize: isMatch ? 14 : 18, fontWeight: 700 }}>Middle schools</Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: isMatch ? 13 : 17, fontWeight: 400 }}>{item.level2}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" sx={{ background: 'rgb(246, 246, 246)', width: '40%' }}>
                                                                    <Typography sx={{ fontSize: isMatch ? 14 : 18, fontWeight: 700 }}>High schools</Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: isMatch ? 13 : 17, fontWeight: 400 }}>{item.level3}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableContainer>
                                                </Collapse>
                                            </>
                                            :
                                            <>
                                                <Stack justifyContent="center" alignItems="center" sx={{ mb: '4em' }}>
                                                    <CustomTypography1>Schools</CustomTypography1>
                                                </Stack>
                                                <Stack justifyContent="center" alignItems="center" >
                                                    <TableContainer component={Paper} style={{ width: '50%', }}>
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell component="th" sx={{ background: 'rgb(246, 246, 246)', width: '40%' }}>
                                                                    <Typography sx={{ fontSize: isMatch ? 14 : 18, fontWeight: 700 }}>Elementary School</Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: isMatch ? 13 : 17, fontWeight: 400 }}>{item.level1}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" sx={{ background: 'rgb(246, 246, 246)', width: '40%' }}>
                                                                    <Typography sx={{ fontSize: isMatch ? 14 : 18, fontWeight: 700 }}>Middle schools</Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: isMatch ? 13 : 17, fontWeight: 400 }}>{item.level2}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" sx={{ background: 'rgb(246, 246, 246)', width: '40%' }}>
                                                                    <Typography sx={{ fontSize: isMatch ? 14 : 18, fontWeight: 700 }}>High schools</Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Typography sx={{ fontSize: isMatch ? 13 : 17, fontWeight: 400 }}>{item.level3}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableContainer>
                                                </Stack>
                                            </>
                                    }


                                </Grid>

                            </Grid>

                        )
                    })
                }
            </StyledCard>
            <Grid container spacing={1} sx={{ p: isMatch ? '2em' : '2em 4em', background: 'whitesmoke' }}>
                {
                    isMatch
                        ?
                        <>
                            <Grid item xs={12} sx={{ m: '10px 3px' }}>
                                <Card sx={{ width: 'auto', height: '15em', }}>
                                    <img width="100%" height="100%" src={Sketch} alt="image" />
                                </Card>
                            </Grid>
                        </>
                        : null

                }
                <Grid item xs={isMatch ? 12 : 6}>

                    <Stack direction="column" sx={{ width: isMatch ? '100%' : '77%' }} spacing={isMatch ? 2 : 5}>
                        <Customtypo isMatch={isMatch} >Be The First To Learn About Our Future Projects</Customtypo>
                        <Typography sx={{ fontSize: isMatch ? 13 : 20, fontWeight: 300 }}>The following is a list for future projects Mural Homes is bringing to Washington state. Please subscribe to our newsletter to receive the latest updates and details for our upcoming projects.</Typography>
                        {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc3WTKa4S8OKjQh13A__BHYZY3pl56dx7tCZBimwjv_WgNgCA/viewform?embedded=true" width="640" height="375" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Email Id"
                                required
                                variant="outlined"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    color: '#fff',
                                    bgcolor: '#00aef0',
                                    width: '40%',
                                    mt: '1em',
                                    '& .hover': {
                                        color: '#fff',
                                        bgcolor: '#00aef0',
                                    }
                                }}>
                                Subscribe
                            </Button>
                            <Snackbar open={open} onClose={handleClose} autoHideDuration={4000}>
                                <Alert onClose={handleClose} variant="filled" severity="error">
                                    Please try again later.
                                </Alert>
                            </Snackbar>
                            <Snackbar open={openError} onClose={handleClose} autoHideDuration={4000}>
                                <Alert onClose={handleClose} variant="filled" severity="success">
                                    Your Subscription has been confirmed, Thank you.
                                </Alert>
                            </Snackbar>
                        </form>
                    </Stack>

                </Grid>
                {
                    isMatch ? null : <Grid item xs={6}>
                        <Card sx={{ width: '50em', height: '33em', }}>
                            <img width="100%" height="100%" src={Sketch} alt="image" />
                        </Card>
                    </Grid>
                }
            </Grid >
            <div>
                <Footer />
            </div>
        </>
    )
}

export default Projectalex61