import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import emailjs from 'emailjs-com';
import MuiAlert from '@material-ui/lab/Alert';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Container from '@material-ui/core/Container';
import { Card } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';



const useStyles = makeStyles((theme) => ({
  block: {
    display: 'none',
  },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function ContactLogin(props) {
  const classes = useStyles();
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [date, setDate] = useState()
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [domainName, setDomainName] = React.useState('');
  const {
    Open,
    Close,
    property,
    Facing
  } = props



  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }
  const handleEmailSubmit = e => {
    e.preventDefault();
    setProgress(true)
    setLoading(true)

    emailjs.sendForm(
      'service_b9v5hfs',
      'template_ftajclh',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setProgress(false);
        setLoading(false);
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setProgress(false);
        setLoading(false);
      });

    e.target.reset()
  }

  React.useEffect(() => {
    // Function to get the domain name from window.location
    const getDomainName = () => {
      const url = new URL(window.location.href);
      setDomainName(url.hostname);
    };

    // Call the function when the component mounts
    getDomainName();

    // Add event listener to handle changes in the URL
    const handleNavigationChange = () => {
      getDomainName();
    };

    window.addEventListener('popstate', handleNavigationChange);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
    };
  }, []);

  const renderText = () => {
    switch (domainName) {
      case 'www.dguptahomes.com':
        return 'DgutaHomes';
      case 'www.homewastate.com':
        return 'HomeWAState';
      default:
        return 'HomeWAState';
    }
  }

  return (
    <div>
      <Dialog open={Open} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper" fullWidth="sm">
        {/* <DialogTitle id="form-dialog-title">X</DialogTitle> */}
        <DialogTitle id="form-dialog-title">Request Access for {renderText()}</DialogTitle>

        {/* <DialogContent> */}
        <DialogContent dividers>
          <DialogContentText>
            <p> <Typography style={{ fontWeight: 600 }}>This website is exclusively designed for Deepti Gupta Real Estate clients.</Typography>Please message Deepti Gupta for access request. If you are a new buyer, browsing for properties please leave us a message and we will contact you for a free consultation as well as provide access to this website. However, if you are already working with another real estate agent, we may not be able to help you any further or provide access to this website. Thank you!
            </p></DialogContentText>
          <form onSubmit={handleEmailSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  required
                  id="Name"
                  name="name"
                  label="Name"
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="Phone"
                  name="phone"
                  label="Phone"
                  fullWidth
                  onChange={handleChange}
                />

              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required

                  margin="dense"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <KeyboardDatePicker
          disableToolbar
          format="MM/dd/yyyy"
          fullWidth
          label="Date"
          name="tour"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  rows={2}
                  fullWidth
                  // variant="outlined"
                  autoComplete="given-name"
                  onChange={handleChange}
                  multiline
                // rows={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  autoFocus
                  id="property"
                  name="property"
                  label="property"
                  fullWidth
                  value={`${property}`}
                  onChange={handleChange}
                  className={classes.block}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography >
                  Are you working with Deepti Gupta Real Estate (yes/no)?
                </Typography>
                <TextField
                  required
                  margin="dense"
                  id="Yes / No"
                  name="deeptiAgent"
                  label="Yes / No"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography >
                  Are you working with another real estate agent (yes/no)?
                </Typography>
                <TextField
                  required
                  margin="dense"
                  id="Yes / No"
                  name="tour"
                  label="Yes / No"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>

            </Grid>
            {progress && <LinearProgress style={{ marginTop: "10px" }} />}
            <Snackbar open={open} autoHideDuration={8000} onClose={Close}>
              <Alert onClose={Close} severity="success">
                Your request has been submitted. Our Admin team will email you shortly with your login details. Thank You!!
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={8000} onClose={Close}>
              <Alert onClose={Close} severity="error">
                Please try again!
              </Alert>
            </Snackbar>

            <DialogActions>
              <Button onClick={Close} color="primary">
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={loading}
              >
                Submit
              </Button>
            </DialogActions>
          </form >
        </DialogContent>
      </Dialog>
    </div>
  );
}