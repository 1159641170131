import React, { useEffect, useContext } from 'react'
import { PropertyContext } from '../../context'
import { Container, Typography, } from '@material-ui/core'
import { Box, Button, Collapse, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import '../HouseMobile.css'

export default function Gallery() {
    const context = useContext(PropertyContext);
    const { listings, getData } = context;
    const [openImage, setOpenImage] = React.useState(false);
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useNavigate();

    const handleClickImage = () => {
        setOpenImage(!openImage);
    };

    
    let listingshouse = listings
    listingshouse = [
        ...listingshouse.filter(house => house.ActiveStatus === "Active"),
        ...listingshouse.filter(house => house.ActiveStatus === "Pending"),
        ...listingshouse.filter(house => house.ActiveStatus === "Sold")
    ]

    useEffect(() => {
        if (listingshouse.length === 0) {
            getData()
        }

    }, [])

    // if (listingshouse.length === 0) {
    //     return <Skeleton  variant="rounded" width={auto} height={795}/>
    // }

    return (
        <div>
            {
                isMatch ?
                    <div>
                        < Container maxWidth="md" style={{ paddingBottom: '10px', }} >
                            <Typography
                                component="h3"
                                variant="h5"
                                align="center"
                                color="text.primary"
                                gutterBottom
                                style={{ fontWeight: '500', paddingTop: '20px', }}>
                                Previous Staging Gallery
                            </Typography>
                            {
                                listingshouse.length === 0 ?
                                    (
                                        <>
                                            <Stack direction="row" spacing={1} >
                                                <Stack width='100%'>
                                                    <Skeleton
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                        variant="rectangular"
                                                        width='auto'
                                                        height={150}
                                                    />
                                                </Stack>
                                                <Stack width='100%' >
                                                    <Skeleton
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                        variant="rectangular"
                                                        width='auto'
                                                        height={150}
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" spacing={1} mt={3}>
                                                <Stack width='100%'>
                                                    <Skeleton
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                        variant="rectangular"
                                                        width='auto'
                                                        height={150}
                                                    />
                                                </Stack>
                                                <Stack width='100%' >
                                                    <Skeleton
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                        variant="rectangular"
                                                        width='auto'
                                                        height={150}
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" spacing={1} mt={3}>
                                                <Stack width='100%'>
                                                    <Skeleton
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                        variant="rectangular"
                                                        width='auto'
                                                        height={150}
                                                    />
                                                </Stack>
                                                <Stack width='100%' >
                                                    <Skeleton
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                        variant="rectangular"
                                                        width='auto'
                                                        height={150}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </>
                                    )
                                    :
                                    <>
                                        <ImageList
                                            sx={{
                                                width: 'auto',
                                                height: 'auto',
                                            }}>
                                            {
                                                listingshouse
                                                    .filter(house => house.ActiveStatus !== "AdminOnly")
                                                    .filter(house => house.StagingGallery === "Yes")
                                                    .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                    .slice(0, 6)
                                                    .map((image) => {
                                                        return (
                                                            <ImageListItem key={image.id} onClick={() => history(`/Our-Listings/${image.MlsId}`)}>

                                                                <img
                                                                    src={`${image.featuredUrl || image.url}`}
                                                                    //   srcSet={`${item.img}`}
                                                                    alt={image.propertyname}
                                                                    loading="eager"

                                                                />

                                                                <ImageListItemBar
                                                                    sx={{
                                                                        background:
                                                                            'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
                                                                            'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
                                                                        // height: '45px',
                                                                    }}
                                                                    position="top"
                                                                    actionIcon={
                                                                        <Typography
                                                                            // variant='h6'
                                                                            align="center"
                                                                            color='secondary'
                                                                            style={{
                                                                                marginTop: '0.5em',
                                                                                right: '0px',
                                                                                backgroundColor: 'rgb(35 142 53)',
                                                                                // backgroundSize: '400% 400%',
                                                                                color: 'white',
                                                                                padding: '3px 13px 3px',
                                                                                borderRadius: '50px 0px 0px 50px',
                                                                                fontSize: '10px',
                                                                            }}

                                                                        >
                                                                            {image.ListedDate}
                                                                        </Typography>
                                                                    }
                                                                    actionPosition="right"

                                                                />
                                                                <ImageListItemBar
                                                                    style={{ maxHeight: '32px' }}
                                                                    title={<Typography
                                                                        variant='button'
                                                                        align="center"
                                                                        onClick={() => history(`/Our-Listings/${image.MlsId}`)}
                                                                    >
                                                                        Know more
                                                                    </Typography>}
                                                                />
                                                            </ImageListItem>


                                                        )
                                                    })
                                            }
                                            {
                                                openImage ? null :
                                                    <>
                                                        {listingshouse
                                                            .filter(house => house.ActiveStatus !== "AdminOnly")
                                                            .filter(house => house.StagingGallery === "Yes")
                                                            .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                            .slice(6, 7)
                                                            .map((image) => {
                                                                return (
                                                                    <ImageListItem key={image.id} >

                                                                        <img
                                                                            src={`${image.featuredUrl || image.url}`}
                                                                            //   srcSet={`${item.img}`}
                                                                            alt={image.propertyname}
                                                                            loading="eager"
                                                                            style={{ filter: 'brightness(0.5)' }}
                                                                        />

                                                                        <ImageListItemBar
                                                                            sx={{
                                                                                background:
                                                                                    'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
                                                                                    'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
                                                                                // height: '45px',
                                                                            }}
                                                                            position="top"
                                                                            actionIcon={
                                                                                <Typography
                                                                                    // variant='h6'
                                                                                    align="center"
                                                                                    color='secondary'
                                                                                    style={{
                                                                                        marginTop: '0.5em',
                                                                                        right: '0px',
                                                                                        backgroundColor: 'rgb(35 142 53)',
                                                                                        // backgroundSize: '400% 400%',
                                                                                        color: 'white',
                                                                                        padding: '3px 13px 3px',
                                                                                        borderRadius: '50px 0px 0px 50px',
                                                                                        fontSize: '10px',
                                                                                    }}

                                                                                >
                                                                                    {image.ListedDate}
                                                                                </Typography>
                                                                            }
                                                                            actionPosition="right"

                                                                        />
                                                                        <Box
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',
                                                                            }}
                                                                        >
                                                                            <Button variant="outlined" onClick={handleClickImage} sx={{
                                                                                color: '#fff',
                                                                                fontSize: '11px',
                                                                                width: '9em',
                                                                                '&.MuiButton-outlined': {
                                                                                    borderColor: '#fff',
                                                                                    color: '#fff',
                                                                                    '&:hover': {
                                                                                        cursor: 'pointer',
                                                                                        transform: 'scale(1.05)',
                                                                                        borderColor: '#fff',
                                                                                        color: '#fff',
                                                                                    },
                                                                                }
                                                                            }}>
                                                                                Load More
                                                                            </Button>
                                                                        </Box>
                                                                        {/* <ImageListItemBar
                                                                            style={{ maxHeight: '32px' }}
                                                                            title={<Typography
                                                                                variant='button'
                                                                                align="center"
                                                                                onClick={() => history(`/Our-Listings/${image.MlsId}`)}
                                                                            >
                                                                                Know more
                                                                            </Typography>}
                                                                        /> */}
                                                                    </ImageListItem>


                                                                )
                                                            })}
                                                    </>
                                            }
                                        </ImageList>
                                        <Collapse in={openImage} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                            <ImageList
                                                sx={{
                                                    width: 'auto',
                                                    height: 'auto',
                                                }}>
                                                {
                                                    listingshouse
                                                        .filter(house => house.ActiveStatus !== "AdminOnly")
                                                        .filter(house => house.StagingGallery === "Yes")
                                                        .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                        .slice(6)
                                                        .map((image) => {
                                                            return (
                                                                <ImageListItem key={image.id} onClick={() => history(`/Our-Listings/${image.MlsId}`)}>

                                                                    <img
                                                                        src={`${image.featuredUrl || image.url}`}
                                                                        //   srcSet={`${item.img}`}
                                                                        alt={image.propertyname}
                                                                        loading="eager"

                                                                    />

                                                                    <ImageListItemBar
                                                                        sx={{
                                                                            background:
                                                                                'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
                                                                                'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
                                                                            // height: '45px',
                                                                        }}
                                                                        position="top"
                                                                        actionIcon={
                                                                            <Typography
                                                                                // variant='h6'
                                                                                align="center"
                                                                                color='secondary'
                                                                                style={{
                                                                                    marginTop: '0.5em',
                                                                                    right: '0px',
                                                                                    backgroundColor: 'rgb(35 142 53)',
                                                                                    // backgroundSize: '400% 400%',
                                                                                    color: 'white',
                                                                                    padding: '3px 13px 3px',
                                                                                    borderRadius: '50px 0px 0px 50px',
                                                                                    fontSize: '10px',
                                                                                }}

                                                                            >
                                                                                {image.ListedDate}
                                                                            </Typography>
                                                                        }
                                                                        actionPosition="right"

                                                                    />
                                                                    <ImageListItemBar
                                                                        style={{ maxHeight: '32px' }}
                                                                        title={<Typography
                                                                            variant='button'
                                                                            align="center"
                                                                            onClick={() => history(`/Our-Listings/${image.MlsId}`)}
                                                                        >
                                                                            Know more
                                                                        </Typography>}
                                                                    />
                                                                </ImageListItem>


                                                            )
                                                        })
                                                }
                                            </ImageList>
                                        </Collapse>

                                    </>
                            }

                        </Container >
                    </div >

                    :
                    <Container style={{ padding: " 0px 20px 20px", borderRadius: "20px", paddingTop: "20px", paddingBottom: "20px", }}>
                        {
                            listingshouse.length === 0 ?
                                (
                                    <>
                                        <Stack direction="row" spacing={1} >
                                            <Stack>
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                />
                                            </Stack>
                                            <Stack  >
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                /></Stack>
                                            <Stack  >
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                /></Stack>

                                        </Stack>
                                        <Stack direction="row" spacing={1} mt={3}>
                                            <Stack>
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                />
                                            </Stack>
                                            <Stack  >
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                /></Stack>
                                            <Stack  >
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                /></Stack>

                                        </Stack>
                                        <Stack direction="row" spacing={1} mt={3}>
                                            <Stack>
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                />
                                            </Stack>
                                            <Stack  >
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                /></Stack>
                                            <Stack  >
                                                <Skeleton
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    variant="rectangular"
                                                    width='400px'
                                                    height='300px'
                                                /></Stack>

                                        </Stack>
                                    </>
                                )
                                :
                                <>
                                    <ImageList sx={{ width: '100%', height: 'auto' }} cols={3} >
                                        {
                                            // listingshouse.length > 0 &&
                                            listingshouse
                                                .filter(house => house.ActiveStatus !== "AdminOnly")
                                                .filter(house => house.StagingGallery === "Yes")
                                                .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                .slice(0, 6)
                                                .map((image) => {
                                                    return (
                                                        <ImageListItem key={image.id} onClick={() => history(`/Our-Listings/${image.MlsId}`)}>
                                                            <img
                                                                src={`${image.featuredUrl || image.url}`}
                                                                //   srcSet={`${item.img}`}
                                                                width="100%"
                                                                height={250}
                                                                alt={image.propertyname}
                                                                loading="eager"
                                                            />
                                                            <ImageListItemBar
                                                                // title={

                                                                //     <Chip label={image.ListedDate} variant="outlined" color="success" style={{ background: 'teal', color: 'white' }} />
                                                                // }
                                                                sx={{
                                                                    background:
                                                                        'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
                                                                        'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
                                                                    height: '45px',
                                                                }}
                                                                position="top"
                                                                actionIcon={
                                                                    <Typography
                                                                        // variant='h6'
                                                                        align="center"
                                                                        color='secondary'
                                                                        style={{
                                                                            top: '20px',
                                                                            right: '0px',
                                                                            backgroundColor: 'rgb(35 142 53)',
                                                                            backgroundSize: '400% 400%',
                                                                            color: 'white',
                                                                            padding: '3px 10px 3px',
                                                                            borderRadius: '50px 0px 0px 50px',
                                                                            fontSize: '13px',
                                                                        }}

                                                                    >
                                                                        {image.ListedDate}
                                                                    </Typography>
                                                                }
                                                                actionPosition="right"
                                                            />

                                                        </ImageListItem>
                                                    )
                                                })
                                        }
                                        {
                                            openImage ? null :
                                                <>
                                                    {listingshouse
                                                        .filter(house => house.ActiveStatus !== "AdminOnly")
                                                        .filter(house => house.StagingGallery === "Yes")
                                                        .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                        .slice(6, 7)
                                                        .map((image) => {
                                                            return (
                                                                <ImageListItem key={image.id} >
                                                                    <img
                                                                        src={`${image.featuredUrl || image.url}`}
                                                                        //   srcSet={`${item.img}`}
                                                                        width="100%"
                                                                        height={250}
                                                                        alt={image.propertyname}
                                                                        loading="eager"
                                                                        style={{ filter: 'brightness(0.5)' }}
                                                                    />

                                                                    <ImageListItemBar
                                                                        // title={

                                                                        //     <Chip label={image.ListedDate} variant="outlined" color="success" style={{ background: 'teal', color: 'white' }} />
                                                                        // }
                                                                        sx={{
                                                                            background:
                                                                                'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
                                                                                'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
                                                                            height: '45px',
                                                                        }}
                                                                        position="top"
                                                                        actionIcon={
                                                                            <Typography
                                                                                // variant='h6'
                                                                                align="center"
                                                                                color='secondary'
                                                                                style={{
                                                                                    top: '20px',
                                                                                    right: '0px',
                                                                                    backgroundColor: 'rgb(35 142 53)',
                                                                                    backgroundSize: '400% 400%',
                                                                                    color: 'white',
                                                                                    padding: '3px 10px 3px',
                                                                                    borderRadius: '50px 0px 0px 50px',
                                                                                    fontSize: '13px',
                                                                                }}

                                                                            >
                                                                                {image.ListedDate}
                                                                            </Typography>
                                                                        }
                                                                        actionPosition="right"
                                                                    />
                                                                    <Box
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',
                                                                        }}
                                                                    >
                                                                        <Button variant="outlined" onClick={handleClickImage} sx={{
                                                                            color: '#fff',
                                                                            fontSize: 'larger',
                                                                            '&.MuiButton-outlined': {
                                                                                borderColor: '#fff',
                                                                                color: '#fff',
                                                                                '&:hover': {
                                                                                    cursor: 'pointer',
                                                                                    transform: 'scale(1.05)',
                                                                                    borderColor: '#fff',
                                                                                    color: '#fff',
                                                                                },
                                                                            }
                                                                        }}>
                                                                            Load More
                                                                        </Button>
                                                                    </Box>
                                                                </ImageListItem>
                                                            )
                                                        })}
                                                </>
                                        }
                                    </ImageList>
                                    <Collapse in={openImage} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                        <ImageList sx={{ width: '100%', height: 'auto', mt: 4 }} cols={3} >
                                            {
                                                // listingshouse.length > 0 &&
                                                listingshouse
                                                    .filter(house => house.ActiveStatus !== "AdminOnly")
                                                    .filter(house => house.StagingGallery === "Yes")
                                                    .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                    .slice(6)
                                                    .map((image) => {
                                                        return (
                                                            <ImageListItem key={image.id} onClick={() => history(`/Our-Listings/${image.MlsId}`)}>
                                                                <img
                                                                    src={`${image.featuredUrl || image.url}`}
                                                                    //   srcSet={`${item.img}`}
                                                                    width="100%"
                                                                    height={250}
                                                                    alt={image.propertyname}
                                                                    loading="eager"
                                                                />
                                                                <ImageListItemBar
                                                                    // title={

                                                                    //     <Chip label={image.ListedDate} variant="outlined" color="success" style={{ background: 'teal', color: 'white' }} />
                                                                    // }
                                                                    sx={{
                                                                        background:
                                                                            'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
                                                                            'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
                                                                        height: '45px',
                                                                    }}
                                                                    position="top"
                                                                    actionIcon={
                                                                        <Typography
                                                                            // variant='h6'
                                                                            align="center"
                                                                            color='secondary'
                                                                            style={{
                                                                                top: '20px',
                                                                                right: '0px',
                                                                                backgroundColor: 'rgb(35 142 53)',
                                                                                backgroundSize: '400% 400%',
                                                                                color: 'white',
                                                                                padding: '3px 10px 3px',
                                                                                borderRadius: '50px 0px 0px 50px',
                                                                                fontSize: '13px',
                                                                            }}

                                                                        >
                                                                            {image.ListedDate}
                                                                        </Typography>
                                                                    }
                                                                    actionPosition="right"
                                                                />

                                                            </ImageListItem>
                                                        )
                                                    })
                                            }
                                        </ImageList>
                                    </Collapse>

                                </>
                        }


                    </Container>
            }
        </div >


    )
}

