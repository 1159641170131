import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar';
import { Checkbox, Divider, Stack, Typography, useMediaQuery, useTheme, useThemeProps } from '@mui/material'
import emailjs from 'emailjs-com';
import MuiAlert from '@material-ui/lab/Alert';
import { FormControlLabel } from '@material-ui/core';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const UpcomingContact = (props) => {
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [isChecked1, setIsChecked1] = React.useState(false);
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const {
        Open,
        Close,
        Property,
        propertyName
    } = props

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    }
    const handleClickError = () => {
        setOpenError(true);
    };

    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }
    const handleEmailSubmit = e => {
        e.preventDefault();
        emailjs.sendForm(
            'gmail-A',
            'template_m7yxtzs',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
            });

        e.target.reset()
    }


    return (
        <div>
            <Dialog open={Open} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper">
                <DialogTitle>Contact Deepti for Upcoming Project</DialogTitle>
                <Divider sx={{ m: isMatch ? '1em 0em' : '10px' }} />
                <DialogContent>
                    <DialogContentText style={{ fontSize: 15, color: "#ff0000b8", fontWeight: 400 }}>
                        Property Name : {propertyName}
                    </DialogContentText>
                    <form onSubmit={handleEmailSubmit}>
                        <input type="hidden" name='heading' value="Upcoming Projects Page" />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            // className="text_contact"
                            // value={values.name}
                            onChange={handleChange}
                        />
                        <TextField
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            // className="text_contact"
                            // value={values.email}
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="number"
                            label="Phone Number"
                            name="number"
                            type="number"
                            autoComplete="number"
                            className="text_contact"
                            // value={values.number}
                            onChange={handleChange}
                        />
                        <TextField
                            type="propertyName"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="propertyName"
                            label="Property Name"
                            name="propertyName"
                            autoComplete="address"
                            className="text_contact"
                            value={propertyName}
                            // value={values.email}
                            onChange={handleChange}
                        />
                        <TextField
                            type="city"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="city"
                            label="City"
                            name="city"
                            autoComplete="address"
                            className="text_contact"
                            value={Property}
                            // value={values.email}
                            onChange={handleChange}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="outlined-multiline-static"
                            label="Message"
                            name="message"
                            multiline
                            rows={4}
                            autoComplete="message"
                            className="text_contact"
                            // value={values.message}
                            onChange={handleChange}
                        />
                        {/* <Grid item xs={12} sm={6}>
                                <Stack direction="row" spacing={5}>
                                    <Stack direction="row" spacing={0}>
                                        <Checkbox id="selling" value="selling" name="selling" label="Selling" onChange={handleChange} />
                                        <Typography sx={{ fontSize: '1.1rem', fontWeight: 400, pt: '8px' }}>SELLING</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={0}>
                                        <Checkbox id="buying" value="buying" name="buying" label="buying" placeholder='Buying' onChange={handleChange} />
                                        <Typography sx={{ fontSize: '1.1rem', fontWeight: 400, pt: '8px' }}>BUYING</Typography>
                                    </Stack>
                                </Stack>
                            </Grid> */}

                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ marginTop: '10em' }}>
                            <Alert onClose={handleClose} severity="success">
                                Email has been sent to admin team. They will reach out to you for scheduling in 1-4 hours.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error">
                                Please try again!
                            </Alert>
                        </Snackbar>
                        <DialogActions >
                            <Button onClick={Close} color="secondary" variant="contained" fullWidth>Cancel</Button>
                            <Button type="submit" variant="contained" color="success" fullWidth>Send</Button>
                        </DialogActions>
                    </form>

                </DialogContent>

            </Dialog>
        </div>
    )
}

export default UpcomingContact
