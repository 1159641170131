import React from 'react'
import '../../components/AvilableHome.css';
import '../../components/Availability.css';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import '../Navbar.css';
import banner22 from '../../images/stagingimg/join.jpg';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  gridCenter: {
    alignItems: "center"
  },
  GridSpace: {
    padding: "0px 30px",
  },
  center: {
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0px 0px 0px",
      textAlign: "center"
    }

  },
  cardGrid: {
    marginTop: "10px",
  },
  bannerPaper: {
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '25em',


  },
  text: {
    color: "#827e7e",
    paddingLeft: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    }
  },
  imagelogo: {
    width: "32px",
    height: "32px",

  },
  text2: {
    fontSize: "20px",
    color: "#2979ff",

  },
  icon: {
    width: "1.8em",
    height: "2em",
    // [theme.breakpoints.down("xs")]:{
    //   width: "1em",
    // }
  },
  gridmobpad: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}




const JoinOurTeam = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }

  const handleEmailSubmit = e => {
    e.preventDefault();

    setLoading(true)
    emailjs.sendForm(
      'gmail',
      'template_m7yxtzs',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setLoading(false)
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setLoading(false)
      });

    e.target.reset()
  }
  return (
    <div>
      <Box style={{ margin: 0, background: "white" }} >

        {/* <Paper className={classes.bannerPaper}>
         
        </Paper> */}

        {/* <Container maxWidth="md">
          <Grid container spacing={3} style={{ alignItems: "center", paddingBottom: "50px" }} >
            <Grid item xs={12} sm={12} md={6} className={classes.center}>
              <Container component="main" maxWidth="xs" className={classes.gridmobpad}>
                <Grid item xs={12} >
                  <Typography className={classes.text2}>
                    Join Our Team
                  </Typography>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container> */}
        <Grid item xs={12} sm={12} md={6}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Card className="container" elevation={5} rounded >
              <div className="body_area" >
                <form className={classes.form}
                  onSubmit={handleEmailSubmit}>
                  <input type="hidden" name='heading' value="Join Our Team page" />
                  <TextField
                    type="email"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    className="text_contact"
                    // value={values.email}
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    className="text_contact"
                    // value={values.name}
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="number"
                    label="Number"
                    name="number"
                    type="number"
                    autoComplete="number"
                    className="text_contact"
                    // value={values.number}
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"

                    fullWidth
                    id="outlined-multiline-static"
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                    autoComplete="message"
                    className="text_contact"
                    // value={values.message}
                    onChange={handleChange}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  >
                    Send
                  </Button>
                  <Snackbar open={open} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Thankyou for writing to us! We have received your request and we will get in touch with you within 2 business days.
                    </Alert>
                  </Snackbar>
                  <Snackbar open={openError} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      Please try again!
                    </Alert>
                  </Snackbar>
                </form >
              </div>
            </Card>
          </Container>
        </Grid>
      </Box>
    </div >
  )
}

export default JoinOurTeam
