import React, { useState, useEffect } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Paper, Typography, Button, useMediaQuery, } from '@mui/material'
import MobBlog from '../Blog/MobBlog'
import sanityClient from '../client';
import { makeStyles } from '@material-ui/styles';
import banner22 from '../images/banner2.jpg';
import { useNavigate } from 'react-router-dom';
import imageUrlBuilder from "@sanity/image-url";
import Footer from '../components/Footer';
import Divider from '@mui/material/Divider';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';

const useStyles = makeStyles((theme) => ({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 25,
        paddingTop: 150
    },
}))

const builder = imageUrlBuilder(sanityClient);


function urlFor(source) {
    return builder.image(source);
}

const Blog = () => {
    const classes = useStyles();
    const [posts, setPosts] = useState(null);
    const history = useNavigate();
    const isMatch = useMediaQuery('(max-width:600px )')


    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "post"]{
            title,
            address,
            mainImage{
                asset->{
                    _id,
                    url
                },               
            },
            description,
            date,
            releaseDate,
            authorname,
            authorimage,
        }`
            )
            .then((data) => setPosts(data))
            .catch(console.error);
    }, []);


    return (
        <>
            {isMatch ? <MobBlog /> :
                <div>
                    <Paper className={classes.bannerPaper}>
                        <Grid item xs={12} align="center" style={{ marginBottom: '10em' }}>
                            <Typography style={{
                                fontSize: '8.8em',
                                fontWeight: '500',
                                color: 'white',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                            }}>Blog</Typography>
                        </Grid>
                    </Paper>
                    <Box display={{ xs: 'none', md: 'block' }} m={1}>
                        <Container className={classes.cardGrid} maxWidth="lg" style={{padding: '10px 0px 10px 0px'}}>
                            <Grid container spacing={2}>
                                {posts && posts
                                    .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                                    .map((post, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={post.address.current}>
                                            <Card onClick={() => history(`${post.address.current}`)} >
                                                <CardActionArea key={index}>
                                                    <CardMedia
                                                        component="img"
                                                        alt={post.title}
                                                        height="200"
                                                        image={urlFor(post.mainImage).url()}
                                                        src="blog posts" />
                                                    <Grid container alignItems="center" style={{ marginTop: '1em' }} >
                                                        <Grid item sm >
                                                            <Typography variant="body1" color="text.secondary" display="block" gutterBottom style={{ marginLeft: '1.3em' }}>
                                                                <DateRangeRoundedIcon sx={{ fontSize: 20 }} /> {post.date}
                                                            </Typography>
                                                        </Grid>
                                                        {/* <Grid item  >
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '0.2em'}}>
                                                    -
                                                </Typography>
                                            </Grid>
                                            <Grid item align="initial">
                                                <Avatar alt="authorimage" sx={{ width: 22, height: 22, marginLeft: '0.2em' }} src={urlFor(post.mainImage).url()} />
                                            </Grid>
                                            <Grid item align="initial">
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '0.2em' }}>
                                                    {post.authorname}
                                                </Typography>
                                            </Grid> */}

                                                        <Divider variant="middle" style={{ width: '100%', marginTop: '0.5em' }} />
                                                    </Grid>

                                                    <CardContent>

                                                        <Grid item spacing={4}>

                                                            <Grid item xs={12} style={{ background: 'lightgrey', padding: '1em', borderRadius: '10px' }}>
                                                                <Typography
                                                                    variant="h7"
                                                                    style={{ fontWeight: '600', fontSize: '18px' }}
                                                                >
                                                                    {post.title}
                                                                </Typography>
                                                            </Grid>
                                                            <br />
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    gutterBottom
                                                                    style={{ fontWeight: '400', fontSize: '15px' }}
                                                                >
                                                                    {post.description}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    variant="contained"
                                                                    style={{ margin: '0px -7px', fontSize: '14px', background: 'linear-gradient(45deg, #e91d1d, #f97f45)', borderRadius: '20px' }}
                                                                    onClick={() => history(`${post.address.current}`)}
                                                                >
                                                                    Read More
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>

                                    ))
                                }
                            </Grid>
                        </Container>
                    </Box>
                    <div>
                        <Footer />
                    </div>
                </div>
            }
        </>
    )

};

export default Blog;
