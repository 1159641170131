import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import bannermob from '../images/banner2.jpg'
import SearchIcon from '@material-ui/icons/Search';
import '../components/Filter.css'
import { InputBase, } from '@mui/material';
import NumberFormat from 'react-number-format';



const useStyles = makeStyles((theme) =>
({
    root: {
        flexGrow: 1,
        paddingTop: 10,
        marginTop: 150,
    },

    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    iconButton: {
        padding: 10,
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 900,
        borderRadius: '800px'

    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,

    },

    searchroot: {
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        [theme.breakpoints.down("sm")]: {
            width: 300,
            borderRadius: '4px'
        },
        margin: 'auto',
        borderRadius: '30px'
    },
    search_lable: {
        fontWeight: 900,
        fontSize: '15px',
        [theme.breakpoints.down("md")]: {
            fontSize: '17px',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '17px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '80px',
            textOverflow: "ellipsis"
        },
    },
    search_input: {
        fontWeight: 500,

    },
    divpadding: {
        paddingBottom: 10,
        margin: 'auto',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
        [theme.breakpoints.down("md")]: {
            minWidth: 140
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: 100
        },
    },
    papersecard: {
        margin: "-8px 0px",
        height: '230px',
        borderRadius: "0px",
        backgroundImage: `url(${bannermob})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    innerpaper: {
        width: 'fit-content',
        margin: "auto",
        padding: "0px 10px",
    },
    filterPaper: {
        borderRadius: "10px"
    },
}),
);
const useStyles2 = makeStyles((theme) =>
({
    chipMob: {
        display: 'flex',
        // justifyContent: 'center',
        paddingTop: "10px",
        flexWrap: 'unset',
        '& > *': {
            margin: theme.spacing(0.6),
        },
    },
}));

const ListingsSearchComp = (props) => {
    const classes = useStyles();

    const {
        citys,
        beds,
        baths,
        propertyType,
        Price,
        Price2,
        searchTerm1,
        searchTerm2,
        searchTerm3,
        searchTerm4,
        minPrice,
        MinPrice,
        maxPrice,
        MaxPrice,
        searchMls
    } = props

    return (
        <>
            <div className="contain_mob">
                <Paper elevation={2} className={classes.papersecardhead}>
                    <Paper className={classes.papersecard}>
                        <div className="search_mob">
                            {/* <Grid align="center" style={{ marginBottom: '1em' }}>
                                <Typography style={{ color: 'white', fontWeight: '500', fontSize: '1.4em' }} gutterBottom> New Communities In Washington State</Typography>
                            </Grid> */}
                            <Paper component="form" className={classes.searchroot}>
                                <Select
                                    disableUnderline
                                    style={{
                                        marginLeft: '1em',
                                        flex: 1,
                                        fontWeight: 420,
                                        fontSize: '15px',
                                        width: 100,
                                    }}
                                    color=""
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    name="city"
                                    defaultValue="none"
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="none" className={classes.search_input}  >
                                        Search by city
                                    </MenuItem>
                                    <MenuItem value="ALL" className={classes.search_input} onClick={() => searchTerm1("")} >
                                        ALL
                                    </MenuItem>
                                    {citys.map((item, index) => (
                                        <MenuItem key={index} value={item} className={classes.search_input} onClick={() => searchTerm1(item)} >
                                            {item + ", " + 'WA'}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton className={classes.iconButton} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                            <Paper component="form" className={classes.searchroot} style={{ marginTop: '1em' }}>
                                <InputBase
                                    sx={{
                                        ml: 1,
                                        flex: 1,
                                        fontWeight: 420,
                                        fontSize: '15px',
                                        width: 100
                                    }}
                                    placeholder="Enter MLS#"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    onChange={(event) => searchMls(event.target.value)}
                                />
                                <IconButton aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </div>
                    </Paper >
                </Paper>
            </div>
            <Paper elevation={4} className={classes.filterPaper}>
                <div className="testContain">
                    <FormControl className={classes.formControl} >
                        <InputLabel id="demo-simple-select-outlined-label" className={classes.search_lable} >Beds</InputLabel>
                        <Select
                            disableUnderline
                            color={'secondary'}
                            labelId="demo-mutiple-name-label"
                            id="school"
                            name="school"
                            className={classes.search_input}
                            inputProps={{
                                underline: {
                                    "&&&:before": {
                                        borderBottom: "none"
                                    },
                                    "&&:after": {
                                        borderBottom: "none"
                                    }
                                }
                            }}
                            defaultValue="All"
                        >
                            <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                Beds
                            </MenuItem>
                            <MenuItem value="All"
                                style={{
                                    fontWeight: 400,
                                    fontSize: 13,
                                    letterSpacing: '0.00938em',
                                }}
                                onClick={() => searchTerm2("")} >
                                All
                            </MenuItem>
                            {beds.map((item, index) => (
                                <MenuItem key={index} value={item} className={classes.search_input} defaultValue
                                    onClick={() => searchTerm2(item)} >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="dividermob"> </div>
                    <FormControl className={classes.formControl} >
                        <InputLabel id="demo-simple-select-outlined-label" className={classes.search_lable} >Baths</InputLabel>
                        <Select
                            disableUnderline
                            color={'secondary'}
                            labelId="demo-mutiple-name-label"
                            id="school"
                            name="school"
                            className={classes.search_input}
                            inputProps={{
                                underline: {
                                    "&&&:before": {
                                        borderBottom: "none"
                                    },
                                    "&&:after": {
                                        borderBottom: "none"
                                    }
                                }
                            }}
                            defaultValue="All"
                        >
                            <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                Baths
                            </MenuItem>
                            <MenuItem value="All"
                                style={{
                                    fontWeight: 400,
                                    fontSize: 13,
                                    letterSpacing: '0.00938em',
                                }}
                                onClick={() => searchTerm3("")} >
                                All
                            </MenuItem>
                            {baths.map((item, index) => (
                                <MenuItem key={index} value={item} className={classes.search_input} defaultValue
                                    onClick={() => searchTerm3(item)} >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="dividermob"> </div>
                    <FormControl className={classes.formControl} >
                        <InputLabel id="demo-simple-select-outlined-label" className={classes.search_lable} >Home Type</InputLabel>
                        <Select
                            disableUnderline
                            color={'secondary'}
                            labelId="demo-mutiple-name-label"
                            id="school"
                            name="school"
                            className={classes.search_input}
                            inputProps={{
                                underline: {
                                    "&&&:before": {
                                        borderBottom: "none"
                                    },
                                    "&&:after": {
                                        borderBottom: "none"
                                    }
                                }
                            }}
                            defaultValue="All"
                        >
                            <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                Home Type
                            </MenuItem>
                            <MenuItem value="All"
                                style={{
                                    fontWeight: 400,
                                    fontSize: 13,
                                    letterSpacing: '0.00938em',
                                }}
                                onClick={() => searchTerm4("")} >
                                All
                            </MenuItem>
                            {propertyType.map((item, index) => (
                                <MenuItem key={index} value={item} className={classes.search_input} defaultValue
                                    onClick={() => searchTerm4(item)} >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="dividermob"> </div>
                    <FormControl className={classes.formControl} >
                        <InputLabel id="demo-simple-select-outlined-label" className={classes.search_lable} >Min Price</InputLabel>
                        <Select
                            disableUnderline
                            color={'secondary'}
                            labelId="demo-mutiple-name-label"
                            id="school"
                            name="school"
                            className={classes.search_input}
                            inputProps={{
                                underline: {
                                    "&&&:before": {
                                        borderBottom: "none"
                                    },
                                    "&&:after": {
                                        borderBottom: "none"
                                    }
                                }
                            }}
                            value={minPrice}
                        >
                            <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                Min Price
                            </MenuItem>
                            {Price.map((item, index) => (
                                <MenuItem key={index} value={item}
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 15,
                                        letterSpacing: '0.00938em',
                                    }}
                                    onClick={() => MinPrice(item)} >
                                    <NumberFormat value={item} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="dividermob"> </div>
                    <FormControl className={classes.formControl} >
                        <InputLabel id="demo-simple-select-outlined-label" className={classes.search_lable} >Max Price</InputLabel>
                        <Select
                            disableUnderline
                            color={'secondary'}
                            labelId="demo-mutiple-name-label"
                            id="school"
                            name="school"
                            className={classes.search_input}
                            inputProps={{
                                underline: {
                                    "&&&:before": {
                                        borderBottom: "none"
                                    },
                                    "&&:after": {
                                        borderBottom: "none"
                                    }
                                }
                            }}
                            value={maxPrice}
                        >
                            <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                Max Price
                            </MenuItem>
                            {Price2.map((item, index) => (
                                <MenuItem key={index} value={item}
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 15,
                                        letterSpacing: '0.00938em',
                                    }}
                                    onClick={() => MaxPrice(item)} >
                                    <NumberFormat value={item} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                                </MenuItem>
                            ))}


                        </Select>
                    </FormControl>
                </div>
            </Paper>
        </>
    )
}

export default ListingsSearchComp