import React, { useEffect } from 'react';
import defaultmage from '../../images/Image_not_available.png';
import '../../components/AvilableHome.css';
import { withPropertyConsumer } from '../../context';
import Divider from '@material-ui/core/Divider';
import '../../components/Availability.css';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import './NavBar.css';
import NumberFormat from 'react-number-format';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Button from '@material-ui/core/Button';
import banner22 from '../../images/Bannermob13.png';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import RoomIcon from '@material-ui/icons/Room';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SortIcon from '@material-ui/icons/Sort';
import ReplayIcon from '@material-ui/icons/Replay';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import firebase from '../../firebase/firebase';
import logoS from '../../images/logoS.5.png';
import BookConsultation from '../Cards/BookConsultation'
import Footer from '../Footer';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const getUnique = (items, value) => {
  return [...new Set(items.map(item => item[value]))]
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperD: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  papermob: {
    padding: 10,
    margin: 'auto',
    background: "#0000ff0d"
  },
  image: {
    width: 128,
    height: 80
  },
  imagemob: {
    width: 80,
    height: 80
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: "100%",
    height: "100%",
    borderRadius: 5
  },
  imgmob: {
    margin: 'auto',
    display: 'block',
    maxHeight: '80px',
    borderRadius: "5px",
    overflow: "hidden",
    height: "100%",
    width: "100%"
  },
  gridCenter: {
    alignItems: "center"
  },
  GridSpace: {
    padding: "5px 30px"
  },
  cardGrid: {
    marginTop: "8px",
    marginBottom: "2em",
  },
  bannerPaper: {
    borderRadius: 0,
    height: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 34,
    paddingTop: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 60,
    },
  },
  cardMob: {
    marginTop: '2em'
  },
  textmob1: {
    color: "#3d4152",
    marginTop: "1px",
    fontSize: "0.9rem",
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 0
  },
  textmob2: {
    width: "100%",
    color: "#7e808c",
    fontSize: ".83rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
  textmob3: {
    display: 'flex',
    color: "#f69022",
    fontSize: ".8rem",
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  textmobLink: {
    display: 'flex',
    textDecoration: 'none',
    color: "rgb(49 11 255 / 42%)",
    fontSize: ".8rem",
    alignItems: 'center',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  textmobSold: {
    display: 'flex',
    textDecoration: 'none',
    color: "#ff0000",
    fontSize: ".8rem",
    alignItems: 'center',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  mobText: {
    fontSize: 12, letterSpacing: "0.01071em", marginBottom: 6
  },
  textAC: {
    color: "#138e22",
    fontSize: ".85rem"
  },
  textPen: {
    color: "#FF5722",
    fontSize: ".85rem"
  },
  textClos: {
    color: "#ff0000",
    fontSize: ".85rem"
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: 900,
    borderRadius: '800px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 420,
    fontSize: '15px',
    width: 100,
  },
  position: {
    position: "relative",
    zIndex: 1
  },
  searchroot: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 400,

    [theme.breakpoints.down("xs")]: {
      width: "auto",
      borderRadius: '4px'
    },
    // margin: 'auto',
    borderRadius: "30px 0px 0px 30px",
  },
  search_lable: {
    fontWeight: 900,
    fontSize: '15px',
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '80px',
      textOverflow: "ellipsis"
    },
  },
  search_input: {
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '0.00938em'

  },
  divpadding: {
    paddingBottom: 10,
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down("md")]: {
      minWidth: 140
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 100
    },
  },
  mobTableBody: {
    background: "#f9fff6 ", borderRadius: "20px", padding: 10,
    display: 'flex', flexDirection: 'column'
  },
  innerpaper: {
    width: 'fit-content',
    margin: "auto",
    padding: "0px 10px",
  },
  filterPaper: {
    borderRadius: "10px"
  },
  root2: {
    display: 'flex',
    background: 'linear-gradient(45deg, #4fbadafc, #3a85d2cf)',
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer"
  },
  root3: {
    display: 'flex',
    background: 'linear-gradient(45deg, #4fbadafc, #3a85d2cf)',
    borderRadius: 0,
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer"
  },
  details: {
    paddingBottom: 8
  },
  texttwo: {
    fontSize: "1.2rem"
  }
}));




function Url(props, context) {

  const propertyname = props.propertyname.toLowerCase();
  const communityname = props.community.toLowerCase();
  let avilableproperty = props.Properties;
  avilableproperty = avilableproperty.filter(house => house.community == props.community)

  var pIname = propertyname || communityname;
  var pname = pIname.split("by")[0];
  var ppname = pname.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  let alldata = props.SoldMergeall
  alldata = alldata.filter(card => card.community == props.community)

  alldata = alldata.filter(value => Object.keys(value).length !== 0);


  return (
    <>
      {/* {alldata.map(card => ( */}
      <TableRow >
        <TableCell style={{ fontSize: 13, maxWidth: 250 }}>{props.Address || 'NA'}
          {/* <p style={{ fontSize: 13, maxWidth: 250 }}>{props.Address ? props.city : ''}{props.zip ? `, ${props.zip}` : ''}</p> */}
        </TableCell>
        <TableCell style={{ fontSize: 13, maxWidth: 150 }}> <NumberFormat value={props.ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </TableCell>
        <TableCell style={{ fontSize: 13, maxWidth: 150 }}> <NumberFormat value={props.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </TableCell>
        <TableCell style={{ fontSize: 13, maxWidth: 150 }}>{props.OfferDate || 'NA'}</TableCell>
        <TableCell style={{ fontSize: 13, maxWidth: 150 }}>{props.Represented || 'NA'}
        </TableCell>
      </TableRow>
      {/* ))
      } */}
    </>
  )
}

function UrlMob(props, context) {
  const propertyname = props.propertyname.toLowerCase();
  const communityname = props.community.toLowerCase();
  let avilableproperty = props.Properties;
  avilableproperty = avilableproperty.filter(house => house.community == props.community)

  var pIname = propertyname || communityname;
  var pname = pIname.split("by")[0];
  var ppname = pname.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  let alldata = props.SoldMergeall
  alldata = alldata.filter(card => card.community == props.community)

  alldata = alldata.filter(value => Object.keys(value).length !== 0);

  return (
    <>
      {/* {alldata.map(card => ( */}
      <TableRow >
        <TableCell align="right" style={{ fontSize: 12, letterSpacing: "0.01071em" }}>{props.Activename}</TableCell>
        <TableCell align="right" style={{ fontSize: 12, letterSpacing: "0.01071em" }}> <NumberFormat value={props.ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </TableCell>
        <TableCell align="right" style={{ fontSize: 12, letterSpacing: "0.01071em" }}> <NumberFormat value={props.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </TableCell>
        <TableCell align="right" style={{ fontSize: 12, letterSpacing: "0.01071em" }}>{props.OfferDate || 'On Receipt'}</TableCell>
        <TableCell align="right" style={{ fontSize: 12, letterSpacing: "0.01071em" }}>{props.Represented || 'NA'}</TableCell>
      </TableRow>
      {/* ))
      } */}
    </>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const SoldSoFarMerge = ({ context, props }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [open10, setOpen10] = React.useState(false);

  useEffect(() => {
    // if(count.length > 2){
    var user = firebase.auth().currentUser;
    if (user == null) {
      // setOpen10(true);
      // setTimeout(()=>  setOpen10(true),2000)
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    allHomes,
    handleChangeSold,
    handleChangeSoldmerge,
    handleRsetSold,
    SoldMergeall,
    SoldSoFarmergeSort,
    SoldmergeStatus,
    city
  } = context;

  console.log("sold merge", SoldSoFarmergeSort)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  let Properties = allHomes;
  let citys = getUnique(context.SoldSoFarmerge.map(card => card), 'city');
  citys = citys.filter(item => item)


  const Sorting = (
    <Dialog
      open={open}
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Sort your priorities out"}</DialogTitle>
      <Divider variant="middle" />
      <FormControl component="fieldset" style={{ paddingLeft: 30 }}>
        <RadioGroup aria-label="gender" value={SoldmergeStatus} name="SoldmergeStatus" onChange={handleChangeSoldmerge}>
          <FormControlLabel  value="dateUp"  control={<Radio />} label="Date : Ascending"  />
          <FormControlLabel value="dateDown"  control={<Radio/>} label="Date : Descending"  />
          <FormControlLabel value="A - Z"  control={<Radio />} label="City : A - Z"  />
          <FormControlLabel value="Z - A"  control={<Radio/>} label="City : Z - A"  />
          <FormControlLabel value="CM A - Z"  control={<Radio />} label="Community : A - Z"  />
          <FormControlLabel value="CM Z - A"  control={<Radio />} label="Community : Z - A"  />
        </RadioGroup>
      </FormControl>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  )

  const Filter = (
    <>
      <Paper component="form" className={classes.searchroot}>
        <Select
          disableUnderline
          className="search_input2"
          color=""
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          name="Soldmergecity"
          value={context.Soldmergecity === "All" ? "none" : context.Soldmergecity}
          onChange={handleChangeSoldmerge}
          // onClick={handleChange2}
          className={classes.input}
          defaultValue="none"
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="none" className={classes.search_input}  >
            Search by city
          </MenuItem>
          <MenuItem value="All" className={classes.search_input}  >
            All
          </MenuItem>
          {citys.map((item, index) => (
            <MenuItem key={index} value={item} className={classes.search_input}  >
              {item + ", " + 'WA'}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </>
  )


  return (
    <div>
      <Box display={{ xs: 'none', sm: 'block' }} m={1} style={{ margin: 0 }} >
        <div className="home-hero-section">
          <div className="home-hero-outer">
            <Paper className={classes.bannerPaper}>
              <div className={classes.position}>
                <Container className={classes.cardGrid} maxWidth="md">
                  <div className="BAnner_text_AU" style={{ textAlign: "center" }}>
                    <img className="Banner_icon_avb_desk" src={logoS} alt="HomesWaState" />
                    {SoldSoFarmergeSort.length > 0 &&
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        {Filter}
                        <Button style={{ borderRadius: 0, background: "#f9a61a" }}
                          onClick={handleClickOpen}
                          elevation={0}
                          startIcon={<SortIcon />}
                          variant="contained">
                          Sort
                        </Button>
                        <Paper elevation={3} style={{ borderRadius: "0px 30px 30px 0px", background: "rgb(26 109 75)" }}>
                          <IconButton style={{ color: " white" }} aria-label="upload picture" component="span" onClick={handleRsetSold, handleChangeSold}>
                            <ReplayIcon onClick={handleRsetSold} />
                          </IconButton>
                        </Paper>
                      </div>}
                    {Sorting}
                    {SoldSoFarmergeSort.length == 0 &&
                      <h2>Loading...</h2>}
                    <h5> *We are updating our website every 24 hours with content</h5>
                  </div>
                </Container>
              </div>
            </Paper>
          </div>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={9}>
              {SoldSoFarmergeSort.map(card =>
                <Grid item key={card} xs={12} sm={12} md={12} spacing={2} >
                  <p style={{ marginBottom: 16 }}></p>
                  <div className={classes.root}>
                    <Paper className={classes.paperD} elevation={0} style={{ borderRadius: "20px", background: "#2979ff1f" }}>
                      <Grid container spacing={2}  >
                        <Grid item>
                          <Link underline='none' to={card.slug ? `/New-constructions/${card.slug}` : '/Last-Solds'} >
                            <ButtonBase className={classes.image}>
                              <img className={classes.img} alt={card.propertyname} src={card.featuredUrl || card.url || defaultmage} />
                              <div className="top-Right_Rec_des_avb">Recommended</div>
                            </ButtonBase>
                          </Link>
                        </Grid>
                        <Grid item xs sm container>
                          <Grid item className={classes.GridSpace}>
                            <Typography gutterBottom variant="subtitle1" style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 500 }}>
                              <HomeWorkIcon color="secondarytext" style={{ width: ".85em", height: ".85em", color: "darkred" }} />  {card.community}
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginLeft: "auto", color: "red" }} >
                            <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                              <ChevronRightRoundedIcon style={{ color: "red", alignSelf: "center" }} /> Sold By Deepti Gupta
                            </Typography>
                          </Grid>
                          <TableContainer style={{ background: "#f9fff6 ", borderRadius: "10px" }} >
                            <Table className={classes.table} size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontSize: 13 }}>Address</TableCell>
                                  <TableCell style={{ fontSize: 13 }}>($)Listing Price</TableCell>
                                  <TableCell style={{ fontSize: 13 }}>($)Sold Price</TableCell>
                                  <TableCell style={{ fontSize: 13 }}>Sold Date</TableCell>
                                  <TableCell style={{ fontSize: 13 }}>Represented</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* {card.map(card =>( */}
                                <>
                                  <Url Properties={Properties} community={card.community} Activename={card.Activename} SoldMergeall={SoldMergeall} Address={card.address} zip={card.zip}
                                    Represented={card.Represented} ListingPrice={card.ListingPrice} startprice={card.startprice} ActiveStatus={card.ActiveStatus} OfferDate={card.OfferDate} Tag={card.Tag} slug={card.slug}
                                    propertyname={card.propertyname} city={card.city} url={card.url} featuredUrl={card.featuredUrl}
                                  />
                                </>
                                {/* // ))} */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>

                      {card.slug && <div style={{ color: "red", width: "fit-content", marginLeft: "auto", marginTop: "10px" }}>
                        <Typography variant="subtitle1">
                          <Link underline='none' to={`/New-constructions/${card.slug}`} style={{ textDecoration: 'none' }} >
                            <Button
                              endIcon={<ChevronRightRoundedIcon />}
                              variant="contained"
                              color="primary"
                              fullWidth
                              style={{ fontSize: 12 }}
                            >
                              Know More
                            </Button>
                          </Link>
                        </Typography>
                      </div>}
                    </Paper>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid xs={3} style={{ marginTop: '2em' }}>
              {
                (SoldSoFarmergeSort.length == 0)
                  ? null :
                  <div>
                    <BookConsultation />
                    {/* <Address /> */}
                  </div>

              }
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* mobile version */}

      <Box display={{ xs: 'block', sm: 'none' }} m={1} style={{ "margin": "-7px 0px" }}>
        <div className="home-hero-section">
          <div className="home-hero-outer">
            <Paper className={classes.bannerPaper}>
              <div className={classes.position}>
                <Container className={classes.cardMob} maxWidth="md">
                  <div className="BAnner_text_AU">
                    {SoldSoFarmergeSort.length > 0 && Filter}
                    {SoldSoFarmergeSort.length == 0 &&
                      <h2>Loading...</h2>}
                    <h5> *We are updating our website every 24 hours with content</h5>
                  </div>
                </Container>
              </div>
            </Paper>
          </div>
        </div>
        {SoldSoFarmergeSort.length > 0 &&
          <div style={{ display: "flex" }}>
            <Button style={{ borderRadius: 0, background: "white", fontSize: 12 }}
              fullWidth
              onClick={handleClickOpen}
              elevation={0}
              startIcon={<SortIcon />}
              variant="contained">
              Sort
            </Button>
            <Paper elevation={3} style={{ borderRadius: 0, background: "#e0e0e0" }}>
              <IconButton color="onprimary" aria-label="upload picture" component="span" onClick={handleRsetSold, handleChangeSold}>
                <ReplayIcon onClick={handleRsetSold} />
              </IconButton>
            </Paper>
          </div>}
        {Sorting}
       
        {SoldSoFarmergeSort.map(card =>
          <Grid item key={card} xs={12} sm={12} md={12} style={{ padding: 10 }}>
            <Paper elevation={5} style={{ borderRadius: 10 }}>
              <div className={classes.root}>
                <Link underline='none' to={card.slug ? `/New-constructions/${card.slug}` : '/Soldsofar'} style={{ textDecoration: 'none' }} >
                  <Paper className={classes.papermob} elevation={0} >
                    <Grid container spacing={1} style={{ alignItems: "center", paddingBottom: 10 }}>
                      <Grid item>
                        <ButtonBase className={classes.imagemob}>
                          <img className={classes.imgmob} alt={card.propertyname} src={card.featuredUrl || card.url || defaultmage} />
                          <div className="top-Right_Rec_mob_width">Recommended</div>
                        </ButtonBase>
                      </Grid>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1" className={classes.textmob1}>
                            {card.community}
                          </Typography>
                          <Grid item >
                            <Grid item xs container direction="row" style={{ alignItems: "center" }}>
                              <Grid item >
                                <Typography className={classes.textmob3}>
                                  <RoomIcon color="secondarytext" style={{ width: ".8em", height: ".8em" }} /> {card.city}
                                </Typography>
                              </Grid>
                              <Grid item xs container direction="row" style={{ justifyContent: "center" }}>
                                <Grid item >
                                  {card.slug && <Typography className={classes.textmob3}>
                                    <Typography variant="subtitle1">
                                      <Link underline='none' to={`/${card.slug}`} className={classes.textmobLink}>
                                        <ChevronRightRoundedIcon />Know More
                                      </Link>
                                    </Typography>
                                  </Typography>}
                                </Grid >
                              </Grid >
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                        <Grid item >
                          <Grid item xs container direction="row" style={{ alignItems: "center", marginTop: "-12px" }}>
                            <Grid item >
                              <Typography className={classes.textmob3}>
                                {/* <RoomIcon color="secondarytext" style={{width: ".85em",height: ".85em"}}/> tyfukf */}
                              </Typography>
                            </Grid>
                            <Grid item xs container >
                              {/* <Grid item >
                            {card.OfferDate && <div> <div className="top-Right_Rec_mob_avb_date" >
                            <EventNoteIcon color="secondarytext" style={{width: ".85em",height: ".85em" }}/> {card.OfferDate}</div> </div>}
                            </Grid> */}
                              <Grid item >
                                <Typography className={classes.textmobSold}>
                                  <FlashOnIcon color="secondarytext" style={{ width: ".8em", height: ".8em" }} />Sold By Deepti Gupta
                                </Typography>
                              </Grid >
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <TableContainer style={{    background: "#f9fff6 ", borderRadius: "20px"}} >
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell  align="right" style={{fontSize: 12, letterSpacing : "0.01071em"}}>Address</TableCell>
                          <TableCell align="right" style={{fontSize: 12, letterSpacing : "0.01071em"}}>($)Listing Price</TableCell>
                          <TableCell align="right" style={{fontSize: 12, letterSpacing : "0.01071em"}}>($)Sold Price</TableCell>
                          <TableCell xs align="right" style={{fontSize: 12, letterSpacing : "0.01071em"}}>Sold Date</TableCell>
                          <TableCell xs align="right" style={{fontSize: 12, letterSpacing : "0.01071em"}}>Represented</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <UrlMob Properties={Properties} community={card.community} Activename={card.Activename}  SoldMergeall={ SoldMergeall}  Address={card.address} Represented={card.Represented}
                          ListingPrice={card.ListingPrice} startprice={card.startprice} ActiveStatus={card.ActiveStatus} OfferDate={card.OfferDate} Tag={card.Tag} slug={card.slug}
                          propertyname={card.propertyname} city={card.city} url={card.url}
                      />
                      </TableBody>
                    </Table>
                  </TableContainer> */}
                    <div className={classes.mobTableBody}>
                      <p className={classes.mobText}>Address : {card.address || 'NA'} {card.address ? card.city : ''}{card.zip ? `, ${card.zip}` : ''}</p>
                      <p className={classes.mobText}>Listing Price : {<NumberFormat value={card.ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</p>
                      <p className={classes.mobText}>Sold Price : {<NumberFormat value={card.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</p>
                      <p className={classes.mobText}>Sold Date : {card.OfferDate || 'NA'}</p>
                    </div>
                  </Paper>
                </Link>
              </div>
            </Paper>
          </Grid>
        )}
         <Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}>
          {
            (SoldSoFarmergeSort.length == 0)
              ? null :
              <div>
                <BookConsultation />
                {/* <Address /> */}
              </div>
          }
        </Grid>
      </Box>
      <Footer />
    </div>
  )
}


export default withPropertyConsumer(SoldSoFarMerge);
















