import React, { useEffect, useState, useContext } from 'react'
import { Container, Box, Paper, Select, useMediaQuery, IconButton, Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import House from '../components/House';
import Loading from '../components/Loading';
import firebase from '../firebase/firebase';
import { PropertyContext } from '../context';
import banner22 from '../images/banner2.jpg';
import Footer from '../components/Footer'
import AvaliableCards from '../components/Cards/AvaliableCards';
import BookConsultation from '../components/Cards/BookConsultation';
import '../components/House.css'
import '../components/HouseMobile.css'
import '../components/Filter.css'
import ListingComponent from '../Listings/ListingComponent'
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) =>
({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 25,
        paddingTop: 150
    },

    cityText: {
        fontSize: "23px",
        marginBottom: 0,
        textAlign: "center",
        fontWeight: 500,
        color: "#e60e0ebd"
    },
    paper: {
        marginBottom: '8rem',

    },
    searchroot: {
        paddingTop: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: 'auto',
        borderRadius: '30px'

    },

    input: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,

    },

    iconButton: {
        padding: 10,
    },
    search_input: {
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: '0.00938em',

    },
})
);





const CityListings = () => {
    const context = useContext(PropertyContext);
    const { listings, getData } = context;
    const classes = useStyles();
    const isMatch = useMediaQuery('(max-width:960px )');
    const [searchTerm1, setSearchTerm1] = React.useState("");
    const [user, setUser] = useState(null)


    const city = listings.map(item => item.city)
    const cities = [...new Set(city)]
    // console.log("cities", cities);


    let listingshouse = listings;
    let listingshouse2 = listings;

    listingshouse = [
        ...listingshouse.filter(house => house.ActiveStatus === "Active").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse.filter(house => house.ActiveStatus === "Pending").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse.filter(house => house.ActiveStatus === "Sold").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
    ]

    listingshouse2 = [
        ...listingshouse2.filter(house => house.ActiveStatus === "AdminOnly").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse2.filter(house => house.ActiveStatus === "Active").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse2.filter(house => house.ActiveStatus === "Pending").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse2.filter(house => house.ActiveStatus === "Sold").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
    ]

    const filteredClient = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            return listingshouse.filter(item => item.city === searchTerm1)
        }
        return listingshouse
    },
    )


    const filteredClient1 = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            return listingshouse2.filter(item => item.city === searchTerm1)
        }
        return listingshouse2
    },
    )

    // React.useEffect(() => {
    //     try {
    //         Axios.get(`https://api.mlsgrid.com/v2/Property?$filter=OriginatingSystemName eq 'nwmls' and StandardStatus eq 'Active' and ListOfficeMlsId eq 'NWM2821'&$expand=Media,Rooms,UnitTypes&$top=3`, {
    //             headers: {
    //                 'Authorization': 'Bearer c37153a44bfc573a32e5476675fd0d7c97feb070'
    //             }
    //         })
    //             .then(res => {
    //                 // setElementryschooldata(data.data.schools);
    //                 console.log("api", res)
    //             })
    //     }
    //     catch (err) {
    //         console.log(console.err)
    //     }
    // }, [])
    // console.log("Api")

    useEffect(() => {
        if (listingshouse.length === 0) {
            getData()
        }

    }, [])

    useEffect(() => {
        if (listingshouse2.length === 0) {
            getData()
        }

    }, [])

    useEffect(() => {
        var user = firebase.auth().currentUser;
        if (user !== null) {
            setUser(true);
        }
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // console.log('This is the user: ', user)
                setUser(true);
            }
        }
        )

    }, [])


    React.useEffect(() => {
        // Listen for authentication changes
        const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const allowedEmail = 'dguptahomes.web@gmail.com';

    if (listingshouse.length === 0) {
        return <Loading />
    }

    if (listingshouse2.length === 0) {
        return <Loading />
    }

    return (
        <div>
            {isMatch ? <ListingComponent listingsmobhouse={listingshouse2} Cities={cities} /> : (
                <div>
                    <Paper className={classes.bannerPaper}>
                        <div className={classes.paper}>
                            <Paper component="form" className={classes.searchroot}>
                                <Select
                                    disableUnderline
                                    color=""
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    name="Soldmergecity"
                                    defaultValue="none"
                                    className={classes.input}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="none" className={classes.search_input}  >
                                        Search by city
                                    </MenuItem>
                                    <MenuItem value="All" className={classes.search_input} onClick={() => setSearchTerm1("")} >
                                        All
                                    </MenuItem>
                                    {cities.map((item, index) => (
                                        <MenuItem key={index} value={item} className={classes.search_input} onClick={() => setSearchTerm1(item)} >
                                            {item + ", " + 'WA'}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton className={classes.iconButton} aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </div>
                    </Paper>
                    <AvaliableCards />

                    <Box display={{ xs: 'none', md: 'block' }} m={1} >
                        <Container style={{ padding: '0px 50px 40px 60px' }}>
                            <Grid container spacing={1}>

                                {
                                    user && user.email === allowedEmail ?
                                        <Grid item xs={9}>
                                            {
                                                listingshouse2.length > 0 &&
                                                filteredClient1.map(item => {
                                                    return (
                                                        <div>
                                                            <House key={item.id} home={item} User={user} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        :

                                        <Grid item xs={9}>
                                            {
                                                listingshouse.length > 0 &&
                                                // listingshouse.filter((item) => {
                                                //     if (searchItem === "city") {
                                                //         return item
                                                //     } else if (item.city.toLowerCase().includes(searchItem.toLowerCase())) {
                                                //         return item
                                                //     }
                                                // })
                                                filteredClient
                                                    .filter(house => house.ActiveStatus !== "AdminOnly")
                                                    // .sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
                                                    .map(item => {
                                                        return (
                                                            <div>
                                                                <House key={item.id} home={item} />
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </Grid>

                                }
                                <Grid item xs={3}>
                                    <div>
                                        <BookConsultation />
                                        {/* <Address /> */}

                                    </div>

                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    <div>
                        <Footer />
                    </div>

                </div>
            )}
        </div>
    )
}


export default CityListings





