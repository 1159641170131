import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, Container, Divider, FormControl, Grid, IconButton, InputLabel, Paper, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import banner22 from '../images/deal2.jpg';
import { PropertyContext } from '../context'
import Loading from '../components/Loading';
import BookConsultation from '../components/Cards/BookConsultation';
import { Search } from '@mui/icons-material';
import { MenuItem, Select } from '@material-ui/core';
import Footer from '../components/Footer';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    bannerPaper: {
        background: ` linear-gradient(
            rgb(197 192 192 / 7%), 
            rgb(0 0 0 / 28%)
          ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'norepeat',
        backgroundPosition: 'center center',
        overflow: 'hidden',
        // padding: 40,
        width: '100%',
        alignItems: 'start',
        justifyContent: 'start',

    },
    cityText: {
        fontSize: "23px",
        marginBottom: 0,
        textAlign: "center",
        fontWeight: 500,
        color: "#e60e0ebd"
    },
    paper: {
        marginBottom: '8rem',

    },
    searchroot: {
        paddingTop: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: 'auto',
        borderRadius: '30px'

    },
    input: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,
        height: '50px'
    },
    input1: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,
    },

    iconButton: {
        padding: 10,
    },
    search_input: {
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: '0.00938em',

    },
    search_input1: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '0.00938em',

    },
}))

const CustomPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: 'auto',
    "&:hover": {
        backgroundColor: ' #4240400d',
    }
}))

const CustomChip = styled(Chip)(({ theme, isMatch }) => ({
    order: 0,
    padding: 0,
    verticalAlign: 'middle',
    boxSizing: 'border-box',
    zIndex: 11,
    left: isMatch ? '0px' : '18px',
    top: isMatch ? '17%' : '25px',
    position: 'absolute',
    color: '#fff',
    paddingLeft: '3px',
    paddingRight: '3px',
    borderRadius: 0,
    fontWeight: 600,
    fontSize: '10px',
    background: '#4BB4B4',
}))


const CustomBox = styled(Box)(({ theme }) => ({
    width: '40px',
    height: '42px',
    zIndex: 11,
    top: '0px',
    right: '30px',
    fontSize: '12px',
    background: '#f50057',
}))

const StyledBox = styled(Box)(({ theme }) => ({
    lineHeight: 1.5,
    textTransform: 'none',
    whiteSpace: 'normal',
    color: '#fff',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

const CustomBox1 = styled(Box)(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: '0px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '20px solid #f50057',
}))

const CustomBox2 = styled(Box)(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: '0px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '20px solid #f50057',
}))


const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '300px',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4em',
    [theme.breakpoints.down("xs")]: {
        height: 250,
        padding: 34,
        paddingTop: 60,
    }
}));


const StyledPaper1 = styled(Paper)(({ theme }) => ({
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 280,
    marginTop: '1em',
    borderRadius: '40px'
}));



const Deals = () => {
    const classes = useStyles();
    const context = useContext(PropertyContext);
    const { homedeals, DealsData } = context;
    const isMatch = useMediaQuery('(max-width:480px)');
    const [searchTerm1, setSearchTerm1] = React.useState("");
    const history = useNavigate();

    const category = homedeals.map(item => item.categories)
    const categories = [...new Set(category)];

    const filteredClient = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            return homedeals.filter(item => item.categories === searchTerm1)
        }
        return homedeals
    },
    )


    React.useEffect(() => {
        if (homedeals.length === 0) {
            DealsData()
        }

    }, [])

    if (homedeals.length === 0) {
        return <Loading />
    }

    return (
        <div>
            {
                isMatch ?
                    <StyledPaper>
                        <Typography gutterBottom
                            sx={{
                                color: '#fff',
                                fontSize: '25px',
                                textAlign: 'center',
                                fontWeight: 500
                            }}>
                            Home Improvement Deals
                        </Typography>
                        <StyledPaper1 component="form">
                            <Select
                                disableUnderline
                                color=""
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                name="Soldmergecity"
                                className={classes.input1}
                                defaultValue="none"
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem disabled value="none" className={classes.search_input1}  >
                                    Search by category
                                </MenuItem>
                                <MenuItem value="All" className={classes.search_input1} onClick={() => setSearchTerm1("")} >
                                    All
                                </MenuItem>
                                {categories.map((item, index) => (
                                    <MenuItem key={index} value={item} className={classes.search_input1} onClick={() => setSearchTerm1(item)} >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            <IconButton aria-label="search" sx={{ mb: '5px' }}
                            >
                                <Search />
                            </IconButton>
                        </StyledPaper1>
                    </StyledPaper>
                    :
                    <Paper className={classes.bannerPaper}>
                        <Typography gutterBottom
                            sx={{
                                pt: '1em',
                                pb: '1em',
                                color: '#fff',
                                fontSize: '5.5em',
                                textAlign: 'center',
                                fontWeight: 500
                            }}>
                            Home Improvement Deals
                        </Typography>
                        <div style={{ marginBottom: '3em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Paper sx={{
                                paddingTop: '2px 10px',
                                display: 'flex',
                                alignItems: 'center',
                                width: 300,
                                marginBottom: '10em',
                                borderRadius: '30px'
                            }}>
                                <Select
                                    disableUnderline
                                    color=""
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    name="Soldmergecity"
                                    defaultValue="none"
                                    className={classes.input}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="none" className={classes.search_input}  >
                                        Search by category
                                    </MenuItem>
                                    <MenuItem value="All" className={classes.search_input} onClick={() => setSearchTerm1("")} >
                                        All
                                    </MenuItem>
                                    {categories.map((item, index) => (
                                        <MenuItem key={index} value={item} className={classes.search_input} onClick={() => setSearchTerm1(item)} >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton aria-label="search" sx={{ mb: '5px' }}
                                >
                                    <Search />
                                </IconButton>
                            </Paper>
                        </div>
                    </Paper>
            }

            <Box display={{ xs: isMatch ? 'block' : 'none', md: isMatch ? 'none' : 'block' }} m={1} >
                <Container sx={{ p: isMatch ? 'none' : '0px 50px 40px 60px' }}>
                    <Grid container spacing={2} sx={{ mt: isMatch ? '1em' : '2em', mb: isMatch ? '1em' : 0 }}>
                        {
                            homedeals.length > 0 && filteredClient
                                .filter((item) => item.IsVisible === "Yes")
                                .sort((a, b) => (a.reviewDate > b.reviewDate) ? -1 : 1)
                                .map((item) => {
                                    return (
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            {
                                                isMatch ?
                                                    <Card sx={{ position: 'relative' }}>
                                                        <Grid item xs={11}>
                                                            <CardHeader
                                                                title={
                                                                    <Typography sx={{ fontSize: '16px', color: '#373F50', fontWeight: 500 }}>
                                                                        {item.name}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <CardMedia
                                                            component="img"
                                                            alt={item.name}
                                                            sx={{
                                                                backgroundSize: 'cover',
                                                                backgroundRepeat: 'norepeat',
                                                                backgroundPosition: 'center center',
                                                                transition: 'transform 0.3s, opacity 0.3s',
                                                            }}
                                                            image={item.houseImage}
                                                            src={item.categories}
                                                            onClick={() => history(`/Deals/${item.dealSlug}`)}
                                                        />
                                                        {(item.rating) === '' ? null : <CustomChip label={`${item.rating} % off`} isMatch={isMatch} />}
                                                        {(item.IsNew) === "Recommended" ?
                                                            <Grid item xs={1} >
                                                                <CustomBox sx={{ position: 'absolute', right: '10px' }}>
                                                                    <StyledBox component="span" >
                                                                        New
                                                                    </StyledBox>
                                                                    <Box sx={{ width: '100%', display: 'flex', }}>
                                                                        <CustomBox1></CustomBox1>
                                                                        <CustomBox2></CustomBox2>
                                                                    </Box>
                                                                </CustomBox>

                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                        <CardContent>
                                                            <Grid item xs={12}>
                                                                <Typography sx={{ fontSize: '14px', color: '#0000008a', }}>
                                                                    {item.shortDescription}
                                                                </Typography>
                                                                <Divider sx={{ mt: '10px' }} />
                                                                <Stack spacing={0.5} display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ p: '10px' }}>
                                                                    <Typography sx={{ fontSize: '13px', }}>
                                                                        Price: $ {item.startprice}
                                                                    </Typography>
                                                                    {(item.rating) === '' ? null : <Typography sx={{ fontSize: '13px', }}>
                                                                        Discount:  Reflected in sale price
                                                                    </Typography>}
                                                                    {(item.shipping) === '' ? null : <Typography sx={{ fontSize: '13px', }}>
                                                                        Shipping:  {item.shipping}
                                                                    </Typography>}
                                                                    <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
                                                                        Final Price:  $ {item.finalprice}  {(item.shipping) === "Free" ? '+ Free Shipping' : item.shipping}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </CardContent>
                                                        <CardActions sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                            <Button size="large" variant='contained' color="success" sx={{ mr: '1em' }} onClick={() => history(`/Deals/${item.dealSlug}`)}> Get Deal</Button>
                                                        </CardActions>
                                                    </Card>
                                                    :
                                                    <Card sx={{ width: '80%', position: 'relative' }}>
                                                        <div style={{ flexGrow: 1, }}>
                                                            <CustomPaper elevation={0} square="true" >
                                                                <Grid container spacing={2} display="flex" flexDirection="row">
                                                                    <Grid item xs={4}>
                                                                        {(item.rating) === '' ? null : <CustomChip label={`${item.rating} % off`} />}
                                                                        <CardActionArea elevation={0}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                alt={item.name}
                                                                                image={item.houseImage}
                                                                                onClick={() => history(`/Deals/${item.dealSlug}`)}
                                                                            />
                                                                        </CardActionArea>
                                                                    </Grid>
                                                                    <Grid item xs={7}>
                                                                        <Typography sx={{ fontSize: '20px', color: '#373F50', fontWeight: 500 }}>
                                                                            {item.name}
                                                                        </Typography>
                                                                        <Divider sx={{ mt: '10px' }} />
                                                                        <Typography sx={{ fontSize: '14px', color: '#0000008a', pt: '10px' }}>
                                                                            {item.shortDescription}
                                                                        </Typography>

                                                                        <Stack spacing={0.5} display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ p: '1em' }}>
                                                                            <Typography sx={{ fontSize: '14px', }}>
                                                                                Price: $ {item.startprice}
                                                                            </Typography>
                                                                            {(item.rating) === '' ? null : <Typography sx={{ fontSize: '14px', }}>
                                                                                Discount:  Reflected in sale price
                                                                            </Typography>}
                                                                            {(item.shipping) === '' ? null : <Typography sx={{ fontSize: '14px', }}>
                                                                                Shipping:  {item.shipping}
                                                                            </Typography>}
                                                                            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                                                                Final Price:  $ {item.finalprice}  {(item.shipping) === "Free" ? '+ Free Shipping' : item.shipping}
                                                                            </Typography>
                                                                        </Stack>

                                                                    </Grid>
                                                                    {(item.IsNew) === "Recommended" ? <Grid item xs={1}>
                                                                        <CustomBox>
                                                                            <StyledBox component="span" >
                                                                                New
                                                                            </StyledBox>
                                                                        </CustomBox>
                                                                        <Box sx={{ width: '100%', display: 'flex', }}>
                                                                            <CustomBox1></CustomBox1>
                                                                            <CustomBox2></CustomBox2>
                                                                        </Box>
                                                                    </Grid>
                                                                        :
                                                                        null
                                                                    }
                                                                </Grid>
                                                                <CardActions sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                    <Button size="large" variant='contained' color="success" sx={{ mr: '1em' }} onClick={() => history(`/Deals/${item.dealSlug}`)}>Get Deal</Button>
                                                                </CardActions>
                                                            </CustomPaper>
                                                        </div>
                                                    </Card>}
                                        </Grid>
                                    )
                                })
                        }

                    </Grid>
                </Container>
            </Box>
            <Footer />
        </div>
    )
}

export default Deals