import React, { useState, useEffect } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Paper, Typography, Button } from '@mui/material';
import banner22 from '../images/blogimg/blogbanner.jpg';
import { makeStyles } from '@material-ui/styles';
import sanityClient from '../client';
import imageUrlBuilder from "@sanity/image-url";
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
// import Loading from '../components/Loading';

const useStyles = makeStyles((theme) => ({
    bannerPaper: {
        // borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        padding: 140,

    },
    text: {
        fontSize: '1em',
        fontWeight: '500',
        color: 'white',
        textAlign: 'center'
    }

}));


const builder = imageUrlBuilder(sanityClient);


function urlFor(source) {
    return builder.image(source);
}
// const serializers = {
//     types: {
//         code: (props) => (
//             <pre data-language={props.node.language}>
//                 <code>{props.node.code}</code>
//             </pre>
//         )
//     }
// }

const MobBlog = () => {
    const classes = useStyles();
    const [posts, setPosts] = useState(null);
    const history = useNavigate();


    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "post"]{
            title,
            address,
            mainImage{
                asset->{
                    _id,
                    url
                },               
            },
            description,
            date,
            releaseDate,
            authorname,
            authorimage,
        }`
            )
            .then((data) => setPosts(data))
            .catch(console.error);
    }, []);
    // console.log("posts", posts)

//  if (listingshouse.length === 0) {
//         return <Loading />
//     }


    return (
        <>
            <Box style={{ margin: 0, background: "white" }}>
                <Paper className={classes.bannerPaper}>
                    <Grid item xs={12} align="center" style={{ marginBottom: '2em' }}>
                        <Typography style={{
                            fontSize: '40px',
                            fontWeight: '500',
                            color: 'white',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                        }}>Blog</Typography>
                    </Grid>

                </Paper>
            </Box>
            <Box style={{ margin: 0, background: "white" }}>
                <Container style={{ background: 'rgb(224 224 224 / 23%)' }}>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                        {posts && posts
                        .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                        .map((post, index) => (
                            <Grid item xs={12} key={post.address.current} >

                                <Card onClick={() => history(`${post.address.current}`)} >
                                    <CardActionArea key={index}>
                                        <CardMedia
                                            component="img"
                                            alt={post.title}
                                            height="200"
                                            image={urlFor(post.mainImage).url()}
                                            src="blog posts" />
                                        <Grid container alignItems="center" style={{marginTop: '1em'}} >
                                            <Grid item sm >
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '1.3em' }}>
                                                <DateRangeRoundedIcon sx={{ fontSize: 20 }} /> {post.date}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item  >
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '0.2em'}}>
                                                    -
                                                </Typography>
                                            </Grid>
                                            <Grid item align="initial">
                                                <Avatar alt="authorimage" sx={{ width: 22, height: 22, marginLeft: '0.2em' }} src={urlFor(post.mainImage).url()} />
                                            </Grid>
                                            <Grid item align="initial">
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '0.2em' }}>
                                                    {post.authorname}
                                                </Typography>
                                            </Grid> */}

                                            <Divider variant="middle" style={{ width: '100%',marginTop: '0.5em' }} />
                                        </Grid>
                                        
                                        <CardContent>

                                            <Grid item spacing={4}>

                                                <Grid item xs={12} style={{background: 'lightgrey' ,padding: '1em', borderRadius: '10px'}}>
                                                    <Typography
                                                        variant="h7"
                                                        style={{ fontWeight: '600', fontSize: '18px' }}
                                                    >
                                                        {post.title}
                                                    </Typography>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12}>
                                                    <Typography
                                                        gutterBottom
                                                        style={{ fontWeight: '400', fontSize: '15px' }}
                                                    >
                                                        {post.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        style={{ margin: '0px -7px', fontSize: '14px', background: 'linear-gradient(45deg, #e91d1d, #f97f45)', borderRadius: '20px'}}
                                                        onClick={() => history(`${post.address.current}`)}
                                                    >
                                                        Read More
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>


                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <div>
                <Footer />
            </div>
        </>
    )

};

export default MobBlog;
