import Axios from "axios";

const instance = Axios.create({
    // baseURL: 'https://cors-anywhere.herokuapp.com/https://api.greatschools.org/',
   
    // baseURL: 'https://cors-anywhere.herokuapp.com/',
    // baseURL: 'https://dgupta-homes-cors.herokuapp.com/',
    baseURL: 'https://cors.bridged.cc/',
    headers: { 
        'Authorization': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
        // 'X-API-KEY' : `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
        'Content-Type': 'application/json',
        'Accept' : "application/json",
        'responseType': "json",
      } 
});

export default instance;