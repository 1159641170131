import React,{Component} from 'react';
import firebase from '../firebase/firebase';
import Properties from '../pages/Properties';
import Login from './Login';


class NewConstAdmin extends Component{
    constructor(props){
        super(props);

        this.state ={
            user: null
        }
    }
    componentDidMount(){
        this.authListener()
    }
   async authListener(){
        await firebase.auth().onAuthStateChanged((user) => {
            if(user){
                this.setState({user});
            }
            else{
                this.setState({user : null});
            }
        });
    }
   
    render(){
        return (
            <div>
                {this.state.user ? (<Properties/>) : (<Login/>)}
            </div>
        )
    }
}
export default NewConstAdmin;