import React, { useState } from 'react'
import { Box, Grid, Container, Card, CssBaseline, useMediaQuery, Button, TextField, Paper, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import banner22 from '../images/Bannermob13.png';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Footer from '../components/Footer';

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'grey',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey',
    },
  },

})


const useStyles = makeStyles((theme) => ({
  bannerPaper: {
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 34,
    paddingTop: 60
  },
  cardGrid: {
    marginTop: "10px",
  },
  cardField: {
    margin: "1em",
  },

}))

function MyFormControlLabel(props) {
  const isMatch = useMediaQuery('(max-width:600px )');

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">PLEASE NOTE - If you are married, its mandatory to fill your spouse information.</Typography>
        <Divider style={{ margin: '1em 0em' }} />
        <Typography variant="h7">As per the Washington State Legislature, property acquired after the marriage ceremony is considered community property. As such, the property belongs equally to both spouses and neither one can dispose off that property through transfer or sale without the permission of the other. </Typography>
      </Grid>

      <Grid xs={isMatch ? 12 : 8} >
        <CustomTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Spouse's Legal First Name"
          name='entry.1038510422'
          autoComplete="name"
          className="text_contact"

        />
      </Grid>
      <Grid xs={isMatch ? 12 : 8} >
        <CustomTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Spouse's Legal Middle Name"
          name='entry.1005422554'
          autoComplete="name"
          className="text_contact"

        />
      </Grid>
      <Grid xs={isMatch ? 12 : 8} >
        <CustomTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Spouse's Legal Last Name"
          name='entry.1219994322'
          autoComplete="name"
          className="text_contact"

        />
      </Grid>
      <Grid xs={isMatch ? 12 : 8} >
        <CustomTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="number"
          label="Spouse's Mobile Number"
          name='entry.1639879148'
          type="number"
          autoComplete="number"
          className="text_contact"
        />
      </Grid>
      <Grid xs={isMatch ? 12 : 8} style={{ marginTop: '1em' }}>
        <CustomTextField
          required
          variant="outlined"
          id="email"
          name='entry.914731706'
          label="Spouse's Email ID"
          type="email"
          className="text_contact"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};



export const SellerInputs = () => {

  const classes = useStyles();
  const isMatch = useMediaQuery('(max-width:600px )');
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClick = () => {
    setOpen(true)
  }
  const handleClick1 = () => {
    setOpen(false)
  }

  const handleClick2 = () => {
    setOpen1(true)
  }

  const handleClick3 = () => {
    setOpen1(false)
  }

  return (
    <>
      <Box style={{ margin: 0, background: "white" }}>
        <Paper className={classes.bannerPaper}>
          <Container className={classes.cardGrid} maxWidth="md">
            <div className="BAnner_text_Nav">
              <h3>Seller Information </h3>
            </div>
          </Container>
        </Paper>
        <Box style={{ margin: 0, background: "white" }}>
          <Container component="main" maxWidth={isMatch ? 'xs' : 'md'} style={{ padding: isMatch ? '10px' : '1em' }}>
            <CssBaseline />
            <Card style={{ padding: isMatch ? '10px' : '14px' }}>
              <Grid xs={12}>
                <Typography style={{ fontSize: '1.8em', fontWeight: 500 }}>Deepti Gupta Real Estate</Typography>
              </Grid>
              <Divider style={{ marginTop: '10px' }} />
              <Grid xs={12} style={{ marginTop: '10px' }}>
                <Typography color="primary" style={{ fontSize: '1em', fontWeight: 400 }}>Please fill in the following contact details as it will help us to serve you better.</Typography>
              </Grid>
              <form action='https://docs.google.com/forms/u/0/d/e/1FAIpQLSd8q0dSOBTX4fGn6rx30R0nCICJ8uk4ligHgGOFXtWAz3yeQQ/formResponse?pli=1'>
                <Grid container spacing={1}>
                  <Grid item xs={isMatch ? 12 : 6} >
                    <CustomTextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Legal First Name"
                      name='entry.1313126989'
                      autoComplete="name"
                      className="text_contact"
                      fontColor="black"
                    />
                  </Grid>

                  <Grid item xs={isMatch ? 12 : 6} >
                    <CustomTextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Legal Middle Name"
                      name='entry.1354625420'
                      autoComplete="name"
                      className="text_contact"

                    />
                  </Grid>

                  <Grid item xs={isMatch ? 12 : 6} >
                    <CustomTextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Legal Last Name"
                      name='entry.18653109'
                      autoComplete="name"
                      className="text_contact"
                    // value={values.name}

                    />
                  </Grid>

                  <Grid item xs={isMatch ? 12 : 6} >
                    <CustomTextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="number"
                      label="Mobile Number"
                      name='entry.1153027982'
                      type="number"
                      autoComplete="number"
                      className="text_contact"
                    />
                  </Grid>

                  <Grid item xs={isMatch ? 12 : 6} style={{ marginTop: '1em' }} >
                    <CustomTextField
                      required
                      variant="outlined"
                      id="email"
                      name='emailAddress'
                      label="Email ID"
                      type="email"
                      className="text_contact"
                      fullWidth
                    />
                  </Grid>



                </Grid>
                <Divider style={{ margin: '1em 0em' }} />
                <Grid container spacing={1} style={{ margin: '1em 0em' }}>
                  <Grid item xs={12} >
                    <FormControl required>
                      <FormLabel id="demo-controlled-radio-buttons-group">Status </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name='entry.889136435'
                      >
                        <FormControlLabel value="Single" control={<Radio name='entry.889136435' />} label="Single" onClick={handleClick1} />
                        <FormControlLabel value="Married" control={<Radio name='entry.889136435' />} label="Married" onClick={handleClick} />

                      </RadioGroup>
                    </FormControl>
                    {/* <Divider /> */}
                  </Grid>
                  <Grid item xs={12}>
                    {open ?
                      <Container style={{ margin: '1em' }}>
                        <MyFormControlLabel />
                        <Divider style={{ paddingTop: '1em' }} />
                      </Container>
                      :
                      null}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required>
                      <FormLabel id="demo-controlled-radio-buttons-group">How did you Hear About us?  </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name='entry.469682701'
                      >
                        <FormControlLabel value="Recommended by friend or colleague" control={<Radio name='entry.469682701' />} label=" Recommended by friend or colleague" onClick={handleClick2} />
                        
                        {
                          open1 ?
                            <Grid xs={isMatch ? 12 : 8} >
                              {/* <Divider style={{ paddingTop: '1em' }} /> */}
                              <CustomTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Friend or colleague Full Name"
                                name='entry.363204752'
                                autoComplete="name"
                                className="text_contact"
                                sx={{ width: isMatch ? '100%' : '130%', ml: '2em', mt: '0em' }}
                              />
                            </Grid>
                            :
                            null
                        }
                        <FormControlLabel value="Social media (FB, WhatsApp, Insta)" control={<Radio name='entry.469682701' />} label="Social media (FB, WhatsApp, Insta)" onClick={handleClick3} />
                        <FormControlLabel value="Search engine (Google, Zillow, etc)" control={<Radio name='entry.469682701' />} label="Search engine (Google, Zillow, etc)" onClick={handleClick3} />
                        <FormControlLabel value="Other" control={<Radio name='entry.469682701' />} label="Other" onClick={handleClick3} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={isMatch ? 12 : 8} >
                    <CustomTextField
                      required
                      variant="outlined"
                      id="address"
                      name='entry.1509534779'
                      label="Listing Address"
                      type="address"
                      className="text_contact"
                      fullWidth
                    />

                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"

                  // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </Card>
          </Container>
        </Box>
      </Box>
      <div>
        <Footer />
      </div>
    </>
  )
}
