import React, { useState, useEffect } from 'react';
import { Backdrop, Button, CircularProgress, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DeleteForever } from '@mui/icons-material';
import firebase from '../firebase/firebase';
import EditIcon from '@mui/icons-material/Edit';
import FeaturedData from './FeaturedData';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    head: {
        backgroundColor: '#2979ff',
        color: '#fff',
    },
    body: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

const FeaturedPannel = () => {
    var fireDB = firebase.database().ref();
    var[propertyObject, setPropertyObject]= useState([])
    var[currentId,setCurrentId]= useState('')
    const [open, setOpen] = useState(false);
    //firebase data retrive

    useEffect(()=>{
        fireDB.child('FeaturedListings').on('value', snapshot =>{
        if(snapshot.val()!= null)
            setPropertyObject({
                ...snapshot.val()
            })
        else
            setPropertyObject({ })
        })
    },[])
    useEffect(()=>{
        fireDB.child('FeaturedListings').child('url').on('child_added', snapshot =>console.log("child event:",snapshot.val()))
    },[])
 
    //similar to component didMount
    //Firebase data push
   
    
        const add= obj => {
            if(window.confirm('Are you sure')){
                if(currentId==='')
                    fireDB.child('FeaturedListings').push(
                        obj,
                        err =>{
                            if(err)
                                console.log(err)
                            else
                            setCurrentId('')
                        }
                    )
                else
            // fireDB.child(`Listings/${currentId}`).set(
                    fireDB.child(`FeaturedListings/${currentId}`).update(
                        obj,
                        err =>{
                            if(err)
                                console.log(err)
                            else
                            setCurrentId('')
                            console.log("obj:", obj);
                        }
                    )
                }
                else { 
                    window.location.reload();
                }
                
    }

    const  onDelete = async (key) =>{
        setCurrentId('')
        if(window.confirm('Are you sure')){
            setOpen(true)
           let data = [...propertyObject[key].url || '',...propertyObject[key].floorUrl || '',...propertyObject[key].siteurl || '',...propertyObject[key].featuredUrl || '']
            
           await Promise.all(
               data.map(element => 
               firebase.storage().refFromURL(element)
                .delete().then(function() {
                    console.log('image deleted');
                }).catch(function(error) {
                    console.log('an error occurred');
                }))
            ).then(()=>
                fireDB.child(`FeaturedListings/${key}`).remove(
                err =>{
                    if(err){
                        console.log(err)
                        setOpen(false)
                    }
                    else{
                        setOpen(false)
                        setCurrentId('')
                    }
                }
            )) 
        }
    }

    function Logout(){
        firebase.auth().signOut();
      }

    console.log("propertyObject:", propertyObject);
    return (
        <div style={{ marginTop: 50 }}>
            <h1>Featured Listings</h1>
            <StyledBackdrop  >
                <CircularProgress color="inherit" />
            </StyledBackdrop>
            <Container maxWidth="md" style={{ textAlign: "center", padding: "10px 0px" }}>
                <FeaturedData style={{marginTop: '1em'}} {...({add,currentId, propertyObject})}/>
            </Container>
            <Container maxWidth="lg" style={{ marginTop: '1em' }}>

                <TableContainer component={Paper} style={{ margin: 'auto', maxWidth: 1300, }}>
                    <Table style={{ minWidth: 700, }}>
                        <TableHead sx={{ background: '#2c7744', color: '#fff' }}>
                            <TableRow>
                                <StyledTableCell>Address</StyledTableCell>
                                <StyledTableCell>City</StyledTableCell>
                                <StyledTableCell>MlsId</StyledTableCell>
                                <StyledTableCell>Listings Price</StyledTableCell>
                                <StyledTableCell>Image</StyledTableCell>
                                <StyledTableCell>Edit/Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { Object.keys(propertyObject).map(id => {
                                return (
                                    <StyledTableRow >
                                        <StyledTableCell align='left'>{propertyObject[id].address}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].city}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].MlsId}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].listingPrice}</StyledTableCell>
                                       
                                        <StyledTableCell align="left"><img src={propertyObject[id].featuredImage} alt="image_png" width="100" height="80" /></StyledTableCell>
                                        <StyledTableCell align="start">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                onClick={()=>{onDelete(id)}}
                                                startIcon={<DeleteForever />}
                                            >

                                            </Button>
                                            <p></p>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={()=>{ setCurrentId(id);  window.scrollTo({top : 0, behavior: 'smooth'})}}
                                                startIcon={<EditIcon />
                                                }></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )

                            })}
                        </TableBody>
                    </Table>

                </TableContainer>
            </Container>
        </div>
    )
}

export default FeaturedPannel