import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import firebase from '../firebase/firebase';
import shortid from 'shortid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HomeIcon from '@material-ui/icons/Home';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SchoolIcon from '@material-ui/icons/School';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import DescriptionIcon from '@material-ui/icons/Description';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Delete, FileOpen, Wallpaper } from '@mui/icons-material';
import { Avatar, Card, Divider, Stack } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 30 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles2 = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
  },
  field: {
    paddingTop: 10,
    paddingRight: 10,
  },
  field2: {
    paddingRight: 10,
    minWidth: 180
  },
  block: {
    paddingTop: 20,

  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const TodayDate = new Date().toISOString().split('T')[0]
const currentTime = new Date().toLocaleTimeString();

const dateTime = `${TodayDate} ${currentTime}`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    autotext: {
      width: 720
    },
    imagetab: {
      padding: 10,
    },
    Logbutton: {
      float: 'right'
    },
    upload: {
      width: '100%',
    },
    input: {
      display: 'none',
    },
  }),
);


const Pannel = (props) => {
  const initialValues = {
    propertyname: '',
    city: '',
    ID: '',
    Activename: '',
    zip: '',
    startprice: '',
    builtyear: '',
    size: '',
    level1: '',
    level2: '',
    level3: '',
    endprice: '',
    ListingPrice: '',
    kitchens: '',
    bathrooms: '',
    bedrooms: '',
    livingrooms: '',
    parking: '',
    address: '',
    url: '',
    community: '',
    schools: '',
    builders: '',
    date: '',
    ID: '',
    timestamp: '',
    siteurl: '',
    short: '',
    floorUrl: '',
    description: '',
    slug: '',
    movein: '',
    facing: '',
    active: '',
    status: '',
    MlsId: '',
    Tag: '',
    Tag1: '',
    HighlightTag: '',
    Represented: '',
    OfferDate: '',
    ActiveStatus: '',
    Sold: '',
    featuredUrl: '',
    Agent1: '',
    Agent2: '',
    OFFMarketDate: '',
    Source: '',
    clientImage: '',
    video: '',
    ciorder: '',
    onMarketDate: '',
  }


  var [values, setValues] = useState(initialValues);
  const [allValues, setAllValues] = React.useState({
    newName: '',
    title: '',
    description: '',
    selectedDate: '',
    file: ''
  });
  // var [images, setImages]= useState([]);
  // var [Mapimages, setMapImages]= useState([]);
  var [url, setUrl] = useState([]);
  var [siteurl, setSiteurl] = useState([]);
  var [featured, setFeatured] = useState([]);
  var [clientImage, setClientImage] = useState([]);
  var [floorUrl, setFloorUrl] = useState([]);
  const [position, setPosition] = useState('')
  const [progress, setProgress] = React.useState(0);
  const [siteprogress, setSiteprogress] = React.useState(0);
  const [floorprogress, SetFloorprogress] = React.useState(0);
  const [clientprogress, SetClientprogress] = React.useState(0);
  const [active, setActive] = React.useState(false);
  const classes = useStyles2();
  const [value, setValue] = React.useState(0);
  var [currentId, setCurrentId] = useState('')
  var fireDB = firebase.database().ref();

  console.log('scoop', Object.keys(props.scoop).map((key) => props.scoop[key].newName))
  // console.log('id', currentId)
  useEffect(() => {
    if (props.currentId == '') {
      setValues({ ...initialValues })
      setUrl([...initialValues.url || ''])
      setFloorUrl([...initialValues.floorUrl || ''])
      setSiteurl([...initialValues.siteurl || ''])
      setFeatured([...initialValues.featuredUrl || ''])
      setClientImage([...initialValues.clientImage || ''])
      setActive(false)
    }
    else {
      setValues({
        ...props.propertyObject[props.currentId]
      })
      setActive(props.propertyObject[props.currentId].active || false)
      setUrl([...props.propertyObject[props.currentId].url || ''])
      setFloorUrl([...props.propertyObject[props.currentId].floorUrl || ''])
      setSiteurl([...props.propertyObject[props.currentId].siteurl || ''])
      setClientImage([...props.propertyObject[props.currentId].clientImage || ''])
      setFeatured([...props.propertyObject[props.currentId].featuredUrl || ''])
      // setAllValues({
      //   ...props.propertyObject[props.currentId].scoop
      // })
      // setFile([...props.scoop[props.currentId].file || ''])
    }
  }, [props.currentId, props.propertyObject])

  const classes2 = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  const handleInputchange = e => {
    var { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    });
  }

  const toggleChecked = () => {
    setActive((prev) => !prev);
  };



  const handleFaturedImage = async (e) => {
    if (e.target.files) {
      async function task() {
        const image = e.target.files[0];
        let folderName = 'imagess';
        const id = shortid.generate();
        var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
        var uploadTask = storageRef.put(image);
        uploadTask.on('state_changed', function (snapshot) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        }, function (error) {
          console.log(error);
        },
          async filename => {
            const downloadURL = await firebase
              .storage()
              .ref("imagess")
              .child(id + image.name)
              .getDownloadURL();
            setFeatured([downloadURL]);
          }
        )
      }
      task();
    }
  }

  const handleInputimage = async (e) => {
    let k = 0;
    let imageData = e.target.files;
    if (e.target.files) {
      async function task() {
        if (k < imageData.length) {
          const image = imageData[k];
          let folderName = 'imagess';
          const id = shortid.generate();
          var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
          var uploadTask = storageRef.put(image);
          uploadTask.on('state_changed', function (snapshot) {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          }, function (error) {
            console.log(error);
          },
            async filename => {
              const downloadURL = await firebase
                .storage()
                .ref("imagess")
                .child(id + image.name)
                .getDownloadURL();
              setUrl(oldState => ([...oldState, downloadURL]));
              k++;
              task();
            }
          )
        }
        else
          k = 0;
      }
      task();
    }
  }


  const handleInputFloorimage = async (e) => {
    let j = 0;
    let FloorImageData = e.target.files;
    if (e.target.files) {
      async function FloorTask() {
        if (j < FloorImageData.length) {
          const image = FloorImageData[j];
          let folderName = 'FloorImages';
          const id = shortid.generate();
          var storageRef = firebase.storage().ref(`${folderName}/${id + "_floorUrl_" + image.name}`)
          var uploadTask = storageRef.put(image);
          uploadTask.on('state_changed', function (snapshot) {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            SetFloorprogress(progress);
          }, function (error) {
            console.log(error);
          },
            async filename => {
              const downloadURL = await firebase
                .storage()
                .ref("FloorImages")
                .child(id + "_floorUrl_" + image.name)
                .getDownloadURL();
              setFloorUrl(oldState => ([...oldState, downloadURL]));
              j++;
              FloorTask();
            }
          )
        }
        else
          j = 0;
      }
      FloorTask();
    }
  }

  const handleInputMapimage = async (e) => {
    let C = 0;
    let SiteImageData = e.target.files;
    if (e.target.files) {
      async function siteMapTask() {
        if (C < SiteImageData.length) {
          const image = SiteImageData[C];
          let folderName = 'Mapimages';
          const id = shortid.generate();
          var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
          var uploadTask = storageRef.put(image);
          uploadTask.on('state_changed', function (snapshot) {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setSiteprogress(progress);
          }, function (error) {
            console.log(error);
          },
            async filename => {
              const downloadURL = await firebase
                .storage()
                .ref("Mapimages")
                .child(id + image.name)
                .getDownloadURL();
              setSiteurl(oldState => ([...oldState, downloadURL]));
              C++;
              siteMapTask();
            }
          )
        }
        else
          C = 0;
      }
      siteMapTask();
    }
  }


  const handleInputClientimage = async (e) => {
    let C = 0;
    let ClientImageData = e.target.files;
    if (e.target.files) {
      async function ClientTask() {
        if (C < ClientImageData.length) {
          const image = ClientImageData[C];
          let folderName = 'Clientimages';
          const id = shortid.generate();
          var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
          var uploadTask = storageRef.put(image);
          uploadTask.on('state_changed', function (snapshot) {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            SetClientprogress(progress);
          }, function (error) {
            console.log(error);
          },
            async filename => {
              const downloadURL = await firebase
                .storage()
                .ref("Clientimages")
                .child(id + image.name)
                .getDownloadURL();
              setClientImage(oldState => ([...oldState, downloadURL]));
              C++;
              ClientTask();
            }
          )
        }
        else
          C = 0;
      }
      ClientTask();
    }
  }


  ////////////////////////////// Site Map ///////////////////////




  const deleteImage = (ImageUrl, setter) => {
    const image = firebase.storage().refFromURL(ImageUrl);
    image.delete().then(function () {
      console.log('Deleting image:', ImageUrl);
      console.log('Using setter:', setter);
      if (setter === setUrl) {
        let newUrl = url.filter(item => item !== ImageUrl);
        setUrl(newUrl)
        setPosition('')
        fireDB.child(`properties/${props.currentId}`).update({ url: newUrl })
      }
      if (setter === setFeatured) {
        let newFeatured = featured.filter(item => item !== ImageUrl);
        setFeatured(newFeatured)
        setPosition('')
        fireDB.child(`properties/${props.currentId}`).update({ featuredUrl: newFeatured })
      }
      if (setter === setFloorUrl) {
        let newFloorUrl = floorUrl.filter(item => item !== ImageUrl);
        setFloorUrl(newFloorUrl)
        setPosition('')
        fireDB.child(`properties/${props.currentId}`).update({ floorUrl: newFloorUrl })
      }
      if (setter === setSiteurl) {
        let newSiteUrl = siteurl.filter(item => item !== ImageUrl);
        setSiteurl(newSiteUrl)
        setPosition('')
        fireDB.child(`properties/${props.currentId}`).update({ siteurl: newSiteUrl })
      }
      if (setter === setClientImage) {
        let newclientImage = clientImage.filter(item => item !== ImageUrl);
        setClientImage(newclientImage)
        setPosition('')
        fireDB.child(`properties/${props.currentId}`).update({ clientImage: newclientImage })
      }
    }).catch(function (error) {
      console.log('an error occurred');
      setPosition('')
    });
  }


  


  const onDelete = async (key) => {
        let data = [...props.scoop[key].file || '', ]
        await Promise.all(
            data.map(element =>
                firebase.storage().refFromURL(element)
                    .delete().then(function () {
                        console.log('image deleted');
                    }).catch(function (error) {
                        console.log('an error occurred');
                    }))
        ).then(() =>
            fireDB.child(`properties/${props.currentId}/scoop/${key}`).remove(
                err => {
                    if (err) {
                        console.log(err)
                    }
                }
            ))
}

  /////////////////////////////// END //////////////////////////

  // console.log("data", data)
  // console.log("urls:", siteurl)

  function submit() {
    setUrl('')
  }

  const handleFormsubmit = e => {
    e.preventDefault();
    props.add({ ...values, url, siteurl, active, floorUrl, featuredUrl: featured, clientImage: clientImage });
    setUrl('')
    setSiteurl('')
    setFloorUrl('')
    setFeatured('')
    setClientImage('')
    setActive(false)
  }

  // console.log("images", {...values, valueimage:url});
  // console.log("check", images)

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleFormsubmit}>
        <div className={classes.root}>
          <Paper className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label="General" icon={<HomeIcon />} {...a11yProps(0)} />
              <Tab label="Price" icon={<MonetizationOnIcon />}{...a11yProps(1)} />
              <Tab label="Information" icon={<LocalOfferIcon />} {...a11yProps(2)} />
              <Tab label="Schools" icon={<SchoolIcon />} {...a11yProps(3)} />
              <Tab label="Featured" icon={< InsertPhotoIcon />}{...a11yProps(4)} />
              <Tab label="Gallery" icon={< BurstModeIcon />}{...a11yProps(5)} />
              <Tab label="Inside Scoop" icon={<FileOpen />}{...a11yProps(6)} />
              {(values.Sold) === "Sold" ? <Tab label="Client Image" icon={<Wallpaper />} {...a11yProps(6)} /> : null}
              {(values.Sold) === "Sold" ? <Tab label="Site Map" icon={<InsertPhotoIcon />} {...a11yProps(7)} /> : <Tab label="Site Map" icon={<InsertPhotoIcon />} {...a11yProps(7)} />}
              {(values.Sold) === "Sold" ? <Tab label="Description" icon={<DescriptionIcon />} {...a11yProps(8)} /> : <Tab label="Description" icon={<DescriptionIcon />} {...a11yProps(8)} />}
              {(values.Sold) === "Sold" ? <Tab label="Floor Plans" icon={<BurstModeIcon />} {...a11yProps(9)} /> : <Tab label="Floor Plans" icon={<BurstModeIcon />} {...a11yProps(9)} />}
            </Tabs>
            <TabPanel value={value} index={0} >
              <h6>General</h6>
              <div>
                <FormControlLabel
                  control={<Switch checked={active} onChange={toggleChecked} />}
                  label="Active"
                />
                <div className={classes.block}>
                  <InputLabel id="demo-simple-select-outlined-label">SOLD?</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.Sold} name="Sold"
                    onChange={handleInputchange}
                    label="Status"
                    className={classes.field2}
                    variant="outlined"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Sold'}>Sold</MenuItem>
                    <MenuItem value={'ProjectAlex61'}>Project Alexander61</MenuItem>
                    <MenuItem value={'Hide'}>Hide this Property</MenuItem>
                  </Select>
                </div>
              </div>
              <div className={classes.field}>
                <TextField
                  label="MLS (ID)"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.MlsId} name="MlsId"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Community"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.community} name="community"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Property-Name"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.propertyname} name="propertyname"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Property-Slug"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.slug} name="slug"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Address"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.address} name="address"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Zip"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.zip} name="zip"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Active-Name"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.Activename} name="Activename"
                  onChange={handleInputchange}
                />
              </div>
              <div>
                <Autocomplete
                  id="free-solo-demo"
                  value={values.city} name="city"
                  onSelect={handleInputchange}
                  options={citys.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="City" margin="normal" variant="outlined"
                      value={values.city} name="city"
                      onChange={handleInputchange}
                    />
                  )}
                />
              </div>
              <div>
                <Autocomplete
                  id="free-solo-demo"
                  value={values.builders} name="builders"
                  onSelect={handleInputchange}
                  options={Builders.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Builders" margin="normal" variant="outlined"
                      value={values.builders} name="builders"
                      onChange={handleInputchange}
                    />
                  )}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <h6>Price</h6>
              <div className={classes.field}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <MonetizationOnIcon />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      label="Listing Price"
                      value={values.ListingPrice}
                      name="ListingPrice"
                      onChange={handleInputchange} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.field}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <MonetizationOnIcon />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      label="Start-Price / Sold"
                      value={values.startprice}
                      name="startprice"
                      onChange={handleInputchange} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.field}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <MonetizationOnIcon />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      label="End-Price"
                      value={values.endprice}
                      name="endprice"
                      onChange={handleInputchange} />
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h6>Information</h6>
              <div className={classes.field}>
                <TextField
                  id="date"
                  type="date"
                  defaultValue={TodayDate}
                  label="Offer Date"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.OfferDate} name="OfferDate"
                  onChange={handleInputchange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  id="date"
                  type="datetime-local"
                  defaultValue={dateTime}
                  label="onMarket Date"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.onMarketDate} name="onMarketDate"
                  onChange={handleInputchange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Size"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.size} name="size"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Bedrooms"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.bedrooms} name="bedrooms"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Bathrooms"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.bathrooms} name="bathrooms"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Parking"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.parking} name="parking"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Move in"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.movein} name="movein"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Facing"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.facing} name="facing"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  label="Represented"
                  id="filled-size-small"
                  variant="filled"
                  size="small"
                  value={values.Represented} name="Represented"
                  onChange={handleInputchange}
                />
              </div>
              <div className={classes.block}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.status} name="status"
                  onChange={handleInputchange}
                  label="Status"
                  className={classes.field2}
                  variant="outlined"
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Coming Soon'}>Coming Soon</MenuItem>
                  <MenuItem value={'Sold Out'}>Sold Out</MenuItem>
                  <MenuItem value={'Currently Available'}>Currently Available</MenuItem>
                  <MenuItem value={'Currently Sold Out'}>Currently Sold Out</MenuItem>
                </Select>
              </div>
              <div className={classes.block}>
                <InputLabel id="demo-simple-select-outlined-label">Tag</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.Tag} name="Tag"
                  onChange={handleInputchange}
                  label="TAG"
                  className={classes.field2}
                  variant="outlined"
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Recommended'}>Recommended</MenuItem>
                </Select>
              </div>
              <div className={classes.block}>
                <InputLabel id="demo-simple-select-outlined-label">Top 10 Tag</InputLabel>
                <Autocomplete
                  id="free-solo-demo"
                  autoComplete
                  includeInputInList
                  value={values.Tag1}
                  name="Tag1"
                  onSelect={handleInputchange}
                  options={Recommended.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Top 10 Tag" margin="normal" variant="outlined"
                      value={values.Tag1} name="Tag1"
                      onChange={handleInputchange}
                    />
                  )}
                />
              </div>
              <div className={classes.block}>
                <InputLabel id="demo-simple-select-outlined-label">Highlight Tag</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.HighlightTag} name="HighlightTag"
                  onChange={handleInputchange}
                  label="Highlight Tag"
                  className={classes.field2}
                  variant="outlined"
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Highlight'}>Highlight</MenuItem>
                </Select>
              </div>
              <div className={classes.block}>
                <InputLabel id="demo-simple-select-outlined-label">Active Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={values.ActiveStatus} name="ActiveStatus"
                  onChange={handleInputchange}
                  label="Active Status"
                  className={classes.field2}
                  variant="outlined"
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'Pending'}>Pending</MenuItem>
                  <MenuItem value={'Closed'}>Closed</MenuItem>
                </Select>
              </div>
              <div className={classes.block}>
                <TextareaAutosize aria-label="minimum height"
                  rowsMin={3} placeholder="Video Url"
                  value={values.video}
                  name="video"
                  onChange={handleInputchange} />
              </div>
            </TabPanel>

            <TabPanel value={value} index={3}>
              <h6>Schools</h6>
              <div style={{ width: 300 }}>
                <Autocomplete
                  options={Schools.sort().map((option) => option.title)}
                  value={values.schools} name="schools"
                  autoComplete
                  includeInputInList
                  onSelect={handleInputchange}
                  autoComplete
                  includeInputInList
                  renderInput={(params) => (
                    <TextField {...params} label="Schools" margin="normal" variant="outlined"
                      value={values.schools} name="schools"
                      onChange={handleInputchange} />
                  )}
                />
                <Autocomplete
                  id="free-solo-demo"
                  autoComplete
                  includeInputInList
                  value={values.level1}
                  name="level1"
                  onSelect={handleInputchange}
                  options={Level1.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Elementary" margin="normal" variant="outlined"
                      value={values.level1} name="level1"
                      onChange={handleInputchange}
                    />
                  )}
                />
                <Autocomplete
                  id="free-solo-demo"
                  autoComplete
                  includeInputInList
                  value={values.level2}
                  name="level2"
                  onSelect={handleInputchange}
                  options={Level2.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Middle" margin="normal" variant="outlined"
                      value={values.level2} name="level2"
                      onChange={handleInputchange}
                    />
                  )}
                />
                <Autocomplete
                  id="free-solo-demo"
                  value={values.level3}
                  name="level3"
                  onSelect={handleInputchange}
                  options={Level3.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="High school" margin="normal" variant="outlined"
                      value={values.level3} name="level3"
                      onChange={handleInputchange}
                    />
                  )}
                />
              </div>
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
              <h6>Brokage</h6>
              <div>
                <div className={classes.field}>
                  <TextField
                    label="OFF Market Date"
                    type="date"
                    id="filled-size-small"
                    variant="filled"
                    size="small"
                    value={values.OFFMarketDate} name="OFFMarketDate"
                    onChange={handleInputchange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    label="Agent1"
                    id="filled-size-small"
                    variant="filled"
                    size="small"
                    value={values.Agent1} name="Agent1"
                    onChange={handleInputchange}
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    label="Agent2"
                    id="filled-size-small"
                    variant="filled"
                    size="small"
                    value={values.Agent2} name="Agent2"
                    onChange={handleInputchange}
                  />
                </div>
                <Autocomplete
                  id="free-solo-demo"
                  value={values.Source}
                  name="Source"
                  onSelect={handleInputchange}
                  options={source.sort().map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Source" margin="normal" variant="outlined"
                      value={values.Source} name="Source"
                      onChange={handleInputchange}
                    />
                  )} />
              </div>
            </TabPanel> */}
            <TabPanel value={value} index={4}>
              <h6>Featured Image</h6>
              <input accept="image/*" className={classes2.input} id="icon-button-file" type="file"
                onChange={handleFaturedImage} />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              <div >
                <Box width="100%" mr={1}>
                  <LinearProgressWithLabel variant="determinate" value={progress}
                    className={classes2.upload} />
                </Box>
              </div>
              <div>
                {(() => {
                  if (featured) {
                    return (featured.map((url, index) => (
                      <div key={index}>
                        <img key={url} src={url} alt="Site Map" width="200" height="130" className={classes2.imagetab} />
                        <Button variant="contained" onClick={() => { deleteImage(url, setFeatured); setPosition(index) }}>
                          Delete
                        </Button>
                        {position === index &&
                          <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                        }
                      </div>
                    )))
                  }
                })()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <h6>Images</h6>
              <input accept="image/*" className={classes2.input} id="icon-button-file" type="file" multiple
                onChange={handleInputimage} />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              <div >
                <Box width="100%" mr={1}>
                  <LinearProgressWithLabel variant="determinate" value={progress}
                    className={classes2.upload} />
                </Box>
              </div>
              <div>
                {(() => {
                  if (url) {
                    return (url.map((url, index) => (
                      <div key={index}>
                        <img key={url} src={url} alt="Site Map" width="200" height="130" className={classes2.imagetab} />
                        <Button variant="contained" onClick={() => { deleteImage(url, setUrl); setPosition(index) }}>
                          Delete
                        </Button>
                        {position === index &&
                          <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                        }
                      </div>
                    )))
                  }
                })()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <h6>Scoop</h6>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {
                    Object.keys(props.scoop)
                    .sort((a, b) => (props.scoop[b].selectedDate > props.scoop[a].selectedDate) ? 1 : -1)
                    .map((id) => {
                      return (
                        <div>
                          <Card sx={{
                            m: '2em 0em',
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '8px',
                          }}>
                            <Grid container spacing={0} style={{ width: '500px' }}>
                              <Grid item xs={12}>
                                <Stack sx={{ width: '100%', p: '16px 16px 6px 16px' }} direction="row" spacing={0}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Avatar variant="rounded" sx={{ width: 50, height: 50, bgcolor: '#ff9c00' }}>{props.scoop[id].newName.charAt(0)}</Avatar>
                                  </Box>
                                  <Stack sx={{ width: '100%', ml: '1em' }}>
                                    <Typography fontWeight={500} sx={{ fontSize: 16, color: '#0288d1', mb: '5px' }}>{props.scoop[id].newName}</Typography>
                                    <Divider />
                                    {/* <CalendarMonth sx={{ color: grey[500] }} /> {scoop[ScoopData].selectedDate} */}
                                    <Typography sx={{ fontSize: 14, mt: '5px' }} fontWeight={500}>{props.scoop[id].title}</Typography>
                                  </Stack>
                                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '1em' }}>
                                    <IconButton>
                                      <Delete sx={{ fontSize: 17 }} onClick={() => { onDelete(id) }} />
                                      {/* <Typography sx={{ fontSize: 14 }} fontWeight={500}>Edit</Typography> */}
                                    </IconButton>
                                  </Box>
                                </Stack>
                                <Stack sx={{ p: '0px 0px 8px 16px' }}>
                                  <Typography variant="body1" color="text.secondary">
                                    {props.scoop[id].description}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </TabPanel>
            {
              (values.Sold) === "Sold" ?
                <TabPanel value={value} index={7}>
                  <h6>Client Image (for sold out)</h6>
                  <div className={classes.field}>
                    <TextField
                      label="Order"
                      id="filled-size-small"
                      type="number"
                      variant="filled"
                      size="small"
                      value={values.ciorder} name="ciorder"
                      onChange={handleInputchange}
                    />
                  </div>
                  <input accept="image/*" className={classes2.input} id="icon-button-file" type="file"
                    onChange={handleInputClientimage} />
                  <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  <div >
                    <Box width="100%" mr={1}>
                      <LinearProgressWithLabel variant="determinate" value={clientprogress}
                        className={classes2.upload} />
                    </Box>
                  </div>
                  <div>
                    {(() => {
                      if (clientImage) {
                        return (clientImage.map((url, index) => (
                          <div key={index}>
                            <img key={url} src={url} alt="Client Image" width="200" height="130" className={classes2.imagetab} />
                            <Button variant="contained" onClick={() => { deleteImage(url, setClientImage); setPosition(index) }}>
                              Delete
                            </Button>
                            {position === index &&
                              <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                            }
                          </div>
                        )))
                      }
                    })()}
                  </div>
                </TabPanel>
                :
                null
            }

            <TabPanel value={value} index={(values.Sold) === "Sold" ? 8 : 7}>
              <h6>Site Map</h6>
              <input accept="image/*" className={classes2.input} id="icon-button-file" type="file" multiple
                onChange={handleInputMapimage} />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              <div >
                <Box width="100%" mr={1}>
                  <LinearProgressWithLabel variant="determinate" value={siteprogress}
                    className={classes2.upload} />
                </Box>
              </div>
              <div>
                {(() => {
                  if (siteurl) {
                    return (siteurl.map((url, index) => (
                      <div key={index}>
                        <img key={url} src={url} alt="Site Map" width="200" height="130" className={classes2.imagetab} />
                        <Button variant="contained" onClick={() => { deleteImage(url, setSiteurl); setPosition(index) }}>
                          Delete
                        </Button>
                        {position === index &&
                          <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                        }
                      </div>
                    )))
                  }
                })()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={(values.Sold) === "Sold" ? 9 : 8}>
              <h6>Description</h6>
              <TextareaAutosize aria-label="minimum height"
                rowsMin={3} placeholder="Short" className={classes2.autotext}
                value={values.short}
                name="short"
                onChange={handleInputchange} />
              <div>
                <TextareaAutosize aria-label="minimum height"
                  rowsMin={10} placeholder="Description" className={classes2.autotext}
                  value={values.description}
                  name="description"
                  onChange={handleInputchange} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={(values.Sold) === "Sold" ? 10 : 9}>
              <h6>Floor Plan Images</h6>
              <input accept="image/*" className={classes2.input} id="icon-button-file" type="file" multiple
                onChange={handleInputFloorimage} />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              <div >
                <Box width="100%" mr={1}>
                  <LinearProgressWithLabel variant="determinate" value={floorprogress}
                    className={classes2.upload} />
                </Box>
              </div>
              <div>
                {(() => {
                  if (floorUrl) {
                    return (floorUrl.map((url, index) => (
                      <div key={index}>
                        <img key={url} src={url} alt="Site Map" width="200" height="130" className={classes2.imagetab} />
                        <Button variant="contained" onClick={() => { deleteImage(url, setFloorUrl); setPosition(index) }}>
                          Delete
                        </Button>
                        {position === index &&
                          <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                        }
                      </div>
                    )))
                  }
                })()}
              </div>
            </TabPanel>


          </Paper>
        </div>
        <Button variant="contained" color="inherit" disableElevation type="submit" onSubmit={submit}>
          {props.currentId == '' ? "save" : "update"}
        </Button>
      </form>
    </>
  );
}

const Recommended = [
  { title: 'Recommended' },
  { title: 'Recommended#1' },
  { title: 'Recommended#2' },
  { title: 'Recommended#3' },
  { title: 'Recommended#4' },
  { title: 'Recommended#5' },
  { title: 'Recommended#6' },
  { title: 'Recommended#7' },
  { title: 'Recommended#8' },
  { title: 'Recommended#9' },
  { title: 'Recommended#10' },
]

const Schools = [
  { title: 'Lake Stevens School District' },
  { title: 'Lake Washington School District' },
  { title: 'Lakewood School District' },
  { title: 'Seattle Public School District' },
  { title: 'Sedro-Woolley School District' },
  { title: 'Everett Public District' },

]

const Level3 = [
  { title: 'Eastlake High School' },
  { title: 'Juanita High School' },
  { title: 'Lake Washington High School' },
  { title: 'Redmond High School' },
  { title: 'Emerson High School' },
  { title: 'Futures School High School' },
  { title: 'International Community School High School' },
  { title: 'Tesla STEM High School' },
  { title: 'Cambridge Program High School' },
]

const Level2 = [
  { title: 'Emerson K-12 Middle School' },
  { title: 'Environmental (EAS) Middle School' },
  { title: 'Evergreen Middle School' },
  { title: 'Finn Hill Middle School' },
  { title: 'International (ICS) Middle School' },
  { title: 'Inglewood Middle School' },
  { title: 'Kamiakin Middle School' },
  { title: 'Kirkland Middle School' },
  { title: 'Northstar Middle School' },
  { title: 'Redmond Middle School' },
  { title: 'Renaissance School of Art and Reasoning Middle School' },
  { title: 'Rose Hill Middle School' },
  { title: 'Stella Schola Middle School' },
  { title: 'Timberline Middle School' },
]
const Level1 = [
  { title: 'Alcott Elementary School' },
  { title: 'Audubon Elementary School' },
  { title: 'Bell Elementary School' },
  { title: 'Blackwell Elementary School' },
  { title: 'Carson Elementary School' },
  { title: 'Clara Barton Elementary School' },
  { title: 'Community Elementary School' },
  { title: 'Dickinson Elementary School' },
  { title: 'Discovery Community Elementary School' },
  { title: 'Einstein Elementary School' },
  { title: 'Ella Baker Elementary School' },
  { title: 'Emerson K-12 Elementary School' },
  { title: 'Explorer Elementary School' },
  { title: 'Franklin Elementary School' },
  { title: 'Frost Elementary School' },
  { title: 'Juanita Elementary School' },
  { title: 'Keller Elementary School' },
  { title: 'Kirk Elementary School' },
  { title: 'Lakeview Elementary School' },
  { title: 'Mann Elementary School' },
  { title: 'McAuliffe Elementary School' },
  { title: 'Mead Elementary School' },
  { title: 'Muir Elementary School' },
  { title: 'Redmond Elementary School' },
  { title: 'Rockwell Elementary School' },
  { title: 'Rosa Parks Elementary School' },
  { title: 'Rose Hill Elementary School' },
  { title: 'Rush Elementary School' },
  { title: 'Sandburg Elementary School' },
  { title: 'Smith Elementary School' },
  { title: 'Thoreau Elementary School' },
  { title: 'Twain Elementary School' },
  { title: 'Wilder Elementary School' },
]
const Builders = [
  { title: 'DR Horton' },
  { title: 'Richmond American Homes' },
  { title: 'Garrette Custom Homes	' },
  { title: 'KB Home' },
  { title: 'Pulte Homes' },
  { title: 'Lennar' },
  { title: 'Toll Brothers Camwest' },
  { title: 'Conner Homes' },
  { title: 'MainVue Homes' },
  { title: 'JK Monarch' },
  { title: 'Century Communities' },
  { title: 'Quadrant Homes' },
  { title: 'Acme Homes' },
  { title: 'Shea Homes' },
  { title: 'Blackwood Builders' },
  { title: 'HC Homes' },
  { title: 'Lungren Homes' },
  { title: 'Murray Franklyn' },
  { title: 'Azure Northwest Homes' },
  { title: 'Sage Homes NW' },
  { title: 'Rush Residential' },
  { title: 'Cornerstone Homes' },
  { title: 'Isola Homes	' },
  { title: 'BDR Homes' },
  { title: 'Westcott Homes' },
  { title: 'Capstone Homes' },
  { title: 'Hardin Construction & Design' },
  { title: 'GProjects' },
  { title: 'BlueFern Development' },
  { title: 'Hinkle Homes' },
  { title: 'John Day Homes' },
  { title: 'Terrene Homes' },
  { title: 'Build Urban' },
  { title: 'High Country Homes' },
  { title: 'Haberzetle Homes' },
  { title: 'Green Canopy Homes' },
  { title: 'Skeers Construction' },
  { title: 'Zetterberg Custom Homes' },
  { title: 'JayMarc Homes' },
  { title: 'Pacific Ridge Homes' },
  { title: 'Lexar Homes' },
  { title: 'Steele Homes' },
  { title: 'Ashworth Homes' },
  { title: 'Dwell Development' },
  { title: 'Mietzner Brothers Properties' },
  { title: 'Macfarlane Homes' },
  { title: 'JMS Homes' },
  { title: 'Scott Donogh Homes' },
  { title: 'Mead Homes' },
  { title: 'Greenbuild Development' },
  { title: 'Influx Development' },
  { title: 'Steve Burnstead' },
  { title: 'Oakridge Homes' },
  { title: 'Ichijo USA' },
  { title: 'Adair Homes' },
  { title: 'Eastside Homes' },
  { title: 'Evergreen Heights' },
  { title: 'Appleton Homes' },
  { title: 'Summit Homes' },
  { title: 'Landed Gentry' },
  { title: 'Domus Homes' },
  { title: 'Glong Construction' },
  { title: 'Gamut360' },
  { title: 'Omega Custom Homes' },
  { title: 'Jabooda Homes' },
  { title: 'Extraordinary Homes' },
  { title: 'Shelter Homes' },
  { title: 'StoryBuilt' },
  { title: 'Classic Concepts' },
  { title: 'M & P Builders' },
  { title: 'Harbour Homes' },
  { title: 'Robinett Homes' },
  { title: 'Hunter Homes' },
  { title: 'William Buchan' },
  { title: 'Great Northwest Homes' },
  { title: 'Design Built Homes' },
  { title: 'Curtis Lang Custom Homes' },
  { title: 'Slusher Homes' },
  { title: 'Wildheart Homes' },
  { title: 'Schneider Homes' },
  { title: 'Roberts Construction' },
  { title: 'Sterling Custom Homes' },
  { title: 'Sager Family Homes' },
  { title: 'Adair Enterprises' },
  { title: 'Hendrickson Construction' },
  { title: 'Buffington Custom Homes' },
  { title: 'Harkey Construction' },
  { title: 'Strandberg Construction' },
  { title: 'BYK Construction' },
  { title: 'Merit Homes' },
  { title: 'K & L Homes' },
  { title: 'Craftsman Group' },
  { title: 'Christopher Homes' },
  { title: 'SNC Development' },
  { title: 'Hawthorne Land Dev' },
  { title: 'KLP' },
  { title: 'Irving Construction' },
  { title: 'Town & Country Homes' },
  { title: 'A & J Precision Homes' },
  { title: 'Dynasty Homes' },
  { title: 'Campbell Homes' },
  { title: 'MN Custom Homes' },
  { title: 'Aho Construction' },
  { title: 'Alliance Properties' },
  { title: 'MacKinnon Design & Development' },
  { title: 'Sapphire Homes' },
  { title: 'Titan Homes NW' },
  { title: 'Wick Homes' },
  { title: 'Polygon Northwest' },
  { title: 'DJ & DJ Contracting' },
  { title: 'Freestone' },
  { title: 'Dunca' },
  { title: 'Kobza Home Builders' },
  { title: 'Eagle Homes' },
  { title: 'Cascade View' },
  { title: 'Village Life' },
  { title: 'RM Homes' },
  { title: 'BJ Builders' },
  { title: 'MGM Construction' },
  { title: 'Fairwinds Development' },
  { title: 'Trailside Homes' },
  { title: 'Waldron Construction' },
  { title: 'Envision Northwest' },
  { title: 'Rick Burnstead Construction' },
  { title: 'Brooks Homes' },
  { title: 'Kohary Builders' },
  { title: 'Rudd Development' },
  { title: 'Element Residential' },
  { title: 'Lubbesmeyer Construction' },
  { title: 'Hansen Construction' },
  { title: 'Hayden Homes' },
  { title: 'Grandview Homes' },
  { title: 'MC Construction' },
]

const citys = [
  { title: 'Sammamish' },
  { title: 'Kirkland' },
  { title: 'Lynnwood' },
  { title: 'Lake Stevens' },
  { title: 'Maple Valley' },
  { title: 'Redmond' },
  { title: 'Bellevue' },
  { title: 'Bothell' },
  { title: 'Issaquah' },
  { title: 'Mill Creek' },
  { title: 'Renton' },
  { title: 'Puyallup' },
]

const source = [
  { title: 'NWMLS' }
]
export default Pannel





























