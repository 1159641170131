import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../NavBar.css';
import banner22 from '../../../images/Bannermob13.png';
import Footer from '../../../components/Footer';
import PropTypes from 'prop-types';
import Zillow from '../../../images/zillow.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import FaceBook from './FaceBook';
import ZillowPage from './Zillow';
import { PropertyContext } from '../../../context'
import Loading from '../../Loading';
import { Grid, Paper, styled, useMediaQuery, Typography, AppBar, Container, Tabs, Tab, Box } from '@mui/material';
import { Reviews } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  cardTop: {
    marginTop: 70
  },

}));

const ReviewsPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  height: '300px',
  width: '100%',
  background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 25,
  paddingTop: 150,
  [theme.breakpoints.down("xs")]: {
    height: 250,
    padding: 34,
    paddingTop: 60,
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: 24,
  [theme.breakpoints.down("sm")]: {
    padding: "24px 0px 0px 0px"
  }

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBox p={3} >
          <Typography>{children}</Typography>
        </StyledBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}



const ReviewsContainer = () => {
  const classes = useStyles();
  const context = React.useContext(PropertyContext);
  const { testimonials, TestimonialData } = context;
  const [value, setValue] = React.useState(0);
  const isMatch = useMediaQuery('(max-width:960px )');

  let FacebookReviews = testimonials;
  let ZillowReviews = testimonials;

  FacebookReviews = [

    ...FacebookReviews.filter(reviews => reviews.platform !== "Zillow"),
  ]

  ZillowReviews = [
    ...ZillowReviews.filter(reviews => reviews.platform !== "Facebook")
  ]

  useEffect(() => {
    if (testimonials.length === 0) {
      TestimonialData()
    }

  }, [])

  if (testimonials.length === 0) {
    return <Loading />
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div>
      <ReviewsPaper className={classes.bannerPaper}>
        <Grid xs={12} align="center" style={{ marginTop: isMatch ? '1em' : '-3em' }}>
          <Typography
            style={{
              fontSize: isMatch ? '30px' : '64px',
              fontWeight: 500,
              color: 'white',
            }}
          >
            Customer Testimonials
          </Typography>
        </Grid>
      </ReviewsPaper>
      <>
        <div>
          <AppBar position="static" sx={{ backgroundColor: 'white' }}>
            <Container maxWidth="md">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                <Tab label="Our Reviews" icon={<Reviews />} {...a11yProps(0)} />
                {/* <Tab label="Zillow" icon={<img src={Zillow} alt="" style={{ width: "1.5em", height: "1.5em" }} />} {...a11yProps(1)} /> */}
              </Tabs>
            </Container>
          </AppBar>
          <Container className={classes.cardGrid} maxWidth="lg">
            <TabPanel value={value} index={0}>
              <FaceBook FacebookReviews={testimonials} />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <ZillowPage ZillowReviews={ZillowReviews} />
            </TabPanel> */}
          </Container>
        </div>
        <Footer />
      </>
    </div>
  )
}
export default ReviewsContainer;
















