import React,{ useEffect } from 'react'
import PropertyContainer from '../components/PropertyContainer'
import ReactGA from 'react-ga';

export const initGA=()=>{
  ReactGA.initialize('G-829TKY23MC');
}
export const logPageView=()=>{
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

const Properties = () => {
    useEffect(() => {
        initGA();
        logPageView();
      }, [])
    
     

   return(
   <>
       <div>
           <PropertyContainer/>
       </div>
    </>
   )
}

export default Properties
