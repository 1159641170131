import React, { Component, useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PropertyContext } from '../context';
import defaultimg from '../images/Image_not_available.png';
import whiteImage from '../images/Image_not_available.png';
import nwmlslogo from '../images/nwmls_small.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import '../components/Singlepage.css';
import '../components/Detailpage.css';
import ImagePopup from '../components/ImagePopup';
import CitysSub from '../components/CitysSub';
import DetailData from '../components/DetailData';
import offer from '../images/cards/offer1.png';
import EmailForm from '../contact/EmailForm';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import RelatedHomes from'../components/RelatedHomes';
import Loading from '../components/Loading';
import SimpleReactLightbox from "simple-react-lightbox";
import Chip from '@material-ui/core/Chip';
import { SRLWrapper } from "simple-react-lightbox";
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import RoomIcon from '@material-ui/icons/Room';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NumberFormat from 'react-number-format';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
// import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import defaultMap from '../images/not-av.png';
import MobDetailpage from '../components/Mobile/MobDetailpage.js';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Footer from '../components/Footer';
import Mortgage from '../components/Mortgage/Mortgage';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import Box from '@material-ui/core/Box';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useParams } from 'react-router-dom'
import Address from '../contact/Address';
import { BuyerContact } from '../contact/BuyerContact';
import BookConsultation from '../components/Cards/BookConsultation';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, styled } from '@mui/material';
import { FavoriteRounded } from '@mui/icons-material';
import firebase from '../firebase/firebase'

const HeartIcon = ({ width, height, fillColor, borderColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fillColor || 'none'}
      stroke={borderColor || 'black'}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.35 20.13c-.76.69-1.93.69-2.69-.01l-.11-.1C5.3 15.27 1.87 12.16 2 8.28c.06-1.7.93-3.33 2.34-4.29 2.64-1.8 5.9-.96 7.66 1.1 1.76-2.06 5.02-2.91 7.66-1.1 1.41.96 2.28 2.59 2.34 4.29.14 3.88-3.3 6.99-8.55 11.76l-.1.09z"></path>
    </svg>
  );
};

const StyledHeartIcon = styled(HeartIcon)(({ theme, isWishlisted }) => ({
  position: 'relative',
  transform: isWishlisted ? 'rotate(-45deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease-in-out',
  border: `2px solid ${isWishlisted ? theme.palette.error.main : 'grey'}`,
  borderRadius: '50%',
}));

const useStyles = makeStyles((theme) => ({
  root: {

    flexGrow: 1,
  },
  root2: {
    display: 'flex',
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer",
    background: 'linear-gradient(110deg, #f50057, #e8ab78, #1bd7b4, #36c91b)',
    backgroundSize: '400% 400%',
    animation: 'change 10s ease-in-out infinite',
    borderRadius: 4,
    padding: 10,
    maxWidth: 390,
    [theme.breakpoints.up("md")]: {
      height: 80,
    },
    alignItems: "center",
  },
  '@keyframes change': {
    '0%': {
      backgroundPosition: ' 0 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    ' 100%': {
      backgroundPosition: '0 50%'
    },
  },
  root3: {
    paddingTop: "5px",
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: "100%",
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  mediaSmall: {
    width: "100%",
    height: "100%"
  },
  paper: {
    margin: 'auto',
  },
  paperimg: {
    position: "relative",
    width: "100%",
    height: 350
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: "5px 24px 16px"
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  FooterPad: {

  },
  appBar: {
    top: 'auto',
    bottom: 0,
    "backgroundColor": "#ffce34",
  },
  imageBody: {
    height: 200,
    width: 250
  },
}));


const useStyles2 = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#2979ffc2'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    margin: 'auto',
    alignItems: "center",
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  card2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#0066ff'
  },
  field: {
    padding: '10px 0px 10px ',
  },
  card3: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#01398e'
  },


  root: {
    display: 'flex',
    background: 'linear-gradient(45deg, #430089, #82ffa1)',
    maxWidth: 500
  },


  root2: {
    display: 'flex',
    background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
    padding: 10,
    maxWidth: 500,

    cursor: "pointer"
  },
  root3: {
    display: 'flex',
    background: 'linear-gradient(45deg, #f59301fc, #f70000)',
    borderRadius: 15,
    padding: 10,
    maxWidth: 500,

    cursor: "pointer"
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',

  },
  content2: {
    flex: '1 '
  },
  cover: {
    width: 151,

  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  hold: {
    paddingTop: 20
  },
  textsecond: {
    color: "white"
  },
  textOne: {
    color: "white",
    fontSize: "1.3rem"
  },
  texttwo: {
    color: "white",
    fontSize: "1.2rem",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {

      fontSize: "0.99rem",
    },
  },
  pad: {
    padding: 8
  },
  graph: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    borderRadius: 30
  },
  cardpad: {
    [theme.breakpoints.up("md")]: {
      padding: 8,
    },

  },
  heading2: {
    paddingTop: 20,
    paddingBottom: 10
  }
}));

const WishlistButton = (props) => {
  var fireDB = firebase.database().ref();
  const [propertyData, setPropertyData] = useState([]);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState(null);

  const handleWishlistClick = () => {
    setIsWishlisted((prevIsWishlisted) => !prevIsWishlisted);
    setOpenDialog(true);
  };



  React.useEffect(() => {
    fireDB.child('properties').on('value', snapshot => {
      if (snapshot.val() != null)
        setPropertyData({
          ...snapshot.val()
        })
      else
        setPropertyData({})
    })
  }, [])



  useEffect(() => {
    // Listen for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const email = user && user.email;
  const Uid = user && user.uid

  const wishlistData = props.wishlist !== null && typeof props.wishlist === 'object'
    ? Object.keys(props.wishlist).map((id) => props.wishlist[id])
    : null;



  
  // console.log('uid', );
  const keys = Object.keys(propertyData).filter((key) => propertyData[key].address === props.Address);

  const handleUpload = (event) => {
    event.preventDefault();
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);
      if (isWishlisted) {
        const id = Object.keys(props.wishlist).filter((id) => props.wishlist[id].email === email);
        console.log("id", id)
        fireDB.child(`properties/${keys}/wishlist/${id}`).remove(
          err => {
            if (err) {
              console.log(err)
            }
          }
        )
      } else {
        const data = {
          wishListed: isWishlisted,
          email: email,
          uid: Uid,
        };

        const propertyPath = `properties/${keys}`;
        // Create a reference to the Firebase database location
        fireDB.child(`${propertyPath}/wishlist`).push(data);
        console.log('wishList', data)
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      const data = {
        wishListed: isWishlisted,
        email: email,
        uid: Uid,
      };

      const propertyPath = `properties/${keys}`;
      // Create a reference to the Firebase database location
      fireDB.child(`${propertyPath}/wishlist`).push(data);
      console.log('wishList', data)
    }
    if (wishlistData !== null) {

    } else {

    };
    setOpenDialog(false)
  }

  const handleNoClick = () => {
    setOpenDialog(false);
  };

  const Favourite = React.useMemo(() => {
    // Check if wishlistData is not null and has items
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);

      if (isWishlisted) {
        return (
          <IconButton onClick={handleWishlistClick}>
            <FavoriteRounded color="error" sx={{ fontSize: '1.4em' }} />
          </IconButton>
        );
      } else {
        return (
          <IconButton onClick={handleWishlistClick}>
            <StyledHeartIcon width={33} height={33} />
          </IconButton>
        );
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      return (
        <IconButton onClick={handleWishlistClick}>
          <StyledHeartIcon width={33} height={33} />
        </IconButton>
      );
    }
  }, [wishlistData, email, handleWishlistClick]);

  const FavouriteText = React.useMemo(() => {
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);

      if (isWishlisted) {
        return <Typography variant="h5">Are you sure you want to remove this property to your wishlist?</Typography>;
      } else {
        return <Typography variant="h5">Are you sure you want to add this property to your wishlist?</Typography>;
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      return <Typography variant="h5">Are you sure you want to add this property to your wishlist?</Typography>;
    }
  }, [wishlistData, email])

  return (
    <div>
      {Favourite}
      <Dialog open={openDialog} onClose={handleNoClick}>
        <DialogTitle>Confirm Wishlist</DialogTitle>
        <DialogContent>
          {FavouriteText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload} color="primary">
            Yes
          </Button>
          <Button onClick={handleNoClick} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
function Cards() {
  const classes = useStyles2();
  return (
    <Container maxWidth="xs">
      <div className={classes.hold}  >
        <Link to="/Contact-US" style={{ textDecoration: "NONE" }}>
          <Card className={classes.root} elevation={5}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5" className={classes.textOne}>
                  Want To Know Offer Review Dates?
                </Typography>
                <Typography variant="body1" className={classes.textsecond}>
                  Find Out Now <DoubleArrowIcon />
                </Typography>
              </CardContent>
            </div>

          </Card>
        </Link>
      </div>

      <div className={classes.hold}  >
        <Link to="/Our-Solds" style={{ textDecoration: "NONE" }}>
          <Card className={classes.root2} elevation={5}>
            <div className={classes.details}>
              <Typography component="h5" variant="h5" className={classes.texttwo}>
                Know what We have Sold So Far <DoubleArrowIcon />
              </Typography>
            </div>
          </Card>
        </Link>
      </div>
    </Container>
  )
}

function ImageSlide(props) {
  const url = props.url
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  console.log("url test", url)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const options = {
    icons: {
      downloadIcon: null,
    },
    buttons: {
      showDownloadButton: false
    },
    thumbnails: {
      showThumbnails: true
    }
  }
  return (
    <Card className={classes.paperimg} elevation={5} >
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >{(() => {
            if (url)
              return url.map((item, index) => (
                <div key={url}>
                  {Math.abs(activeStep - index) <= 20 ? (
                    <img className={classes.img} src={item} alt={item} />
                  ) : null}

                </div>
              ))
            else {
              return <div >

                <img className={classes.img} src={whiteImage} alt="Image" />

              </div>
            }

          })()}

          </SwipeableViews>
        </SRLWrapper>
        <div className="image_next_back">
          <div className="Next_desk">
            <Button size="small" onClick={handleNext} disabled={activeStep === (url ? url.length : 1) - 1} >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft className="Arrow_desk" style={{ fontSize: "2rem" }} /> : <KeyboardArrowRight className="Arrow_desk" style={{ fontSize: "2rem" }} />}
            </Button>
          </div>
          <div className="Back_desk">
            <Button size="small" onClick={handleBack} disabled={activeStep === 0} >
              {theme.direction === 'rtl' ? <KeyboardArrowRight className="Arrow_desk" style={{ fontSize: "2rem" }} /> : <KeyboardArrowLeft className="Arrow_desk" style={{ fontSize: "2rem" }} />}
            </Button>
          </div>
        </div>

      </SimpleReactLightbox>
    </Card>
  )
}

// function BookConsultation(props) {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);

//   const handelClickOpen = () => {
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false);
//   };
//   return (
//     <div style={{ paddingTop: 8, paddingBottom: 8 }}>
//       <Card className={classes.root2} elevation={5} onClick={handelClickOpen}>
//         <Typography component="h5" variant="h5" style={{ fontSize: '1.3rem' }}>
//           Book a consultation call With Deepti today!! <DoubleArrowIcon />
//         </Typography>
//       </Card>
//       <BuyerContact Open={open} Close={handleClose} />
//     </div>

//   )
// }


//////////////////////////////////////main //////////////////////////////////////////////
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />
  }
}

class Detailpage extends
  React.Component {

  constructor(props) {
    super(props);
    // Scroll items
    this.scrollDetails = React.createRef();
    this.scrollSchool = React.createRef();
    this.scrollSitemap = React.createRef();

    this.state = {
      slug: this.props.match.params.slug,
      defaultimg,
      value: 0,
      value1: 0,
      menuDrawer: false,
      openPopup: false,
      index: false,
      mort: false,
      domain: ''
    };
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.getDomainName();
    window.addEventListener('popstate', this.handleNavigationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleNavigationChange);
  }

  getDomainName = () => {
    const url = new URL(window.location.href);
    this.setState({ domain: url.hostname });
  };

  handleNavigationChange = () => {
    this.getDomainName();
  };

  renderText = () => {
    const { domain } = this.state;
    switch (domain) {
      case 'www.dguptahomes.com':
        return 'DguptaHomes.com';
      case 'www.homewastate.com':
        return 'HomeWAState.com';
      default:
        return 'HomeWAState.com';
    }
  };
  // componentDidMount() {
  //   const {  getHouse } = this.context
  //   getHouse(this.state.slug);
  // }

  // componentDidUpdate(prevProps) {
  //   const { allHomes, getHouse } = this.context
  //   if (this.allHomes !== prevProps.allHomes) {
  //     getHouse(this.state.MlsId);
  //   }
  // }

  handleClick() {
    this.setState({ mort: true })
  }
  handleClose() {
    this.setState({ mort: false })
  }

  static contextType = PropertyContext;
  render() {
    const { getHouse } = this.context;
    const home = getHouse(this.state.slug);
    const match = window.location.pathname.split('/')[1] === 'New-constructions' ? true : false;
    const domain = window.location.hostname;
    const value = this.state.value;
    const value1 = this.state.value1;
    const scrollDetails = this.state.myRef;
    const options = {
      icons: {
        downloadIcon: null,
      },
      buttons: {
        showDownloadButton: false
      },
      thumbnails: {
        showThumbnails: false
      }
    }

    const open = this.state.open
    if (!home) {
      return <div>
        <Loading />
      </div>
    }
    // scroll functions
    const scrollToDetail = () => window.scrollTo({ behavior: 'smooth', top: this.scrollDetails.current.offsetTop })
    const scrollToSchool = () => window.scrollTo({ behavior: 'smooth', top: this.scrollSchool.current.offsetTop })
    const scrollToSitemap = () => window.scrollTo({ behavior: 'smooth', top: this.scrollSitemap.current.offsetTop })
    //  tab manu change  
    const handleChange = (event, value) => {
      this.setState({ value });
    };

    const buttonChange = (event, value1) => {
      this.setState({ value1 });
    };


    const setOpenPopup = () => {
      this.setState({
        openPopup: true,
        index: false
      });
    }
    const setOpenPopup2 = () => {
      this.setState({
        openPopup: true,
        index: true
      });
    }
    const closePopup = () => {
      this.setState({
        openPopup: false
      });
    }
    const { propertyname,
      description,
      city,
      schools,
      siteurl,
      ActiveStatus,
      level1,
      level2,
      level3,
      builders,
      address,
      bathrooms,
      bedrooms,
      kitchens,
      startprice,
      endprice,
      community,
      size,
      parking,
      url,
      Tag,
      status,
      floorUrl,
      OfferDate,
      featuredUrl,
      zip,
      Agent1,
      Agent2,
      OFFMarketDate,
      Source,
      scoop,
      wishlist
    } = home;
    let images = featuredUrl ? [featuredUrl, ...url || featuredUrl] : url
    console.log("Array", Array.isArray(images))
    console.log("Scoop", home)
    return (<>
      <SimpleReactLightbox>

        <Box display={{ xs: 'block', sm: 'none' }} m={1} style={{ "margin": "0" }}>
          <MobDetailpage
            Tag={Tag}
            OfferDate={OfferDate}
            status={status}
            startprice={startprice}
            endprice={endprice}
            url={images}
            Details={description}
            ActiveStatus={ActiveStatus}
            Bedroom={bedrooms}
            Bathroom={bathrooms}
            Kitchen={kitchens}
            Adderss={address}
            Map={siteurl}
            siteMap={siteurl}
            community={community}
            Schools={schools}
            Level1={level1}
            level2={level2}
            Level3={level3}
            Builders={builders}
            Size={size}
            bathrooms={bathrooms}
            bedrooms={bedrooms}
            parking={parking}
            propertyname={propertyname}
            floorUrl={floorUrl}
            city={city}
            zip={zip}
            Agent1={Agent1}
            Agent2={Agent2}
            OFFMarketDate={OFFMarketDate}
            Source={Source}
            Scoop={scoop}
            wishlist={wishlist}
          >

          </MobDetailpage>
        </Box>
        <Box display={{ xs: 'none', sm: 'block' }} m={1}  >
          <Container maxWidth="Lg" style={{ padding: "0px 50px 30px 50px" }}>
            <div style={{ paddingBottom: "20px" }} >
              <Card style={{ padding: " 0px 20px 20px", borderRadius: "20px" }} elevation={0} varient="outlined">

                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                      <div className="info_top_desk">
                        <h3>{propertyname}</h3>
                      </div>
                      <Stack display="flex" >
                        <WishlistButton Address={address} wishlist={wishlist} />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="searchResult__stars2_desk">
                      <RoomIcon className="searchResult__star_desk" />
                      <p>
                        <strong>{"  " + `${city} ` + "  "}</strong>
                        {(status == "Currently Available") && <Chip
                          size="small"
                          label={status}
                          clickable
                          style={{ backgroundColor: 'rgb(35 142 53)', color: ' white' }}
                        />}
                        {(status == "Coming Soon") && <Chip
                          size="small"
                          label={status}
                          clickable
                          style={{ backgroundColor: 'rgb(255 206 52)', color: ' white' }}
                        />}
                        {(status == "Currently Sold Out") && <Chip
                          size="small"
                          label={status}
                          clickable
                          style={{ backgroundColor: 'rgb(223 102 21)', color: ' white' }}
                        />}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div  >
                      {(() => {
                        if (status == 'Sold Out') {
                          return (
                            <p className="Pricebody_desk">
                              <strong className="price_from_desk">
                                SOLD OUT
                              </strong>
                            </p>)
                        }
                        if (status == 'Currently Sold Out') {
                          return (
                            <p className="Pricebody_desk">
                              <strong className="price_from_desk">
                                CURRENTLY SOLD OUT
                              </strong>
                            </p>)
                        }
                        if (!startprice) {
                          return (
                            <p className="Pricebody_desk">
                              <strong className="singleprice_from_desk">
                                Not Available
                              </strong>
                            </p>)
                        }
                        else if (!endprice) {
                          return (
                            <div>
                              <p className="Pricebody_desk">
                                <div className="starting_desk">
                                  Starting
                                </div>
                                <strong className="singleprice_from_desk">
                                  <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </strong>
                              </p>
                            </div>)
                        }
                        else {
                          return (<div>
                            <p className="Pricebody_desk">
                              {/* from  */}
                              <strong className="singleprice_from_desk">
                                <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                {'  -  '}
                                <NumberFormat value={endprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>)
                        }
                      })()}
                    </div>

                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div>
                      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Link Link underline='none' color="textSecondary" component={RouterLink} to='/' >
                          Home
                        </Link>
                        <Link underline='none' color="textSecondary" >
                          {city}
                        </Link>
                        <Typography color="textPrimary">{propertyname}</Typography>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <Grid item xs={6} >
                    <ImageSlide url={images} style={{ borderRadius: "20px 0px 0px 20px", height: 400 }} />
                  </Grid>
                  <Grid item xs={6}  >
                    {!Array.isArray(images) && <SRLWrapper options={options}>
                      <Card elevation={5} style={{ height: 172 }} >
                        <CardMedia
                          style={{ height: "100%", width: "100" }}
                          component="img"
                          image={defaultimg}
                          title="Paella dish"
                        />
                      </Card>
                    </SRLWrapper>}
                    {(() => {
                      if (images) {
                        if (images.length <= 6) {
                          return <SimpleReactLightbox>
                            <Paper elevation={0}>
                              <Grid container spacing={2}  >
                                <Grid item xs >
                                  {images[0] && <SRLWrapper options={options}>
                                    <Card elevation={5} style={{ height: 172 }} >
                                      <CardMedia
                                        style={{ height: "100%", width: "100" }}
                                        component="img"
                                        image={images[0] || defaultimg}
                                        src="images"
                                        title="Paella dish"
                                      />
                                    </Card>
                                  </SRLWrapper>}
                                </Grid>
                                <Grid item xs>
                                  {images[1] && <SRLWrapper options={options}>
                                    <Card elevation={5} style={{ height: 172 }} >
                                      <CardMedia
                                        style={{ height: "100%", width: "100" }}
                                        component="img"
                                        image={images[1] || defaultimg}
                                        src="images"
                                        title="Paella dish"
                                      />
                                    </Card>
                                  </SRLWrapper>}
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}  >
                                <Grid item xs>
                                  {images[2]
                                    && <SRLWrapper options={options}>
                                      <Card elevation={5} style={{ height: 172 }} >
                                        <CardMedia
                                          style={{ height: "100%", width: "100" }}
                                          component="img"
                                          image={images[2] || defaultimg}
                                          src="images"
                                          title="Paella dish"
                                        />
                                      </Card>
                                    </SRLWrapper>}
                                </Grid>
                                <Grid item xs>
                                  {images[3] && <SRLWrapper options={options}>
                                    <Card elevation={5} style={{ height: 172 }} >
                                      <CardMedia
                                        style={{ height: "100%", width: "100" }}
                                        component="img"
                                        image={images[3] || defaultimg}
                                        src="images"
                                        title="Paella dish"
                                      />
                                    </Card>
                                  </SRLWrapper>}
                                </Grid>
                              </Grid>
                            </Paper>
                          </SimpleReactLightbox>
                        }

                        if (images.length >= 7) {
                          return <SimpleReactLightbox>
                            <Paper elevation={0}>
                              <Grid container spacing={2}  >
                                <Grid item xs >
                                  {images[0] && <SRLWrapper options={options}>
                                    <Card elevation={5} style={{ height: 172 }} >
                                      <CardMedia
                                        style={{ height: "100%", width: "100" }}
                                        component="img"
                                        image={images[0] || defaultimg}
                                        src="images"
                                        title="Paella dish"
                                      />
                                    </Card>
                                  </SRLWrapper>}
                                </Grid>
                                <Grid item xs>
                                  <Grid container spacing={2} >
                                    <Grid item xs>
                                      {images[1] && <SRLWrapper options={options}>
                                        <Card elevation={5} style={{ height: 172 }} >
                                          <CardMedia
                                            style={{ height: "100%", width: "100" }}
                                            component="img"
                                            image={images[1] || defaultimg}
                                            src="images"
                                            title="Paella dish"
                                          />
                                        </Card>
                                      </SRLWrapper>}
                                    </Grid>
                                  </Grid>

                                </Grid>
                              </Grid>
                              <Grid container spacing={2}  >
                                <Grid item xs>
                                  {images[2]
                                    && <SRLWrapper options={options}>
                                      <Card elevation={5} style={{ height: 172 }} >
                                        <CardMedia
                                          style={{ height: "100%", width: "100" }}
                                          component="img"
                                          image={images[2] || defaultimg}
                                          src="images"
                                          title="Paella dish"
                                        />
                                      </Card>
                                    </SRLWrapper>}
                                </Grid>
                                <Grid item xs>
                                  <Grid container spacing={2} >
                                    <Grid item xs>
                                      <Grid container spacing={2} direction="column" >
                                        <Grid item xs>
                                          {images[3] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 77 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={images[3] || defaultimg}
                                                src="images"
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                        <Grid item xs>
                                          {images[4] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 77 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={images[4] || defaultimg}
                                                src="images"
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs>
                                      <Grid container spacing={2} direction="column" >
                                        <Grid item xs>
                                          {images[5] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 77 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={images[5]}
                                                src="images"
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                        <Grid item xs>
                                          {images[6] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 77 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={images[6] || defaultimg}
                                                src="images"
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>

                                      </Grid>
                                    </Grid>

                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </SimpleReactLightbox>
                        }
                      }
                    })()}
                  </Grid>
                </Grid>
              </Card>
            </div>
            <Card style={{ padding: " 0px 20px 20px", borderRadius: "20px" }} elevation={0}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Paper elevation={0}  >
                    <div className="detail_container_desk">
                      <h3>{address}</h3>
                      <p>{this.renderText()}</p>
                      {/* {domain === "https://testhomewa.web.app/" ?  <p>HomeWAState.com</p> : <p>DGUPTAHOMES.com</p>  } */}
                      <Divider />
                      <div >
                        <div >
                          <Paper elevation={0} >
                            <DetailData
                              Details={description}
                              Bedroom={bedrooms}
                              Bathroom={bathrooms}
                              Kitchen={kitchens}
                              Adderss={address}
                              Map={siteurl ? <img Style=" width: 600px; height: auto" src={siteurl} alt={propertyname} /> : <img Style=" width: 600px; height: auto" src={defaultMap} alt={propertyname} />}
                              siteMap={siteurl}
                              community={community}
                              Schools={schools}
                              url={images}
                              Level1={level1}
                              level2={level2}
                              Level3={level3}
                              Builders={builders}
                              Size={size}
                              ActiveStatus={ActiveStatus}
                              floorUrl={floorUrl}
                              bathrooms={bathrooms}
                              bedrooms={bedrooms}
                              parking={parking}
                              city={city}
                              zip={zip}
                              Agent1={Agent1}
                              Agent2={Agent2}
                              OFFMarketDate={OFFMarketDate}
                              Source={Source}
                              status={status}
                              startprice={startprice}
                              propertyname={propertyname}
                              Scoop={scoop}
                              wishlist={wishlist}
                            >
                            </DetailData>
                            {/* {this.state.mort && <Mortgage /> } */}
                          </Paper>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Container component="main" maxWidth="md">
                    <BookConsultation />
                  </Container>
                  <Container style={{ marginTop: "8px" }} maxWidth="md" >
                    <Address />
                  </Container>
                  <CitysSub props={home} city={home.city} />
                  <Cards />
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Box>
        <Footer />
      </SimpleReactLightbox>
      {/* </Container> */}
    </>
    )
  }

}

export default withRouter(Detailpage);










