import React, {useState, useEffect } from 'react';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles} from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import DialogActions from '@material-ui/core/DialogActions';
// import { ClickAwayListener } from '@material-ui/core';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import ExploreIcon from '@material-ui/icons/Explore';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import TransferWithinAStationRoundedIcon from '@material-ui/icons/TransferWithinAStationRounded';
import Divider from '@material-ui/core/Divider';
import './ImagePopup.css';
import Avilable from './Avilable';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import greatschool from '../images/greatschool.png';
import API from '../axios';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import SimpleReactLightbox from "simple-react-lightbox";
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import { SRLWrapper } from "simple-react-lightbox";
import CardContent from '@material-ui/core/CardContent';
import DashboardIcon from '@material-ui/icons/Dashboard';
const Zillow = require("node-zillow")
// var convert = require('xml-js');
// const parser = require('xml2js');

// 

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      minHeight:500,
      background: 'red',
      borderRadius:'30px'
    },
    h5:{
      padding:0,
    },
    tabcolour: {
      background: 'red',
    },
    text:{
      fontWeight:800,
      fontSize:'12px',
      color: ' #4b4b4b'
    },
    h7:{
      fontSize:16,
      lineHeight:2,
     
    },
    chip: {
      display: 'flex',
      // justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.9),
      },
    },
    chiptext:{
      fontSize:12,
      fontWeight:500,
      paddingRight:9,
      paddingLeft:9
    },
  }));
 
  function Floor(props){
    const classes = useStyles();
        var str= props.floorUrl
        var str1 = str.split("%2F")[1];
        var str2 = str1.split(/.jpg|.png/)[0].replaceAll("%20", " ")
        
        
    
        console.log("floor", str2)
        return (
          <div> 
    
    <Grid item key={str} style={{"padding":"10px"}}  >
          <Card  elevation={4} spacing={2} style={{width:290}}>
          <CardActionArea>
            <div className={classes.imageBody}>
            <div  className="containerport">
          {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
          <CardMedia
              className={classes.mediaSmall} 
              component="img"
              image={props.floorUrl}alt={str2} 
              title="Live from space album cover"
            />
             <div className="middle" style={{ top : '42%'}}>
                <div className="textwrap">Click to view</div>
              </div>
            </div>
            </div>
            <CardContent>
              
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                  <Typography className="mobav_title">
                      <h2>{str2}</h2>
                    </Typography>
                   
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card> 
        </Grid> 
          </div>
        )
    
      }
    
  function SiteMap(props){
    const classes = useStyles();
        var str= props.SiteMap
        // var str1 = str.split("%2F")[1];
        // var str2 = str1.split(/.jpg|.png/)[0].replaceAll("%20", " ")
        
        
    
        // console.log("floor", str2)
        return (
          <div> 
    
    <Grid item key={str} style={{"padding":"10px"}}  >
          <Card  elevation={4} spacing={2} style={{width:290}} className="containerport">
          <CardActionArea>
            <div className={classes.imageBody}>
          {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
            <CardMedia
              className={classes.mediaSmall} 
              component="img"
              image={props.SiteMap}alt={props.propertyname} 
              title="Live from space album cover"
            />
             <div className="middle">
                <div className="textwrap">Click to view</div>
              </div>
            </div>
            {/* <CardContent>
              
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                  <Typography className="mobav_title">
                      <h2>{str2}</h2>
                    </Typography>
                   
                  </Grid>
                </Grid>
              </Grid>
            </CardContent> */}
          </CardActionArea>
        </Card> 
        </Grid> 
    {/*          
            <div className="top-Right_Rec_mob_D">{str2}</div>
            <img className={classes.img} src={props.floorUrl} alt={str2} /> */}
            
          </div>
        )
    
      }
    
    
function ImagePopup(props){
    const classes = useStyles();
  const [value, setValue] = React.useState('one');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const[elementryschooldata, setElementryschooldata]=useState('')
  const[middleschooldata, setMiddleschooldata]=useState([])
  const[highschooldata, setHighschooldata]=useState([])
  const[zillowdata, setZillowdata]=useState([])
  const zillow = new Zillow('X1-ZWz1i39n9du1or_8i7yl')
  const options = {
    icons: {
      downloadIcon: null,
    },
    buttons :{
        showDownloadButton: false
    }
    
  }
  const options2 = {
    icons: {
      downloadIcon: null,
    },
    buttons :{
        showDownloadButton: false
    },
    thumbnails: {
      showThumbnails: false
  }
}
  const { loading = false } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if(props.index == true){
   
}

  useEffect(() => {
    try{
        API.get(`/https://api.greatschools.org/search/schools?key=3af844f82a4edbd9d7143d1bf9134b66&state=WA&q=${props.Level1}&levelCode=elementary-schools&limit=1`)
        .then(data=>{
              setElementryschooldata(data.data.schools.school);
              console.log("elem",data)
            })
              
      
        API.get(`/https://api.greatschools.org/search/schools?key=3af844f82a4edbd9d7143d1bf9134b66&state=WA&q=${props.level2}&levelCode=middle-schools&limit=1`)
        .then(data=>{
              setMiddleschooldata(data.data.schools.school);
              console.log("middle",data)
            })
        API.get(`/https://api.greatschools.org/search/schools?key=3af844f82a4edbd9d7143d1bf9134b66&state=WA&q=${props.Level3}&levelCode=high-schools&limit=1`)
        .then(data=>{
              setHighschooldata(data.data.schools.school);
              console.log("high",data)
        })}
        catch(err){
          console.log(console.err)
        }
        API.get("/http://www.zillow.com/webservice/GetSearchResults.htm?zws-id=X1-ZWz1i39n9du1or_8i7yl&address=2114+Bigelow+Ave&citystatezip=Seattle%2C+WA")
        .then(data=>{
              
              console.log("res2",data)
           
            })
            API.get("/https://www.zillow.com/webservice/GetSearchResults.htm?zws-id=0fcb62681dmsh4275411a13d757cp1c186cjsn70998f120d73&address=2114+Bigelow+Ave&citystatezip=Seattle%2C+WA"
         )
        .then(response => {
          console.log("res",response);
        })
        .catch(err => {
          console.error(err);
        });

        
        // API.get(`/https://api.bridgedataoutput.com/api/v2/zestimates?access_token=9c80e82295b6f675272c7bac93e68817&limit=1&address=${props.Adderss}`)
        // .then(data=>{
        //   var zillowz = (data.data.bundle.[0].zestimate).toString();
        //   setZillowdata(zillowz)
        //       console.log("res7",zillowz)
        //       console.log("res9",zillowdata)
        //     }) 
        //     .catch(err => {
        //         console.error(err);
        //       });
          
        // fetch(`https://api.bridgedataoutput.com/api/v2/zestimates?access_token=9c80e82295b6f675272c7bac93e68817&zestimate.gt=200000000`)
        // .then(response => {
        //   console.log("res6",response);
        // })
        // .catch(err => {
        //   console.error(err);
        // });
//         fetch("https://zillow-free.p.rapidapi.com/property/15162566/zestimate", {
// 	"method": "GET",
// 	"headers": {
// 		"x-rapidapi-key": "0fcb62681dmsh4275411a13d757cp1c186cjsn70998f120d73",
// 		"x-rapidapi-host": "zillow-free.p.rapidapi.com"
// 	}
// })
//         .then(response => {
//           console.log("res3",response);
//         })
//         .catch(err => {
//           console.error(err);
//         });
        

      //   var zillow = new Zillow('X1-ZWz1i39n9du1or_8i7yl')
      //   let link = "https://cors-anywhere.herokuapp.com/"
      //   const parameters = {
      //     address: "2114 Bigelow Ave",
      //     citystatezip: "Seattle, WA",
      //     rentzestimate: true
      // }
      
      // zillow.get('GetSearchResults', parameters)
      //     .then(results => {
      //         console.log(results)
      //         return results
      //     })

  
//  async function fetchData(){
//    const request = await axios.get(`/Alameda?key=3af844f82a4edbd9d7143d1bf9134b66`)
//    console.log(request)
    
//     // .then(response=>response.text())
//     // .then(data=>{
//     //     console.log(data);  //string
//     //     let parser = new DOMParser();
//     //     let xml = parser.parseFromString(data, "text/xml");
        
//     //     console.log(xml);
      
//     // });
//  } fetchData();
console.log("zillow",zillowdata)
}, [])

    const {
      title, 
      city,
      children, 
      Schools,
      Level1,
      level2,
      Level3, 
      Builders,
      Details,
      Bedroom,
      Bathroom,
      Kitchen,
      Adderss,
      floorUrl,
      Map,
      siteMap,
      propertyname,
      community,
      index, ...other} = props;
  //  console.log("item2",siteMap.length)
    return (
        
        <div className={classes.root} >
           <Paper elevation={0} >
              <Tabs 
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab label="Availablity" icon={<TransferWithinAStationRoundedIcon/>}value="one"
                className={classes.text}
                {...a11yProps('one') }
                className={classes.text} />
                <Tab label="School" icon={<SchoolRoundedIcon/>} value="two" {...a11yProps('two')} 
                className={classes.text}/>
                <Tab label="Community Map" icon={<ExploreIcon />}  value="three" {...a11yProps('three')}
                className={classes.text} />
                { (() => { if(floorUrl != undefined && floorUrl !="")
                return <Tab label="Floor Plans" icon={<DashboardIcon />}  value="four" {...a11yProps('four')}
                className={classes.text} />
              })()}
              <Tab label="Details" icon={<HomeWorkRoundedIcon/>}
                value="five" {...a11yProps('five')}
                className={classes.text}
              />
            </Tabs>
            <Divider/>
          <TabPanel value={value} index="five">
          <div className={classes.chip}>
          {/* <h5>Highlights : </h5> */}
          <Chip
        icon={<OpenWithRoundedIcon/>}
        label={`Size : ${props.Size} Sq.ft`}
        className={classes.chiptext}
      />
      <Chip
        icon={<LocalHotelIcon />}
        label={`Beds : ${props.bedrooms}`}
        className={classes.chiptext}
      />
       <Chip
        icon={<BathtubIcon/>}
        label={`Baths : ${props.bathrooms}`}
        className={classes.chiptext}
      />
      <Chip
        icon={<DirectionsCarRoundedIcon/>}
        label={`Bay Garage : ${props.parking}`}
        className={classes.chiptext}
      />
      </div>
          <div className="detail_font">
            <h5>Details</h5>
            <h6>{props.Details} </h6>
            {/* <h5>Zillow: ${zillowdata}</h5> */}
            {/* <h5> <NumberFormat value={zillowdata.toString()} displayType={'text'} thousandSeparator={true} prefix={'Zillow: $ '}  renderText={value => <div>{value}</div>} /></h5>
            <NumberFormat value={zillowdata} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            <NumberFormat value={zillowdata.toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} /> */}
          </div>
          </TabPanel>
          <TabPanel value={value} index="three">
          <h6 className="Schools_h6">Community Map</h6>
            <div className="Schools_map">
          <h7 >{props.Adderss}</h7>
            </div>
           { !Array.isArray(props.siteMap) &&<SimpleReactLightbox>
            <SRLWrapper  options={options2}>
            <Card style={{width: "fit-content"}}>
            { loading ?  (<Skeleton variant="rect" width={210} height={118} />) : props.Map}
            </Card>
            </SRLWrapper>
            </SimpleReactLightbox>}
            
            { (() => { if(Array.isArray(props.siteMap) )
             if(siteMap.length === 1)
             
             return <SimpleReactLightbox>
             <SRLWrapper options={options2}>
             <Card style={{width: "fit-content"}}>
               <img src={siteMap} alt="Site siteMap"   Style=" width: 600px; height: auto" />
               </Card>
               </SRLWrapper>
               </SimpleReactLightbox>
             else
       return <SimpleReactLightbox >
        <SRLWrapper options={options}>
          
              <div className="FloorPlanGrid_Desk">
            { siteMap.map((data) => (
            <Grid container style={{ width: "auto"}}>
              <SiteMap SiteMap={data} propertyname={propertyname}/>
              </Grid>)
              )}
              </div>
          
        </SRLWrapper >
    </SimpleReactLightbox>
            })()}

          </TabPanel>
          <TabPanel value={value} index="four">
          <h6 className="Schools_h6">Floor Plans</h6>
            <div className="Schools_map">
          <h7 >{props.Adderss}</h7>
            </div>
            
            { (() => { if(Array.isArray(props.floorUrl) )
             if(floorUrl.length === 1)
             
             return <SimpleReactLightbox>
             <SRLWrapper options={options2}>
               <Card style={{width: "fit-content"}}>
               <img src={floorUrl} alt="Site floorUrl"  Style=" width: 600px; height: auto" />
               </Card>
               </SRLWrapper>
               </SimpleReactLightbox>
             else
       return <SimpleReactLightbox >
        <SRLWrapper options={options}>
          
              <div className="FloorPlanGrid_Desk">
            { floorUrl.map((data) => (
            <Grid container style={{ width: "auto"}}>
              <Floor floorUrl={data}/>
              </Grid>)
              )}
              </div>
          
        </SRLWrapper >
    </SimpleReactLightbox>
            })()}

          </TabPanel>
          <TabPanel value={value} index="two">
            <div className='great_div' >
              <a rel="canonical" href="https://www.greatschools.org/WA/"  target="_blank">
                <img src={greatschool} alt="Great School" className='great_schoolLogo' /></a>
                <h6 className="SchoolsD_hs"> {props.Schools}</h6>
              </div>
             <div className="Schools_h">
             { (() => {  
               if(!props.Level1 && !props.level2 && !props.Level3){
               return <div>
                        {/* <h6 className="Schools_h6"> Available Soon</h6> */}
                        <p><strong>Available Soon.</strong>This home is within the <a rel="canonical" href="https://www.greatschools.org/WA/"  target="_blank">Lake Washington School District.</a></p>
                        <p> <strong>{props.city} enrollment policy is not based solely on geography.</strong>  Please check the school district website to see all schools serving this home.</p>
                      </div>
               }
               else{
                 return(
              
            <TableContainer component={Paper}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell > <div className="Single_tab">Type</div></TableCell>
            <TableCell> <div className="Single_tab" >Schools</div></TableCell>
            <TableCell> <div className="Single_tab" >Rating</div></TableCell>
            {/* <TableCell> <div className="Single_tab" >Students</div></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell component="th" >
                <h6 className="Schools_h6"> Elementary school</h6>
              </TableCell>
              <TableCell ><h7 className="Schools_h7">{props.Level1? props.Level1 : 'NA'} </h7></TableCell>
              <TableCell >
              <div  className="Schools_Rating2">
                <h7 className="Schools_h7">{elementryschooldata?.gsRating ? elementryschooldata.gsRating +''+'/'+''+'10' :'NA'} </h7>
              </div>
                </TableCell>
              {/* <TableCell ><h7 className="Schools_h7">{elementryschooldata?.enrollment ? elementryschooldata.enrollment :'NA'} </h7></TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell component="th" >
              <h6 className="Schools_h6">Middle schools</h6>
              </TableCell>
              <TableCell ><h7 className="Schools_h7">{props.level2? props.level2 : 'NA'}</h7> </TableCell>
              <TableCell >
              <div  className="Schools_Rating2">
                <h7 className="Schools_h7">{middleschooldata?.gsRating ? middleschooldata?.gsRating +''+'/'+''+'10' :'NA'} </h7>
                </div>
                </TableCell>
              {/* <TableCell ><h7 className="Schools_h7">{middleschooldata?.enrollment ? middleschooldata.enrollment :'NA'} </h7></TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell component="th" >
                <h6 className="Schools_h6">High schools</h6>
              </TableCell>
              <TableCell ><h7 className="Schools_h7">{props.Level3? props.Level3 : 'NA'}</h7> </TableCell>
              <TableCell >
              <div  className="Schools_Rating2">
                <h7 className="Schools_h7">{highschooldata?.gsRating ? highschooldata.gsRating +''+'/'+''+'10' :'NA'}</h7>
                </div></TableCell>
              {/* <TableCell ><h7 className="Schools_h7">{highschooldata?.enrollment ? highschooldata.enrollment :'NA'} </h7></TableCell> */}
            </TableRow>
            
        </TableBody>
        <caption> <strong>
        School data provided by <>
        <a href="https://www.greatschools.org/" target="_blank">GreatSchools.</a> </>
        School service boundaries are intended to be used as reference only. To verify enrollment eligibility for a property, contact the school directly.
        </strong></caption>
      </Table>
    </TableContainer>
             )}
    })()}
      </div>

            
          </TabPanel>
          <TabPanel value={value} index="one">
            {/* <h5>Contact  Us to know current availability</h5> */}
            {/* <h6>{props.Adderss}</h6> */}
            <h7><Avilable props={props}/></h7>
            {/* <Availability props={props}/> */}
          </TabPanel>
          </Paper>
          </div>
        
      );
    }
export default ImagePopup;




{/* // return(
//     <div>
//           <Dialog open={openPopup} onClose={closePopup}>
//               <DialogContent>
//               <Paper  variant="outlined" square >
//                      <Tabs */}
//                          value={value}
//                          indicatorColor="primary"
//                          textColor="primary"
//                          onChange={handleChange}
//                          aria-label="disabled tabs example"
//                      >
//                          <Tab label="Community Images" 
//                           />
//                          <Tab label="Community Map" />
//                      </Tabs>
//                  </Paper>
//                  {children}
                 
//               </DialogContent>
//           </Dialog>
//     </div>
// )
// }