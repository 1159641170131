import React, { useState, useEffect, useContext } from 'react';
import { PropertyContext } from '../context';
import PropTypes from 'prop-types';
import { Box, Grid, Container, Card, CssBaseline, useMediaQuery, Button, TextField, Checkbox, Paper, Typography } from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import banner22 from '../images/Bannermob13.png';
import Divider from '@mui/material/Divider';
import Footer from '../components/Footer';
import { styled } from '@mui/material/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiInput from '@mui/material/Input';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import firebase from '../firebase/firebase';

// const theme = createTheme({
//     components: {
//       // Name of the component ⚛️
//       MuiSelect: {
//         select:{
//             '&:focus':{
//                 borderColor: 'grey',
//             },
//             '&.Mui-focused':{
//                 borderColor: 'grey',
//             }
//         }
//       },
//     },
//   });



const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'grey',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'grey',
        },
    },

})



const CustomSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'grey',
        },
    },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const useStyles = makeStyles((theme) => ({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        padding: 34,
        paddingTop: 60
    },
    cardGrid: {
        marginTop: "10px",
    },
    cardField: {
        margin: "1em",
    },

}))



function MyFormControlLabel(props) {
    const isMatch = useMediaQuery('(max-width:600px )');

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="h6">PLEASE NOTE - If you are married, its mandatory to fill your spouse information.</Typography>
                <Divider style={{ margin: '1em 0em' }} />
                <Typography variant="h7">As per the Washington State Legislature, property acquired after the marriage ceremony is considered community property. As such, the property belongs equally to both spouses and neither one can dispose off that property through transfer or sale without the permission of the other. </Typography>
            </Grid>

            <Grid xs={isMatch ? 12 : 8} >
                <CustomTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Spouse's Full Name"
                    name='entry.1507825095'
                    autoComplete="name"
                    className="text_contact"

                />
            </Grid>
            <Grid xs={isMatch ? 12 : 8} >
                <CustomTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="number"
                    label="Spouse's Mobile Number"
                    name='entry.1891817580'
                    type="number"
                    autoComplete="number"
                    className="text_contact"
                />
            </Grid>
            <Grid xs={isMatch ? 12 : 8} style={{ marginTop: '1em' }}>
                <CustomTextField
                    required
                    variant="outlined"
                    id="email"
                    name='entry.2011530915'
                    label="Spouse's Email ID"
                    type="email"
                    className="text_contact"
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}

MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
};



export const BuyerInputs = () => {
    const classes = useStyles();
    const isMatch = useMediaQuery('(max-width:600px )');
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [startPrice, setStartPrice] = React.useState([]);
    const [endPrice, setEndPrice] = React.useState([]);
    const [type, setType] = React.useState([]);
    const [squareFeet, setSquareFeet] = React.useState([]);
    const [direction, setDirection] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const [looking, setLooking] = React.useState([]);
    const theme = useTheme();


    // const [formData, setFormData] = useState({
    //     'entry.1745975336': '', //First Name// firstName
    //     'entry.1716077613': '',//Last Name
    //     'entry.630251844': '',//Middle Name
    //     'emailAddress': '',//Email
    //     'entry.428503467': '',//Phone Number
    //     'entry.1894665470': '',//Current Address
    //     'entry.1629193004': [],//City
    //     'entry.1426315482': [], //Square Feet  // For multi-select
    //     'entry.865438625': '',//Starting Price
    //     'entry.1008622395': '',//Ending Price
    // });


    // const handleFormChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData(prevData => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    //     // console.log(`Field: ${name}, Value: ${value}`);
    // };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSquareFeet(
            typeof value === 'string' ? value.split(',') : value,
        );;
    };

    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;
        setType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange2 = (event) => {
        const {
            target: { value },
        } = event;
        setDirection(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setCity(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleClick = () => {
        setOpen(true)
    }

    const handleClick1 = () => {
        setOpen(false)
    }

    const handleClick2 = () => {
        setOpen1(true)
    }

    const handleClick3 = () => {
        setOpen1(false)
    }

    const handleChange4 = (event) => {
        const {
            target: { value },
        } = event;
        setStartPrice(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange5 = (event) => {
        const {
            target: { value },
        } = event;
        setEndPrice(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange6 = (event) => {
        const {
            target: { value },
        } = event;
        setLooking(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    // const fetchData = async () => {
    //     try {
    //         // Initialize Firestore
    //         const db = firebase.firestore().collection("testbi");
    //         // Fetch documents
    //         const querySnapshot =  await db.get();;
    //         const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //         console.log(data.filter(item => item.City === "Kirkland"));
    //     } catch (error) {
    //         console.error("Error fetching data: ", error);
    //     }
    // };

    // useEffect(() => {
    //     fetchData()
    // }, []);

    return (
        <>
            <Box style={{ margin: 0, background: "white" }} >
                <Paper className={classes.bannerPaper}>
                    <Container className={classes.cardGrid} maxWidth="md">
                        <div className="BAnner_text_Nav">
                            <h3>Buyer Information </h3>
                        </div>
                    </Container>
                </Paper>

                <Box style={{ margin: 0, background: "white" }}>
                    <Container component="main" maxWidth={isMatch ? 'xs' : 'md'} style={{ padding: isMatch ? '10px' : '1em' }}>
                        <CssBaseline />
                        <Card style={{ padding: isMatch ? '10px' : '14px' }}>
                            <Grid xs={12}>
                                <Typography style={{ fontSize: '1.8em', fontWeight: 500 }}>Deepti Gupta Real Estate</Typography>
                            </Grid>
                            <Divider style={{ marginTop: '10px' }} />
                            <Grid xs={12} style={{ marginTop: '10px' }}>
                                <Typography color="primary" style={{ fontSize: '1em', fontWeight: 400 }}>Please fill in the following contact details as it will help us to serve you better.</Typography>
                            </Grid>
                            <form action='https://docs.google.com/forms/u/0/d/e/1FAIpQLSd_ffx6orlIkmtYPXjSqXHIgLBt68X6xQVYyvoKglA85jcJdA/formResponse'>
                                <Grid container spacing={1} >
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label="First Name"
                                            name='entry.1745975336'
                                            autoComplete="name"
                                            className="text_contact"
                                            fontColor="black"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="name"
                                            label="Middle Name"
                                            name='entry.1716077613'
                                            autoComplete="name"
                                            className="text_contact"

                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Last Name"
                                            name='entry.630251844'
                                            autoComplete="name"
                                            className="text_contact"
                                        // value={values.name}

                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <CustomTextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="number"
                                            label="Mobile Number"
                                            name='entry.428503467'
                                            type="number"
                                            autoComplete="number"
                                            className="text_contact"
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} style={{ marginTop: '1em' }}>
                                        <CustomTextField
                                            required
                                            variant="outlined"
                                            id="email"
                                            name='emailAddress'
                                            label="Email ID"
                                            type="email"
                                            className="text_contact"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} style={{ marginTop: '1em' }}>
                                        <CustomTextField
                                            required
                                            variant="outlined"
                                            id="address"
                                            name="entry.1894665470"
                                            label="Current Address"
                                            type="address"
                                            className="text_contact"
                                            fullWidth
                                        />

                                    </Grid>
                                </Grid>
                                <Divider style={{margin: '1em 0em'}} />

                                <Grid container spacing={1} >
                                    <Grid item xs={12}>
                                        <FormControl required>
                                            <FormLabel id="demo-controlled-radio-buttons-group">Do you have a pre-approval? </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name='entry.1477449943'
                                            >
                                                <FormControlLabel value="Yes" control={<Radio name='entry.1477449943' />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio name='entry.1477449943' />} label="No" />
                                                <FormControlLabel value="No - I need help with lender contact" control={<Radio name='entry.1477449943' />} label="No - I need help with lender contact" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl required>
                                            <FormLabel id="demo-controlled-radio-buttons-group">Status </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name='entry.935512899'
                                            >
                                                <FormControlLabel value="Single" control={<Radio name='entry.935512899' />} label="Single" onClick={handleClick1} />
                                                <FormControlLabel value="Married" control={<Radio name='entry.935512899' />} label="Married" onClick={handleClick} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {open ?
                                            <Container style={{ margin: '1em' }}>
                                                <MyFormControlLabel />
                                                <Divider style={{ marginTop: '1em' }} />
                                            </Container>
                                            :
                                            null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl required>
                                            <FormLabel id="demo-controlled-radio-buttons-group">How did you Hear About us? </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name='entry.231723094'
                                            >
                                                <FormControlLabel value="Recommended by friend or colleague" control={<Radio name='entry.231723094' />} label=" Recommended by friend or colleague" onClick={handleClick2} />
                                                {
                                                    open1 ?
                                                        <Grid xs={isMatch ? 12 : 8} >
                                                            <CustomTextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                id="name"
                                                                label="Friend or colleague Full Name"
                                                                name='entry.420374322'
                                                                autoComplete="name"
                                                                className="text_contact"
                                                                sx={{ width: isMatch ? '100%' : '130%', ml: '2em', mt: '0em' }}
                                                            />
                                                        </Grid>
                                                        :
                                                        null
                                                }
                                                <FormControlLabel value="Social media (FB, WhatsApp, Insta)" control={<Radio name='entry.231723094' />} label="Social media (FB, WhatsApp, Insta)" onClick={handleClick3} />
                                                <FormControlLabel value="Search engine (Google, Zillow, etc)" control={<Radio name='entry.231723094' />} label="Search engine (Google, Zillow, etc)" onClick={handleClick3} />
                                                <FormControlLabel value="Other" control={<Radio name='entry.231723094' />} label="Other" onClick={handleClick3} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Divider style={{ marginTop: '1em' }} />
                                    <Grid item xs={12}>
                                        <Typography>Looking For </Typography>
                                        <FormControl sx={{ m: 1, width: 300 }} required>
                                            <InputLabel id="demo-multiple-checkbox-label">Looking for</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                name='entry.1086807689'
                                                multiple
                                                value={looking}
                                                onChange={handleChange6}
                                                renderValue={(selected) => selected.join(', ')}
                                                input={<OutlinedInput label="Looking for" />}
                                                MenuProps={MenuProps}

                                            >
                                                {residence.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={looking.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                </Grid>

                                <Divider />

                                <Grid container spacing={1} style={{ background: 'lightgrey', margin: '10px -2px', borderRadius: '5px' }} >
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <Typography>Price Range</Typography>

                                        <FormControl sx={{ m: 1, width: 300 }}>

                                            <InputLabel id="demo-simple-select">Starting Price</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='entry.865438625'
                                                value={startPrice}
                                                onChange={handleChange4}
                                                input={<OutlinedInput label="Starting Price" />}
                                                MenuProps={MenuProps}
                                            >
                                                {Price.map((item, index) => (
                                                    <MenuItem key={index} value={item}  >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6} style={{ marginTop: isMatch ? 'null' : '1.5em' }}>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-simple-select">Ending Price</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='entry.1008622395'
                                                value={endPrice}
                                                onChange={handleChange5}
                                                input={<OutlinedInput label="Ending Price" />}
                                                MenuProps={MenuProps}
                                            >
                                                {Price.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                    <Divider  />
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <Typography>Preferred Sq ft</Typography>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Preferred Sq ft</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                name='entry.1426315482'
                                                multiple
                                                value={squareFeet}
                                                onChange={handleChange}
                                                renderValue={(selected) => selected.join(', ')}
                                                input={<OutlinedInput label="Preferred Sq ft" />}
                                                MenuProps={MenuProps}
                                            >
                                                {square.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={squareFeet.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <Typography>Property Type</Typography>
                                        <FormControl sx={{ m: 1, width: 300 }}>

                                            <InputLabel id="demo-multiple-checkbox-label">Property Type</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                name='entry.1517310292'
                                                multiple
                                                value={type}
                                                onChange={handleChange1}
                                                renderValue={(selected) => selected.join(', ')}
                                                input={<OutlinedInput label="Property Type" />}
                                                MenuProps={MenuProps}
                                            >
                                                {property.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={type.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <Typography>Preferred Facing</Typography>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Preferred Facing</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                name='entry.850824006'
                                                multiple
                                                value={direction}
                                                onChange={handleChange2}
                                                renderValue={(selected) => selected.join(', ')}
                                                input={<OutlinedInput label="Preferred Facing" />}
                                                MenuProps={MenuProps}
                                            >
                                                {direct.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={direction.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 6}>
                                        <Typography>Preferred Areas</Typography>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Preferred Areas</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                name='entry.1629193004'
                                                value={city}
                                                onChange={handleChange3}
                                                renderValue={(selected) => selected.join(', ')}
                                                input={<OutlinedInput label="Preferred Areas" />}
                                                MenuProps={MenuProps}
                                            >
                                                {citys.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={city.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Card>
                    </Container>
                </Box>

            </Box>

            <div>
                <Footer />
            </div>
        </>


    )
}


const Price = [
    '$ 100,000',
    '$ 200,000',
    '$ 300,000',
    '$ 400,000',
    '$ 500,000',
    '$ 600,000',
    '$ 700,000',
    '$ 800,000',
    '$ 900,000',
    '$ 1,000,000',
    '$ 1,100,000',
    '$ 1,200,000',
    '$ 1,300,000',
    '$ 1,400,000',
    '$ 1,500,000',
    '$ 1,600,000',
    '$ 1,700,000',
    '$ 1,800,000',
    '$ 1,900,000',
    '$ 2,000,000',
    '$ 2,100,000',
    '$ 2,200,000',
    '$ 2,300,000',
    '$ 2,400,000',
    '$ 2,500,000',
    '$ 2,600,000',
    '$ 2,700,000',
    '$ 2,800,000',
    '$ 2,900,000',
    '$ 3,000,000',
    '$ 3,100,000',
    '$ 3,200,000',
    '$ 3,300,000',
    '$ 3,400,000',
    '$ 3,500,000',
    '$ 3,600,000',
    '$ 3,700,000',
    '$ 3,800,000',
    '$ 3,900,000',
    '$ 4,000,000',
    '$ 4,100,000',
    '$ 4,200,000',
    '$ 4,300,000',
    '$ 4,400,000',
    '$ 4,500,000',
    '$ 4,600,000',
    '$ 4,700,000',
    '$ 4,800,000',
    '$ 4,900,000',
    '$ 5,000,000',
]

const residence = [
    'Primary Residence',
    'Investment Policy',
]


const square = [
    '1000 Sq.ft - 1500 Sq.ft',
    '1500 Sq.ft - 2000 Sq.ft',
    '2000 Sq.ft - 2500 Sq.ft',
    '2500 Sq.ft - 3000 Sq.ft',
    '3000 Sq.ft - 3500 Sq.ft',
    '3500 Sq.ft - 4000 Sq.ft',
    '4000 Sq.ft - 4500 Sq.ft',
    '4500 Sq.ft - 5000 Sq.ft',
    '5000 Sq.ft - 5500 Sq.ft',
    '5500 Sq.ft - 6000 Sq.ft',
]

const property = [
    'New Construction',
    'Resale',
]

const direct = [
    'East',
    'West',
    'North',
    'South',
]

const citys = [
    'Bothell',
    'Sammamish',
    'Kirkland',
    'Lynnwood',
    'Lake Stevens',
    'Maple Valley',
    'Redmond',
    'Bellevue',
    'Issaquah',
    'Mill Creek',
    'Renton',
    'Puyallup',
]


