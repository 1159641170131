

import React from 'react';
// import { useContext } from 'react';
// import { PropertyContext } from '../context'
import defaultmage from '../../images/room-1.jpeg';
import '../../components/AvilableHome.css';
import { withPropertyConsumer } from '../../context';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import '../../components/Availability.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import CardActionArea from '@material-ui/core/CardActionArea';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';

import RoomIcon from '@material-ui/icons/Room';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';


const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  pad:{
    backgroundColor: '#F6F5F5'
  },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 250,
      borderRadius:15
    },
    
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      borderRadius:50
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    divider:{
        backgroundColor: "#ffce34"
    },
    cardContent: {
      flexGrow: 1,
    },
    button:{
        borderRadius:0
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    mediaSmall:{
      height: 150,
    },
  }));
  

  


    
const ComingSoon = ({context,props}) => {
    const classes = useStyles();
    const { ComingSoon,avilable, allHomes}= context;
    
    const [openn, setOpenn] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
   
    const handleClickOpen = () => {
        setOpen1(true);
      };
    
      const handleClose = () => {
        setOpen1(false);
      };
      const handleOpen = () => {
        setOpenn(true);
      };
    
      const handleDone = () => {
        setOpenn(false);
      };
    // let avilableproperty = avilable;
    //  avilableproperty = avilableproperty.filter(house => house.community == allHomes.community)
  
    //   const Avcount =  avilableproperty.length;


    return( 
    <div> 
      <Box display={{ xs: 'none', sm: 'block' }} m={1}  >
     <CssBaseline />
      
      <main>
        
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {ComingSoon.map((card) => (
            //   <Grid item key={card} xs={12} sm={6} md={4}>
            <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card} elevation={3}>
                <Link underline='none'  to={`/${card.slug}`} >
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.url || defaultmage }
                    title="Image title"
                  />
                  </Link>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h7" component="h6" color="primary">
                      {/* {card.Activename} */}
                    </Typography>
                    <div className="cards__wrap">
                    <div className="card_facing">
                    <Typography variant="h9" component="h8" >
                      Facing : {card.facing? card.facing : 'NA'}
                    </Typography>
                    </div>
                    <strong>| </strong>
                    <div  className="card_price">
                            From : 
                           { card.startprice? <Typography gutterBottom variant="h7" component="h6" id="price_from">
                                <NumberFormat value={card.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                            </Typography> : " NA"}
                        
                    </div>
                    </div>
                    <div className="card_text" >
                    <h7  className="card_text">
                    Move in : {card.movein != ''? card.movein : 'NA'}, Builder : {card.builders != ''? card.builders : 'NA'}
                        , City : {card.city != ''? card.city   : 'NA'}
                    </h7>
                    </div>
                    {/* <Typography  variant="h9" component="h10" >
                        Move in : {card.movein != ''? card.movein : 'NA'}, Builder : {card.builders != ''? card.builders : 'NA'}
                        , City : {card.city != ''? card.city   : 'NA'}
                    </Typography> */}
                    <Divider className={classes.divider}/>
                    <div className="card_bt">
                    <h7>
                        Br : { card.bedrooms != ''? card.bedrooms  : 'NA'} <strong>| </strong>
                        Ba : {card.bathrooms != ''? card.bathrooms  : 'NA'}<strong>  |  </strong>
                        {card.size != ''? card.size  : 'NA' } Sq.ft
                    </h7>
                    </div>
                  </CardContent>
                  {/* <CardActions> */}
                 
                  {/* </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
          
        </Container>
      </main>
     </Box>





      <Box display={{ xs: 'block', sm: 'none' }} m={1} style={{"margin" : "0"}}>
      
      </Box>
    </div>
    )
}
export default withPropertyConsumer(ComingSoon);
















