import React from 'react'
import { Cancel } from '@mui/icons-material';
import { Button, Card, Grid, Stack, styled, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Image from '../../images/UpcominPro1.png'

const CustomCard = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '450px',
    background: '#241b14',
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: '#241b14',
        cursor: 'auto'
    },
    // [theme.breakpoints.down("md")]: {
    //   margin: 0,
    //   top: 'auto',
    //   right: 10,
    //   bottom: '7em',
    //   left: 'auto',
    //   position: 'fixed',
    //   height: '60%',
    //   width: '53%',
    //   background: '#241b14',
    //   borderRadius: '10px',
    // },
    // [theme.breakpoints.down("sm")]: {
    //   margin: 0,
    //   top: 'auto',
    //   right: 10,
    //   bottom: '7em',
    //   left: 'auto',
    //   position: 'fixed',
    //   height: '60%',
    //   width: '56%',
    //   background: '#241b14',
    //   borderRadius: '10px',
    // },
    // [theme.breakpoints.down("xs")]: {
    //   margin: 0,
    //   top: 'auto',
    //   right: 10,
    //   bottom: '7em',
    //   left: 'auto',
    //   position: 'fixed',
    //   height: '60%',
    //   width: '56%',
    //   background: '#241b14',
    //   borderRadius: '10px',
    // },

}))


const UpcomingCard = () => {
    const history = useNavigate();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    }
    return (
        <div>
            {open &&
                <Grid container spacing={0} justify="center">
                    <Grid item xs={12} sm={6} md={4} justify="center" sx={{ p: '1em' }}>
                        <CustomCard>
                            <Stack sx={{ display: 'flex', flexDirection: 'column', }}>
                                <Stack alignItems="end" sx={{ m: '5px', }}><Cancel
                                    sx={{
                                        ml: '2em',
                                        color: '#f50057',
                                        '&:hover':
                                        {
                                            cursor: 'pointer'
                                        }
                                    }}
                                    onClick={handleClose}
                                />
                                </Stack>
                                <img style={{
                                    width: '100%',
                                    height: '370px',
                                    padding: '0px 30px',
                                   objectFit: 'revert'
                                }} src={Image} alt="Upcoming" />
                                <Stack alignItems="center" sx={{ pb: '15px' }}>
                                    <Button
                                        variant='filled'
                                        sx={{
                                            color: 'black',
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            borderRadius: '40px',
                                            backgroundColor: 'white',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        onClick={() => history('/UpcomingProjects')}
                                    >
                                        Book now
                                    </Button>
                                </Stack>
                            </Stack>
                        </CustomCard>
                    </Grid>
                </Grid>

            }
        </div>
    )
}

export default UpcomingCard