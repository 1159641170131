import React from 'react'
import { Container, styled, Grid, Box, CardContent, Typography, Stack } from '@mui/material'
import { LocationOnRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import defaultmage from '../../images/Image_not_available.png';
import firebase from '../../firebase/firebase';

const CustomContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        // // minWidth:140
        paddingLeft: "24px",
        paddingRight: "24px"
    },
    [theme.breakpoints.down("md")]: {
        // minWidth:140
        padding: 0
    },
    padding: '1em 0em',
}))
const CustomDiv = styled('div')(({ theme }) => ({
    padding: 8,
}))

const CustomTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontSize: '14px',
    fontWeight: 500
}))


const CustomCard11 = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: 120,
    background: '#64b3f4',
    borderRadius: 15,
    cursor: "pointer"
}))


const WishListed = ({ CurrentData }) => {
    const [user, setUser] = React.useState(null);
    const history = useNavigate();

    let properties = CurrentData;
    const wishlist = properties.map((item) => item.wishlist)

    React.useEffect(() => {
        // Listen for authentication changes
        const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const email = user && user.email;
    const wishlistData = wishlist !== null && typeof wishlist === 'object'
        ? Object.keys(wishlist).map((id) => wishlist[id])
        : null;

    const filteredProperties = properties.filter((item) => item.wishlist)
    console.log('wishList',wishlistData)
    return (
        <CustomContainer maxWidth="xl">
            <Grid container justify="center"
                alignItems="stretch">
                <Grid >
                    <Grid container>

                    </Grid>
                </Grid>
            </Grid>
        </CustomContainer>
    )
}

export default WishListed
//how to add items to array element in reactjs?