import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import defaultmage from '../images/room-1.jpeg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PropertyContext } from '../context';
import defaultimg from '../images/Image_not_available.png';
import whiteImage from '../images/Image_not_available.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import '../components/Singlepage.css';
import '../components/Detailpage.css';
import ImagePopup from '../components/ImagePopup';
import ListingsCitySub from '../Listings/ListingsCitySub';
import ListingsDetail from '../Listings/ListingsDetail';
import offer from '../images/cards/offer1.png';
import EmailForm from '../contact/EmailForm';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import RelatedHomes from'../components/RelatedHomes';
import Loading from '../components/Loading';
import SimpleReactLightbox from "simple-react-lightbox";
import Chip from '@material-ui/core/Chip';
import { SRLWrapper } from "simple-react-lightbox";
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import RoomIcon from '@material-ui/icons/Room';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NumberFormat from 'react-number-format';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
// import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import defaultMap from '../images/not-av.png';
import ListingsMobDetail from '../Listings/ListingsMobDetail';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Footer from '../components/Footer';
import Mortgage from '../components/Mortgage/Mortgage';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import Box from '@material-ui/core/Box';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import MobileStepper from '@material-ui/core/MobileStepper';
import { useParams } from 'react-router-dom'
import Address from '../contact/Address';
import BookConsultation from '../components/Cards/BookConsultation';
import SEO from '../context/SEO';


const useStyles = makeStyles((theme) => ({
  root: {

    flexGrow: 1,
  },
  root3: {
    paddingTop: "5px",
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: "100%",
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  mediaSmall: {
    width: "100%",
    height: "100%"
  },
  paper: {
    margin: 'auto',
  },
  paperimg: {
    position: "relative",
    width: "100%",
    height: 350
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: "5px 24px 16px"
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  FooterPad: {

  },
  appBar: {
    top: 'auto',
    bottom: 0,
    "backgroundColor": "#ffce34",
  },
  imageBody: {
    height: 200,
    width: 250
  },
}));


const useStyles2 = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#2979ffc2'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    margin: 'auto',
    alignItems: "center",
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  card2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#0066ff'
  },
  field: {
    padding: '10px 0px 10px ',
  },
  card3: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#01398e'
  },


  root: {
    display: 'flex',
    background: 'linear-gradient(45deg, #430089, #82ffa1)',
    maxWidth: 500
  },


  root2: {
    display: 'flex',
    background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
    padding: 10,
    maxWidth: 500,

    cursor: "pointer"
  },
  root3: {
    display: 'flex',
    background: 'linear-gradient(45deg, #f59301fc, #f70000)',
    borderRadius: 15,
    padding: 10,
    maxWidth: 500,

    cursor: "pointer"
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',

  },
  content2: {
    flex: '1 '
  },
  cover: {
    width: 151,

  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  hold: {
    paddingTop: 20
  },
  textsecond: {
    color: "white"
  },
  textOne: {
    color: "white",
    fontSize: "1.3rem"
  },
  texttwo: {
    color: "white",
    fontSize: "1.2rem",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {

      fontSize: "0.99rem",
    },
  },
  pad: {
    padding: 8
  },
  graph: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    borderRadius: 30
  },
  cardpad: {
    [theme.breakpoints.up("md")]: {
      padding: 8,
    },

  },
  heading2: {
    paddingTop: 20,
    paddingBottom: 10
  }
}));


function Cards() {
  const classes = useStyles2();
  return (
    <Container maxWidth="xs">
      <div className={classes.hold}  >
        <Link to="/Contact-US" style={{ textDecoration: "NONE" }}>
          <Card className={classes.root} elevation={5}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5" className={classes.textOne}>
                  Want To Know Offer Review Dates?
                </Typography>
                <Typography variant="body1" className={classes.textsecond}>
                  Find Out Now <DoubleArrowIcon />
                </Typography>
              </CardContent>
            </div>

          </Card>
        </Link>
      </div>

      <div className={classes.hold}  >
        <Link to="/Last-Solds" style={{ textDecoration: "NONE" }}>
          <Card className={classes.root2} elevation={5}>
            <div className={classes.details}>
              <Typography component="h5" variant="h5" className={classes.texttwo}>
                Know what We have Sold So Far <DoubleArrowIcon />
              </Typography>
            </div>
          </Card>
        </Link>
      </div>
    </Container>
  )
}

function ImageSlide(props) {
  const url = props.url
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  console.log("url test", url)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const options = {
    icons: {
      downloadIcon: null,
    },
    buttons: {
      showDownloadButton: false
    }
  }
  return (<Card className={classes.paperimg} elevation={5} >
    <div className={classes.root}>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >{(() => {
            if (url)
              return url.map((item, index) => (
                <div key={url}>
                  {Math.abs(activeStep - index) <= 40 ? (
                    <img className={classes.img} src={item} alt={item} />
                  ) : null}

                </div>
              ))
            else {
              return <div >

                <img className={classes.img} src={whiteImage} alt="Image" />

              </div>
            }

          })()}

          </SwipeableViews>
        </SRLWrapper>
        <div className="image_next_back">
          <div className="Next_desk">
            <Button size="small" onClick={handleNext} disabled={activeStep === (url ? url.length : 1) - 1} >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft className="Arrow_desk" style={{ fontSize: "2rem" }} /> : <KeyboardArrowRight className="Arrow_desk" style={{ fontSize: "2rem" }} />}
            </Button>
          </div>
          <div className="Back_desk">
            <Button size="small" onClick={handleBack} disabled={activeStep === 0} >
              {theme.direction === 'rtl' ? <KeyboardArrowRight className="Arrow_desk" style={{ fontSize: "2rem" }} /> : <KeyboardArrowLeft className="Arrow_desk" style={{ fontSize: "2rem" }} />}
            </Button>
          </div>
        </div>

      </SimpleReactLightbox>
    </div>
  </Card>
  )
}


const Listingpage = () => {
  const context = useContext(PropertyContext);
  const { listings, getData } = context;
  const [domainName, setDomainName] = React.useState('');
  const { MlsId } = useParams();
  //   console.log(MlsId)
  console.log("listings", listings && listings.filter(item => item.MlsId === MlsId).map(item => item.url))

  const options = {
    icons: {
      downloadIcon: null,
    },
    buttons: {
      showDownloadButton: false
    },
    thumbnails: {
      showThumbnails: false
    }
  }

  React.useEffect(() => {
    // Function to get the domain name from window.location
    const getDomainName = () => {
      const url = new URL(window.location.href);
      setDomainName(url.hostname);
    };

    // Call the function when the component mounts
    getDomainName();

    // Add event listener to handle changes in the URL
    const handleNavigationChange = () => {
      getDomainName();
    };

    window.addEventListener('popstate', handleNavigationChange);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
    };
  }, []);

  // Function to render different text based on the domain name
  const renderText = () => {
    switch (domainName) {
      case 'www.dguptahomes.com':
        return 'DguptaHomes.com';
      case 'www.homewastate.com':
        return 'HomeWAState.com';
      default:
        return 'HomeWAState.com';
    }
  };



  React.useEffect(() => {
    if (listings.length === 0) {
      getData()
    }

  }, [])

  if (listings.length === 0) {
    return <Loading />
  }


  return (
    <>
      {listings && listings.filter(item => item.PropertyId === MlsId).map(item => item.address)}
      <SimpleReactLightbox>
        {
          listings && listings.filter(item => item.MlsId === MlsId).map((item) => {
            return (
              <>
                <Box display={{ xs: 'block', sm: 'none' }} m={1} style={{ "margin": "0" }}>
                  <ListingsMobDetail
                    Tag={item.Tag}
                    OfferDate={item.OfferDate}
                    startprice={item.startprice}
                    endprice={item.endprice}
                    url={item.url}
                    Details={item.description}
                    ActiveStatus={item.ActiveStatus}
                    Bedroom={item.bedrooms}
                    Bathroom={item.bathrooms}
                    Kitchen={item.kitchens}
                    Adderss={item.address}
                    Map={item.siteurl}
                    siteMap={item.siteurl}
                    community={item.community}
                    Schools={item.schools}
                    Level1={item.level1}
                    level2={item.level2}
                    Level3={item.level3}
                    Builders={item.builders}
                    Size={item.size}
                    PlotSize={item.PlotSize}
                    bathrooms={item.bathrooms}
                    bedrooms={item.bedrooms}
                    parking={item.parking}
                    propertyname={item.propertyname}
                    floorUrl={item.floorUrl}
                    city={item.city}
                    MlsId={MlsId}
                    AnnualTaxes={item.AnnualTaxes}
                    Hoa={item.Hoa}
                    TaxYear={item.TaxYear}
                    PropertyType={item.PropertyType}
                    Roof={item.Roof}
                    exterior={item.exterior}
                    style={item.style}
                    video={item.video}
                    builtyear={item.builtyear}
                    ListingPrice={item.ListingPrice}
                    zip={item.zip}>
                  </ListingsMobDetail>
                </Box>
                <Box display={{ xs: 'none', sm: 'block' }} m={1}  >
                  <Container maxWidth="Lg" style={{ padding: "0px 50px 30px 50px" }}>
                    <SEO propertyAddress={item.address} propertyDescription={item.description} propertyImage={item.featuredUrl || item.url[0]}/>
                    <div style={{ paddingBottom: "20px" }} >
                      <Card style={{ padding: " 0px 20px 20px", borderRadius: "20px" }} elevation={0} varient="outlined">

                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className="info_top_desk">
                              <h3>{item.address}</h3>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="searchResult__stars2_desk">
                              <RoomIcon className="searchResult__star_desk" />
                              <p>
                                <strong>{"  " + `${item.city} ` + "  "}</strong>
                                {(item.ActiveStatus == "Active") && <Chip
                                  size="small"
                                  label={item.ActiveStatus}
                                  clickable
                                  style={{ backgroundColor: 'rgb(4, 133, 184)', color: ' white' }}
                                />}
                                {(item.ActiveStatus == "Pending") && <Chip
                                  size="small"
                                  label={item.ActiveStatus}
                                  clickable
                                  style={{ backgroundColor: 'rgb(216, 18, 18)', color: ' white' }}
                                />}
                                {(item.ActiveStatus == "Just Sold") && <Chip
                                  size="small"
                                  label={item.ActiveStatus}
                                  clickable
                                  style={{ backgroundColor: 'rgb(223 102 21)', color: ' white' }}
                                />}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div  >
                              {(() => {
                                if (item.ActiveStatus == 'Sold') {
                                  return (
                                    <p className="Pricebody_desk">
                                      <strong className="price_from_desk">
                                        Sold
                                      </strong>
                                    </p>)
                                }
                                if (item.ActiveStatus == 'Just Sold') {
                                  return (
                                    <p className="Pricebody_desk">
                                      <strong className="price_from_desk">
                                        JUST SOLD
                                      </strong>
                                    </p>)
                                }
                                if (!item.ListingPrice) {
                                  return (
                                    <p className="Pricebody_desk">
                                      <strong className="singleprice_from_desk">
                                        Not Available
                                      </strong>
                                    </p>)
                                }
                                else if (!item.startprice) {
                                  return (
                                    <div>
                                      <p className="Pricebody_desk">
                                        <div className="starting_desk">
                                          Listed Price
                                        </div>
                                        <strong className="singleprice_from_desk">
                                          <NumberFormat value={item.ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </strong>
                                      </p>
                                    </div>)
                                }
                                else {
                                  return (<div>
                                    <p className="Pricebody_desk">
                                      {/* from  */}
                                      <strong className="singleprice_from_desk">
                                        <NumberFormat value={item.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        {'  -  '}
                                        <NumberFormat value={item.endprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </strong>
                                    </p>
                                  </div>)
                                }
                              })()}
                            </div>

                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <div>
                              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link Link underline='none' color="textSecondary" component={RouterLink} to='/' >
                                  Home
                                </Link>
                                <Link underline='none' color="textSecondary" >
                                  {item.city}
                                </Link>
                                <Typography color="textPrimary">{MlsId}</Typography>
                              </Breadcrumbs>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                          <Grid item xs={6} >
                            <ImageSlide url={item.url} style={{ borderRadius: "20px 0px 0px 20px", height: 400 }} />
                          </Grid>
                          <Grid item xs={6}  >
                            {(() => {
                              if (item.url) {
                                if (item.url.length <= 6) {
                                  return <SimpleReactLightbox>
                                    <Paper elevation={0}>
                                      <Grid container spacing={2}  >
                                        <Grid item xs >
                                          {item.url[0] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 172 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={item.url[0]}
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                        <Grid item xs>
                                          {item.url[1] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 172 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={item.url[1]}
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={2}  >
                                        <Grid item xs>
                                          {item.url[2]
                                            && <SRLWrapper options={options}>
                                              <Card elevation={5} style={{ height: 172 }} >
                                                <CardMedia
                                                  style={{ height: "100%", width: "100" }}
                                                  component="img"
                                                  image={item.url[2]}
                                                  title="Paella dish"
                                                />
                                              </Card>
                                            </SRLWrapper>}
                                        </Grid>
                                        <Grid item xs>
                                          {item.url[3] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 172 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={item.url[3]}
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </SimpleReactLightbox>
                                }

                                if (item.url.length >= 7) {
                                  return <SimpleReactLightbox>
                                    <Paper elevation={0}>
                                      <Grid container spacing={2}  >
                                        <Grid item xs >
                                          {item.url[0] && <SRLWrapper options={options}>
                                            <Card elevation={5} style={{ height: 172 }} >
                                              <CardMedia
                                                style={{ height: "100%", width: "100" }}
                                                component="img"
                                                image={item.url[0]}
                                                title="Paella dish"
                                              />
                                            </Card>
                                          </SRLWrapper>}
                                        </Grid>
                                        <Grid item xs>
                                          <Grid container spacing={2} >
                                            <Grid item xs>
                                              {item.url[1] && <SRLWrapper options={options}>
                                                <Card elevation={5} style={{ height: 172 }} >
                                                  <CardMedia
                                                    style={{ height: "100%", width: "100" }}
                                                    component="img"
                                                    image={item.url[1]}
                                                    title="Paella dish"
                                                  />
                                                </Card>
                                              </SRLWrapper>}
                                            </Grid>
                                          </Grid>

                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={2}  >
                                        <Grid item xs>
                                          {item.url[2]
                                            && <SRLWrapper options={options}>
                                              <Card elevation={5} style={{ height: 172 }} >
                                                <CardMedia
                                                  style={{ height: "100%", width: "100" }}
                                                  component="img"
                                                  image={item.url[2]}
                                                  title="Paella dish"
                                                />
                                              </Card>
                                            </SRLWrapper>}
                                        </Grid>
                                        <Grid item xs>
                                          <Grid container spacing={2} >
                                            <Grid item xs>
                                              <Grid container spacing={2} direction="column" >
                                                <Grid item xs>
                                                  {item.url[3] && <SRLWrapper options={options}>
                                                    <Card elevation={5} style={{ height: 77 }} >
                                                      <CardMedia
                                                        style={{ height: "100%", width: "100" }}
                                                        component="img"
                                                        image={item.url[3]}
                                                        title="Paella dish"
                                                      />
                                                    </Card>
                                                  </SRLWrapper>}
                                                </Grid>
                                                <Grid item xs>
                                                  {item.url[4] && <SRLWrapper options={options}>
                                                    <Card elevation={5} style={{ height: 77 }} >
                                                      <CardMedia
                                                        style={{ height: "100%", width: "100" }}
                                                        component="img"
                                                        image={item.url[4]}
                                                        title="Paella dish"
                                                      />
                                                    </Card>
                                                  </SRLWrapper>}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs>
                                              <Grid container spacing={2} direction="column" >
                                                <Grid item xs>
                                                  {item.url[5] && <SRLWrapper options={options}>
                                                    <Card elevation={5} style={{ height: 77 }} >
                                                      <CardMedia
                                                        style={{ height: "100%", width: "100" }}
                                                        component="img"
                                                        image={item.url[5]}
                                                        title="Paella dish"
                                                      />
                                                    </Card>
                                                  </SRLWrapper>}
                                                </Grid>
                                                <Grid item xs>
                                                  {item.url[6] && <SRLWrapper options={options}>
                                                    <Card elevation={5} style={{ height: 77 }} >
                                                      <CardMedia
                                                        style={{ height: "100%", width: "100" }}
                                                        component="img"
                                                        image={item.url[6]}
                                                        title="Paella dish"
                                                      />
                                                    </Card>
                                                  </SRLWrapper>}
                                                </Grid>

                                              </Grid>
                                            </Grid>

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </SimpleReactLightbox>
                                }
                              }
                            })()}
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                    <Card style={{ padding: " 0px 20px 20px", borderRadius: "20px" }} elevation={0}>
                      <Grid container spacing={3}>
                        <Grid item xs={8}>
                          <Paper elevation={0}  >
                            <div className="detail_container_desk">
                              <h3>{item.address}</h3>
                              <p>{renderText()}</p>
                              <Divider />
                              <div >
                                <div >

                                  <Paper elevation={0} >
                                    <ListingsDetail
                                      Details={item.description}
                                      Bedroom={item.bedrooms}
                                      Bathroom={item.bathrooms}
                                      Kitchen={item.kitchens}
                                      Adderss={item.address}
                                      Map={item.siteurl ? <img Style=" width: 600px; height: auto" src={item.siteurl} alt={item.propertyname} /> : <img Style=" width: 600px; height: auto" src={defaultMap} alt={item.propertyname} />}
                                      siteMap={item.siteurl}
                                      community={item.community}
                                      Schools={item.schools}
                                      url={item.images}
                                      Level1={item.level1}
                                      level2={item.level2}
                                      Level3={item.level3}
                                      Builders={item.builders}
                                      Size={item.size}
                                      PlotSize={item.PlotSize}
                                      ActiveStatus={item.ActiveStatus}
                                      floorUrl={item.floorUrl}
                                      bathrooms={item.bathrooms}
                                      bedrooms={item.bedrooms}
                                      parking={item.parking}
                                      city={item.city}
                                      MlsId={MlsId}
                                      AnnualTaxes={item.AnnualTaxes}
                                      Hoa={item.Hoa}
                                      TaxYear={item.TaxYear}
                                      PropertyType={item.PropertyType}
                                      Roof={item.Roof}
                                      exterior={item.exterior}
                                      style={item.style}
                                      video={item.video}
                                      builtyear={item.builtyear}
                                      zip={item.zip}
                                    // School={extras.map(school => <li key={school}>{school}</li>)}
                                    >
                                    </ListingsDetail>
                                    {/* {this.state.mort && <Mortgage /> } */}
                                  </Paper>
                                </div>
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                        <Grid item xs={4}>
                          <Container component="main" maxWidth="md">
                            <BookConsultation />
                          </Container>
                          <Container style={{ marginTop: "8px" }} maxWidth="md" >
                            <Address />
                          </Container>
                          <ListingsCitySub props={item} city={item.city} />
                          <Cards />
                        </Grid>
                      </Grid>
                    </Card>


                  </Container>



                  {/* <Container fixed> */}
                  <div clssName="container_maindiv">
                    <div clssName="container_div">

                      <Footer />
                    </div>
                  </div>
                </Box>
              </>
            )
          })
        }
      </SimpleReactLightbox>
    </>
  )
}

export default Listingpage





