import React from 'react'
import { Button, Card, CardContent, CardMedia, Chip, Collapse, Container, Divider, FormControl, Grid, Input, InputAdornment, InputLabel, Link, OutlinedInput, Paper, Slider, Stack, Step, StepLabel, Stepper, styled, Typography, useMediaQuery } from '@mui/material'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import NumberFormat from 'react-number-format';
import { Assignment, Calculate, CalendarMonth, DoubleArrow, GroupAdd, Home, KeyboardArrowLeft, KeyboardArrowRight, Refresh, ResetTv, Send, Settings, VideoLabel } from '@mui/icons-material';
import Chart from "react-apexcharts";
import ReactApexChart from 'react-apexcharts';
import offer from '../../images/cards/offer1.png';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px'
}));


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <Home />,
        2: <Calculate />,
        3: <Assignment />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


const CustomDiv = styled('div')(({ theme }) => ({
    padding: 8,
}))

const CustomCard = styled(Card)(({ theme, isMatch }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(45deg, #430089, #82ffa1)',
    borderRadius: 15,
    maxWidth: 350,
    cursor: "pointer",
    marginTop: '1em'
}))

const CustomCard1 = styled(Card)(({ theme, isMatch }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
    borderRadius: 15,
    padding: isMatch ? '8px' : '5px',
    maxWidth: 350,
    [theme.breakpoints.up("md")]: {
        height: 80,
    },
    alignItems: "center",
    cursor: "pointer"
}))


const CustomFormControl = styled(FormControl)({
    '& label': {
        fontSize: '16px',
        color: '#333', // Label color
        marginBottom: '5px', // Spacing below label
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#aaa', // Field border color
        },
        '&:hover fieldset': {
            borderColor: '#777', // Field border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: '#555', // Field border color when focused
        },
    },
    '& .MuiInputBase-input': {
        fontSize: '14px',
        color: '#000', // Text color
        padding: '10px', // Padding inside the input field
    },
});


function calculateLoanmonthlymortgage(principal, annualInterestRate, loanTermInYears, paymentsPerYear) {
    if (
        !isNaN(principal) &&
        !isNaN(annualInterestRate) &&
        !isNaN(loanTermInYears) &&
        !isNaN(paymentsPerYear)
    ) {
        const monthlyInterestRate = annualInterestRate / (12 * 100);
        const numberOfPayments = loanTermInYears * paymentsPerYear;
        const numerator = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments);
        const denominator = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
        const monthlyPayment = -(principal * (numerator / denominator));
        return monthlyPayment.toFixed(2);
    } else {
        return '';
    }
}



const Buydown = () => {
    const isMatch = useMediaQuery('(max-width:960px )');
    const [activeStep, setActiveStep] = React.useState(0);
    const [pamount, setPamount] = React.useState(250000);
    const [mortgagePoints, setMortgagePoints] = React.useState(1.75);
    const [originalinterestRate, setOriginalInterestRate] = React.useState(5.125);
    const [numberofYear, setNumberofYear] = React.useState(30);
    const [paymentsPerYear, setPaymentsPerYear] = React.useState(12);
    const [buydownInterestRate, setBuydownInterestRate] = React.useState(4.75);
    const [loanMonthlyMortgage, setLoanMonthlyMortgage] = React.useState('');
    const [showBuydownCost, setShowBuydownCost] = React.useState(false);
    const [buydownCost, setBuydownCost] = React.useState(0);
    const [originalLoanMonthlyMortgage, setOriginalLoanMonthlyMortgage] = React.useState(0);
    const [tni, setTNI] = React.useState(0);
    const [buydownloanmonthlymortgage, setBuydownloanmonthlymortgage] = React.useState(0);
    const [spmab, setSPMAB] = React.useState(0);
    const [breakevenMonths, setBreakevenMonths] = React.useState(0);
    const [breakevenpointinyears, setBreakevenPointInYears] = React.useState(0);
    const [months, setMonths] = React.useState(0);
    const steps = ['Enter Mortgage Details', 'Perform Calculation', 'Review Mortgage Details'];

    const maxValue = 2000000;
    const maxValue1 = 10;
    const maxYear = 40;
    // const formatPercentage = (value) => {
    //     return value.toFixed(2) + '%';
    // };
    const handleMortgageChange = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setMortgagePoints(newValue);
        }
    };

    const handleInterestChange = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setOriginalInterestRate(newValue);
        }
    };

    const handleYearChange = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setNumberofYear(newValue);
        }
    };

    const handleBuydownInterestRate = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setBuydownInterestRate(newValue);
        }
    };

    const Totalnumberofinstallments = numberofYear * paymentsPerYear


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({
            top: 10,
            behavior: 'smooth'
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({
            top: 10,
            behavior: 'smooth'
        });
    };
    // const handleCalculateBuydownCost = () => {
    //     // Perform calculations for buydown cost here
    //     // For example:
    //     const differenceInRates = originalinterestRate - buydownInterestRate;
    //     const years = numberofYear;
    //     const payments = paymentsPerYear * years;
    //     const buydownCostCalculation = (differenceInRates / 100) * pamount / payments;

    //     setBuydownCost(buydownCostCalculation);
    // };
    const calculateBuydownCost = () => {
        const result = (mortgagePoints / 100) * pamount;
        const res = Math.round(result);
        setBuydownCost(res);
        setShowBuydownCost(true);
        // Calculate original loan's monthly mortgage
        const originalLoanMonthlyMortgage = calculateLoanmonthlymortgage(
            parseFloat(pamount),
            parseFloat(originalinterestRate),
            parseFloat(numberofYear),
            parseFloat(paymentsPerYear)
        );
        const originalLoanMonthlyMortgage1 = Math.abs(originalLoanMonthlyMortgage)
        setOriginalLoanMonthlyMortgage(Math.round(originalLoanMonthlyMortgage1));

        const Buydownloanmonthlymortgage = calculateLoanmonthlymortgage(
            parseFloat(pamount),
            parseFloat(buydownInterestRate),
            parseFloat(numberofYear),
            parseFloat(paymentsPerYear),
        );
        const Buydownloanmonthlymortgage1 = Math.abs(Buydownloanmonthlymortgage)
        setBuydownloanmonthlymortgage(Math.round(Buydownloanmonthlymortgage1));

        const Totalnumberofinstallments = numberofYear * paymentsPerYear
        setTNI(Math.round(Totalnumberofinstallments))

        const Savingpermonthafterbuydown = originalLoanMonthlyMortgage1 - Buydownloanmonthlymortgage1;
        const roundedResult = Number(Savingpermonthafterbuydown.toFixed(2));
        setSPMAB(roundedResult)
    };

    const Buydowncost = () => {
        const result = mortgagePoints / 100 * pamount;
        return result.toLocaleString('en-US',
            {
                style: 'decimal',
                maximumFractionDigits: 2, // Display only two decimal places
                minimumFractionDigits: 2, // Always display two decimal places });;
            });
    }

    const OriginalLoanmonthlymortgage = () => {
        const result = calculateLoanmonthlymortgage(
            parseFloat(pamount),
            parseFloat(originalinterestRate),
            parseFloat(numberofYear),
            parseFloat(paymentsPerYear),
        );
        return Math.abs(result);
    }

    const Buydownloanmonthlymortgage = () => {
        const result = calculateLoanmonthlymortgage(
            parseFloat(pamount),
            parseFloat(buydownInterestRate),
            parseFloat(numberofYear),
            parseFloat(paymentsPerYear),
        );
        return Math.abs(result);
    }

    const Savingpermonthafterbuydown = () => {
        const result = OriginalLoanmonthlymortgage() - Buydownloanmonthlymortgage();
        const roundedResult = Number(result.toFixed(2)); // Round to two decimal places
        return roundedResult.toLocaleString('en-US', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    };


    const Breakevenmonths = () => {
        const result1 = Buydowncost();
        const result2 = Savingpermonthafterbuydown();
        const dividedResult = (parseFloat(result1.replace(/,/g, '')) / parseFloat(result2.replace(/,/g, ''))).toFixed(2);
        const result = parseFloat(dividedResult).toLocaleString('en-US', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });

        return result
    }

    console.log("olm", Breakevenmonths())

    const Breakevenpointinyears = () => {
        const result1 = parseFloat(Breakevenmonths().replace(/,/g, ''));
        const result2 = 12;
        const dividedResult = result1 / result2;
        const roundedResult = Number(dividedResult.toFixed(2));
        const result = roundedResult.toLocaleString('en-US', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        return result
    }

    const breakmonths = Math.round(Breakevenmonths());
    const breakyears = Math.round(Breakevenpointinyears());
    const Savings = Math.round(Savingpermonthafterbuydown());
    const Buydownmonth = Math.round(Buydownloanmonthlymortgage());
    const Originalmortgage = Math.round(OriginalLoanmonthlymortgage());

    var data = {
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: 200,
                donut: {
                    size: '85%',
                    labels: {
                        show: true,
                        name: {
                            show: true,

                        },
                        value: {
                            show: true,

                        },
                        total: {
                            showAlways: true,
                            show: true,
                            label: "Breakeven point in years",

                            formatter: function (w) {
                                return w.globals.series.reduce((a, b, c) => {
                                    return `${a}`
                                }

                                )
                            }
                        }
                    }
                }
            }
        },
        labels: ['Breakeven point in years', 'Breakeven months', 'Saving per month after buydown', 'Buydown loan monthly mortgage', 'Original Loan monthly mortgage'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom'
                },
            },

        }]
    };


    var data1 = {
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: 300,
                donut: {
                    size: '89%',
                    labels: {
                        show: true,
                        name: {
                            show: true,

                        },
                        value: {
                            show: true,

                        },
                        total: {
                            showAlways: true,
                            show: true,
                            fontSize: '12px',
                            label: "Breakeven point in years",
                            formatter: function (w) {
                                return w.globals.series.reduce((a, b, c) => {
                                    return `${a}`
                                }

                                )
                            }
                        }
                    }
                }
            }
        },
        labels: ['Breakeven point in years', 'Breakeven months', 'Saving per month after buydown', 'Buydown loan monthly mortgage', 'Original Loan monthly mortgage'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 250,
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'start',
                },
            },

        }]
    };



    var series = [breakyears, breakmonths, Savings, Buydownmonth, Originalmortgage]

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={isMatch ? 0 : 2} sx={{ mt: '1em', p: isMatch ? '1em' : '2em' }}>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Loan Amount
                            </Typography>
                            <CurrencyTextField gutterBottom
                                id="standard-basic"
                                variant="outlined"
                                fullWidth
                                decimalPlaces="0"
                                currencySymbol="$"
                                size="small" label="Loan Amount"
                                value={pamount} onChange={(event, value) => setPamount(value)} />
                            <PrettoSlider aria-label="pretto slider" value={typeof pamount === 'number' ? pamount : 0} onChange={(event, vAmt) => { setPamount(vAmt) }} max={maxValue} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Mortgage points
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Mortgage points</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={mortgagePoints}
                                    onChange={handleMortgageChange}
                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.01,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" value={typeof mortgagePoints === 'number' ? mortgagePoints : 0} onChange={(event, vAmt) => { setMortgagePoints(vAmt) }} max={maxValue1} step={0.01} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Original Interest Rate (%)
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-amount">Interest Rate (%)</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={originalinterestRate}
                                    onChange={handleInterestChange}
                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.001,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" value={typeof originalinterestRate === 'number' ? originalinterestRate : 0} onChange={(event, vAmt) => { setOriginalInterestRate(vAmt) }} max={maxValue1} step={0.001} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Number of Years
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-amount"> Number of Years</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={numberofYear}
                                    onChange={handleYearChange}
                                    startAdornment={<InputAdornment position="start"><CalendarMonth /></InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 30,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" value={typeof numberofYear === 'number' ? numberofYear : 0} onChange={(event, vAmt) => { setNumberofYear(vAmt) }} max={maxYear} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Number of  Payments Per Year
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Payment Per Year</InputLabel>
                                <OutlinedInput
                                    label="Payment Per Year"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={paymentsPerYear}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6} sx={{ mt: isMatch ? '2em' : null }}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Buydown Rate (%)
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Buydown Rate (%)</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={buydownInterestRate}
                                    onChange={handleBuydownInterestRate}
                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.001,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" value={typeof buydownInterestRate === 'number' ? buydownInterestRate : 0} onChange={(event, vAmt) => { setBuydownInterestRate(vAmt) }} max={maxValue1} step={0.001} />
                        </Grid>
                        {/* Add similar components for interestRate and numberOfPeriods */}
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={isMatch ? 2 : 2} sx={{ m: isMatch ? '1em 0.5em 0em 0em' : '1em 0em', p: isMatch ? null : '0em 2em' }}>
                        <Grid item xs={isMatch ? 10 : 12}>
                            {
                                isMatch ?
                                <Stack sx={{ backgroundColor: '#f0f0f0', p: '1em', borderRadius: '5px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Loan Amount: $ {pamount.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Mortgage points: {mortgagePoints.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Original Interest Rate (%): {originalinterestRate.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#607D8B',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Number of Years: {numberofYear.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#607D8B',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Number of Payments : {paymentsPerYear.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#607D8B',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Buydown Rate (%): {buydownInterestRate.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    </Stack>
                                    :
                                    <Stack sx={{ backgroundColor: '#f0f0f0', p: '1em', borderRadius: '5px' }}>
                                        <Stack
                                            direction={isMatch ? "column" : "row"}
                                            divider={isMatch ? null : <Divider orientation="vertical" flexItem />}
                                            spacing={isMatch ? 1 : 2}
                                            sx={{ mt: '1em', justifyContent: isMatch ? 'none' : 'center', alignItems: 'center' }}>
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Loan Amount: $ ${pamount.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Mortgage points: ${mortgagePoints.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Original Interest Rate (%): ${originalinterestRate.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                            />
                                        </Stack>
                                        <Stack
                                            direction={isMatch ? "column" : "row"}
                                            divider={isMatch ? null : <Divider orientation="vertical" flexItem />}
                                            spacing={isMatch ? 1 : 2}
                                            sx={{ mt: '1em', justifyContent: isMatch ? 'start' : 'center', alignItems: 'center' }}>
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Number of Years: ${numberofYear.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Number of Payments Per Year: ${paymentsPerYear.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Buydown Rate (%): ${buydownInterestRate.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                            />
                                        </Stack>
                                    </Stack>
                            }

                            <Divider sx={{ mt: '2em', mb: '1em' }} flexItem />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Buydown cost
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Buydown cost ($)</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={buydownCost.toLocaleString('en-US')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Original Loan monthly mortgage
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Monthly mortgage($)</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={originalLoanMonthlyMortgage.toLocaleString('en-US')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Total number of installments
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">No.of installments</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={tni.toLocaleString('en-US')}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Buydown loan monthly mortgage
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Buydown loan($)</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={buydownloanmonthlymortgage.toLocaleString('en-US')}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Saving per month after buydown
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Savingafter buydown($)</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={spmab.toLocaleString('en-US')}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={isMatch ? 0 : 2} display="flex" justifyContent="center" alignItems="center" sx={{ pt: isMatch ? '2em' : null, m: isMatch ? '1em 0.5em 0em 0.5em' : '1em' }}>
                        <Grid item xs={isMatch ? 10 : 12}>
                            {
                                isMatch ?
                                    <Grid container spacing={2} sx={{ backgroundColor: '#f0f0f0', p: '1em', borderRadius: '5px' }}>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                Buydown loan monthly mortgage: $ {buydownloanmonthlymortgage.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                Original Loan monthly mortgage:  $ {originalLoanMonthlyMortgage.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#607D8B',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Total number of installments: {tni.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Buydown loan monthly mortgage: {buydownloanmonthlymortgage.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{
                                                    backgroundColor: '#607D8B',
                                                    color: '#fff'
                                                }}>
                                                <Typography sx={{ fontSize: '1.18em' }}>
                                                    Saving per month after buydown: $ {spmab.toLocaleString('en-US')}
                                                </Typography>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Stack sx={{ backgroundColor: '#f0f0f0', p: '1em', borderRadius: '5px' }}>
                                        <Stack
                                            direction={isMatch ? "column" : "row"}
                                            divider={isMatch ? null : <Divider orientation="vertical" flexItem />}
                                            spacing={isMatch ? 1 : 2}
                                            sx={{ mt: '1em', justifyContent: isMatch ? 'start' : 'center', alignItems: 'center' }}>
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Buydown cost: $ ${buydownCost.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Original Loan monthly mortgage: $ ${originalLoanMonthlyMortgage.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Total number of installments: ${tni.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                            />
                                        </Stack>
                                        <Stack
                                            direction={isMatch ? "column" : "row"}
                                            divider={isMatch ? null : <Divider orientation="vertical" flexItem />}
                                            spacing={isMatch ? 1 : 2}
                                            sx={{ mt: '1em', justifyContent: isMatch ? 'start' : 'center', alignItems: 'center' }}>
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Buydown loan monthly mortgage: ${buydownloanmonthlymortgage.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                            />
                                            <Chip
                                                color="info"
                                                disabled={false}
                                                size="medium"
                                                variant="elevated"
                                                label={`Saving per month after buydown: ${spmab.toLocaleString('en-US')}`}
                                                sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                            />
                                        </Stack>
                                    </Stack>
                            }

                            <Divider sx={{ mt: '2em', mb: '1em' }} flexItem />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6} >
                            <Card sx={{
                                m: isMatch ? null : '1em 2em',
                                p: isMatch ? '8px' : '1em',
                                backgroundColor: '#5faaf3',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom variant="h4" component="h1">
                                    {breakevenMonths}
                                </Typography>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom>
                                    Breakeven months
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6} >
                            <Card sx={{
                                m: isMatch ? '1em 0em' : '1em 2em',
                                p: isMatch ? '8px' : '1em',
                                backgroundColor: '#f50057',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom variant="h4" component="h1">
                                    {breakevenpointinyears} years, {months} months
                                </Typography>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom>
                                    Breakeven point in years
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                )
            default:
                return 'Unknown step';
        }
    };

    React.useEffect(() => {
        if (activeStep === 1) {
            const result = (mortgagePoints / 100) * pamount;
            const res = Math.round(result);
            setBuydownCost(res);
            setShowBuydownCost(true);
            // Calculate original loan's monthly mortgage
            const originalLoanMonthlyMortgage = calculateLoanmonthlymortgage(
                parseFloat(pamount),
                parseFloat(originalinterestRate),
                parseFloat(numberofYear),
                parseFloat(paymentsPerYear)
            );
            const originalLoanMonthlyMortgage1 = Math.abs(originalLoanMonthlyMortgage)
            setOriginalLoanMonthlyMortgage(Math.round(originalLoanMonthlyMortgage1));

            const Buydownloanmonthlymortgage = calculateLoanmonthlymortgage(
                parseFloat(pamount),
                parseFloat(buydownInterestRate),
                parseFloat(numberofYear),
                parseFloat(paymentsPerYear),
            );
            const Buydownloanmonthlymortgage1 = Math.abs(Buydownloanmonthlymortgage)
            setBuydownloanmonthlymortgage(Math.round(Buydownloanmonthlymortgage1));

            const Totalnumberofinstallments = numberofYear * paymentsPerYear
            setTNI(Math.round(Totalnumberofinstallments))

            const Savingpermonthafterbuydown = originalLoanMonthlyMortgage1 - Buydownloanmonthlymortgage1;
            const roundedResult = Number(Savingpermonthafterbuydown.toFixed(2));
            setSPMAB(roundedResult)
        }
        else if (activeStep === 2) {
            const result1 = buydownCost;
            const result2 = spmab;
            const dividedResult = (result1 / result2).toFixed(2);
            const result = parseFloat(dividedResult).toLocaleString('en-US');
            setBreakevenMonths(Math.round(result));

            const results1 = parseFloat(Math.round(result));
            const results2 = 12;
            const dividedResults = results1 / results2;
            console.log('dividedResults', dividedResults)
            // Get the integer part
            const integerPart = Math.floor(dividedResults);
            console.log('integerPart', integerPart)
            // Get the decimal part
            const remainderResult = results1 % results2;
            console.log('months', remainderResult)
            // Format parts separately
            const integerFormatted = integerPart.toLocaleString('en-US');
            // const decimalFormatted = parseFloat(decimalPart).toLocaleString('en-US', { minimumFractionDigits: 1 }).split('.')[1];
            // console.log('months1', decimalFormatted)
            // Set your state or use the parts as needed
            setBreakevenPointInYears(integerFormatted);
            setMonths(remainderResult)
            // const results1 = parseFloat(breakevenMonths);
            // const results2 = 12;
            // const dividedResults = results1 / results2;
            // const roundedResults = Number(dividedResults.toFixed(2));
            // const results = roundedResults.toLocaleString('en-US');
            // setBreakevenPointInYears(results);
        }
    }, [activeStep, mortgagePoints, pamount, originalinterestRate, numberofYear, paymentsPerYear, buydownInterestRate, breakevenMonths, breakevenpointinyears]);

    return (
        <div>
            <Card elevation={0} >
                <Container maxWidth="xl">
                    <Grid container spacing={1} sx={{ mt: '2em' }}>
                        <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography id="disabled-slider" gutterBottom variant="h4" component="h1">
                                Buydown Calculator
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: '1em' }} />
                </Container>
                <Grid container spacing={2} sx={{ p: isMatch ? 0 : '1em 6em' }}>
                    <Grid item xs={12} sx={{ mt: isMatch ? '1em' : null }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    <Grid item xs={isMatch ? 12 : 8}>
                        <Paper
                            sx={{
                                p: isMatch ? '0em 0em 2em 0em' : '0em 3em 2em 0em',
                                mb: '1em',
                                m: isMatch ? '1em' : null,
                                // backgroundColor: '#5faaf3', // Change the background color to your preference
                                borderRadius: '8px', // Add border-radius for a rounded look
                            }}
                        >
                            {activeStep === 2 ? (
                                <>
                                    <>
                                        {getStepContent(activeStep)}
                                    </>
                                    <Grid container spacing={2} sx={{ m: '1em' }} >
                                        <Grid item xs={isMatch ? 10 : 12} display="flex" justifyContent="center" alignItems="center">
                                            <Button
                                                variant='contained'
                                                color="success"
                                                onClick={() => setActiveStep(0)}
                                                sx={{
                                                    fontSize: '1em',
                                                    fontWeight: 400,
                                                    textTransform: 'capitalize'
                                                }}
                                                endIcon={<Refresh />}
                                            >Reset</Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid container spacing={2} sx={{ m: '1em' }}>
                                    {getStepContent(activeStep)}
                                    {(activeStep === 0) ?
                                        null
                                        :
                                        <Grid item xs={isMatch ? 4 : 6} display="flex" justifyContent="start" alignItems="center" sx={{mt: '1em'}}>
                                            <Button
                                                onClick={handleBack}
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    fontSize: '1em',
                                                    fontWeight: 400,
                                                    textTransform: 'capitalize'
                                                }}
                                                startIcon={<KeyboardArrowLeft />}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={(activeStep === 0) ? isMatch ? 10 : 11 : 6} display="flex" justifyContent="end" alignItems="center" sx={{mt: activeStep === 0 ? null : '1em' }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{
                                                fontSize: '1em',
                                                fontWeight: 400,
                                                textTransform: 'capitalize',
                                                ml: isMatch ? null : '3em'
                                            }}
                                            endIcon={activeStep === 1 ? <Send /> : <KeyboardArrowRight />}
                                        >
                                            {activeStep === 1 ? 'Calculate' : 'Next'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={isMatch ? 12 : 4}>
                        <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                            <Grid item xs={12} direction="row">
                                <CustomDiv>
                                    <Link href="/Contact-US" style={{ textDecoration: "NONE" }}>
                                        <CustomCard elevation={5}>
                                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                <CardContent sx={{ flex: '1 0 auto', }}>
                                                    <Typography component="h5" variant="h5" sx={{ color: "white" }}>
                                                        Want To Know Offer Review Dates?
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color: "white" }}>
                                                        Find Out Now <DoubleArrow />
                                                    </Typography>
                                                </CardContent>
                                            </div>
                                            <CardMedia
                                                sx={{ width: 151, }}
                                                image={offer}
                                                title="Live from space album cover"
                                            />
                                        </CustomCard>
                                    </Link>
                                </CustomDiv>
                            </Grid>
                            <Grid item xs={12} direction="row" sx={{ mb: '1em' }}>
                                <CustomDiv>
                                    <Link href="/Last-Solds" style={{ textDecoration: "NONE" }}>
                                        <CustomCard1 elevation={5} isMatch={isMatch} >
                                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                <Typography component="h6" variant="h6" sx={{ color: "white" }}>
                                                    Know what We have Sold So Far <DoubleArrow />
                                                </Typography>
                                            </div>
                                        </CustomCard1>
                                    </Link>
                                </CustomDiv>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Card>
        </div >
    )
}

export default Buydown