import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import ListingsDailog from '../../contact/ListingsDailog';
import { DoubleArrow, MonetizationOn } from '@mui/icons-material';


const CustomContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    // // minWidth:140
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  [theme.breakpoints.down("md")]: {
    // minWidth:140
    padding: 0
  },
  padding: '1em 0em',
}))

const CustomDiv = styled('div')(({ theme }) => ({
  padding: 8,
}))

const CustomTypography = styled(Typography)(({ theme, isMatch }) => ({
  color: "white",
  fontSize: isMatch ? "1.054rem" : "1.2rem",
  padding: "10px",
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.99rem",
  },
}))

const CustomCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: ' linear-gradient(110deg, #9756da, #82ffa8, #238bd5, #2f74a5)',
  backgroundSize: '400% 400%',
  animation: 'change 10s ease-in-out infinite',
  borderRadius: 15,
  padding: 10,
  width: '100%',
  [theme.breakpoints.up("md")]: {
    height: 80,
  },
  alignItems: "center",
  '@keyframes change': {
    '0%': {
      backgroundPosition: ' 0 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    ' 100%': {
      backgroundPosition: '0 50%'
    },
  }
}))

const CustomCard1 = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
  borderRadius: 15,
  padding: 10,
  width: '100%',
  [theme.breakpoints.up("md")]: {
    height: 80,
  },
  alignItems: "center",
  cursor: "pointer"
}))

const CustomCard2 = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: 'linear-gradient(45deg, #f59301fc, #f70000)',
  borderRadius: 15,
  padding: 10,
  width: '100%',
  [theme.breakpoints.up("md")]: {
    height: 80,
  },
  alignItems: "center",
  cursor: "pointer"
}))

const CustomCard3 = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: 'linear-gradient(45deg, #00416A, #799F0C, #FFE000)',
  borderRadius: 15,
  padding: 10,
  width: '100%',
  [theme.breakpoints.up("md")]: {
    height: 80,
  },
  alignItems: "center",
  cursor: "pointer"
}))


function AvaliableCards() {
  const match = window.location.pathname.split('/')[1] === 'Our-Listings' ? true : false;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const isMatch = useMediaQuery('(max-width:960px )')

  const handleClickOpen = () => {
    setOpen(true);
    // setOpen1(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomContainer maxWidth="md">
        <Grid container justify="center" alignItems="stretch">
          <Grid>
            <Grid container>
              <Grid item xs={isMatch ? 6 : 12} sm={isMatch ? 6 : 3}>
                <CustomDiv>
                  <Link to="/Contact-US" style={{ textDecoration: "NONE" }}>
                    <CustomCard elevation={5}>
                      <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <CustomTypography component="h5" variant="h5" isMatch={isMatch}>
                          Want To Know Offer Review Dates?  Find Out Now  <DoubleArrow />
                        </CustomTypography>
                      </div>
                    </CustomCard>
                  </Link>
                </CustomDiv>
              </Grid>
              <Grid item xs={isMatch ? 6 : 12} sm={isMatch ? 6 : 3}>
                <CustomDiv>
                  <Link to="/Last-Solds" style={{ textDecoration: "NONE" }}>
                    <CustomCard1 elevation={5}>
                      <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <CustomTypography component="h5" variant="h5" isMatch={isMatch}>
                          Know what We have Sold So Far <DoubleArrow />
                        </CustomTypography>
                      </div>
                    </CustomCard1>
                  </Link>
                </CustomDiv>
              </Grid>
              <Grid item xs={isMatch ? 6 : 12} sm={isMatch ? 6 : 3} >
                <CustomDiv>
                  {(
                    match === true ?
                      <div >
                        <CustomCard3 elevation={5} onClick={handleClickOpen}>
                          <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <CustomTypography component="h5" variant="h5" isMatch={isMatch}>
                              Contact Deepti for Listing <DoubleArrow />
                            </CustomTypography>
                          </div>
                        </CustomCard3>
                        <ListingsDailog Open={open} Close={handleClose} />
                      </div>
                      :
                      <Link to="/ListingsbyDeepti" style={{ textDecoration: "NONE" }}>
                        <CustomCard3 elevation={5}>
                          <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <CustomTypography component="h5" variant="h5" isMatch={isMatch}>
                              Past sold listings by Deepti  <DoubleArrow />
                            </CustomTypography>
                          </div>
                        </CustomCard3>
                      </Link>
                  )}
                </CustomDiv>
              </Grid>
              <Grid item xs={isMatch ? 6 : 12} sm={isMatch ? 6 : 3}>
                <CustomDiv>
                  <Link to="/Mortgage" style={{ textDecoration: "NONE" }}>
                    <CustomCard2 elevation={5}>
                      <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <CustomTypography component="h5" variant="h5" isMatch={isMatch}>
                          Use Our Mortgage Calculator <MonetizationOn />
                        </CustomTypography>
                      </div>
                    </CustomCard2>
                  </Link>
                </CustomDiv>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </>
  );
}
export default AvaliableCards

