import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC984fKTY4HLKv-aXdiDDBza9j3zHPG_ZQ",
    authDomain: "dgupta-homes.firebaseapp.com",
    databaseURL: "https://dgupta-homes.firebaseio.com",
    projectId: "dgupta-homes",
    storageBucket: "dgupta-homes.appspot.com",
    messagingSenderId: "604200176792",
    appId: "1:604200176792:web:fd765026e85074b22cd4ed"
  };
  
  // Initialize Firebase
//  firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig)
 
//   fireDB = firebase.database().ref();
//  export default {  fireDB  }
 export default firebase


 
// var fireDB = firebase.initializeApp(firebaseConfig)
 

//  export default fireDB.database().ref();