import React from 'react'
import { Box, ButtonBase, Card, Container, Divider, Grid, Link, Paper, Stack, styled, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import banner22 from '../images/Bannermob13.png';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import NumberFormat from 'react-number-format';
import logoS3 from '../images/logoS.1.png'
import logoS2 from '../images/logoS.2.png'
import Footer from '../components/Footer';
import { LocationOn, Mail, Phone, Policy, Web } from '@mui/icons-material';
import Loading from '../components/Loading';

const StyledPaper = styled(Paper)(({ theme, isMatch }) => ({
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#00102e',
        color: theme.palette.common.white,
        padding: '10px',
        display: 'table-cell',
        flexDirection: 'column',
        fontSize: '15px',
        fontWeight: 500
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        border: '1px solid #e0e0e0'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: '1px solid #e0e0e0',
    },
}));

const StyledTypo = styled('span')(({ theme, isMatch }) => ({
    fontSize: isMatch ? '16px' : '17px',
    fontWeight: 500,
    color: '#00102e',
    margin: '5px 10px 0em 0em',
}));

const StyledTypo1 = styled(Typography)(({ theme, isMatch }) => ({
    fontSize: isMatch ? '15px' : '17px',
    color: '#00102e',
}));

const StyledLink = styled(Link)(({ theme, isMatch }) => ({
    fontSize: isMatch ? '16px' : '18px',
    fontWeight: 500,
    textDecoration: 'none',
    color: '#1080a2',
    marginLeft: isMatch ? '1em' : 'none',
    '&:hover': {
        color: '#91bdfa',
    },
}));

function createData(calories, fat, carbs, protein) {
    return { calories, fat, carbs, protein };
}

const rows = [
    createData(800000, 28000, 48000, 20000),
    createData(1000000, 35000, 60000, 25000),
    createData(1200000, 42000, 72000, 30000),
    createData(1500000, 52000, 90000, 37500),
    createData(2000000, 70000, 120000, 50000)
];



// React.useEffect(() => {
//     // Simulate a delay of 2 seconds (adjust as needed)
//     const delay = 2000;

//     const timer = setTimeout(() => {
//       // Set loading to false after the delay
//       setLoading(false);
//     }, delay);

//     // Clear the timer if the component unmounts
//     return <Loading/>;
//   }, []); 

const OurDetails = () => {
    const isMatch = useMediaQuery('(max-width:960px )')
    return (
        <div>
            <StyledPaper isMatch={isMatch}>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ m: isMatch ? '6em' : 'none', mt: isMatch ? '9em' : 'none' }}>
                    <Typography sx={{
                        fontSize: isMatch ? '2.5em' : '5em',
                        fontWeight: '500',
                        color: '#fff',
                        marginBottom: isMatch ? '0em' : '2em'
                    }}>Our Details</Typography>
                </Grid>
            </StyledPaper>
            <Box display={{ xs: 'block', md: 'block' }} m={1}>
                <Container maxWidth="lg" sx={{ padding: isMatch ? '1em' : '3em 0em' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} spacing={1}>
                            <Typography
                                sx={{
                                    fontSize: isMatch ? '1.7em' : '3em',
                                    fontWeight: 500,
                                    color: '#00102e'
                                }}
                            >
                                1% Listing Offering
                            </Typography>
                            <Typography gutterBottom sx={{ fontSize: isMatch ? '15px' : '17px', mt: '10px' }}>
                                At <Link href="https://www.homewastate.com/"
                                    sx={{
                                        color: '#ff4081',
                                        fontWeight: 400,
                                        textDecorationColor: '#ff4081'
                                    }}>
                                    Dgupta Gupta Real Estate
                                </Link>, we understand that selling your home is a significant decision, and we're committed to making the process as seamless and cost-effective as possible. That's why we are excited to introduce our exclusive 1% Listing Offering, designed to save you money while providing you with top-notch real estate services
                            </Typography>
                        </Grid>

                        <Grid item xs={12} spacing={2}>
                            <StyledTypo isMatch={isMatch} >Why Choose Our 1% Listing Offering?</StyledTypo>
                            <Grid item xs={12} spacing={2} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }} >
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>1. Expert Guidance:</StyledTypo>
                                    When you choose our 1% Listing Offering, you don't have to compromise on the quality of service. Our experienced real estate professionals will guide you through the entire selling process, from pricing your property right to negotiating the best deal.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={2} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>2. Cost Savings:</StyledTypo>
                                    Traditional real estate commissions can take a substantial chunk out of your sale proceeds. With our 1% Listing Offering, you can keep more money in your pocket, putting it towards your next home or other important investments.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={2} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>3. Comprehensive Marketing:</StyledTypo>
                                    Your property will benefit from our full-scale marketing strategy, including professional photography, virtual tours, online listings, and more. We'll ensure that your home gets the attention it deserves.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={2} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo>4. Local Expertise:</StyledTypo>
                                    We know your neighborhood like the back of our hand. Our local expertise helps us price your property accurately and market it to potential buyers who are specifically looking for homes in your area.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={2} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>5. Open Communication:</StyledTypo>
                                    We believe in transparency and open communication. You'll always be in the loop, receiving regular updates and feedback on the progress of your sale.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={2} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>6. Dedicated Support:</StyledTypo>
                                    Our team is dedicated to your success. We'll be there to answer your questions, address your concerns, and provide the support you need at every step of the selling process.
                                </StyledTypo1>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={1} >
                            <StyledTypo1 gutterBottom isMatch={isMatch}>
                                <StyledTypo isMatch={isMatch}>How It Works?</StyledTypo>
                            </StyledTypo1>
                            <Grid item xs={12} spacing={1} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>1. Get in Touch:</StyledTypo>
                                    Contact us to schedule a consultation. We'll meet with you to discuss your property and your goals.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={1} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>2. Agree on Terms:</StyledTypo>
                                    If you're happy with what you hear, we'll agree on the terms of our 1% Listing Offering.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={1} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>3. List Your Property:</StyledTypo>
                                    We'll start the marketing and listing process, making your property visible to potential buyers.

                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={1} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch}>
                                    <StyledTypo isMatch={isMatch}>4. Showings and Negotiations:</StyledTypo>
                                    We'll handle showings and negotiate on your behalf to secure the best possible deal.
                                </StyledTypo1>
                            </Grid>
                            <Grid item xs={12} spacing={1} sx={{ m: isMatch ? '1em 0em 1em 1.5em' : '1em 0em 1em 4em' }}>
                                <StyledTypo1 gutterBottom isMatch={isMatch} >
                                    <StyledTypo isMatch={isMatch}>5. Close the Sale:</StyledTypo>
                                    We'll guide you through the closing process, ensuring a smooth and successful transaction.
                                </StyledTypo1>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" isMatch={isMatch}>
                                                <Typography sx={{ fontSize: isMatch ? '12px' : 'none' }}>Home Sold Price</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Typography >Our Commission</Typography>
                                                <Typography >3.5% (1% selling side, 2.5% buying side)</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Typography>Other Listing Agent Commission</Typography>
                                                <Typography>6% (3% selling side, 3% buying side)</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Typography>Your savings with</Typography>
                                                <Typography>Dgupta Gupta Real Estate</Typography>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ border: '1px solid #e0e0e0' }}>
                                        {rows.map((row) => (
                                            <StyledTableRow >
                                                <StyledTableCell align="center">
                                                    <NumberFormat value={row.calories} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <NumberFormat value={row.fat} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <NumberFormat value={row.carbs} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    <NumberFormat value={row.protein} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} spacing={1} sx={{m : '1em'}}>
                            <StyledTypo1 gutterBottom isMatch={isMatch}>
                                Don't let high commission fees eat into your home's equity. Choose
                                <Link href="https://www.homewastate.com/"
                                    sx={{
                                        color: '#ff4081',
                                        fontWeight: 400,
                                        textDecorationColor: '#ff4081',
                                        m: '15px 10px'
                                    }}>
                                    Dgupta Gupta Real Estate
                                </Link>
                                and take advantage of our 1% Listing Offering today. You deserve the best value without sacrificing quality service.
                            </StyledTypo1>
                        </Grid>
                        <Card elevation={0.5} sx={{ m: isMatch ? '1em 0em 1em 1em' : '1em', width: '100%', p: isMatch ? '1em' : '2em', backgroundColor: '#f5f5f5' }}>
                            <StyledTypo sx={{ fontSize: '20px', color: '#00102e' }}>Contact us</StyledTypo>
                            <Divider sx={{mt: '1em'}}/>
                            <Grid container spacing={1}>
                                
                                {
                                    isMatch ?
                                        <Grid item xs={isMatch ? 12 : 3} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent="center" alignItems="center">
                                            <img style={{ width: '200px' }} src={logoS3} alt="HomesWaState" />
                                        </Grid>
                                        :
                                        <Grid item xs={isMatch ? 12 : 3} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent="center" alignItems="center">
                                            <img style={{ width: '200px' }} src={logoS3} alt="HomesWaState" />
                                            <StyledTypo1 gutterBottom >
                                                <StyledLink href="https://www.homewastate.com/" isMatch={isMatch}>
                                                    Dgupta Gupta Real Estate
                                                </StyledLink>
                                            </StyledTypo1>
                                        </Grid>
                                }

                                <Grid item xs={isMatch ? 12 : 3} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent={isMatch ? "start" : "center"} alignItems="center">
                                    <StyledLink href="tel:425-588-8470" >
                                        <ButtonBase disableRipple>
                                            <Phone sx={{ color: '#585858', height: isMatch ? '50px' : '100px', fontSize: isMatch ? '2em' : '2.8em', }} />
                                        </ButtonBase>
                                    </StyledLink>
                                    <StyledLink href="tel:425-588-8470" isMatch={isMatch} >
                                        425-588-8470
                                    </StyledLink>
                                    {/* <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">

                            </Link> */}
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 3} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent={isMatch ? "start" : "center"} alignItems="center">
                                    <StyledLink href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact" >
                                        <ButtonBase disableRipple>
                                            <Mail sx={{ color: '#585858', height: isMatch ? '50px' : '100px', fontSize: isMatch ? '2em' : '2.8em', }} />
                                        </ButtonBase>
                                    </StyledLink>
                                    <StyledLink href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact" isMatch={isMatch}>
                                        dguptahomes@gmail.com
                                    </StyledLink>
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 3} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent={isMatch ? "start" : "center"} alignItems="center">
                                    <StyledLink href="https://www.homewastate.com/" >
                                        <ButtonBase disableRipple>
                                            <Web sx={{ color: '#585858', height: isMatch ? '50px' : '100px', fontSize: isMatch ? '2em' : '2.8em', }} />
                                        </ButtonBase>
                                    </StyledLink>
                                    <StyledLink href="https://www.homewastate.com/" isMatch={isMatch}>
                                        HomeWAState.com
                                    </StyledLink>
                                </Grid>
                                {/* <Grid item xs={isMatch ? 12 : 3} spacing={1} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent={isMatch ? "start" : "center"} alignItems="center">
                                    <ButtonBase disableRipple>
                                        <LocationOn sx={{ color: '#585858', height: isMatch ? '50px' : '100px', fontSize: isMatch ? '2em' : '2.8em', }} />
                                    </ButtonBase>
                                    <StyledTypo1 gutterBottom isMatch={isMatch} sx={{marginLeft: isMatch ? '1em' : 'none',}}>[Your Office Address]</StyledTypo1>
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 3} spacing={1} display="flex" flexDirection={isMatch ? "row" : "column"} justifyContent={isMatch ? "start" : "center"} alignItems="center">
                                    <ButtonBase disableRipple>
                                        <Policy sx={{ color: '#585858', height: isMatch ? '50px' : '100px', fontSize: isMatch ? '2em' : '2.8em', }} />
                                    </ButtonBase>
                                    <StyledTypo1 gutterBottom isMatch={isMatch} sx={{marginLeft: isMatch ? '1em' : 'none',}}>[Your Real Estate License Number]</StyledTypo1>
                                </Grid> */}
                                <Grid item xs={12} spacing={1}><Divider sx={{ m: '1em 0em' }} /></Grid>
                                <Grid item xs={12} spacing={1}>
                                    <StyledTypo1 gutterBottom isMatch={isMatch} sx={{ mt: isMatch ? '1em' : '2em', fontSize: '16px', fontWeight: 500 }}>*Subject to terms and conditions. The 1% Listing Offering may not apply to all properties. Contact us for more details and to see if your property qualifies.</StyledTypo1>
                                </Grid>
                            </Grid>
                        </Card>


                    </Grid>
                </Container>

            </Box >
            <Footer />
        </div >
    )
}

export default OurDetails