import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import ExploreIcon from '@material-ui/icons/Explore';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import TransferWithinAStationRoundedIcon from '@material-ui/icons/TransferWithinAStationRounded';
import Divider from '@material-ui/core/Divider';
import './ImagePopup.css';
import white from '../images/Image_not_available.png'
import nwmlslogo from '../images/nwmls_small.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import SimpleReactLightbox from "simple-react-lightbox";
import Availability from './Availability';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import { SRLWrapper } from "simple-react-lightbox";
import CardContent from '@material-ui/core/CardContent';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import ReactReadMoreReadLess from "react-read-more-read-less"
import { Avatar, Button, IconButton, Stack, styled, Tab, Tabs } from '@mui/material';
import ScoopPannel from '../admin/ScoopPannel';
import { CalendarMonth, Edit, LocationOn } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import ScoopCard from './ScoopCard';
import firebase from '../firebase/firebase'

const Zillow = require("node-zillow")
var convert = require('xml-js');
const parser = require('xml2js');

// 

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: 500,
    background: 'red',
    borderRadius: '30px'
  },
  h5: {
    padding: 0,
  },
  tabcolour: {
    background: 'red',
  },
  text: {
    fontWeight: 800,
    fontSize: '12px',
    color: ' #4b4b4b',
    minWidth: 140,
    maxWidth: 150,
  },
  h7: {
    fontSize: 16,
    lineHeight: 2,

  },
  chip: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '1em',
    '& > *': {
      margin: theme.spacing(0.9),
    },
  },
  chiptext: {
    fontSize: 12,
    fontWeight: 500,
    paddingRight: 9,
    paddingLeft: 9,
  },
  Typography: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
  body: {
    marginTop: '1em',
    flex: 1,
    textAlign: 'justify',
    fontSize: '15px',
    fontWeight: '400',

  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  background: '#e0e0e0',
  margin: '1em',
  borderRadius: '7px',
  padding: '5px',
  '& .Mui-selected': {
    color: '#f50057'
  },
  '& .MuiTabs-indicator': {
    background: '#f50057',
    color: '#e0e0e0'
  }
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  color: 'black',
  fontWeight: '500'
}))

function Floor(props) {
  const classes = useStyles();
  var str = props.floorUrl
  var str1 = str.split("2F")[1];
  var str2 = str1.split(/.jpg|.png/)[0].replaceAll("%20", " ")
  var str3 = (str.includes("_floorUrl_")) ? str2.split("_floorUrl_")[1] : str2
  // var str3 = str.split("_floorUrl_")[1];
  // var str3 = (9 !==str1) ? str2.slice(9) : str2
  // var filename = str.split('/').pop().split('#')[0].split('?')[0];
  // var encoded = window.encodeURIComponent(props.floorUrl)
  console.log("floor", str)
  return (
    <div>

      <Grid item key={str} style={{ "padding": "10px" }}  >
        <Card elevation={4} spacing={2} style={{ width: 290 }}>
          <CardActionArea>
            <div className={classes.imageBody}>
              <div className="containerport">
                <CardMedia className="imageee"
                  className={classes.mediaSmall}
                  component="img"
                  image={props.floorUrl} alt={str}
                  title="Live from space album cover"
                />
                <div className="middle" style={{ top: '42%' }}>
                  <div className="textwrap">Click to view</div>
                </div>
              </div>
            </div>
            <CardContent>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography className="mobav_title">
                      <h2>{str3}</h2>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </div>
  )

}

function SiteMap(props) {
  const classes = useStyles();
  var str = props.SiteMap

  return (
    <div>

      <Grid item key={str} style={{ "padding": "10px" }}  >
        <Card elevation={4} spacing={2} style={{ width: 290 }} className="containerport">
          <CardActionArea>
            <div className={classes.imageBody}>
              {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
              <CardMedia className="imageee"
                className={classes.mediaSmall}
                component="img"
                image={props.SiteMap} alt={props.propertyname}
                title="Live from space album cover"
              />
              <div className="middle">
                <div className="textwrap">Click to view</div>
              </div>
            </div>
          </CardActionArea>
        </Card>
      </Grid>

    </div>
  )

}


function DetailData(props) {
  const classes = useStyles();
  var fireDB = firebase.database().ref();
  const [propertyData, setPropertyData] = React.useState([]);
  const [scoopData, setScoopData] = React.useState([]);
  const [value, setValue] = React.useState('one');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [elementryschooldata, setElementryschooldata] = useState([])
  const [middleschooldata, setMiddleschooldata] = useState([])
  const [highschooldata, setHighschooldata] = useState([])
  const [zillowdata, setZillowdata] = useState([])
  const DetailScrolldiv = React.useRef(null)
  const AvaliableScrolldiv = React.useRef(null)
  const SchoolScrolldiv = React.useRef(null)
  const SitemapScrolldiv = React.useRef(null)
  const FloorScrolldiv = React.useRef(null)
  const ScoopScrolldiv = React.useRef(null)
  const zillow = new Zillow('X1-ZWz1i39n9du1or_8i7yl')
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openAv, setOpenAV] = React.useState(true);
  const [openSc, setOpenSc] = React.useState(true);
  const [openSmap, setOpenSmap] = React.useState(true);
  const [openFloor, setOpenFloor] = React.useState(true);
  const [openScoop, setOpenScoop] = React.useState(true);
  const [user, setUser] = useState(null)
  const AuthStr = 'Bearer'.concat(`hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`);


  const DetailScroll = () => DetailScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const AvaliableScroll = () => AvaliableScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const SchoolScroll = () => SchoolScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const SitemapScroll = () => SitemapScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const FloorScroll = () => FloorScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const ScoopScroll = () => ScoopScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  const options = {
    icons: {
      downloadIcon: null,
    },
    buttons: {
      showDownloadButton: false
    }

  }
  const options2 = {
    icons: {
      downloadIcon: null,
    },
    buttons: {
      showDownloadButton: false
    },
    thumbnails: {
      showThumbnails: false
    }
  }

  const config = {
    headers: { 'Authorization': 'Bearer hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4' }
  };

  const styles = theme => ({
    listItemText: {
      fontSize: '1.2em',//Insert your required size
    }
  });
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAva = () => {
    setOpenAV(!openAv);
  };
  const handleClickSchool = () => {
    setOpenSc(!openSc);
  };
  const handleClickSitemap = () => {
    setOpenSmap(!openSmap);
  };
  const handleClickFloor = () => {
    setOpenFloor(!openFloor);
  };
  const handleClickScoop = () => {
    setOpenScoop(!openScoop);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (props.index == true) {

  }

  useEffect(() => {
    try {
      Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?state=wa&city=${props.city}&district_name=${props.Schools}&level_codes=e&school_type=(public or charter)&name=${props.Level1}&limit=25`,
        {
          headers: {
            'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
          }
        })
        .then(data => {
          setElementryschooldata(data.data.schools);
          console.log("elem", data)
        })


      Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?state=wa&city=${props.city}&district_name=${props.Schools}&level_codes=m&name=${props.level2}&limit=25`, {
        headers: {
          'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
        }
      })
        .then(data => {
          setMiddleschooldata(data.data.schools);
          console.log("middle", data)
        })

      Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?state=wa&city=${props.city}&district_name=${props.Schools}&name=${props.Level3}&level_codes=h&limit=25`, {
        headers: {
          'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
        }
      })
        .then(data => {
          setHighschooldata(data.data.schools);
          console.log("high", data)
        })

    }
    catch (err) {
      console.log(console.err)
    }


  }, [])

  const {
    title,
    city,
    children,
    Schools,
    Level1,
    level2,
    Level3,
    Builders,
    Details,
    Bedroom,
    Bathroom,
    Kitchen,
    Adderss,
    floorUrl,
    Map,
    ActiveStatus,
    url,
    siteMap,
    propertyname,
    community,
    Agent1,
    Agent2,
    OFFMarketDate,
    Source,
    status,
    startprice,
    Scoop,
    wishlist,
    index, ...other } = props;
  //  console.log("item2",siteMap.length)

  const wishlistData = wishlist !== null && typeof wishlist === 'object'
    ? Object.keys(wishlist).map((id) => wishlist[id])
    : null;
  // console.log('wishLists', wishlistData.map((item) => item.email));
  const schoolSearch = [Level1];
  const schoolMidd = [level2];
  const schoolHigh = [Level3]

  let elemschool = schoolSearch.map(item => {
    const obj = elementryschooldata.find(o => o.name === item) || { ...item }
    return { ...item, name: obj.name, rating: obj.rating }

  });
  console.log('elementary', elemschool)


  let middschool = schoolMidd.map(item => {
    const obj = middleschooldata.find(o => o.name === item) || { ...item }
    return { ...item, name: obj.name, rating: obj.rating }

  });

  console.log('elementary', middschool)

  let highschool = schoolHigh.map(item => {
    const obj = highschooldata.find(o => o.name === item) || { ...item }
    return { ...item, name: obj.name, rating: obj.rating }

  });

  console.log('elementary', highschool)

  React.useEffect(() => {
    fireDB.child('properties').on('value', snapshot => {
      if (snapshot.val() != null)
        setPropertyData({
          ...snapshot.val()
        })
      else
        setPropertyData({})
    })
  }, [])

  const keys = Object.keys(propertyData).filter((key) => propertyData[key].address === Adderss);
  const propertyPath = `properties/${keys}/scoop`;


  const addCardToFirebase = (newCard) => {
    const propertyPath = `properties/${keys}`;
    // Create a reference to the Firebase database location

    fireDB.child(`${propertyPath}/scoop`).push(newCard);
  }

  const EditCardToFirebase = (newCard, id) => {
    const propertyPath = `properties/${keys}`;
    // Create a reference to the Firebase database location

    fireDB.child(`${propertyPath}/scoop/${id}`).update(newCard);
  }


  React.useEffect(() => {
    const dataRef = fireDB.child(`${propertyPath}`);
    dataRef.on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const dataArray = Object.values(data);
      setScoopData(dataArray);
    });

    return () => {
      // Unsubscribe from the Firebase database when the component unmounts
      dataRef.off();
    };
  }, [propertyPath]);

  // console.log('key', Object.keys(propertyData).filter((key) => propertyData[key].propertyname === propertyname))
  // // const singleID = scoopData.map((item) => item)
  console.log('pn', scoopData)


  React.useEffect(() => {
    // Listen for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const allowedEmail = 'dguptahomes.web@gmail.com';


  return (

    <div className={classes.root} >
      <Paper elevation={0} >
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="disabled tabs example"
        >
          <StyledTab label="Details" icon={<HomeWorkRoundedIcon />}
            value="one" onClick={DetailScroll}
            className={classes.text} {...a11yProps('one')}
          />
          <Divider orientation='vertical' style={{ height: 'auto', margin: '0em 1em' }} />
          {/* <Tab label="Availablity" icon={<TransferWithinAStationRoundedIcon />} value="two" onClick={AvaliableScroll}
            className={classes.text} {...a11yProps('two')} /> */}
          <StyledTab label="School" icon={<SchoolRoundedIcon />} value="two" onClick={SchoolScroll}
            className={classes.text} {...a11yProps('two')} />
          <Divider orientation='vertical' style={{ height: 'auto', margin: '0em 1em' }} />
          <StyledTab label="Community Map" icon={<ExploreIcon />} value="three" onClick={SitemapScroll}
            className={classes.text} {...a11yProps('three')} />
          {(() => {
            if (floorUrl != undefined && floorUrl != "")
              return <Divider orientation='vertical' style={{ height: 'auto', margin: '0em 1em' }} />
          })()}
          {(() => {
            if (floorUrl != undefined && floorUrl != "")
              return <StyledTab label="Floor Plans" icon={<DashboardIcon />} value='four'
                className={classes.text} onClick={FloorScroll} {...a11yProps('four')} />
          })()}
          <Divider orientation='vertical' style={{ height: 'auto', margin: '0em 1em' }} />
          <StyledTab label="Inside scoop" icon={<ExploreIcon />} value={floorUrl ? "five" : "four"} onClick={ScoopScroll}
            className={classes.text} {...a11yProps(floorUrl ? "five" : "four")} />
        </StyledTabs>
        <Divider />
        <div ref={DetailScrolldiv}>
          <div className={classes.chip}>
            {/* <h5>Highlights : </h5> */}
            <Chip
              icon={<OpenWithRoundedIcon />}
              label={`Size : ${props.Size} Sq.ft`}
              className={classes.chiptext}
            />
            <Chip
              icon={<LocalHotelIcon />}
              label={`Beds : ${props.bedrooms}`}
              className={classes.chiptext}
            />
            <Chip
              icon={<BathtubIcon />}
              label={`Baths : ${props.bathrooms}`}
              className={classes.chiptext}
            />
            <Chip
              icon={<DirectionsCarRoundedIcon />}
              label={`Bay Garage : ${props.parking}`}
              className={classes.chiptext}
            />
          </div>
          <div className="detail_font">
            <h5>Details</h5>
            <h6>{props.Details} </h6>
            {/* <h6>
              <ReactReadMoreReadLess
                readMoreClassName="reactMoreClassName"
                charLimit={300}
                readMoreText={<Stack sx={{width: '18%'}} align="start"><Button color="primary" variant="text" disableFocusRipple sx={{textTransform: 'Capitalize', fontSize: '14px',}}>Continue Reading<ExpandMore/></Button></Stack>}
                readLessText={<Stack sx={{width: '12%'}}><Button color="primary" variant="text" disableElevation sx={{textTransform: 'Capitalize', fontSize: '14px'}}>Show less<ExpandLess/></Button></Stack >}
                className={classes.body}
              >
                {props.Details}
              </ReactReadMoreReadLess> </h6> */}
            {/* {(status === 'Currently Available' || status === 'Coming Soon') ?
              <Grid item xs style={{ marginBottom: '1.5em' }}>
                {(Agent1 !== '') ? <Typography className={classes.Typography}>Listed by {Builders}  </Typography> : null}
                
                <Typography className={classes.Typography} >Source : NWMLS <img src={nwmlslogo} alt="NWMLS Logo" height="17px" style={{ marginLeft: '5px', marginBottom: '3px' }} /> </Typography>
              </Grid>
              :
              <>
                <Grid item xs style={{ marginBottom: '1.5em' }}>
                  <Typography className={classes.Typography} >
                    This home last sold for <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                  
                  </Typography>
                  {(Agent1 !== '') ? <Typography className={classes.Typography}>Listed by {Builders}  </Typography> : null}
                 
                  <Typography className={classes.Typography} >Source : NWMLS  <img src={nwmlslogo} alt="NWMLS Logo" height="17px" style={{ marginLeft: '5px', marginBottom: '3px' }} /> </Typography>
                </Grid>

              </>
            } */}
          </div>

        </div>

        {/* <div ref={AvaliableScrolldiv}>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              {open ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <Typography style={{ fontSize: "1.2rem" }}>Availability</Typography>
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Availability props={props} url={props.url} />
          </Collapse>
        </div> */}


        <div ref={SchoolScrolldiv}>
          <ListItem button onClick={handleClickSchool}>
            <ListItemIcon>
              {openSc ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <Typography style={{ fontSize: "1.2rem" }}>Area Schools</Typography>
            {/* <ListItemText primary="Community Map"  classes={{primary:classes.listItemText}}/> */}
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={openSc} timeout="auto" unmountOnExit style={{ padding: "30px 0px 40px 30px" }}>
            <div className='great_div' >
              {/* <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">
                <img src={greatschool} alt="Great School" className='great_schoolLogo' /></a> */}
              <div style={{ fontSize: '1rem', fontWeight: '450' }}>School District</div>
              <h6 style={{ fontSize: 13, fontWeight: 400, marginTop: 0 }}> {props.Schools}</h6>
            </div>
            <div className="Schools_h">
              {(() => {
                if (!props.Level1 && !props.level2 && !props.Level3) {
                  return <div>
                    {/* <h6 className="Schools_h6"> Available Soon</h6> */}
                    <p><strong>Available Soon.</strong>This home is within the <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">{props.Schools}.</a></p>
                    <p> <strong>{props.city} enrollment policy is not based solely on geography.</strong>  Please check the school district website to see all schools serving this home.</p>
                  </div>
                }
                else {
                  return (

                    <TableContainer component={Paper} style={{ width: '80%' }}>
                      <Table >
                        <TableHead>
                          <TableRow>
                            <TableCell > <div className="Single_tab">Type</div></TableCell>
                            <TableCell> <div className="Single_tab" >Schools</div></TableCell>
                            {/* <TableCell> <div className="Single_tab" >Rating</div></TableCell> */}
                            {/* <TableCell> <div className="Single_tab" >Students</div></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" >
                              <h6 className="Schools_h6"> Elementary school</h6>
                            </TableCell>
                            <TableCell >
                              {/* {elemschool.map((item) =>(
                                <div>
                                  <h7>

                                  </h7>
                                </div>
                              ))} */}
                              <h7 className="Schools_h7">{props.Level1 ? props.Level1 : 'NA'} </h7>
                            </TableCell>
                            {/* <TableCell >
                              {elemschool.map((item) => (
                                <div className="Schools_Rating2" key={item.name}>
                                  <h7 className="Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'}</h7>

                                </div>
                              ))}
                            </TableCell> */}
                            {/* <TableCell ><h7 className="Schools_h7">{elementryschooldata?.enrollment ? elementryschooldata.enrollment :'NA'} </h7></TableCell> */}
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" >
                              <h6 className="Schools_h6">Middle schools</h6>
                            </TableCell>
                            <TableCell ><h7 className="Schools_h7">{props.level2 ? props.level2 : 'NA'}</h7> </TableCell>
                            {/* <TableCell >
                              {middschool.map((item) => (
                                <div className="Schools_Rating2" key={item.name}>
                                  <h7 className="Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'}</h7>
                                </div>
                              ))}
                            </TableCell> */}
                            {/* <TableCell ><h7 className="Schools_h7">{middleschooldata?.enrollment ? middleschooldata.enrollment :'NA'} </h7></TableCell> */}
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" >
                              <h6 className="Schools_h6">High schools</h6>
                            </TableCell>
                            <TableCell ><h7 className="Schools_h7">{props.Level3 ? props.Level3 : 'NA'}</h7> </TableCell>
                            {/* <TableCell >
                              {highschool.map((item) => (
                                <div className="Schools_Rating2" key={item.name}>
                                  <h7 className="Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'}</h7>
                                </div>
                              ))}
                            </TableCell> */}
                            {/* <TableCell ><h7 className="Schools_h7">{highschooldata?.enrollment ? highschooldata.enrollment :'NA'} </h7></TableCell> */}
                          </TableRow>

                        </TableBody>
                        {/* <caption> <strong>
                          School data provided by <>
                            <a href="https://www.greatschools.org/" target="_blank">GreatSchools.</a> </>
                          School service boundaries are intended to be used as reference only. To verify enrollment eligibility for a property, contact the school directly.
                        </strong></caption> */}
                      </Table>
                    </TableContainer>
                  )
                }
              })()}
            </div>
          </Collapse>
        </div>
        <div ref={SitemapScrolldiv}>
          <ListItem button onClick={handleClickSitemap}>
            <ListItemIcon>
              {openSmap ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <Typography style={{ fontSize: "1.2rem" }}>Community Map</Typography>
            {/* <ListItemText primary="Community Map"  classes={{primary:classes.listItemText}}/> */}
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={openSmap} timeout="auto" unmountOnExit style={{ padding: "30px 30px 40px " }}>
            {/* <h6 className="Schools_h6">Community Map</h6> */}
            <div className="Schools_map">
              <h7 >{props.Adderss}</h7>
            </div>
            {!Array.isArray(props.siteMap) && <SimpleReactLightbox>
              <SRLWrapper options={options2}>
                <Card style={{ width: "fit-content" }}>
                  <img src={white} alt="Site Map" style={{ "width": "100%" }} />
                </Card>
              </SRLWrapper>
            </SimpleReactLightbox>}

            {(() => {
              if (Array.isArray(props.siteMap))
                if (siteMap.length === 1)

                  return <SimpleReactLightbox>
                    <SRLWrapper options={options2}>
                      <Card style={{ width: "fit-content" }}>
                        <img src={siteMap} alt="Site siteMap" Style=" width: 600px; height: auto" />
                      </Card>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                else
                  return <SimpleReactLightbox >
                    <SRLWrapper options={options}>

                      <div className="FloorPlanGrid_Desk">
                        {siteMap.map((data) => (
                          <Grid container style={{ width: "auto" }}>
                            <SiteMap SiteMap={data} propertyname={propertyname} />
                          </Grid>)
                        )}
                      </div>

                    </SRLWrapper >
                  </SimpleReactLightbox>
            })()}
          </Collapse>
        </div>
        {
          floorUrl && <div ref={FloorScrolldiv}>
            <ListItem button onClick={handleClickFloor}>
              <ListItemIcon>
                {openFloor ? <ExpandLess style={{
                  "background": "rgb(255 141 0)",
                  "border-radius": "1000px",
                  "color": "white",
                  "height": "20px",
                  "width": "20px"
                }} /> : <ExpandMore style={{
                  "background": "rgb(255 141 0)",
                  "border-radius": "1000px",
                  "color": "white",
                  "height": "20px",
                  "width": "20px"
                }} />}
              </ListItemIcon>
              <Typography style={{ fontSize: "1.2rem" }}>Floor Plans</Typography>
              {/* <ListItemText primary="Community Map"  classes={{primary:classes.listItemText}}/> */}
            </ListItem>
            <Divider variant="middle" />
            <Collapse in={openFloor} timeout="auto" unmountOnExit style={{ padding: "30px 30px 40px " }}>

              {/* <h6 className="Schools_h6">Floor Plans</h6> */}
              <div className="Schools_map">
                <h7 >{props.Adderss}</h7>
              </div>

              {(() => {
                if (Array.isArray(props.floorUrl))
                  if (floorUrl.length === 1)

                    return <SimpleReactLightbox>
                      <SRLWrapper options={options2}>
                        {/* <Card style={{ width: "fit-content" }}>
                          <img src={floorUrl} alt="Site floorUrl" Style=" width: 600px; height: auto" />
                        </Card> */}
                        <div className="FloorPlanGrid_Desk">
                          {floorUrl.map((data) => (
                            <Grid container style={{ width: "auto" }}>
                              <Floor floorUrl={data} />
                            </Grid>)
                          )}
                        </div>
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  else
                    return <SimpleReactLightbox >
                      <SRLWrapper options={options}>

                        <div className="FloorPlanGrid_Desk">
                          {floorUrl.map((data) => (
                            <Grid container style={{ width: "auto" }}>
                              <Floor floorUrl={data} />
                            </Grid>)
                          )}
                        </div>

                      </SRLWrapper >
                    </SimpleReactLightbox>
              })()}
            </Collapse>
          </div>
        }
        <div ref={ScoopScrolldiv}>
          <ListItem button onClick={handleClickScoop}>
            <ListItemIcon>
              {openScoop ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <Typography style={{ fontSize: "1.2rem" }}>Inside Scoop</Typography>
          </ListItem>
          <Divider variant="middle" />
          <Collapse in={openScoop} timeout="auto" unmountOnExit style={{ padding: "30px 30px 40px", background: '#b0b0b070', margin: '1em', borderRadius: '1em', }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography style={{ fontSize: "1.2rem" }}>Inside Scoop</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                {user && user.email === allowedEmail ? <Button variant='contained' color="info" onClick={handleClickOpen}>Add Details</Button> : null}
                <ScoopPannel Open={open} Close={handleClose} propertyname={Adderss} addCardToFirebase={addCardToFirebase} wishlistData={wishlistData} />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: '0em 2em' }}>
              {
                Scoop !== null && typeof Scoop === 'object' ?
                  <>
                    {
                      Object.keys(Scoop)
                        .sort((a, b) => (Scoop[b].selectedDate > Scoop[a].selectedDate) ? 1 : -1)
                        .map((id) => {
                          return (
                            <ScoopCard ScoopData={id} scoop={Scoop} propertyname={Adderss} UpdateScoop={keys} user={user} allowedEmail={allowedEmail} />
                          )
                        })
                    }
                  </>
                  :
                  null
              }

            </Grid>
          </Collapse>
        </div>


      </Paper >

    </div >

  );
}
export default DetailData;



