import React from 'react'
import { CssBaseline, Card, CardContent, Typography, Grid, Button, } from '@mui/material'
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import OutlinedInput from '@mui/material/OutlinedInput';
import logoS2 from '../images/logoS.5.png'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Address = () => {
    const [loading, setLoading] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClickError = () => {
        setOpenError(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });

    };


    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }

    const handleSubmit = e => {
        e.preventDefault();

        setLoading(true)
        emailjs.sendForm(
            'service_b9v5hfs',
            'template_d54orqc',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
                setLoading(false)
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
                setLoading(false)
            });
        // #0d76d6
        e.target.reset()
    }
    return (
        <div>
            <Card style={{ background: '#0d76d6', color: 'white' }}>
                <CssBaseline />
                <Grid xs={12} align="center" style={{ marginTop: '-4em' }}>
                    <img src={logoS2} width={200} height={200} alt="HomesWaState" />
                </Grid>
                <CardContent style={{ marginTop: '-5em' }}>
                    {/* <TextField
                        id="outlined-read-only-input"
                        label="Read Only"
                        defaultValue="Hello World"
                        InputProps={{
                            readOnly: true,
                        }}
                    /> */}
                    <Typography gutterBottom variant="h5" component="div" style={{ color: 'white', fontWeight: '500' }}>
                        Need to sell before you buy?
                    </Typography>
                    {/* <Typography gutterBottom variant="h5" component="div" style={{ color: 'white', fontWeight: '500' }} >
                        Deepti Gupta  Real Estate can help.
                    </Typography> */}
                    <Typography sx={{ fontSize: 15, }} component="div" gutterBottom color='text.secondary'>
                        Enter your address to request your free no obligation consultation.
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <input type="hidden" name='heading' value="Potential Sale Request" />
                            <Grid item xs={12}>
                                <OutlinedInput
                                    style={{ background: 'White', color: 'black' }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="address"
                                    placeholder="Property address"
                                    name="address"
                                    autoComplete="address"
                                    className="text_contact"
                                    size="small"
                                    onChange={handleChange}
                                // value={values.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OutlinedInput
                                    style={{ background: 'White', color: 'black' }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    placeholder="Contact number"
                                    name="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    type="number"
                                    size="small"
                                    onChange={handleChange}
                                // value={values.name}
                                />
                            </Grid>
                            <Grid item xs={12} align="center" >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ marginTop: '1px', backgroundColor: '#f50057' }}
                                    disabled={loading}
                                    onClick={handleClick({
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    })}
                                >
                                    Submit
                                </Button>
                                <Snackbar
                                    anchorOrigin={{ vertical, horizontal }}
                                    open={open}
                                    onClose={handleClose}
                                    autoHideDuration={8000}
                                    key={vertical + horizontal}>
                                    <Alert onClose={handleClose} severity="success">
                                        Thank you for getting in touch!

                                        We appreciate you contacting us. One of our team members will get back in touch with you soon! Have a great day!
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openError} onClose={handleClose} autoHideDuration={8000}>
                                    <Alert onClose={handleClose} severity="error">
                                        Please try again!
                                    </Alert>
                                </Snackbar>
                            </Grid>
                        </Grid>
                    </form>

                </CardContent>
            </Card>
        </div>
    )
}

export default Address