import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../firebase/firebase';
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, Link, styled, TextField, Typography, useTheme } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.homewastate.com/">
        homeWAState.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: '#f50057',
  background: '#f50057',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export default function AdminLogin() {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (email === 'dguptahomes.web@gmail.com')
      await firebase.auth().signInWithEmailAndPassword(email, password).then((user) => { })
        // .then(setLoading(true))
        .catch((error) => {
          console.log(error);

        });
    setLoading(false)
  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledDiv>
        <StyledAvatar>
          <LockOutlined />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <StyledForm  onClick={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ margin: theme.spacing(3, 0, 2), }}
            disabled={loading || !email || !password}
          >
           {loading ? <CircularProgress size={24} /> : 'Sign In'} 
          </Button>
        </StyledForm>
      </StyledDiv>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}