import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card, CardMedia, Typography, Button, useMediaQuery, Box, Stack, Skeleton } from '@mui/material';
import ReactReadMoreReadLess from "react-read-more-read-less"
import { PropertyContext } from '../../context'
import Rating from '@mui/material/Rating';

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: 300,
        width: 350,
        backgroundColor: '#ebebeb',
        boxShadow: '0px 0px 5px #c7c7c7',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        marginTop: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('md')]: {
            minHeight: 280,
            width: 330,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 260,
            width: 310,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'auto',
            width: '100%',
        }
    },
    avatarContainer: {
        height: 100,
        width: 100,
        position: 'absolute',
        top: -50,
        zIndex: 1,
        borderRadius: '100%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: 90,
            width: 90,
            top: -45,
        },
        [theme.breakpoints.down('md')]: {
            height: 80,
            width: 80,
            top: -40,
        },
        [theme.breakpoints.down('xs')]: {
            height: 70,
            width: 70,
            top: -35,
        }
    },
    avatar: {
        width: '85%',
        height: '85%',
        backgroundColor: '#06232a',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '& > p': {
            margin: 0,
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '3.5rem',
            fontWeight: 700,
            color: '#fda12e',
            [theme.breakpoints.down('md')]: {
                fontSize: '3rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.5rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2.5rem'
            }
        }
    },
    // body: {
    //     marginTop: 80,
    //     flex: 1,
    //     fontFamily: 'Helvetica, Arial, sans-serif',
    //     textAlign: 'center',
    //     '& > p': {
    //         '&:before': {
    //             content: `"\\201C"`,
    //             fontSize: '2rem',
    //             color: '#fda12e'
    //         },
    //         '&:after': {
    //             content: `"\\201D"`,
    //             color: '#fda12e',
    //             fontSize: '2rem',
    //             position: 'absolute',
    //         },
    //         margin: '0px 9px',
    //         lineHeight: '20px',
    //         [theme.breakpoints.down('sm')]: {
    //             lineHeight: '18px'
    //         },
    //     },
    //     [theme.breakpoints.down('md')]: {
    //         marginTop: 55,
    //         fontSize: '0.98rem',
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         marginTop: 45,
    //         fontSize: '0.94rem',
    //         textAlign: 'justify',
    //     }
    // },
    author: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: "15px 0px 10px 0px",
        '& h3': {
            margin: 0,
            color: '#fda12e',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '1.3rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.2rem'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.1rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2rem',
            }
        },
        '& p': {
            margin: 0,
            fontFamily: 'serif',
            fontStyle: 'italic',
            fontSize: '0.9rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '0.89rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.88rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.87rem'
            }
        },
        body: {
            marginTop: '1em',
            flex: 1,
            textAlign: 'justify',
            fontSize: '15px',
            fontWeight: '400',

        }
    }
}));

const TestiData = () => {
    const classes = useStyle();
    const isMatch = useMediaQuery('(max-width:600px )')
    const context = React.useContext(PropertyContext);
    const { testimonials, TestimonialData } = context;

    let SellerReviews = testimonials;
    SellerReviews = [
        ...SellerReviews.filter(reviews => reviews.clientType === "Seller"),
    ]


    // console.log("sellerR", SellerReviews);

    // console.log("seller", SellerReviews);

    useEffect(() => {
        if (SellerReviews.length === 0) {
            TestimonialData()
        }

    }, [])

    return (
        <div>
            {
                SellerReviews.length === 0 ?
                    (
                        <Stack mt={3} mb={3}>
                            <Stack direction="row" spacing={1}>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={1} mt={3}>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                            </Stack>

                            <Stack direction="row" spacing={1} mt={3}>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                                <Stack width='50%'>
                                    <Card sx={{
                                        p: '10px 20px',
                                        border: '1px solid #aaa',
                                        backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                    }}>
                                        <Skeleton
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                            variant="rectangular"
                                            height={150}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                        </Box>
                                    </Card>
                                </Stack>
                            </Stack>
                        </Stack >
                    )
                    :
                    <Grid container sapcing={1}>
                        {
                            SellerReviews.map((item) => {
                                return (
                                    <Grid item xs={isMatch ? 6 : 12} sm={6} md={4}>

                                        <Card
                                            style={{
                                                padding: '10px 20px', border: '1px solid #aaa', backgroundColor: '#ebebeb',
                                                boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                            }}
                                            elevation={0}>
                                            <CardMedia
                                                component="img"
                                                alt="client testimonals"
                                                height={isMatch ? "120" : "350"}
                                                image={item.houseImage}
                                                src="Client Testimonals"
                                            />
                                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                                <Typography gutterBottom variant={isMatch ? "h6" : "h5"} component="div" style={{ textAlign: 'center', color: '#fda12e' }}>
                                                    {item.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="center">
                                                <Rating style={{ color: "red" }} name="read-only" value={item.rating} readOnly size="small" />
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                                <p>
                                                    <ReactReadMoreReadLess
                                                        readMoreClassName="reactMoreClassName"
                                                        charLimit={isMatch ? 120 : 150}
                                                        readMoreText={<Button color="primary">Read more ▼</Button>}
                                                        readLessText={<Button color="primary">Read less ▲</Button>}
                                                        className={classes.body}
                                                    >
                                                        {item.feedback}
                                                    </ReactReadMoreReadLess>
                                                </p>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
            }
        </div>
    )
}

export default TestiData;





