import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ propertyAddress, propertyDescription, propertyImage }) => {
    return (
        <div>
            <Helmet>
                <meta property="og:title" content={propertyAddress} />
                <meta property="og:image" content={`${propertyImage}`} />
                <meta property="og:description" content={propertyDescription} />
            </Helmet>
        </div>
    );
};

export default SEO;
