import React from 'react'
import loading from '../images/gif/loadin2.gif';
import loading2 from '../images/logoS.1.png';
import { makeStyles } from '@mui/styles';
import { Box, Skeleton, Stack } from '@mui/material';


const useStyles = makeStyles((theme) =>
({
    loading_gify: {
        marginTop: '7px',
        width: 'fit-content',
        margin: 'auto',
        zIndex: 1,
        marginBottom: '10px',
    },
    loadingImg: {
        height: 50,
        width: '100%'
    },
    heading: {
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgb(63, 63, 63)',
    },
    skeliton: {
        marginTop: '-450px',
        padding: '20px',
    },
    skeliton2: {
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    skelitonMob: {
        padding: '20px',
        marginTop: '-110px',
    },
}))


export default function Loading() {
    const classes = useStyles()
    return (
        <>
            <Box display={{ xs: 'block', sm: 'none' }} m={1} >
                <div className={classes.skelitonMob}>
                    <Skeleton variant="rect" width="100%" height="400px" />
                </div>

                <div className={classes.skeliton2}>
                    <Skeleton variant="rect" width="100%" height="240px" />
                </div>
            </Box>
            <Box display={{ xs: 'none', sm: 'block',  }} m={1} >
                <div className={classes.heading} style={{ textAlign: "center" }}>

                    <Stack direction="column" alignItems="center" className={classes.loading_gify} >
                        <img src={loading2} alt="loading" style={{ "width": "300px", marginTop: 100 }} />
                        <img src={loading} alt="loading" style={{ "width": "180px" }} />
                    </Stack >
                </div>
                <Stack className={classes.skeliton}>
                    <Skeleton variant="rect" width="auto" height="600px" />
                </Stack>
                <Stack className={classes.skeliton2}>
                    <Skeleton variant="rect" width="auto" height="400px" />
                </Stack>
            </Box>
        </>
    )
}