import React, { useState, useRef } from 'react'
import { Avatar, Box, Paper, Rating, Stack, styled, useMediaQuery, useTheme, Typography, Button, Dialog, DialogTitle, IconButton, DialogContent, Divider, Container, Grid, TextField, Alert, InputAdornment, FormControl, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../components/NavBar/NavBar.css';
import banner22 from '../images/Bannermob13.png';
import firebase from '../firebase/firebase';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import Footer from '../components/Footer';
import emailjs from 'emailjs-com';

const useStyles = makeStyles((theme) => ({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        padding: 34,
        paddingTop: 60
    },
    cardGrid: {
        marginTop: "10px",
    },
    cardField: {
        margin: "1em",
    },

}))


const countries = [
    { code: 'US', name: 'United States', dialCode: '+1' },
    { code: 'IN', name: 'India', dialCode: '+91' },
    { code: 'GB', name: 'United Kingdom', dialCode: '+44' },
    { code: 'CA', name: 'Canada', dialCode: '+1' },
    // Add more countries as needed
];



const Contactus2 = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMatch = useMediaQuery('(max-width:600px)')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [notes, setNotes] = useState('');
    const [otp1, setOtp1] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [message, setMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [verified, setVerified] = useState(false);
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [message1, setMessage1] = useState('');
    const [otpSent1, setOtpSent1] = useState(false);
    const [verified1, setVerified1] = useState(false);
    const [alertColor, setAlertColor] = useState('');
    const [country, setCountry] = useState(countries[0].dialCode);
    const [selectedMethod, setSelectedMethod] = useState('email');
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const inputRefs = useRef([]);

    const handleChange = (element, index) => {
        const value = element.value;
        if (isNaN(value)) return; // Only allow numeric input

        // Update OTP state
        setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

        // Focus next input
        if (value !== "" && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };
    // Import necessary functions from the Firebase SDK
    // import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

    // const formatPhoneNumber = () => {
    //     return `${country}${phoneNumber}`;
    //   };
    const formatPhoneNumber = () => {
        return `${country}${phoneNumber}`;
    };

    const getCountryCode = (phoneNumber) => {
        const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
        if (phoneNumberObj && phoneNumberObj.isValid()) {
            return phoneNumberObj.countryCallingCode; // Extract the country code
        }
        return null;
    };

    const generateOtp = () => {
        const otp = Math.floor(100000 + Math.random() * 900000).toString();
        setGeneratedOtp(otp);
        return otp;
    };

    // const sendOtp = () => {
    //     // if (phoneNumber.length !== 10) {
    //     //     setMessage('Please enter a valid mobile number.');
    //     //     return;
    //     // }
    //     const formattedPhoneNumber = `+91${number}`;
    //     console.log('numb', formattedPhoneNumber)
    //     const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',);

    //     const appVerifier = window.recaptchaVerifier;

    //     firebase.auth().signInWithPhoneNumber(formattedPhoneNumber, recaptchaVerifier).then(function(e) {
    //         // setVerificationId(confirmationResult.verificationId);
    //         // setMessage('OTP sent successfully!');
    //         let code = prompt('enter the otp', '');
    //         if(code === null) return;
    //         e.confirm(code).then(function(result){
    //             console.log(result.user, 'user');
    //             document.querySelector('label').textContent
    //         })
    //     })
    //         .catch(function (error) {
    //             setMessage('Failed to send OTP. Please try again.');
    //             console.error('Error during sign-in with phone number:', error);
    //         });
    // };


    // const verifyOtp = () => {
    //     if (!verificationId) {
    //         setMessage('Please request an OTP first.');
    //         return;
    //     }

    //     const credential = firebase.auth().PhoneAuthProvider.credential(verificationId, otp);

    //     firebase.auth().signInWithCredential(credential)
    //         .then(() => {
    //             setMessage('Your mobile number is verified!');
    //         })
    //         .catch((error) => {
    //             setMessage('Invalid OTP. Please enter the correct OTP.');
    //             console.error('Error verifying OTP:', error);
    //         });
    // };


    // const getOtp = async (e) => {
    //     e.preventDefault();
    //     setError("");
    //     if (!number) {
    //         return setError("Please enter a valid phone number!");
    //     }

    //     try {
    //         const auth = getAuth();
    //         const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    //             size: 'normal',
    //             callback: (response) => {
    //                 console.log('reCAPTCHA solved');
    //             },
    //             'expired-callback': () => {
    //                 setError("reCAPTCHA expired. Please try again.");
    //             }
    //         }, auth);

    //         recaptchaVerifier.render();
    //         // Send OTP to the provided phone number
    //         const confirmationResult =  await firebase.auth().signInWithPhoneNumber(number, recaptchaVerifier);

    //         // Store confirmationResult to use it in OTP verification
    //         setResult(confirmationResult);
    //         setFlag(true);  // Show OTP input field
    //         setMessage("OTP has been sent.");
    //     } catch (error) {
    //         console.error("Error during OTP send:", error);
    //         setError("Failed to send OTP. Please check the phone number.");
    //     }
    // };

    // const verifyOtp = async (e) => {
    //     e.preventDefault();
    //     if (!otp) {
    //         return setError("Please enter the OTP.");
    //     }

    //     try {
    //         // Verify the OTP with the confirmationResult
    //         const confirmationResult = result; // Use the result object from getOtp
    //         await confirmationResult.confirm(otp); // Verify the OTP entered by the user
    //         setMessage("Phone number verified successfully!");
    //     } catch (error) {
    //         console.error("Error during OTP verification:", error);
    //         setError("Invalid OTP. Please try again.");
    //     }
    // };


    // const formattedPhoneNumber = ensureCountryCode(phoneNumber.toString());
    // console.log('numb', formattedPhoneNumber)

    const sendEmailOtp = () => {
        const generatedOtp = generateOtp();

        // EmailJS configuration
        const serviceId = 'service_b9v5hfs';
        const templateId = 'template_lc2aseu';
        const userId = 'user_c6L1p3GVRkDc5i4AlvRat';

        const templateParams = {
            email: email,
            otp: generatedOtp,
            name: name
        };

        // Send OTP to user's email
        emailjs.send(serviceId, templateId, templateParams, userId)
            .then((response) => {
                setMessage1('OTP sent to your email!');
                setOtpSent(true);
                setAlertColor('info');
                console.log('error', response)
            })
            .catch((error) => {
                setMessage1('Failed to send OTP. Please try again.');
                console.error('Email sending error:', error);
            });
    };


    const sendPhoneOtp = () => {

        const formattedPhoneNumber = formatPhoneNumber();
        const phoneNumberObj = parsePhoneNumberFromString(formattedPhoneNumber);
        console.log(formattedPhoneNumber)

        if (!name || !email || !phoneNumber) {
            setMessage('Please fill in all the fields before sending OTP.');
            return;
        }
        if (!phoneNumberObj || !phoneNumberObj.isValid()) {
            setMessage('Invalid phone number. Please enter a valid phone number.');
            return;
        }
        // if (phoneNumber.length !== 10) {
        //     setMessage('Please enter a valid phone number.');
        //     return;
        // }

        // Initialize reCAPTCHA verifier (creates a new instance every time)
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
        }
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });

        firebase.auth().signInWithPhoneNumber(formattedPhoneNumber, window.recaptchaVerifier)
            .then((result) => {
                setVerificationId(result.verificationId);
                setMessage('OTP has been sent to your phone number');
                setAlertColor('info');
                setOtpSent(true);
            })
            .catch((error) => {
                console.error('Error during OTP send:', error);
                setMessage('Failed to send OTP. Please ensure the phone number is in the correct format.');
                setAlertColor('error');
            });
    };



    // Function to verify the OTP

    const verifyOtp = () => {
        if (selectedMethod === 'email') {
            const enteredOtp = Array.isArray(otp) ? otp.join('') : String(otp).trim();
            console.log('Entered OTP:', enteredOtp);
            console.log('Generated OTP:', String(generatedOtp));
            if (enteredOtp === String(generatedOtp)) {
                setVerified(true);
                setMessage('Email verified successfully!');
                setAlertColor('success');
            } else {
                setMessage('Invalid OTP. Please try again.');
                setAlertColor('warning');
            }
        } else {
            const enteredOtp = Array.isArray(otp) ? otp.join('') : String(otp).trim();
            const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, enteredOtp);
            firebase.auth().signInWithCredential(credential)
                .then(() => {
                    setVerified(true);
                    setMessage('Phone number verified successfully!');
                    setAlertColor('success');
                })
                .catch((error) => {
                    setMessage('Failed to verify OTP. Please try again.');
                    setAlertColor('warning');
                    console.error('Error verifying OTP:', error);
                });
        }
    };

    // const verifyOtp = () => {
    //     const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
    //     firebase.auth().signInWithCredential(credential)
    //         .then((result) => {
    //             setMessage('Phone number verified successfully!');
    //             setVerified(true);
    //             setAlertColor('success');
    //         })
    //         .catch((error) => {
    //             setMessage('Failed to verify OTP. Please try again.');
    //             setAlertColor('error');
    //             console.error('Error verifying OTP:', error);
    //         });
    // };

    // const verifyEmailOtp = () => {
    //     if (otp1 === generatedOtp) {
    //         setVerified1(true);
    //         setMessage1('Email verified successfully!');
    //         setOtpSent1(false);
    //         setAlertColor('success');
    //     } else {
    //         setMessage1('Invalid OTP. Please try again.');
    //         setAlertColor('error');
    //     }
    // }

    const sendOtp = () => {
        if (selectedMethod === 'email') {
            sendEmailOtp();
        } else {
            sendPhoneOtp();
        }
    };

    return (
        <div>
            <Box style={{ margin: 0, background: "white" }} >
                <Paper className={classes.bannerPaper}>
                    <Container className={classes.cardGrid} maxWidth="md">
                        <div className="BAnner_text_Nav">
                            <h3>Contact US - CRY Dandiya </h3>
                        </div>
                    </Container>
                </Paper>

                <Container maxWidth="sm" sx={{ pt: '3em', pb: '2em' }}>
                    <Paper elevation={2}>
                        <form action='https://docs.google.com/forms/u/0/d/e/1FAIpQLScjUP2fREKzFzMuno1UzyhymXRXeHDppLq5Zk2laId8JZ61pA/formResponse'>
                            <Grid container spacing={2} sx={{ p: '2em' }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: isMatch ? '20px' : '22px', fontWeight: 500 }} gutterBottom> SPECIAL OFFER for CRY Dandiya - 2024 </Typography>
                                    <Divider sx={{ mt: '1em' }} />
                                    <ul style={{ paddingTop: '1em', paddingLeft: isMatch ? '1.5em' : 'null' }}>
                                        <li style={{fontSize: isMatch ? '15px' : '17px'}}>
                                            <Typography sx={{fontSize: isMatch ? '15px' : '16px', fontWeight: 400, color: theme.palette.grey[700] }} gutterBottom>
                                            Know someone looking to buy or sell? Refer a friend, family member, or colleague to our team, and you’ll receive $500 as a thank-you when they close on a home before April 2025!
                                            </Typography>
                                        </li>
                                        <li style={{fontSize: isMatch ? '15px' : '17px'}}>
                                            <Typography sx={{fontSize: isMatch ? '15px' : '16px', fontWeight: 400, color: theme.palette.grey[700] }} gutterBottom>
                                            Your referral will enjoy up to $5,000 in credits towards home upgrades or closing costs, along with the Full-Service Expertise of Washington’s Top 1% real estate team. Visit our website to see our sales success stories and read our 5-star reviews. 
                                            </Typography>
                                        </li>
                                        <li style={{fontSize: isMatch ? '15px' : '17px'}}>
                                            <Typography sx={{fontSize: isMatch ? '15px' : '16px', fontWeight: 400, color: theme.palette.grey[700] }} gutterBottom>
                                            This is a limited-time offer, so don’t miss out on the chance to share the savings and help your network find their dream home!
                                            </Typography>
                                        </li>
                                    </ul>

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name='entry.1964668610'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>

                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name='emailAddress'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                </Grid>
                                {/* <Grid item xs={isMatch ? 12 : 4} display="flex" justifyContent="center" alignItems="center">
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ mt: '1em', width: '90px', borderRadius: '5px' }}
                                        onClick={sendEmailOtp}
                                        disabled={!email || otpSent1 || verified1}
                                    >
                                        Get OTP
                                    </Button>
                                </Grid>
                                {otpSent1 && (
                                    <>
                                        <Grid item xs={isMatch ? 12 : 8}>
                                            <TextField
                                                label="Enter OTP"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={otp1}
                                                onChange={(e) => setOtp1(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={isMatch ? 12 : 4} display="flex" justifyContent="center" alignItems="center">
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{ mt: '1em', width: '90px', borderRadius: '5px' }}
                                                onClick={verifyEmailOtp}
                                                disabled={verified1}
                                            >
                                                Verify OTP
                                            </Button>
                                        </Grid>
                                    </>

                                )} */}
                                {/* <Grid item xs={isMatch ? 12 : 8}>{message1 && <Alert severity={alertColor} autoHideDuration={3000}>{message1}</Alert>}</Grid> */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Phone Number"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name='entry.280256854'
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FormControl>
                                                        <Select
                                                            value={country}
                                                            onChange={(e) => setCountry(e.target.value)}
                                                            displayEmpty
                                                            disableUnderline
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Remove the default border
                                                                },
                                                                '& .MuiSelect-select': {
                                                                    padding: 0, // Remove padding to align with the text field
                                                                },
                                                                '& .MuiSelect-icon': {
                                                                    right: 0, // Adjust icon position
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Remove border on hover
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Remove border on focus
                                                                },
                                                                '&.Mui-focused .MuiInputBase-input': {
                                                                    border: 'none', // Remove border when focused
                                                                },
                                                            }}
                                                        >
                                                            {countries.map((country) => (
                                                                <MenuItem key={country.code} value={country.dialCode}>
                                                                    {country.dialCode}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormControl component="fieldset" sx={{ mt: 2 }}>
                                        <RadioGroup
                                            row
                                            value={selectedMethod}
                                            onChange={(e) => setSelectedMethod(e.target.value)}
                                        >
                                            <FormControlLabel value="email" control={<Radio />} label="Receive OTP via Email" />
                                            <FormControlLabel value="phone" control={<Radio />} label="Receive OTP via Phone" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {/* <Grid item xs={isMatch ? 12 : 4} display="flex" justifyContent="center" alignItems="center">
                                    <Button variant="contained" fullWidth onClick={sendOtp} sx={{ mt: '1em', width: '90px', borderRadius: '5px' }} disabled={!phoneNumber}>
                                        Get OTP
                                    </Button>
                                </Grid> */}
                                {!otpSent && (<Grid item xs={12}><div id="recaptcha-container"></div></Grid>)}

                                {otpSent && (
                                    <>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                            <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
                                                {otp.map((data, index) => (
                                                    <TextField
                                                        key={index}
                                                        value={data}
                                                        onChange={(e) => handleChange(e.target, index)}
                                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                                        inputProps={{
                                                            maxLength: 1,
                                                            style: { textAlign: 'center', fontSize: '1.5rem' },
                                                        }}
                                                        inputRef={(ref) => (inputRefs.current[index] = ref)}
                                                        sx={{
                                                            width: isMatch ? '40px' : '50px',
                                                            '& input': {
                                                                height: isMatch ? '15px' : '20px', // Adjust padding for text alignment
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                borderRadius: '8px',
                                                                '& fieldset': {
                                                                    borderColor: 'primary.main',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'secondary.main',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'success.main',
                                                                    borderWidth: 2,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                            {/* <TextField
                                                label="Enter OTP"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                            /> */}
                                        </Grid>
                                        {/* <Grid item xs={isMatch ? 12 : 4} display="flex" justifyContent="center" alignItems="center">
                                            <Button variant="contained" fullWidth onClick={verifyOtp} sx={{ mt: '1em', width: '90px', borderRadius: '5px' }}>
                                                Verify OTP
                                            </Button>
                                        </Grid> */}
                                    </>
                                )}
                                {
                                    email && phoneNumber ? (
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                            {!otpSent ? (
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ mt: '1em', width: '120px', borderRadius: '5px' }}
                                                    onClick={sendOtp}
                                                    disabled={!(name && (selectedMethod === 'email' ? email : phoneNumber))}
                                                >
                                                    Get OTP
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ mt: '1em', width: '120px', borderRadius: '5px' }}
                                                    onClick={verifyOtp}
                                                    disabled={verified}
                                                >
                                                    Verify OTP
                                                </Button>
                                            )}
                                        </Grid>
                                    )
                                        :
                                        null
                                }

                                <Grid item xs={isMatch ? 12 : 12}>{message && <Alert severity={alertColor} autoHideDuration={3000}>{message}</Alert>}</Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        id="message"
                                        name="entry.1892949126"
                                        label="Message"
                                        fullWidth
                                        autoComplete="given-name"
                                        onChange={(e) => setNotes(e.target.value)}
                                        multiline
                                        rows={5}
                                    />
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 12} display="flex" justifyContent="center" alignItems="center">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            mt: 'auto',
                                            mb: 'auto',
                                            width: '150px',
                                        }}
                                        disabled={
                                            !name || // Disable if name is empty
                                            !email || // Disable if email is empty
                                            !phoneNumber || // Disable if phone number is empty
                                            !otpSent || // Disable if OTP has not been sent
                                            !verified // Disable if OTP hasn't been verified
                                        }
                                    // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={isMatch ? 12 : 12} display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="caption" display="block" sx={{ mt: 1, color: 'gray' }}>
                                        By submitting this form, you agree to provide your contact information, which may be used to reach out to you regarding our services, events, and updates. Your information will be kept confidential and will not be shared with third parties without your consent.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Container>
                <Footer />
            </Box>
        </div >
    )
}

export default Contactus2