import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import BookConsultation from '../../Cards/BookConsultation';
import { useMediaQuery } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import defaultmage from '../../../images/Image_not_available.png';

const useStyles = makeStyles((theme) => ({

  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));




const Zillow = (props) => {
  const classes = useStyles();
  const isMatch = useMediaQuery('(max-width:960px )');
  const { ZillowReviews } = props;

  return (
    <div>
      <Grid container spacing={2}>
        {
          isMatch ?
            <div>
              {ZillowReviews
                .sort(function (a, b) { return new Date(b.reviewDate) - new Date(a.reviewDate) })
                .map((card) => (
                  <Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}>
                    <div className={classes.root} >
                      <Paper className={classes.paper} style={{ padding: 10 }} elevation={3}>
                        <Grid container spacing={2} >
                          <Grid item>
                            <ButtonBase className={classes.image}>
                              <img className={classes.img} alt="complex" src={card.houseImage || defaultmage} style={{ width: "100px" }} />
                            </ButtonBase>
                          </Grid>
                          <Grid item>
                            <ButtonBase className={classes.image}>
                              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.orange}>
                                {card.name.charAt(0)}
                              </Avatar>
                            </ButtonBase>
                          </Grid>
                          <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                              <Grid item xs>
                                {/* <Typography gutterBottom variant="subtitle1">
                                <StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} />
                              </Typography> */}
                                <Stack spacing={1}>
                                  <Rating style={{ color: "red" }} name="read-only" value={card.rating} readOnly size="small" />
                                </Stack>
                                <Typography variant="body2" gutterBottom>
                                  {card.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {card.reviewDate}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer', fontSize: '0.85rem' }}>
                                  {card.feedback}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  </Grid>
                ))}
              <Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}>
                <BookConsultation />
              </Grid>
            </div>
            :
            <Grid container spacing={2}>
              <Grid item xs={9}>
                {ZillowReviews
                  .sort(function (a, b) { return new Date(b.reviewDate) - new Date(a.reviewDate) })
                  .map((card) => (
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <p style={{ marginBottom: 16 }}></p>
                      <div className={classes.root} >
                        <Paper className={classes.paper} style={{ padding: 10 }} elevation={3}>
                          <Grid container spacing={2} >
                            <Grid item>
                              <ButtonBase className={classes.image}>
                                <img className={classes.img} alt="complex" src={card.houseImage || defaultmage} style={{ width: "100px" }} />
                              </ButtonBase>
                            </Grid>
                            <Grid item>
                              <ButtonBase className={classes.image}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.orange}>
                                  {card.name.charAt(0)}
                                </Avatar>
                              </ButtonBase>
                            </Grid>
                            <Grid item xs={12} sm container>
                              <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                  <Stack spacing={1}>
                                    <Rating style={{ color: "red" }} name="read-only" value={card.rating} readOnly size="small" />
                                  </Stack>
                                  {/* <Typography gutterBottom variant="subtitle1">
                                  <StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} />
                                </Typography> */}
                                  <Typography variant="body2" gutterBottom>
                                    {card.name}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {card.reviewDate}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" style={{ cursor: 'pointer', fontSize: '0.85rem' }}>
                                    {card.feedback}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    </Grid>
                  ))}
              </Grid>
              <Grid xs={3} style={{ marginTop: '1em' }}>
                <BookConsultation />
              </Grid>
            </Grid>
        }

      </Grid >
    </div >
  )
}
export default Zillow;












