import React,{useEffect} from 'react';
import './AvilableHome.css';
import { withPropertyConsumer } from '../context';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Button from '@material-ui/core/Button';
import DailogForm from '../contact/DailogForm';
import DailogformContact from '../contact/DailogformContact';
import firebase from '../firebase/firebase';
import TourLogin from '../user/TourLogin';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  pad:{
    backgroundColor: '#F6F5F5'
  },
  });

  

  function Row(props) {
    const { item } = props;
    const [open, setOpen] = React.useState(false);
    const[currentUser, setCurrentUser]=React.useState(false)
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const classes = useRowStyles();
console.log("item", item)
    const handleClickOpen = () => {
        setOpen1(true);
        setOpen2(true);
      };
    
      const handleClose = () => {
        setOpen1(false);
      };
      const handleClose2 = () => {
        setOpen2(false);
      };
      console.log("currentuser", open2  )


      useEffect(() => {    
      
        var user = firebase.auth().currentUser;
        if (user !== null) {
          setCurrentUser(true);
      }
      else setCurrentUser(false)
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            console.log('This is the user: ', user)
            setCurrentUser(true);
        } 
        else setCurrentUser(false)
      }
      )
      
      }, []);
  
    return (<React.Fragment key={item}>
        <TableRow hover key={item} className={classes.root}>
          <TableCell>
          <div  className="Schools_Rating">
          <Tooltip  disableFocusListener disableTouchListener title="Click to know more">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon style={{"color": "white"}}/> : <KeyboardArrowDownIcon  style={{"color": "white"}}/>}
            </IconButton>
              </Tooltip>
              </div>
          </TableCell>
          <TableCell >
              <h7 className="SchoolsA_h7">
                   {item.Activename !== ''? item.Activename : 'NA'}
               </h7>
          </TableCell>
          <TableCell >
              <h7 className="SchoolsA_h7">
                   {item.movein !== ''? item.movein : 'NA'}
               </h7>
          </TableCell>
          {/* <TableCell align="right">{item.movein}</TableCell> */}
          {/* <TableCell ><h7 className="SchoolsA_h7">{item.facing}</h7></TableCell> */}
          <TableCell ><h7 className="SchoolsA_h7">
          { (() => {
               if (!item.startprice){       
               return ( 'Not released' ) 
               }
               else{
                   return ( <NumberFormat value={item.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />  )
                       }   
           })()}</h7>
          </TableCell>
          <TableCell >
              <Button  variant="contained"  color="primary"  size="small"
                       endIcon={<ChevronRightRoundedIcon />} onClick={handleClickOpen}>
                       Tour Now
               </Button>
               {currentUser === true &&<DailogForm Open={open1} Close={handleClose} property={item.community} Facing={item.facing} AvName={item.Activename}/>}
                  {currentUser === false && <TourLogin Open={open2} onClose={ handleClose2}  />}
           </TableCell >

          {/* <TableCell align="right">{item.city}</TableCell> */}
        </TableRow>
    
        <TableRow className={classes.pad} >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
              {/* <Divider  /> */}
                <Typography  gutterBottom component="div" className="Single_tabA">
                  Details:
                
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                    <TableCell><div className="Single_tabB">Facing</div></TableCell>
                    <TableCell><div className="Single_tabB">Size</div></TableCell>
                    <TableCell><div className="Single_tabB">Beds</div></TableCell>
                    <TableCell><div className="Single_tabB">Bath</div></TableCell>
                    <TableCell><div className="Single_tabB">Builder</div></TableCell>
                    <TableCell><div className="Single_tabB">City</div></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   
                      <TableRow >
                        <TableCell ><h7 className="SchoolsA_h6">{item.facing !== ''? item.facing : 'NA'}</h7></TableCell>
                      {/* <TableCell ><h7 className="SchoolsA_h6">
                      <NumberFormat value={item.size} displayType={'text'} thousandSeparator={true}  /> </h7></TableCell> */}
                      <TableCell ><h7 className="SchoolsA_h6">
                     {item.size !== ''? item.size  : 'NA'} </h7></TableCell>
                      <TableCell ><h7 className="SchoolsA_h6">{item.bedrooms !== ''? item.bedrooms  : 'NA'}</h7></TableCell>
                      <TableCell ><h7 className="SchoolsA_h6">{item.bathrooms !== ''? item.bathrooms  : 'NA'}</h7></TableCell>
                      <TableCell ><h7 className="SchoolsA_h6">{item.builders !== ''? item.builders : 'NA'}</h7></TableCell>
                      <TableCell ><h7 className="SchoolsA_h6">{item.city !== ''? item.city   : 'NA'}</h7></TableCell>
                      </TableRow>
                      <TableRow>
                        <p></p>
                      </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
  
      </React.Fragment>
      );
    }
    
const Avilable = ({context,props}) => {
    const [openn, setOpenn] = React.useState(false);
    const { avilable, }= context;
    // const {community}= props;
    let avilableproperty = avilable;

     avilableproperty = avilableproperty.filter(house => house.community === props.community)
     const handleOpen = () => {
      setOpenn(true);
    };
  
    const handleDone = () => {
      setOpenn(false);
    };
        console.log(props.community)
        console.log(avilable,"avilable")
    console.log("available",avilableproperty )
    if(avilableproperty.length === 0){
        return(
        <div className="Schools_KNW">
            <h5 className="Schools_h6AD">Contact  Us to know current availability  
            <div   className="Schools_BT">
            <Button  variant="contained"  color="primary"  size="small"
                       endIcon={<ChevronRightRoundedIcon />} onClick={handleOpen}>
                       Know More
               </Button></div> 
               <DailogformContact Open={openn} Close={handleDone} property={props.propertyname}/>
               </h5>
        </div>
        )
    }



    return( 
    <div> 
          <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
      <caption> <strong>HomeWAState.com Listings</strong></caption>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell><div className="Single_tabA">Name</div></TableCell>
            <TableCell><div className="Single_tabA">Completion Date</div></TableCell>
            {/* <TableCell ><div className="Single_tabA">House Facing</div></TableCell> */}
            <TableCell ><div className="Single_tabA">Price ($)</div></TableCell>
            <TableCell />
            {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
        {avilableproperty.map((item )=> (
                // return <AvilableHome key={item.id} home={item}/>
                //  console.log("item",item)
                
                <Row key={item} item={item} />
                 
        ))}
        
      
        </TableBody>
      </Table>
    </TableContainer>
       
    </div>
    )
}
export default withPropertyConsumer(Avilable);




















// import React from 'react';
// // import { useContext } from 'react';
// // import { PropertyContext } from '../context'
// import House from './House';
// import AvilableHome from './AvilableHome';
// import { withPropertyConsumer } from '../context';
// import ImagePopup from './ImagePopup'

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';

// const useRowStyles = makeStyles({
//     root: {
//       '& > *': {
//         borderBottom: 'unset',
//       },
//     },
//   });

  
// const Avilable = ({context,props}) => {
//     const { avilable, allHomes}= context;
//     const {community}= props;
//     let avilableproperty = avilable;
//      avilableproperty = avilableproperty.filter(house => house.community == props.community)
//         console.log(props.address)
//         console.log(avilable,"avilable")
//     console.log("available",avilableproperty )
//     if(avilableproperty.length== 0){
//         return(
//         <div>
//             <h5>Contact  Us to know current availability</h5>
//         </div>
//         )
//     }
//     return( 
//     <div> 
//         <div>
//             {/* {avilableproperty.map(item => {
//                 return <AvilableHome key={item.id} home={item}/>
//             })} */}
//               <TableContainer component={Paper}>
//       <Table aria-label="collapsible table">
//         <TableHead>
//           <TableRow>
//             <TableCell> <h8><strong>Available Properties under "{props.Adderss}"</strong></h8></TableCell>
//             <TableCell />
//           </TableRow>
//         </TableHead>
//         </Table>
       
//         {avilableproperty.map(item => {
//                 return <AvilableHome key={item.id} home={item}/>
//             })}
          
        
      
//     </TableContainer>
//         </div>
//     </div>
//     )
// }
// export default withPropertyConsumer(Avilable);