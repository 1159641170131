import React, { Component } from 'react'
// import items from './data';
import firebase from './firebase/firebase'


const PropertyContext = React.createContext();

class Properties extends Component {
    state = {
        rooms: [],
        sortedProperty: [],
        sortedCity: [],
        avilable: [],
        AvailabilityNav: [],
        AvailabilityNavSort: [],
        allHomes: [],
        featuredRooms: [],
        featuredProperty: [],
        loading: true,
        type: 'all',
        AVmergeStatus: '',
        AVmergeStatus: '',
        SoldmergeStatus: '',
        school: 'ALL',
        minPrice: 100000,
        maxPrice: 5000000,
        price: 5000000,
        builder: 'ALL',
        MlsId: 'ALL',
        propertyData: [],
        community: '',
        // city:[],
        city: 'ALL',
        AVcity: 'All',
        AVmergecity: 'All',
        Soldmergecity: 'All',
        Soldcity: 'All',
        clickCity: false,
        level1: 'ALL',
        Tag: "Recommended",
        clickDis: false,
        clickDis2: false,
        sortedscl: [],
        sortedscl2: [],
        sortedscl3: [],
        sortedscl4: [],
        sortedEle: [],
        sortedEle2: [],
        sortedEle3: [],
        sortedEle4: [],
        sortedbuilder: [],
        sortedbuilder2: [],
        sortedbuilder3: [],
        sortedbuilder4: [],
        ComingSoon: [],
        sortedlevel1: false,
        schooldistrict: false,
        sortedbuilders: false,
        AVprice: '',
        AVDate: '',
        AVcitySort: '',
        SoldSoFar: [],
        SoldSoFarSort: [],
        test: [],
        test2sort: [],
        Avlist: [],
        AvlistSort: [],
        Mergeall: [],
        SoldMergeall: [],
        SoldSoFarmerge: [],
        SoldSoFarmergeSort: [],
        listings: [],
        testimonials: [],
        homedeals: [],
        featuredListings: [],
        contacts: [],
        allListingHomes: [],
        updateListings: [],
        page: 0,
        offset: 0,
        max: 15
    };
    // Get Data


    componentDidMount() {
        var fireDB = firebase.database().ref();
        fireDB.child('properties').on('value', snapshot => {
            const newdata = Object.values(snapshot.val())
            // console.log("new data",newdata)

            let Property = newdata;
            Property = newdata.filter(home => home.active === false && home.Sold !== 'Sold');
            let featuredProperty = Property;
            let active = newdata;
            active = newdata.filter(home => home.active === true);
            let Sold = newdata;
            Sold = newdata.filter(home => home.Sold === 'Sold');
            // let featuredRooms = rooms.filter(room => room.featured === true);
            // let maxPrice= Math.max(...Property.map(newdata =>newdata.endprice));
            // console.log("price",maxPrice);
            let ComingSn = newdata;
            ComingSn = newdata.filter(home => home.status === 'Coming Soon');
            // console.log("currentAV", CurrentAv);


            // recommend tag sort
            const recommendedProperties = Property.filter(property => property.Tag === 'Recommended' || property.Tag !== 'Recommended');
            const sortedProperties = [ ...recommendedProperties]
        

            let Offer = active.filter(home => home.ActiveStatus)


            let result = active.map(item => {
                const obj = Property.find(o => o.community === item.community) || { ...item }
                return { ...item, slug: obj.slug, Tag: obj.Tag, propertyname: obj.propertyname, url: obj.url, featuredUrl: obj.featuredUrl }

            });


            ///////////////////////////  avaliable merge //////////////////////////////////////   

            let merge = [
                ...active.filter(house => house.OfferDate),
                ...active.filter(house => !house.OfferDate)
            ]
            

            merge = [
                ...active.filter(house => house.ActiveStatus === "Active"),
            ];

            merge = merge.map(item => {
                const obj = Property.find(o => o.community === item.community) || { ...item }
                return { ...item, slug: obj.slug ? obj.slug : null, Tag: obj.Tag, propertyname: obj.propertyname, url: obj.url, featuredUrl: obj.featuredUrl, }

            });


            let Avmerge = Property.map(item => {
                //         return {...active.filter(o => o.community === item.community)}
                return merge.filter(house => house.community === item.community)

            });

            // Avmerge = Avmerge.filter((c, index) => {
            //     return Avmerge.indexOf(c) === index;
            // });
            // Avmerge = [...new Map(Avmerge.map(item => [JSON.stringify(item), item])).values()];
            Avmerge = Avmerge.filter(value => Object.keys(value).length !== 0);
            Avmerge = [...new Map(Avmerge.map(item => [JSON.stringify(item), item])).values()];
            Avmerge = Avmerge.map(card => card.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            }))
            
            console.log("Availablr", Avmerge);

            let merge2 = [
                ...active.filter(house => house.OfferDate),
                ...active.filter(house => !house.OfferDate)
            ]


            merge2 = [
                ...active.filter(house => house.ActiveStatus == "Pending"),
            ];

            merge2 = merge2.map(item => {
                const obj = Property.find(o => o.community === item.community) || { ...item }
                return { ...item, slug: obj.slug ? obj.slug : null, Tag: obj.Tag, propertyname: obj.propertyname, url: obj.url, featuredUrl: obj.featuredUrl }

            });

            let Avmerge2 = Property.map(item => {

                return merge2.filter(house => house.community == item.community)
            });


            Avmerge2 = Avmerge2.filter(value => Object.keys(value).length !== 0);
            Avmerge2 = [...new Map(Avmerge2.map(item => [JSON.stringify(item), item])).values()];
            Avmerge2 = Avmerge2.map(card => card.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            }))
              console.log("pending",Avmerge2)

            let Mergeall = [
                ...Avmerge,
                ...Avmerge2
            ]

            //   let demo3 =testfinal.map(card => card[0])
            let Avlist = Mergeall.map(card => card.filter(card => card.OfferDate)[0] || card[0])


            Avlist = [
                ...Avlist.filter(house => house.OfferDate),
                ...Avlist.filter(house => !house.OfferDate)
            ]

            Avlist = Avlist.filter((c, index) => {
                return Avlist.indexOf(c) === index;
            });

            Avlist = Avlist.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })
            Avlist = [
                ...Avlist.filter(house => house.ActiveStatus == "Active"),
                ...Avlist.filter(house => house.ActiveStatus == "Pending")
            ]


            /////////////////////////////////////// Avaliable END ///////////////////////////////

            result = [
                ...result.filter(house => house.OfferDate),
                ...result.filter(house => !house.OfferDate)
            ]


            result = [
                ...result.filter(house => house.ActiveStatus == "Active"),
                ...result.filter(house => house.ActiveStatus == "Pending"),
            ];

            /////////////////////////////////////////////// Sold So far merge //////////////////////////////////

            let Soldmerge = [
                ...Sold.filter(house => house.OfferDate),
                ...Sold.filter(house => !house.OfferDate)
            ]

            Soldmerge = Soldmerge.map(item => {
                const obj = Property.find(o => o.community === item.community) || { ...item }
                return { ...item, slug: obj.slug, featuredUrl: obj.featuredUrl };
            });

            Soldmerge = Soldmerge.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })

            let SoldOut = Sold.map(item => {
                const obj = Property.find(o => o.community === item.community) || { ...item }
                return { ...item, slug: obj.slug, Tag: obj.Tag, propertyname: obj.propertyname, featuredUrl: obj.featuredUrl };
            });


            SoldOut = SoldOut.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })


            this.setState({
                rooms: Property,
                avilable: active,
                featuredProperty,
                //    sortedProperty: Property,
                sortedProperty: sortedProperties,
                allHomes: Property,
                AvailabilityNav: result,
                SoldSoFar: SoldOut,
                SoldSoFarSort: SoldOut,
                SoldSoFarmerge: Soldmerge,
                SoldSoFarmergeSort: Soldmerge,
                SoldSoFarmergeSort: Soldmerge,
                // SoldSoFarmerge: Soldlist,
                // SoldSoFarmergeSort: Soldlist,
                // SoldSoFarmergeSort: Soldlist,
                AvailabilityNavSort: result,
                Mergeall: Mergeall,
                SoldMergeall: Soldmerge,
                Avlist: Avlist,
                AvlistSort: Avlist,
                loading: false,
                ComingSoon: ComingSn,


            })

        })

    }


    getHouse = (slug) => {
        let temphouse = [...this.state.allHomes];
        const propertyslug = temphouse.find(home => home.slug === slug);
        // console.log("slug",propertyslug)
        return propertyslug;
    };
    handleChange2 = event => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.filterProperty);
        this.setState({ school: 'ALL', builder: 'ALL', level1: 'ALL', clickDis: true, schooldistrict: false })
    }

    handleChange3 = () => {

        this.setState({ clickDis: true, page: 0 })
    }
    handleChange4 = () => {

        this.setState({ clickDis: true, level1: 'ALL', builder: 'ALL' })
    }
    handleChange5 = (event) => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.filterProperty);
        this.setState({ clickDis: true, page: 0, school: 'ALL', city: 'ALL', level1: 'ALL', clickDis: true, schooldistrict: false })

    }

    handleChange6 = (event) => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.filterProperty);
        this.setState({ school: 'ALL', city: 'ALL', level1: 'ALL', builder: 'ALL', clickDis: true, schooldistrict: false })

    }


    handleChange = (event) => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.filterProperty);
        // console.log(`this is type:${target},this is name:${name},value:${value}`);
        // console.log("sorted",this.state.sortedProperty);

    };
    filterProperty = () => {
        let {
            allHomes, school, price, city, minPrice, maxPrice, builder, sortedCity, sortedscl, sortedbuilder, sortedEle, level1, Tag, Tag1, MlsId
        } = this.state
        let all = [...allHomes];
        let tempProperty = [...allHomes];
        let tempCity = [...allHomes]
        let tempscl = [...allHomes]
        let tempscl2 = [...allHomes]
        let tempscl3 = [...allHomes]
        let tempscl4 = [...allHomes]
        let tempbuilder = [...allHomes]
        let tempbuilder2 = [...allHomes]
        let tempbuilder3 = [...allHomes]
        let tempbuilder4 = [...allHomes]
        let tempele = [...allHomes]
        let tempele1 = [...allHomes]
        let tempele3 = [...allHomes]
        let tempele4 = [...allHomes]
        // if(city !== '' && school !=='All')
        if (school !== 'ALL') {
            tempProperty = tempProperty.filter(house => house.schools == school)
            tempscl = tempscl.filter(house => house.schools == school)
            tempscl2 = sortedCity.filter(house => house.schools == school)
            tempscl3 = sortedEle.filter(house => house.schools == school)
            tempscl4 = sortedbuilder.filter(house => house.schools == school)
            this.setState({ sortedscl: tempscl })
            this.setState({ sortedscl2: tempscl2, schooldistrict: true })
            this.setState({ sortedscl3: tempscl3, schooldistrict: true })
            this.setState({ sortedscl4: tempscl4, schooldistrict: true })
        } else {
            this.setState({ schooldistrict: false })
        }


        if (builder !== 'ALL') {
            tempProperty = tempProperty.filter(house => house.builders == builder)
            tempbuilder = tempbuilder.filter(house => house.builders == builder)
            tempbuilder2 = sortedCity.filter(house => house.builders == builder)
            tempbuilder3 = sortedscl.filter(house => house.builders == builder)
            tempbuilder4 = sortedEle.filter(house => house.builders == builder)
            this.setState({ sortedbuilder: tempbuilder })
            this.setState({ sortedbuilder2: tempbuilder2, sortedbuilders: true })
            this.setState({ sortedbuilder3: tempbuilder3, sortedbuilders: true })
            this.setState({ sortedbuilder4: tempbuilder4, sortedbuilders: true })
        } else {
            this.setState({ sortedbuilders: false })
        }

        if (level1 !== 'ALL') {
            tempProperty = tempProperty.filter(house => house.level1 == level1)
            tempele = tempele.filter(house => house.level1 == level1)
            tempele1 = sortedCity.filter(house => house.level1 == level1)
            tempele3 = sortedscl.filter(house => house.level1 == level1)
            tempele4 = sortedbuilder.filter(house => house.level1 == level1)
            this.setState({ sortedEle: tempele })
            this.setState({ sortedEle2: tempele1, sortedlevel1: true })
            this.setState({ sortedEle3: tempele3, sortedlevel1: true })
            this.setState({ sortedEle4: tempele4, sortedlevel1: true })
        } else {
            this.setState({ sortedlevel1: false })
        }


        tempProperty = tempProperty.filter(house => house.startprice >= minPrice &&
            house.endprice <= maxPrice || house.startprice == '')



        // if(city !== 'All'){
        //     tempProperty = tempProperty.filter(house => house.city == city)
        // }
        if (city !== '' && city !== 'ALL') {
            tempProperty = tempProperty.filter(house => house.city.toLowerCase().indexOf(city.toLowerCase()) !== -1)
            tempCity = tempCity.filter(house => house.city.toLowerCase().indexOf(city.toLowerCase()) !== -1)
            this.setState({
                sortedCity: tempCity,
                clickCity: true
            })
            //  console.log("city",tempCity)
        }
        if (city === 'ALL') {
            this.setState({
                clickCity: false
            })
            // if(school == 'All'){
            //     this.setState ({
            //         schooldistrict: false 
            //     })
            // }

        }
        // let tempProperty1 = [
        //     ...tempProperty.sort((a, b) => (b.onMarketDate > a.onMarketDate) ? 1 : -1).filter(house => house.status === "Currently Available"),
        //     ...tempProperty.sort((a, b) => (b.onMarketDate > a.onMarketDate) ? 1 : -1).filter(house => house.status !== "Currently Available"),
            
        //   ]
       
        this.setState({
            sortedProperty: tempProperty
        })
        // console.log("city:",city)
        console.log("sorted",tempProperty);
    };

    // activeProperty =() =>{
    //     let{
    //         avilable,community,
    //     } = this.state
    //     let tempavilable = [...avilable]
    //     tempavilable = tempavilable.filter(house => house.community === community)
    // };
    handleChangeAV = event => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.AvailableFilter);
        // console.log(`av:${target},this is name:${name},value:${value}`);
        // console.log("sorted",this.state.sortedProperty);

    };
    AvailableFilter = () => {
        let {
            allHomes, AVStatus, AVDate, AVcitySort, startprice, AVprice, AVcity, AVmin, minPrice, maxPrice, builder, sortedCity, level1, Tag, AvailabilityNav
        } = this.state
        let tempAVProperty = [...AvailabilityNav]
        if (AVcity !== '' && AVcity !== 'All') {
            tempAVProperty = tempAVProperty.filter(house => house.city.toLowerCase().indexOf(AVcity.toLowerCase()) !== -1)
        }



        if (AVStatus == 'min-max') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return a.startprice - b.startprice
                // return new Date(a.OfferDate) - new Date(b.OfferDate)
            })
        }
        if (AVStatus == 'max-min') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return b.startprice - a.startprice
            })
        }
        if (AVStatus == 'dateUp') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return new Date(a.OfferDate) - new Date(b.OfferDate)
            })
        }
        if (AVStatus == 'dateDown') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })
        }

        if (AVStatus == 'A - Z') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return a.city.localeCompare(b.city);
            })
        }
        if (AVStatus == 'Z - A') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return b.city.localeCompare(a.city);
            })
        }
        if (AVStatus == 'CM A - Z') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return a.propertyname.localeCompare(b.propertyname);
            })
        }
        if (AVStatus == 'CM Z - A') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return b.propertyname.localeCompare(a.propertyname);
            })
        }

        if (AVStatus == 'default') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
            ];
        }

        if (AVStatus !== '') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
            ];

        }

        if (AVStatus === 'Active') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
            ];

        }

        if (AVStatus === 'Pending') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Pending"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Pending"),
            ];

        }
        this.setState({
            AvailabilityNavSort: tempAVProperty
        })

    }

    handleChangeAVmerge = event => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.AvailableMergeFilter);
        console.log(`av:${target},this is name:${name},value:${value}`);
        console.log("sorted", this.state.sortedProperty);

    };
    AvailableMergeFilter = () => {
        let {
            allHomes, AVmergeStatus, AVDate, AVcitySort, startprice, AVprice, AVmergecity, test, Avlist
        } = this.state
        let tempAVProperty = [...Avlist]
        if (AVmergecity !== '' && AVmergecity !== 'All') {
            tempAVProperty = tempAVProperty.filter(house => house.city.toLowerCase().indexOf(AVmergecity.toLowerCase()) !== -1)
            // tempAVProperty = tempAVProperty.map(card => card.filter(card => card.city.toLowerCase().indexOf(AVmergecity.toLowerCase())!== -1))
        }
        if (AVmergeStatus == 'A - Z') {
            // tempAVProperty = tempAVProperty.map(card => card[0].sort((a, b) =>  a.city.localeCompare(b.city)
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return a.city.localeCompare(b.city);
            })
        }
        if (AVmergeStatus == 'Z - A') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return b.city.localeCompare(a.city);
            })
        }
        if (AVmergeStatus == 'CM A - Z') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return a.propertyname.localeCompare(b.propertyname);
            })
        }
        if (AVmergeStatus == 'CM Z - A') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return b.propertyname.localeCompare(a.propertyname);
            })
        }
        if (AVmergeStatus == 'dateUp') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return new Date(a.OfferDate) - new Date(b.OfferDate)
            })
        }
        if (AVmergeStatus == 'dateDown') {
            tempAVProperty = tempAVProperty.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })
        }
        if (AVmergeStatus === 'Active') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
            ];

        }
        tempAVProperty = [
            ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
            ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
        ]

        if (AVmergeStatus == 'default') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
            ];
        }

        if (AVmergeStatus !== '') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Active"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Active"),
            ];
        }


        if (AVmergeStatus === 'Pending') {
            tempAVProperty = [
                ...tempAVProperty.filter(house => house.ActiveStatus == "Pending"),
                ...tempAVProperty.filter(house => house.ActiveStatus != "Pending"),
            ];

        }


        tempAVProperty = tempAVProperty.filter(value => Object.keys(value).length !== 0);


        this.setState({
            AvlistSort: tempAVProperty
        })

    }
    handleChangeSoldmerge = event => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.SoldMergeFilter);
        console.log(`av:${target},this is name:${name},value:${value}`);
        console.log("sorted", this.state.sortedProperty);
        console.log("value", this.state.SoldmergeStatus);

    };
    SoldMergeFilter = () => {
        let {
            allHomes, SoldSoFarmerge, SoldmergeStatus, Soldmergecity
        } = this.state
        let tempSoldProperty = [...SoldSoFarmerge]
        if (Soldmergecity !== '' && Soldmergecity !== 'All') {
            tempSoldProperty = tempSoldProperty.filter(house => house.city.toLowerCase().indexOf(Soldmergecity.toLowerCase()) !== -1)

        }
        if (SoldmergeStatus == 'A - Z') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return a.city.localeCompare(b.city);
            })
        }
        if (SoldmergeStatus == 'Z - A') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return b.city.localeCompare(a.city);
            })
        }
        if (SoldmergeStatus == 'CM A - Z') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return a.community.localeCompare(b.community);
            })
        }
        if (SoldmergeStatus == 'CM Z - A') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return b.community.localeCompare(a.community);
            })
        }
        if (SoldmergeStatus === 'dateUp') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return new Date(a.OfferDate) - new Date(b.OfferDate)
            })
        }
        if (SoldmergeStatus == 'dateDown') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })
        }


        tempSoldProperty = tempSoldProperty.filter(value => Object.keys(value).length !== 0);


        this.setState({
            SoldSoFarmergeSort: tempSoldProperty
        })

    }




    handleChangeSold = event => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.SoldSort);
        console.log(`this is type:${target},this is name:${name},value:${value}`);
        console.log("sorted", this.state.sortedProperty);

    };
    SoldSort = () => {
        let {
            allHomes, SoldStatus, SoldSoFar, Soldcity,
        } = this.state
        let tempSoldProperty = [...SoldSoFar]
        if (Soldcity !== '' && Soldcity !== 'All') {
            tempSoldProperty = tempSoldProperty.filter(house => house.city.toLowerCase().indexOf(Soldcity.toLowerCase()) !== -1)
        }



        if (SoldStatus == 'min-max') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return a.startprice - b.startprice
                // return new Date(a.OfferDate) - new Date(b.OfferDate)
            })
        }
        if (SoldStatus == 'max-min') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return b.startprice - a.startprice
            })
        }
        if (SoldStatus == 'dateUp') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return new Date(a.OfferDate) - new Date(b.OfferDate)
            })
        }
        if (SoldStatus == 'dateDown') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return new Date(b.OfferDate) - new Date(a.OfferDate)
            })
        }

        if (SoldStatus == 'A - Z') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return a.city.localeCompare(b.city);
            })
        }
        if (SoldStatus == 'Z - A') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return b.city.localeCompare(a.city);
            })
        }
        if (SoldStatus == 'CM A - Z') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return a.propertyname.localeCompare(b.propertyname);
            })
        }
        if (SoldStatus == 'CM Z - A') {
            tempSoldProperty = tempSoldProperty.sort(function (a, b) {
                return b.propertyname.localeCompare(a.propertyname);
            })
        }


        this.setState({
            SoldSoFarSort: tempSoldProperty
        })
    }

    handleRset = () => {

        this.setState({ AVmergeStatus: "", AVDate: "", AVprice: '', Soldmergecity: "" })
    }

    handleRsetSold = () => {

        this.setState({ SoldStatus: "" })
    }
    // updateListings = (Listings) => {

    //     this.setState({ listings: Listings })
    // }
    getListingHouse = (MlsId) => {
        let listhouse = [...this.state.listings];
        const listingMlsId = listhouse.find(item => item.MlsId === MlsId);
        // console.log("MlsId ", listingsMlsId )
        return listingMlsId;
    };
    getData = () => {
        var fireDB = firebase.database().ref();
        fireDB.child('Listings').on('value', snapshot => {
            const newdata = Object.values(snapshot.val())
            this.setState({ listings: newdata })
            // console.log(this.state.listings)
        })
    }

    TestimonialData = () => {
        var fireDB = firebase.database().ref();
        fireDB.child('Testimonials').on('value', snapshot => {
            const newdata = Object.values(snapshot.val())
            this.setState({ testimonials: newdata })
            // console.log(this.state.listings)
        })
    }

    WhatsAppContacts = () => {
        var fireDB = firebase.database().ref();
        fireDB.child('Contacts').on('value', snapshot => {
            const newdata = Object.values(snapshot.val())
            this.setState({ contacts: newdata })
            // console.log(this.state.listings)
        })
    }


    FeaturedListings = () => {
        var fireDB = firebase.database().ref();
        fireDB.child('FeaturedListings').on('value', snapshot => {
            const newdata = Object.values(snapshot.val())
            this.setState({ featuredListings: newdata })
            // console.log(this.state.listings)
        })
    }

    DealsData = () => {
        var fireDB = firebase.database().ref();
        fireDB.child('Homedeals').on('value', snapshot => {
            const newdata = Object.values(snapshot.val())
            this.setState({ homedeals: newdata })
            // console.log(this.state.listings)
        })
    }

    handleChangeListing = event => {
        const target = event.target;
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.ListingFilter);
        // console.log(`av:${target},this is name:${name},value:${value}`);
        // console.log("sorted", this.state.sortedProperty);

    };
    ListingFilter = () => {
        let {
            listings
        } = this.state
        let templistings = [...listings]
        if (listings === "") {
            templistings = templistings.filter(item => item.city.toLowerCase().indexOf(listings.toLowerCase()) !== -1)

        }
        this.setState({
            templistings
        })
    }

    render() {
        return (
            <PropertyContext.Provider value={{
                ...this.state, getHouse: this.getHouse, handleChange: this.handleChange,
                handleChange2: this.handleChange2, handleChange3: this.handleChange3, handleChange4: this.handleChange4,
                handleChange5: this.handleChange5, handleChange6: this.handleChange6, handleChangeAV: this.handleChangeAV, handleChangeSold: this.handleChangeSold,
                handleRset: this.handleRset, handleRsetSold: this.handleRsetSold, handleChangeAVmerge: this.handleChangeAVmerge, handleChangeSoldmerge: this.handleChangeSoldmerge,
                updateListings: this.updateListings, getListingHouse: this.getListingHouse, getData: this.getData, handleChangeListing: this.handleChangeListing, TestimonialData: this.TestimonialData,
                WhatsAppContacts: this.WhatsAppContacts, FeaturedListings: this.FeaturedListings, handlePageSelect: this.handlePageSelect, DealsData: this.DealsData,
            }}>
                {this.props.children}
            </PropertyContext.Provider>
        );
    }
}

const PropertyConsumer = PropertyContext.Consumer;

export function withPropertyConsumer(Component) {
    return function ConsumerWrapper(props) {
        return <PropertyConsumer>
            {value => <Component {...props} context={value} />}
        </PropertyConsumer>
    }
}

export { Properties, PropertyConsumer, PropertyContext };














