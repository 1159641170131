import React, { useState, useEffect, } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BuyerContact } from '../../contact/BuyerContact';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Container, CssBaseline, Card, CardContent, Typography, CardActions, Grid, TextField, Button, LinearProgress } from '@mui/material'
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';
import OutlinedInput from '@mui/material/OutlinedInput';
import logoS2 from '../../images/logoS.5.png';
import Snackbar from '@material-ui/core/Snackbar';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';


const useStyles = makeStyles((theme) => ({

  root2: {
    display: 'flex',
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer",
    background: 'linear-gradient(110deg, #f50057, #e8ab78, #1bd7b4, #36c91b)',
    backgroundSize: '400% 400%',
    animation: 'change 10s ease-in-out infinite',
    borderRadius: 4,
    padding: 10,
    maxWidth: 390,
    [theme.breakpoints.up("md")]: {
      height: 80,
    },
    alignItems: "center",
  },
  '@keyframes change': {
    '0%': {
      backgroundPosition: ' 0 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    ' 100%': {
      backgroundPosition: '0 50%'
    },
  },
  textfield: {
    background: 'White',
    color: 'black'
  }

}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function BookConsultation() {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [date, setDate] = useState();
  const [openError, setOpenError] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClickError = () => {
    setOpenError(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, open: false });

  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };




  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }
  const handleEmailSubmit = e => {
    e.preventDefault();
    setProgress(true)
    setLoading(true)
    emailjs.sendForm(
      'service_b9v5hfs',
      'template_h41a2rl',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setProgress(false);
        setLoading(false);
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setLoading(false);
        setProgress(false);
      });

    e.target.reset()
  }

  return (
    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
      {/* <Card className={classes.root2} elevation={5} onClick={handelClickOpen}>
          <Typography component="h5" variant="h5" style={{ fontSize: '1.3rem' }}>
            Book a Consultation call With Deepti today!! <DoubleArrowIcon />
          </Typography>
        </Card>
        <BuyerContact Open={open} Close={handleClose} /> */}
      <Card style={{ background: '#0d76d6', color: 'white' }}>
        <CssBaseline />
        <Grid xs={12} align="center" style={{ marginTop: '-4em' }}>
          <img src={logoS2} width={200} height={200} alt="HomesWaState" />
        </Grid>
        <CardContent style={{ marginTop: '-5em' }}>
          {/* <TextField
                        id="outlined-read-only-input"
                        label="Read Only"
                        defaultValue="Hello World"
                        InputProps={{
                            readOnly: true,
                        }}
                    /> */}
          <Typography gutterBottom variant="h5" component="div" style={{ color: 'white', fontWeight: '500' }}>
          Book a consultation call with Deepti today!!
          </Typography>
          <form onSubmit={handleEmailSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} >
                <OutlinedInput
                  style={{ background: 'White', color: 'black' }}
                  // autoFocus
                  variant="outlined"
                  required
                  id="Name"
                  name="name"
                  placeholder="Name"
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} >
                <OutlinedInput
                  style={{ background: 'White', color: 'black' }}
                  required
                  variant="outlined"
                  id="Phone"
                  name="phone"
                  placeholder="Phone"
                  fullWidth
                  onChange={handleChange}
                />

              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  style={{ background: 'White', color: 'black', }}
                  required
                  variant="outlined"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                            <TextField
                                type="address"
                                required
                                variant="outlined"
                                fullWidth
                                id="address"
                                label="Address"
                                name="address"
                                autoComplete="address"
                                className="text_contact"
                                value={`${Address}`}
                                onChange={handleChange}
                            />
                        </Grid> */}
              <Grid item xs={12} >
                <FormControl sx={{ background:'white', borderRadius: '3px', padding: '3px 0px 0px 7px' }} variant="outlined">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="MM/dd/yyyy"
                      fullWidth
                      variant="outlined"
                      label="Consultation Date"
                      name="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      renderInput={(params) => <TextField className={classes.textfield} {...params} />}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  style={{ background: 'White', color: 'black' }}
                  variant="outlined"
                  id="message"
                  name="message"
                  placeholder="Message"
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleChange}
                  multiline
                  rows={5}
                />
              </Grid>

              <Grid item xs={12} align="center" >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#f50057' }}
                  disabled={loading}
                  onClick={handleClick({
                    vertical: 'bottom',
                    horizontal: 'right',
                  })}
                >
                  Submit
                </Button>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                autoHideDuration={8000}
                key={vertical + horizontal}>
                <Alert onClose={handleClose} severity="success">
                  Thank you for getting in touch!
                  We appreciate you contacting us. One of our team members will get back in touch with you soon! Have a great day!
                </Alert>
              </Snackbar>
              <Snackbar open={openError} onClose={handleClose} autoHideDuration={8000}>
                <Alert onClose={handleClose} severity="error">
                  Please try again!
                </Alert>
              </Snackbar>
            </Grid>
          </form >


        </CardContent>
      </Card>
    </div>

  )
}

export default BookConsultation



