import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import Divider from '@material-ui/core/Divider';
import '../components/ImagePopup.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { ArticleRounded, MapRounded, RoofingRounded, ThreeDRotationRounded, WorkOutlineRounded, HolidayVillageRounded, StarRounded, GroupWorkRounded } from '@mui/icons-material';
import { Drawer, IconButton, MenuList } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import TourIcon from '@mui/icons-material/Tour';
import BookTour from '../contact/BookTour';
import Axios from 'axios';
import nwmlslogo from '../images/nwmls_small.png';
import LicenseDrawer from './LicenseDrawer';


// 

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        minHeight: 500,
        background: 'red',
        borderRadius: '30px'
    },
    h5: {
        padding: 0,
    },
    tabcolour: {
        background: 'red',
    },
    text: {
        fontWeight: 800,
        fontSize: '12px',
        color: ' #4b4b4b',
        minWidth: 140,
        maxWidth: 150,
    },
    text2: {
        fontWeight: 800,
        fontSize: '12px',
        backgroundColor: '#ffce34',
        color: 'black',
        minWidth: 190,
        maxWidth: 150,
    },
    h7: {
        fontSize: 16,
        lineHeight: 2,

    },
    chip: {
        display: 'flex',
        // justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.9),
        },
    },
    chiptext: {
        fontSize: 12,
        fontWeight: 500,
        paddingRight: 9,
        paddingLeft: 9
    },
    listitemtext: {

        textTransform: "none",
        fontSize: " 2rem",
        fontWeight: 600,
        width: "auto",


    },
}));




function DetailData(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState('one');
    const [open2, setOpen2] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [openSc, setOpenSc] = React.useState(true);
    const [openHm, setOpenHm] = React.useState(true);
    const [openBk1, setOpenBk1] = React.useState(false);
    const [elementryschooldata, setElementryschooldata] = useState([]);
    const [middleschooldata, setMiddleschooldata] = useState([]);
    const [highschooldata, setHighschooldata] = useState([]);

    const HomeDetailScrolldiv = React.useRef(null)
    const DetailScrolldiv = React.useRef(null)
    const SchoolScrolldiv = React.useRef(null)
    
    var elemStr = props.Level1;
    var elemschoolStr = elemStr.slice(0, elemStr.indexOf('School'));
    var elementarySchool = elemschoolStr + 'School';
    console.log("School String", elementarySchool)
    var elemzip = elemStr.slice(-5);
    console.log("School String", elemzip)
    var str = props.Level1;
    var arr = str.slice(0, str.indexOf('WA'));
    var elemcity = arr.substring(arr.indexOf(',') + 1)
    console.log("Array", elemcity);



    var middStr = props.level2;
    var middschoolStr = middStr.slice(0, middStr.indexOf('School'));
    var middleSchool = middschoolStr + 'School';
    console.log("School", middleSchool)
    var middzip = middStr.slice(-5);
    console.log("zip", middzip)
    var str2 = props.level2;
    var arr2 = str2.slice(0, str2.indexOf('WA'));
    var middcity = arr2.substring(arr2.indexOf(',') + 1)
    console.log("city", middcity);



    var highStr = props.Level3;
    var highschoolStr = highStr.slice(0, highStr.indexOf('School'));
    var highSchool = highschoolStr + 'School';
    console.log("School", highSchool)
    var highzip = highStr.slice(-5);
    console.log("zip", highzip)
    var str3 = props.Level3;
    var arr3 = str3.slice(0, str3.indexOf('WA'));
    var highcity = arr3.substring(arr3.indexOf(',') + 1)
    console.log("city", highcity);


    const HomeDetailScroll = () => HomeDetailScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const DetailScroll = () => DetailScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const SchoolScroll = () => SchoolScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const handleClickHomeDetails = () => {
        setOpenHm(!openHm);
    };

    const handleClickSchool = () => {
        setOpenSc(!openSc);
    };
    const handleClickBook = () => {
        setOpenBk1(true);
    };
    const handleClose = () => {
        setOpenBk1(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  
    const handleClickOpen1 = () => {
        setOpen2(true);
    };


    const handleClose1 = () => {
        setOpen2(false);
    };


    useEffect(() => {
        try {
            Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?zip=${elemzip}&city=${elemcity}&level_codes=e&school_type=(public or charter)&name=${elementarySchool}&limit=25`,
                {
                    headers: {
                        'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
                    }
                })
                .then(data => {
                    setElementryschooldata(data.data.schools);
                    console.log("elem", data)
                })


            Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?zip=${middzip}&city=${middcity}&level_codes=m&name=${middleSchool}&limit=25`, {
                headers: {
                    'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
                }
            })
                .then(data => {
                    setMiddleschooldata(data.data.schools);
                    console.log("middle", data)
                })

            Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?zip=${highzip}&city=${highcity}&level_codes=m&name=${highSchool}&limit=25`, {
                headers: {
                    'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
                }
            })
                .then(data => {
                    setHighschooldata(data.data.schools);
                    console.log("high", data)
                })
        }
        catch (err) {
            console.log(console.err)
        }


    }, [])

    const {
        title,
        city,
        children,
        Schools,
        Level1,
        level2,
        Level3,
        Builders,
        Details,
        Bedroom,
        Bathroom,
        Kitchen,
        Adderss,
        floorUrl,
        Map,
        ActiveStatus,
        url,
        siteMap,
        propertyname,
        community,
        video,
        MlsId,
        style,
        builtyear,
        zip,
        index, } = props;
    console.log("video", video)
    console.log("scll", level2)


    const schoolSearch = [elementarySchool];
    const schoolMidd = [middleSchool];
    const schoolHigh = [highSchool];

    let elemschool = schoolSearch.map(item => {
        const obj = elementryschooldata.find(o => o.name === item) || { ...item }
        return { ...item, name: obj.name, rating: obj.rating }

    });
    console.log('elementary', elemschool)


    let middschool = schoolMidd.map(item => {
        const obj = middleschooldata.find(o => o.name === item) || { ...item }
        return { ...item, name: obj.name, rating: obj.rating }

    });

    console.log('elementary', middschool)

    let highschool = schoolHigh.map(item => {
        const obj = highschooldata.find(o => o.name === item) || { ...item }
        return { ...item, name: obj.name, rating: obj.rating }

    });

    console.log('elementary', highschool)



    return (

        <div className={classes.root} >
            <Paper elevation={0} >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="disabled tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab label="Details" icon={<HomeWorkRoundedIcon />}
                        value="one" onClick={DetailScroll}
                        className={classes.text} {...a11yProps('one')}
                    />
                    <Tab label="Home Details" icon={<HolidayVillageRounded />}
                        value="two" onClick={HomeDetailScroll}
                        className={classes.text} {...a11yProps('two')}
                    />
                    {/* <Tab label="Availablity" icon={<TransferWithinAStationRoundedIcon />} value="two" onClick={AvaliableScroll}
                        className={classes.text} {...a11yProps('two')} /> */}
                    <Tab label="School" icon={<SchoolRoundedIcon />} value="three" onClick={SchoolScroll}
                        className={classes.text} {...a11yProps('three')} />
                    {
                        (ActiveStatus === 'Sold' || ActiveStatus === 'Pending') ?
                            null
                            :
                            <div>
                                <Tab label="BOOK YOUR TOUR NOW" icon={<TourIcon />} onClick={handleClickBook}
                                    className={classes.text2} />
                                <BookTour Open={openBk1} Close={handleClose} Address={props.Adderss} MlsId={props.MlsId} />
                            </div>
                    }
                </Tabs>
                <Divider />
                <div ref={DetailScrolldiv}>
                    <div className={classes.chip}>
                        {/* <h5>Highlights : </h5> */}
                        <Chip
                            icon={<OpenWithRoundedIcon />}
                            label={`Size : ${props.Size} Sq.Ft`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<MapRounded />}
                            label={`Plot Size : ${props.PlotSize}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<StarRounded />}
                            label={`Style : ${props.style}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<WorkOutlineRounded />}
                            label={`Built year : ${props.builtyear}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<LocalHotelIcon />}
                            label={`Beds : ${props.bedrooms}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<BathtubIcon />}
                            label={`Baths : ${props.bathrooms}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<DirectionsCarRoundedIcon />}
                            label={`Bay Garage : ${props.parking}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<MonetizationOnRoundedIcon />}
                            label={`Annual Taxes : ${props.AnnualTaxes}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<AttachMoneyRoundedIcon />}
                            label={`HOA Dues: ${props.Hoa}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<TodayRoundedIcon />}
                            label={`Tax Year : ${props.TaxYear}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<RoofingRounded />}
                            label={`Roof : ${props.Roof}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<ArticleRounded />}
                            label={`Property Type : ${props.PropertyType}`}
                            className={classes.chiptext}
                        />
                        <Chip
                            icon={<GroupWorkRounded />}
                            label={`Exterior : ${props.exterior}`}
                            className={classes.chiptext}
                        />
                    </div>
                    <div className="detail_font">
                        <h5>Details</h5>
                        <h6>{props.Details} </h6>
                    </div>
                </div>
                <div ref={HomeDetailScrolldiv}>
                    <ListItem button onClick={handleClickHomeDetails}>
                        <ListItemIcon>
                            {openHm ? <ExpandLess style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} /> : <ExpandMore style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} />}
                        </ListItemIcon>
                        <Typography style={{ fontSize: "1.2rem" }}>Home Details</Typography>
                    </ListItem>
                    <Divider variant="middle" />
                    <Collapse in={openHm} timeout="auto" unmountOnExit style={{ padding: "30px 0px 40px 30px" }}>
                        <TableContainer component={Paper}  >
                            <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Source</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400", width: '30%' }} >NWMLS #{` ${props.MlsId}`}
                                        <IconButton disableRipple
                                            sx={{
                                                '&:hover': {
                                                    background: 'none',
                                                }
                                            }}>
                                            <img src={nwmlslogo} alt="NWMLS Logo" height="20px"
                                                style={{
                                                }} onClick={handleClickOpen1} />
                                        </IconButton>
                                    </TableCell>
                                    <Drawer
                                        anchor='right'
                                        open={open2}
                                        onClose={handleClose1}
                                        sx={{
                                            width: '50%',
                                            flexShrink: 0,
                                            [`& .MuiDrawer-paper`]: { width: '50%', bgcolor: 'rgb(5, 30, 52)', color: 'white' },
                                        }}
                                    >
                                        <LicenseDrawer Close={handleClose1} />
                                    </Drawer>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Size</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }} >{` ${props.Size} Sq.Ft.`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Plot Size</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.PlotSize}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Beds</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.bedrooms}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Baths</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.bathrooms}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Bay Garage</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.parking}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Annual Taxes</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.AnnualTaxes}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>HOA Dues</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Hoa}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Tax Year </TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.TaxYear}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Roof</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Roof}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Property Type</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.PropertyType}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Exterior</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.exterior}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Style</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.style}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Built year</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.builtyear}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>School District</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Schools}`}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Elementary School</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{elementarySchool}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Middle School</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{middleSchool}</TableCell>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>High School</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{highSchool}</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                        {
                            (ActiveStatus === 'Sold') ?
                                null
                                :
                                <div>
                                    <Divider variant="middle" />
                                    {(() => {
                                        if (video !== undefined && video !== "")
                                            return (
                                                <div>
                                                    <MenuList>
                                                        <MenuItem>
                                                            <ListItemIcon>
                                                                <ThreeDRotationRounded />
                                                            </ListItemIcon>
                                                            <Typography style={{ fontSize: "1.2rem" }}>360 Virtual Tour</Typography>
                                                        </MenuItem>
                                                    </MenuList>
                                                    <div style={{ padding: "30px 30px 40px " }}>
                                                        <iframe src={`${props.video}`}
                                                            width="100%"
                                                            height="500px"
                                                            frameborder="0"
                                                            allowfullscreen
                                                            allow="xr-spatial-tracking">
                                                        </iframe>
                                                    </div>
                                                </div>
                                            )
                                    })()}
                                </div>
                        }
                    </Collapse>
                </div>


                <div ref={SchoolScrolldiv}>
                    <ListItem button onClick={handleClickSchool}>
                        <ListItemIcon>
                            {openSc ? <ExpandLess style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} /> : <ExpandMore style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} />}
                        </ListItemIcon>
                        <Typography style={{ fontSize: "1.2rem" }}>Area Schools</Typography>
                        {/* <ListItemText primary="Community Map"  classes={{primary:classes.listItemText}}/> */}
                    </ListItem>
                    <Divider variant="middle" />
                    <Collapse in={openSc} timeout="auto" unmountOnExit style={{ padding: "30px 0px 40px 30px" }}>
                        <div className='great_div' >
                            {/* <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">
                                <img src={greatschool} alt="Great School" className='great_schoolLogo' /></a> */}
                            <div style={{ fontSize: '1rem', fontWeight: '450' }}>School District</div>
                            <h6 style={{ fontSize: 13, fontWeight: 400, marginTop: 0 }}> {props.Schools}</h6>

                        </div>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">
                                    <img src={greatschool} alt="Great School" className='great_schoolLogo' />
                                </a>
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <h7 className="Schools_h7" style={{ marginTop: 0 }}> {props.Schools}</h7>
                            </Grid> */}
                            <Grid item xs={12}>
                                {(() => {
                                    if (!props.Level1 && !props.level2 && !props.Level3) {
                                        return <div>
                                            {/* <h6 className="Schools_h6"> Available Soon</h6> */}
                                            <p><strong>Available Soon.</strong>This home is within the <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank" >Lake Washington School District.</a></p>
                                            <p> <strong>{props.city} enrollment policy is not based solely on geography.</strong>  Please check the school district website to see all schools serving this home.</p>
                                        </div>
                                    }
                                    else {
                                        return (

                                            <TableContainer component={Paper} style={{ width: '80%' }}>
                                                <Table >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell > <div className="Single_tab">Type</div></TableCell>
                                                            <TableCell> <div className="Single_tab" >Schools</div></TableCell>
                                                            {/* <TableCell> <div className="Single_tab" >Rating</div></TableCell> */}
                                                            {/* <TableCell> <div className="Single_tab" >Students</div></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" >
                                                                <h6 className="Schools_h6" > Elementary school</h6>
                                                            </TableCell>
                                                            <TableCell ><h7 className="Schools_h7">{elementarySchool ? elementarySchool : 'NA'} </h7></TableCell>
                                                            {/* <TableCell >
                                                                {elemschool.map((item) => (
                                                                    <div className="Schools_Rating2" key={item.id}>
                                                                        <h7 className="Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'}</h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                                                            {/* <TableCell ><h7 className="Schools_h7">{elementryschooldata?.enrollment ? elementryschooldata.enrollment :'NA'} </h7></TableCell> */}
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" >
                                                                <h6 className="Schools_h6">Middle schools</h6>
                                                            </TableCell>
                                                            <TableCell ><h7 className="Schools_h7">{middleSchool ? middleSchool : 'NA'}</h7> </TableCell>
                                                            {/* <TableCell >
                                                                {middschool.map((item) => (
                                                                    <div className="Schools_Rating2" key={item.id}>
                                                                        <h7 className="Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'}</h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                                                            {/* <TableCell ><h7 className="Schools_h7">{middleschooldata?.enrollment ? middleschooldata.enrollment :'NA'} </h7></TableCell> */}
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" >
                                                                <h6 className="Schools_h6">High schools</h6>
                                                            </TableCell>
                                                            <TableCell ><h7 className="Schools_h7">{highSchool ? highSchool : 'NA'}</h7> </TableCell>
                                                            {/* <TableCell >
                                                                {highschool.map((item) => (
                                                                    <div className="Schools_Rating2" key={item.id}>
                                                                        <h7 className="Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'}</h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                                                            {/* <TableCell ><h7 className="Schools_h7">{highschooldata?.enrollment ? highschooldata.enrollment :'NA'} </h7></TableCell> */}
                                                        </TableRow>

                                                    </TableBody>
                                                    {/* <caption> <strong>
                                                        School data provided by <>
                                                            <a href="https://www.greatschools.org/" target="_blank">GreatSchools.</a> </>
                                                        School service boundaries are intended to be used as reference only. To verify enrollment eligibility for a property, contact the school directly.
                                                    </strong></caption> */}
                                                </Table>
                                            </TableContainer>
                                        )
                                    }
                                })()}
                            </Grid>
                        </Grid>
                        <div className="Schools_h">

                        </div>
                    </Collapse>
                </div>

            </Paper>

        </div>

    );
}
export default DetailData;



