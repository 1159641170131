import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Checkbox, IconButton, Stack, Typography } from '@mui/material'
import emailjs from 'emailjs-com';
import MuiAlert from '@material-ui/lab/Alert';
import { FormControlLabel } from '@material-ui/core';
import { CloseRounded } from '@mui/icons-material';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ListingsDailog = (props) => {
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [isChecked1, setIsChecked1] = React.useState(false);
    const {
        Open,
        Close,
    } = props

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    }
    const handleClickError = () => {
        setOpenError(true);
    };

    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }
    const handleEmailSubmit = e => {
        e.preventDefault();
        emailjs.sendForm(
            'service_b9v5hfs',
            'template_n577qqi',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
            });

        e.target.reset()
    }


    return (
        <div>
            <Dialog open={Open} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper">
                <DialogTitle>Connect with Deepti
                    <IconButton
                        aria-label="close"
                        onClick={Close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent >
                    <form onSubmit={handleEmailSubmit}>
                        <Grid container elevation={1} rounded spacing={1} style={{padding: '0px 10px'}}>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    // className="text_contact"
                                    // value={values.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    // className="text_contact"
                                    // value={values.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Phone Number"
                                    name="phone"
                                    type="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    // value={values.number}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="address"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="address"
                                    label="Address"
                                    name="address"
                                    autoComplete="address"
                                    className="text_contact"
                                    // value={values.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    autoComplete="message"
                                    className="text_contact"
                                    // value={values.message}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Stack direction="row" spacing={5}>
                                    <Stack direction="row" spacing={0}>
                                        <Checkbox id="selling" value="selling" name="selling" label="Selling" onChange={handleChange} />
                                        <Typography sx={{ fontSize: '1.1rem', fontWeight: 400, pt: '8px' }}>SELLING</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={0}>
                                        <Checkbox id="buying" value="buying" name="buying" label="buying" placeholder='Buying' onChange={handleChange} />
                                        <Typography sx={{ fontSize: '1.1rem', fontWeight: 400, pt: '8px' }}>BUYING</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} >
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{ m: '1em 0em' }}
                                >
                                    Send
                                </Button>
                            </Grid>
                        </Grid>

                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ marginTop: '10em' }}>
                            <Alert onClose={handleClose} severity="success">
                                Email has been sent to admin team. They will reach out to you for scheduling in 1-4 hours.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error">
                                Please try again!
                            </Alert>
                        </Snackbar>
                    </form>

                </DialogContent>

            </Dialog>
        </div>
    )
}

export default ListingsDailog
