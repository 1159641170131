import React, { useState, useEffect } from 'react';
import firebase from '../firebase/firebase'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pannel from './Pannel';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';

const Loading = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const StyledTableCell = withStyles((theme) =>
    createStyles({
        head: {
            backgroundColor: '#2979ff',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    root: {
        flexGrow: 1,
    },
    LogoutButton: {
        float: 'right',
        marginRight: '200px',
        color: 'white',
    },
    DashBord: {
        marginTop: 50,
    },
    paper: {
        margin: 'auto',
        maxWidth: 1300,
    },
    title: {
        flexGrow: 1,
    },
});

const AdminPannel = () => {
    const classes = useStyles();
    const classes2 = Loading();
    var fireDB = firebase.database().ref();
    var [propertyObject, setPropertyObject] = useState([])
    var [propertyObject1, setPropertyObject1] = useState([])
    var [currentId, setCurrentId] = useState('')
    const [open, setOpen] = useState(false);
    //firebase data retrive
    const propertyPath = `properties/${currentId}/scoop`;

    useEffect(() => {
        fireDB.child('properties').on('value', snapshot => {
            if (snapshot.val() != null)
                setPropertyObject({
                    ...snapshot.val()
                })
            else
                setPropertyObject({})
        })
    }, [])

    React.useEffect(() => {
        fireDB.child(`${propertyPath}/`).on('value', snapshot => {
            if (snapshot.val() != null)
                setPropertyObject1({
                    ...snapshot.val()
                })
            else
                setPropertyObject1({})
        })
    }, [propertyPath]);

    useEffect(() => {
        fireDB.child('properties').child('url').on('child_added', snapshot => console.log("child event:", snapshot.val()))
    }, [])

    //similar to component didMount
    //Firebase data push
    // console.log('scoop', Object.keys(propertyObject1).map((key) => key))

    const add = obj => {
        if (window.confirm('Are you sure')) {
            if (currentId === '')
                fireDB.child('properties').push(
                    obj,
                    err => {
                        if (err)
                            console.log(err)
                        else
                            setCurrentId('')
                        console.log("obj:", obj);
                    }
                )
            else
                // fireDB.child(`properties/${currentId}`).set(
                fireDB.child(`properties/${currentId}`).update(
                    obj,
                    err => {
                        if (err)
                            console.log(err)
                        else
                            setCurrentId('')
                        console.log("obj:", obj);
                    }
                )
        }

    }

    const onDelete = async (key) => {
        if (window.confirm('Are you sure')) {
            setOpen(true)
            let data = [...propertyObject[key].url || '', ...propertyObject[key].floorUrl || '', ...propertyObject[key].siteurl || '', ...propertyObject[key].featuredUrl || '', ...propertyObject[key].clientImage || '']

            await Promise.all(
                data.map(element =>
                    firebase.storage().refFromURL(element)
                        .delete().then(function () {
                            console.log('image deleted');
                        }).catch(function (error) {
                            console.log('an error occurred');
                        }))
            ).then(() =>
                fireDB.child(`properties/${key}`).remove(
                    err => {
                        if (err) {
                            console.log(err)
                            setOpen(false)
                        }
                        else {
                            setOpen(false)
                            setCurrentId('')
                        }
                    }
                ))
        }
    }



    console.log("propertyObject:", propertyObject);
    return (
        <>
            <div className={classes.DashBord} >
                <h1>Main</h1>
                <Backdrop className={classes2.backdrop} open={open} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Pannel {...({ add, currentId, propertyObject })} scoop={propertyObject1}/>
                <div>
                    <TableContainer component={Paper} className={classes.paper} >
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Property name</StyledTableCell>
                                    <StyledTableCell>MLS ID</StyledTableCell>
                                    <StyledTableCell align="right">City</StyledTableCell>
                                    {/* <StyledTableCell align="right">School</StyledTableCell> */}
                                    <StyledTableCell align="right">Address</StyledTableCell>
                                    <StyledTableCell align="right">community</StyledTableCell>
                                    <StyledTableCell align="right">Active-Name</StyledTableCell>
                                    <StyledTableCell align="right">Active-Status</StyledTableCell>
                                    <StyledTableCell align="right">Starting-Price</StyledTableCell>
                                    <StyledTableCell align="right">Images</StyledTableCell>
                                    <StyledTableCell align="right">Edit / Delet</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(propertyObject).map(id => {
                                        return <StyledTableRow key={id}
                                            style={{
                                                backgroundColor: propertyObject[id].active ? "rgb(45 136 8 / 52%)" : propertyObject[id].Sold === 'Sold' ? "rgb(228 203 25 / 52%)" : "",
                                                color: propertyObject[id].active ? "white" : "",
                                            }}>

                                            <StyledTableCell component="th" scope="row">
                                                {propertyObject[id].propertyname}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {propertyObject[id].MlsId}
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="right">{propertyObject[id]}</StyledTableCell> */}
                                            <StyledTableCell align="right">{propertyObject[id].city}</StyledTableCell>
                                            {/* <StyledTableCell align="right">{propertyObject[id].schools}</StyledTableCell> */}
                                            <StyledTableCell align="right">{propertyObject[id].address}</StyledTableCell>
                                            <StyledTableCell align="right">{propertyObject[id].community}</StyledTableCell>
                                            <StyledTableCell align="right">{propertyObject[id].Activename}</StyledTableCell>
                                            <StyledTableCell align="right" style={{ color: propertyObject[id].status === "Active" ? "white" : propertyObject[id].status === "Pending" ? "red" : "" }}>{propertyObject[id].status}</StyledTableCell>
                                            <StyledTableCell align="right"><NumberFormat value={propertyObject[id].startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></StyledTableCell>
                                            {/* <StyledTableCell align="right">{imageu}</StyledTableCell> */}
                                            <StyledTableCell align="right"><img src={propertyObject[id].featuredUrl || propertyObject[id].url} alt="image_png" width="100" height="80" /></StyledTableCell>
                                            <StyledTableCell align="right">
                                                {/* <DeleteForeverIcon onClick={()=>{onDelete(id)}} /> */}
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => { onDelete(id) }}
                                                    startIcon={<DeleteForeverIcon />}
                                                >
                                                </Button>
                                                <p></p>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setCurrentId(id)
                                                        console.log("id", id); window.scrollTo({ top: 0, behavior: 'smooth' })
                                                    }}
                                                    startIcon={<EditIcon />
                                                    }

                                                >
                                                </Button>
                                                {/* <EditIcon onClick={()=>{ setCurrentId(id)}} /> */}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>
    );
}

export default AdminPannel








