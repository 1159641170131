import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import './MobileDetail.css';
import Availability from '../Availability'
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import DailogForm from '../../contact/DailogForm';
import Footer from '../Footer';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import white from '../../images/Image_not_available.png';
import nwmlslogo from '../../images/nwmls_small.png'
import TourLogin from '../../user/TourLogin';
import firebase from '../../firebase/firebase';
import { PlayCircleFilledWhiteRounded } from '@mui/icons-material';
import { BuyerContact } from '../../contact/BuyerContact';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MobileStepper, Stack, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import ScoopPannel from '../../admin/ScoopPannel';
import ScoopCard from '../ScoopCard';
import { FavoriteRounded } from '@mui/icons-material';

const HeartIcon = ({ width, height, fillColor, borderColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fillColor || 'none'}
      stroke={borderColor || 'black'}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.35 20.13c-.76.69-1.93.69-2.69-.01l-.11-.1C5.3 15.27 1.87 12.16 2 8.28c.06-1.7.93-3.33 2.34-4.29 2.64-1.8 5.9-.96 7.66 1.1 1.76-2.06 5.02-2.91 7.66-1.1 1.41.96 2.28 2.59 2.34 4.29.14 3.88-3.3 6.99-8.55 11.76l-.1.09z"></path>
    </svg>
  );
};

const StyledHeartIcon = styled(HeartIcon)(({ theme, isWishlisted }) => ({
  position: 'relative',
  transform: isWishlisted ? 'rotate(-45deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease-in-out',
  border: `2px solid ${isWishlisted ? theme.palette.error.main : 'grey'}`,
  borderRadius: '50%',
}));

const useStyles = makeStyles((theme) => ({
  root: {

    flexGrow: 1,
  },
  root3: {
    paddingTop: "5px",
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 250,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  mediaSmall: {
    width: "100%",
    height: "100%"
  },
  paper: {
    margin: 'auto',
  },
  paperimg: {
    width: "100%"
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: "5px 24px 16px"
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  FooterPad: {

  },
  appBar: {
    top: 'auto',
    bottom: 0,
    display: 'flex',
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer",
    background: 'linear-gradient(110deg, #f50057, #e8ab78, #1bd7b4, #36c91b)',
    backgroundSize: '400% 400%',
    animation: 'change 10s ease-in-out infinite',
    borderRadius: 4,
    padding: 10,
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: 80,
    },
    alignItems: "center",
  },
  '@keyframes change': {
    '0%': {
      backgroundPosition: ' 0 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    ' 100%': {
      backgroundPosition: '0 50%'
    },
  },

  imageBody: {
    height: 200,
    width: 250
  },
  Typography: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
}));

function Floor(props) {
  const classes = useStyles();
  var str = props.floorUrl
  var str1 = str.split("%2F")[1];
  var str2 = str1.split(/.jpg|.png/)[0].replaceAll("%20", " ")
  var str3 = (str.includes("_floorUrl_")) ? str2.split("_floorUrl_")[1] : str2

  return (
    <div>

      <Grid item key={str2} style={{ "padding": "10px" }} >
        <Card className={classes.root} elevation={0} spacing={2} style={{ width: 250 }} variant="outlined">
          <CardActionArea>
            <div className={classes.imageBody}>
              {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
              <CardMedia
                className={classes.mediaSmall}
                component="img"
                image={props.floorUrl} alt={str2}
                title="Live from space album cover"
              />
            </div>
            <CardContent>

              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography className="mobav_title">
                      <h2>{str3}</h2>
                    </Typography>

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/*          
        <div className="top-Right_Rec_mob_D">{str2}</div>
        <img className={classes.img} src={props.floorUrl} alt={str2} /> */}

    </div>
  )

}


function SiteMap(props) {
  const classes = useStyles();
  var str = props.SiteMap

  // var str1 = str.split("%2F")[1];
  // var str2 = str1.split(/.jpg|.png/)[0].replaceAll("%20", " ")

  return (
    <div>

      <Grid item key={str} style={{ "padding": "10px" }} >
        <Card className={classes.root} elevation={0} spacing={2} style={{ width: 250 }} variant="outlined">
          <CardActionArea>
            <div className={classes.imageBody}>
              {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
              <CardMedia
                className={classes.mediaSmall}
                component="img"
                image={props.SiteMap} alt={str}
                title="Live from space album cover"
              />
            </div>
            {/* <CardContent>
          
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
              <Typography className="mobav_title">
                  <h2>{str2}</h2>
                </Typography>
               
              </Grid>
            </Grid>
          </Grid>
        </CardContent> */}
          </CardActionArea>
        </Card>
      </Grid>
      {/*          
        <div className="top-Right_Rec_mob_D">{str2}</div>
        <img className={classes.img} src={props.floorUrl} alt={str2} /> */}

    </div>
  )

}


const WishlistButton = (props) => {
  var fireDB = firebase.database().ref();
  const [propertyData, setPropertyData] = useState([]);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState(null);

  const handleWishlistClick = () => {
    setIsWishlisted((prevIsWishlisted) => !prevIsWishlisted);
    setOpenDialog(true);
  };



  React.useEffect(() => {
    fireDB.child('properties').on('value', snapshot => {
      if (snapshot.val() != null)
        setPropertyData({
          ...snapshot.val()
        })
      else
        setPropertyData({})
    })
  }, [])



  useEffect(() => {
    // Listen for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const email = user && user.email;
  const Uid = user && user.uid

  const wishlistData = props.wishlist !== null && typeof props.wishlist === 'object'
    ? Object.keys(props.wishlist).map((id) => props.wishlist[id])
    : null;




  // console.log('uid', );
  const keys = Object.keys(propertyData).filter((key) => propertyData[key].address === props.Address);

  const handleUpload = (event) => {
    event.preventDefault();
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);
      if (isWishlisted) {
        const id = Object.keys(props.wishlist).filter((id) => props.wishlist[id].email === email);
        console.log("id", id)
        fireDB.child(`properties/${keys}/wishlist/${id}`).remove(
          err => {
            if (err) {
              console.log(err)
            }
          }
        )
      } else {
        const data = {
          wishListed: isWishlisted,
          email: email,
          uid: Uid,
        };

        const propertyPath = `properties/${keys}`;
        // Create a reference to the Firebase database location
        fireDB.child(`${propertyPath}/wishlist`).push(data);
        console.log('wishList', data)
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      const data = {
        wishListed: isWishlisted,
        email: email,
        uid: Uid,
      };

      const propertyPath = `properties/${keys}`;
      // Create a reference to the Firebase database location
      fireDB.child(`${propertyPath}/wishlist`).push(data);
      console.log('wishList', data)
    }
    if (wishlistData !== null) {

    } else {

    };
    setOpenDialog(false)
  }

  const handleNoClick = () => {
    setOpenDialog(false);
  };

  const Favourite = React.useMemo(() => {
    // Check if wishlistData is not null and has items
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);

      if (isWishlisted) {
        return (
          <IconButton onClick={handleWishlistClick}>
            <FavoriteRounded color="error" sx={{ fontSize: '1.2em' }} />
          </IconButton>
        );
      } else {
        return (
          <IconButton onClick={handleWishlistClick}>
            <StyledHeartIcon width={30} height={30} />
          </IconButton>
        );
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      return (
        <IconButton onClick={handleWishlistClick}>
          <StyledHeartIcon width={30} height={30} />
        </IconButton>
      );
    }
  }, [wishlistData, email, handleWishlistClick]);

  const FavouriteText = React.useMemo(() => {
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);

      if (isWishlisted) {
        return <Typography variant="h7">Are you sure you want to remove this property to your wishlist?</Typography>;
      } else {
        return <Typography variant="h7">Are you sure you want to add this property to your wishlist?</Typography>;
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      return <Typography variant="h7">Are you sure you want to add this property to your wishlist?</Typography>;
    }
  }, [wishlistData, email])

  return (
    <div>
      {Favourite}
      <Dialog open={openDialog} onClose={handleNoClick}>
        <DialogTitle>Confirm Wishlist</DialogTitle>
        <DialogContent>
          {FavouriteText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload} color="primary">
            Yes
          </Button>
          <Button onClick={handleNoClick} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


function MobDetailpage(props) {
  const classes = useStyles();
  const theme = useTheme();
  var fireDB = firebase.database().ref();
  const [propertyData, setPropertyData] = React.useState([]);
  const [scoopData, setScoopData] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openmap, setOpenMap] = React.useState(true);
  const [openFloor, setOpenFloor] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [openSc, setOpenSc] = React.useState(true);
  const [openHm, setOpenHm] = React.useState(true);
  const [openScoop, setOpenScoop] = React.useState(true);
  const [user, setUser] = React.useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    title,
    city,
    children,
    Schools,
    Level1,
    level2,
    Level3,
    Builders,
    Details,
    Bedroom,
    Bathroom,
    builders,
    Kitchen,
    Adderss,
    Map,
    Tag,
    startprice,
    endprice,
    status,
    propertyname,
    community,
    url,
    floorUrl,
    OfferDate,
    Agent1,
    Agent2,
    OFFMarketDate,
    Source,
    Scoop,
    wishlist,
    index, ...other } = props;

  var pIname = propertyname.toLowerCase()
  var pname = pIname.split("by")[0];
  // var ppname = pname.charAt(0).toUpperCase() + pname.slice(1)
  var words = pname.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClick = () => {
    setOpenMap(!openmap);
  };
  const handleClickFloor = () => {
    setOpenFloor(!openFloor);
  };

  const handleClickScoop = () => {
    setOpenScoop(!openScoop);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen2(false);
  };

  const handleClickSchoolDetails = () => {
    setOpenSc(!openSc);
  };


  React.useEffect(() => {
    fireDB.child('properties').on('value', snapshot => {
      if (snapshot.val() != null)
        setPropertyData({
          ...snapshot.val()
        })
      else
        setPropertyData({})
    })
  }, [])

  const keys = Object.keys(propertyData).filter((key) => propertyData[key].address === Adderss);
  const propertyPath = `properties/${keys}/scoop`;


  const addCardToFirebase = (newCard) => {
    const propertyPath = `properties/${keys}`;
    // Create a reference to the Firebase database location

    fireDB.child(`${propertyPath}/scoop`).push(newCard);
  }

  React.useEffect(() => {
    const dataRef = fireDB.child(`${propertyPath}`);
    dataRef.on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const dataArray = Object.values(data);
      setScoopData(dataArray);
    });

    return () => {
      // Unsubscribe from the Firebase database when the component unmounts
      dataRef.off();
    };
  }, [propertyPath]);


  // useEffect(() => {

  //   var user = firebase.auth().currentUser;
  //   if (user !== null) {
  //     setCurrentUser(true);
  //   }
  //   else setCurrentUser(false)
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       setCurrentUser(true);
  //     }
  //     else setCurrentUser(false)
  //   }
  //   )

  // }, []);


  const options = {
    icons: {
      downloadIcon: null,
    },
    buttons: {
      showDownloadButton: false
    }
  }
  const SiteUrl = window.location.pathname;

  React.useEffect(() => {
    // Listen for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const allowedEmail = 'dguptahomes.web@gmail.com';
  const wishlistData = wishlist !== null && typeof wishlist === 'object'
    ? Object.keys(wishlist).map((id) => wishlist[id])
    : null;
  return (
    <div>
      <Paper style={{ width: '100%', marginTop: '4em' }} elevation={0} >
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >{(() => {
              if (url)
                return url.map((item, index) => (
                  <div key={url}>
                    {Math.abs(activeStep - index) <= 20 ? (
                      <img className={classes.img} src={item} alt={propertyname} />
                    ) : null}
                  </div>
                ))
              else {
                return <div >

                  <img className={classes.img} src={white} alt="Image" />

                </div>
              }

            })()}
            </SwipeableViews>
          </SRLWrapper>
        </SimpleReactLightbox>
        {status == 'Sold Out' && <div className="top-left_sold_mob_D">Sold Out</div>}
        {status == 'Currently Available' && <div className="top-left_avai_mob_D">Currently Available</div>}
        {status == 'Coming Soon' && <div className="top-left_coming_mob_D">Coming Soon</div>}
        {status == 'Currently Sold Out' && <div className="top-left_c_Sold_mob_D">Currently Sold Out</div>}
        {Tag == 'Recommended' &&
          <>
            <div className="top-Right_Rec_mob_D">Recommended</div>
            <Stack display="flex" justifyContent="end" alignItems="end"
              sx={{ position: 'absolute', top: '11em', right: '5px' }}>
              <WishlistButton Address={Adderss} wishlist={wishlist} />
            </Stack>
          </>}
        {Tag == 'Recommended' ?
          null
          :
          <Stack display="flex" justifyContent="end" alignItems="end"
            sx={{ position: 'absolute', top: '9em', right: '5px' }}>
            <WishlistButton Address={Adderss} wishlist={wishlist} />
          </Stack>
        }
        <MobileStepper
          steps={url ? url.length : 1}
          position="static"
          variant="text"
          activeStep={activeStep}
          sx={{ background: '#fafafa' }}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === (url ? url.length : 1) - 1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </Paper>

      <Paper style={{ margin: 'auto' }} elevation={0}>
        <div style={{ paddingTop: "5px", }}>
          <div style={{ margin: "5px 24px 16px" }}>
            <Grid container alignItems="center">
              <Grid container spacing={2} >
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Stack direction="column" spacing={0.5}>
                      <Typography style={{ fontSize: '15px', fontWeight: 500 }}>
                        {words}
                      </Typography>
                      <Typography variant="h7" style={{ color: '#565656c9', fontWeight: 400 }}>
                        By {Builders}
                      </Typography>
                    </Stack>
                    {/* <div className='mobsearchResult__info2'>
                      <div className="mobsearchResult__infoTop2">
                        <h5>{words}</h5>
                        <h7>By {Builders}</h7>
                      </div>
                    </div> */}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    <div className="searchResult__" >
                      {(() => {
                        if (status == 'Sold Out') {
                          return (
                            <p id="price_1">
                              <strong id="price_from">
                                SOLD OUT
                              </strong>
                            </p>)
                        }
                        if (status == 'Currently Sold Out') {
                          return (
                            <p id="price_1">
                              <strong id="price_from">
                                CURRENTLY SOLD OUT
                              </strong>
                            </p>)
                        }
                        if (!startprice) {
                          return (
                            <p id="price_1">
                              <strong id="price_from">
                                Not Available
                              </strong>
                            </p>)
                        }
                        else if (!endprice) {
                          return (
                            <div>
                              <p id="price_1">
                                <div className="starting">
                                  Starting
                                </div>
                                <strong id="price_from">
                                  <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </strong>
                              </p>
                            </div>)
                        }
                        else {
                          return (<div>
                            <p id="price_1">
                              {/* from  */}
                              <strong id="price_from">
                                <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                {' - '}
                                <NumberFormat value={endprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              </strong>
                            </p>
                          </div>)
                        }
                      })()}
                      <p id="price_divider"></p>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <div className='mobsearchResult__info2'>
              <div className="mobsearchResult__infoTop2">
                <h8>{Adderss}</h8>
              </div>
            </div> */}
            <Stack sx={{ mt: '20px' }}>
              <Typography style={{ fontSize: '14px' }}>{Adderss}</Typography>
            </Stack>
            {/* {(status === 'Currently Available' || status === 'Coming Soon') ?
              <Grid item xs style={{ marginBottom: '10px', marginTop: '15px' }}>
                {(Agent1 !== '') ? <Typography className={classes.Typography}>Listed by {Builders}  </Typography> : null}
                {
                <Typography className={classes.Typography} >Source : NWMLS <img src={nwmlslogo} alt="NWMLS Logo" height="14px" style={{ marginLeft: '5px', marginBottom: '3px' }} /> </Typography>
              </Grid>
              :
              <>
                <Grid item xs style={{ marginBottom: '10px', marginTop: '15px' }}>
                  <Typography className={classes.Typography} >
                    This home last sold for <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                    
                  </Typography>
                  {(Agent1 !== '') ? <Typography className={classes.Typography}>Listed by {Builders}  </Typography> : null}
                 
                  <Typography className={classes.Typography} >Source : NWMLS <img src={nwmlslogo} alt="NWMLS Logo" height="14px" style={{ marginLeft: '5px', marginBottom: '3px' }} /> </Typography>
                </Grid>

              </>
            } */}
            <div className="CallButton">
              <Grid container spacing={1} >
                <Grid item xs>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    className={classes.button}
                    startIcon={<CallIcon />}
                  >
                    <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">Call</Link>
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ "background-color": "#128C7E" }}
                    startIcon={<WhatsAppIcon />}
                  >
                    <Link color="inherit" style={{ textDecoration: 'none', color: 'white' }} href={`https://api.whatsapp.com/send?phone=+14255888470&text=Looking for ${propertyname} city: ${city}`}>WhatsApp</Link>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
          <Divider variant="middle" />

          <div className={classes.section2}>
            <div className="space_around">
              <div className="mobsearchResult__infoTop2">
                <h5>Highlights</h5>
              </div>
            </div>
            <Chip
              icon={<OpenWithRoundedIcon />}
              label={`Size : ${props.Size} Sq.ft`}
              className={classes.chip}
            />
            <Chip
              icon={<LocalHotelIcon />}
              label={`Beds : ${props.bedrooms}`}
              className={classes.chip}
            />
            <Chip
              icon={<BathtubIcon />}
              label={`Baths : ${props.bathrooms}`}
              className={classes.chip}
            />
            <Chip
              icon={<DirectionsCarRoundedIcon />}
              label={`Bay Garage : ${props.parking}`}
              className={classes.chip}
            />
          </div>
          <Divider variant="middle" />
        </div>

        {/* <div className="avalibleflex">
          <Availability props={props} url={props.url} />
          <Divider variant="middle" />
        </div> */}
        {/* <div className={classes.section2}>
          <div className="space_around">
            <div className="mobsearchResult__infoTop2">
              <h5>Area Schools</h5>
            </div>
          </div>
          <SchoolRating city={props.city} Level1={props.Level1} level2={props.level2} Level3={props.Level3} Schools={props.Schools} />
        </div> */}
        <div>
          <ListItem button onClick={handleClickSchoolDetails}>
            <ListItemIcon>
              {openHm ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <ListItemText primary="Area Schools" style={{ "font-weight": "500" }} />
          </ListItem>
          <Collapse in={openSc} timeout="auto" unmountOnExit className={classes.section2} >
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                                <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">
                                    <img src={greatschool} alt="Great School" className='great_schoolLogo' />
                                </a>
                            </Grid> */}

              <Grid item xs={12}>
                <div style={{ fontSize: '1rem', fontWeight: '450' }}>School District</div>
                <h7 className="Schools_h7" style={{ marginTop: 0 }}> {props.Schools}</h7>
              </Grid>
              <Grid item xs={12}>
                {(() => {
                  if (!props.Level1 && !props.level2 && !props.Level3) {
                    return <div>
                      {/* <h6 className="Schools_h6"> Available Soon</h6> */}
                      <p><strong>Available Soon.</strong>This home is within the <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">{props.Schools}.</a></p>
                      <p> <strong>{props.city} enrollment policy is not based solely on geography.</strong>  Please check the school district website to see all schools serving this home.</p>
                    </div>
                  }
                  else {
                    return (
                      <TableContainer component={Paper} elevation={2}>
                        <Table >
                          <TableHead>
                            <TableRow>
                              <TableCell > <div className="Mob_Single_tab">Type</div></TableCell>
                              <TableCell> <div className="Mob_Single_tab" >Schools</div></TableCell>
                              {/* <TableCell> <div className="Mob_Single_tab" >Rating</div></TableCell> */}
                              {/* <TableCell> <div className="Single_tab" >Students</div></TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" >
                                <h6 className="Mob_Schools_h6"> Elementary school</h6>
                              </TableCell>
                              <TableCell ><h7 className="Mob_Schools_h7">{props.Level1 ? props.Level1 : 'NA'} </h7></TableCell>
                              {/* <TableCell >
                                                                {elemschool.map((item) => (
                                                                    <div className="Mob_Schools_Rating2" key={item.name}>
                                                                        <h7 className="Mob_Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'} </h7>
                                                                    </div>
                                                                ))}

                                                            </TableCell> */}
                              {/* <TableCell ><h7 className="Schools_h7">{elementryschooldata?.enrollment ? elementryschooldata.enrollment :'NA'} </h7></TableCell> */}
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" >
                                <h6 className="Mob_Schools_h6">Middle schools</h6>
                              </TableCell>
                              <TableCell ><h7 className="Mob_Schools_h7">{props.level2 ? props.level2 : 'NA'}</h7> </TableCell>
                              {/* <TableCell >
                                                                {middschool.map((item) => (
                                                                    <div className="Mob_Schools_Rating2" key={item.name}>
                                                                        <h7 className="Mob_Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'} </h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                              {/* <TableCell ><h7 className="Schools_h7">{middleschooldata?.enrollment ? middleschooldata.enrollment :'NA'} </h7></TableCell> */}
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" >
                                <h6 className="Mob_Schools_h6">High schools</h6>
                              </TableCell>
                              <TableCell ><h7 className="Mob_Schools_h7">{props.Level3 ? props.Level3 : 'NA'}</h7> </TableCell>
                              {/* <TableCell >
                                                                {highschool.map((item) => (
                                                                    <div className="Mob_Schools_Rating2" key={item.name}>
                                                                        <h7 className="Mob_Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'} </h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                              {/* <TableCell ><h7 className="Schools_h7">{highschooldata?.enrollment ? highschooldata.enrollment :'NA'} </h7></TableCell> */}
                            </TableRow>

                          </TableBody>
                          {/* <caption style={{ "font-size": "10px" }}> <strong>
                                                        School data provided by <>
                                                            <a href="https://www.greatschools.org/" target="_blank">GreatSchools.</a> </>
                                                        School service boundaries are intended to be used as reference only. To verify enrollment eligibility for a property, contact the school directly.
                                                    </strong></caption> */}
                        </Table>
                      </TableContainer>

                    )
                  }
                })()}
              </Grid>
              <Grid xs={12} style={{ marginTop: '1.5em' }}>


              </Grid>
            </Grid>
          </Collapse>

        </div>
        <Divider variant="middle" />
        <div className={classes.section2}>
          <div className="space_around">
            <div className="mobsearchResult__infoTop2">
              <h5>Community Info</h5>
            </div>
          </div>
          <Typography variant="body2" component="p">
            {props.Details}
          </Typography>
        </div>
        <Divider variant="middle" />
        <div className={classes.section2}>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              {openmap ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <ListItemText primary="Community Map" style={{ "font-weight": "500" }} />
          </ListItem>
          <Collapse in={openmap} timeout="auto" unmountOnExit>
            {!Array.isArray(Map) &&
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <img src={white} alt="Site Map" style={{ "width": "100%" }} />
                </SRLWrapper>
              </SimpleReactLightbox>}

            {(() => {
              if (Array.isArray(Map))
                if (Map.length === 1)

                  return <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                      <img src={Map} alt="Site Map" style={{ "width": "100%" }} />
                    </SRLWrapper>
                  </SimpleReactLightbox>
                else
                  return <SimpleReactLightbox >
                    <SRLWrapper options={options}>

                      <div className="FloorPlanGrid4">
                        {Map.map((data) => (
                          <Grid container >
                            <SiteMap SiteMap={data} />
                          </Grid>)
                        )}
                      </div>

                    </SRLWrapper >
                  </SimpleReactLightbox>
            })()}
          </Collapse>
        </div>
        {(() => {
          if (floorUrl != undefined && floorUrl != "")
            return (<div >
              <Divider variant="middle" />
              <div className={classes.section2}>
                <ListItem button onClick={handleClickFloor}>
                  <ListItemIcon>
                    {openFloor ? <ExpandLess style={{
                      "background": "rgb(255 141 0)",
                      "border-radius": "1000px",
                      "color": "white",
                      "height": "20px",
                      "width": "20px"
                    }} /> : <ExpandMore style={{
                      "background": "rgb(255 141 0)",
                      "border-radius": "1000px",
                      "color": "white",
                      "height": "20px",
                      "width": "20px"
                    }} />}
                  </ListItemIcon>
                  <ListItemText primary="Floor Plans" style={{ "font-weight": "500" }} />
                </ListItem>

                <Collapse in={openFloor} timeout="auto" unmountOnExit>

                  <SimpleReactLightbox >
                    <SRLWrapper options={options}>

                      <div className="FloorPlanGrid4">
                        {floorUrl.map((data) => (
                          <Grid container >
                            <Floor floorUrl={data} />
                          </Grid>)
                        )}</div>

                    </SRLWrapper >
                  </SimpleReactLightbox>

                </Collapse>
              </div>
            </div>
            )
        })()}
        <Divider variant="middle" />
        <div className={classes.section2}>
          <ListItem button onClick={handleClickScoop}>
            <ListItemIcon>
              {openScoop ? <ExpandLess style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} /> : <ExpandMore style={{
                "background": "rgb(255 141 0)",
                "border-radius": "1000px",
                "color": "white",
                "height": "20px",
                "width": "20px"
              }} />}
            </ListItemIcon>
            <ListItemText primary="Inside Scoop" style={{ "font-weight": "500" }} />
          </ListItem>
          <Collapse in={openScoop} timeout="auto" unmountOnExit style={{ background: '#b0b0b070', margin: '1em', borderRadius: '1em', }}>
            <Grid container spacing={1}>
              <Grid item xs={6} style={{ padding: '2.5em 1em 1em 1em' }}>
                <Typography style={{ fontSize: "1.2rem" }}>Inside Scoop</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', padding: '2em 1em 2em 1em' }}>
                {user && user.email === allowedEmail ? <Button variant='contained' color="info" onClick={handleClickOpen1}>Add Details</Button> : null}
                <ScoopPannel Open={open2} Close={handleClose1} propertyname={Adderss} addCardToFirebase={addCardToFirebase} wishlistData={wishlistData}/>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: '0em 1em' }}>
              {
                Scoop !== null && typeof Scoop === 'object' ?
                  <>
                    {
                      Object.keys(Scoop)
                        .sort((a, b) => (Scoop[b].selectedDate > Scoop[a].selectedDate) ? 1 : -1)
                        .map((id) => {
                          return (
                            <ScoopCard ScoopData={id} scoop={Scoop} propertyname={Adderss} UpdateScoop={keys} user={user} allowedEmail={allowedEmail} />
                          )
                        })
                    }
                  </>
                  :
                  null
              }

            </Grid>
          </Collapse>
        </div>
      </Paper>
      {/* <Footer /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ "min-height": "48px" }}>
          <Button
            sx={{ color: '#000' }}
            onClick={handleClickOpen}
            fullWidth > Book a consultation call With Deepti today!! </Button>
          <BuyerContact Open={open} Close={handleClose} />
          {/* {currentUser === true && <DailogForm Open={open1} Close={handleClose} property={props.propertyname} Facing={props.city} />}
          {currentUser === false && <TourLogin Open={open2} onClose={handleClose2} />} */}
        </Toolbar>
      </AppBar>
    </div>

  );
}
export default MobDetailpage;



