import { Container, Typography, Grid, Input, IconButton, useMediaQuery, Box, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom'
import House from '../components/House';
import Loading from '../components/Loading';
import firebase from '../firebase/firebase';
import { PropertyContext } from '../context';
import banner22 from '../images/banner2.jpg';
import Footer from '../components/Footer'
import AvaliableCards from '../components/Cards/AvaliableCards';
import { Link as RouterLink } from 'react-router-dom';
import House2 from '../components/House2';
import '../components/House.css'
import '../components/HouseMobile.css'
import '../components/Filter.css'
import SearchIcon from '@material-ui/icons/Search';
import Address from '../contact/Address';
import BookConsultation from '../components/Cards/BookConsultation';
import UpcomingCard from '../components/Mobile/UpcomingCard';
import { Paper, styled } from '@mui/material';

const useStyles = makeStyles((theme) =>
({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 25,
        paddingTop: 150
    },
    cityText: {
        fontSize: "23px",
        marginBottom: 0,
        textAlign: "center",
        fontWeight: 500,
        color: "#e60e0ebd"
    },
    paper: {
        marginBottom: '3rem'
    },
    papermob: {
        padding: theme.spacing(6),
        margin: 'auto',
        "&:hover": {
            backgroundColor: ' #4240400d',

        }
    },
    searchroot: {
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: 'auto',
        borderRadius: '30px'
    },
    searchroot2: {
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        [theme.breakpoints.down("sm")]: {
            width: 300,
            borderRadius: '4px'
        },
        margin: 'auto',
        borderRadius: '30px'
    },
    papersecard: {
        margin: "-8px 0px",
        height: '230px',
        borderRadius: "0px",
        backgroundImage: `url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    iconButton: {
        marginLeft: '15px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,
    },
    search_input: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '0.00938em',

    },
})
);

const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '300px',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 25,
    paddingTop: 150,
    [theme.breakpoints.down("xs")]: {
        height: 250,
        padding: 34,
        paddingTop: 60,
    }
}));


const StyledPaper1 = styled(Paper)(({ theme }) => ({
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 280,
    marginTop: '1em',
    borderRadius: '4px'
}));


const ListingComponent = (props) => {
    const context = useContext(PropertyContext);
    const { listings, getData } = context;
    const classes = useStyles();
    const [searchItem, setSearchItem] = useState('');
    const [searchTerm, setSearchTerm] = React.useState("");
    const isMatch = useMediaQuery('(max-width:600px)')
    const { listingsmobhouse, Cities } = props;
    const [user, setUser] = useState(null)

    let listingshouse = listings;
    listingshouse = [
        ...listingshouse.filter(house => house.ActiveStatus === "Active").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse.filter(house => house.ActiveStatus === "Pending").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1),
        ...listingshouse.filter(house => house.ActiveStatus === "Sold").sort((a, b) => (b.OfferDate > a.OfferDate) ? 1 : -1)
    ]

    useEffect(() => {
        if (listingshouse && listingsmobhouse.length === 0) {
            getData()
        }

    }, [])

    const filteredClient = React.useMemo(() => {
        if (searchTerm.length > 0) {
            return listingshouse.filter(item => item.city === searchTerm)
        }
        return listingshouse
    },
    )


    const filteredClient1 = React.useMemo(() => {
        if (searchTerm.length > 0) {
            return listingsmobhouse.filter(item => item.city === searchTerm)
        }
        return listingsmobhouse
    },
    )

    React.useEffect(() => {
        // Listen for authentication changes
        const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const allowedEmail = 'dguptahomes.web@gmail.com';

    if (listingshouse && listingsmobhouse.length === 0) {
        return <Loading />
    }

    // if (listingsmobhouse.length === 0) {
    //     return <Loading />
    // }

    return (
        <div>
            {isMatch ?
                <div className="contain_mob">
                    <StyledPaper>
                        <StyledPaper1 component="form" className={classes.searchroot2}>
                            <Select
                                disableUnderline
                                className="search_input2"
                                color=""
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                name="Soldmergecity"
                                // value={Property.city}
                                // onClick={handleChange2}
                                className={classes.input}
                                defaultValue="none"
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem disabled value="none" className={classes.search_input}  >
                                    Search by city
                                </MenuItem>
                                <MenuItem value="All" className={classes.search_input} onClick={() => setSearchTerm("")} >
                                    All
                                </MenuItem>
                                {Cities.map((item, index) => (
                                    <MenuItem key={index} value={item} className={classes.search_input} onClick={() => setSearchTerm(item)} >
                                        {item + ", " + 'WA'}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledPaper1>
                    </StyledPaper>
                    <div style={{ paddingTop: '0.5rem' }}><AvaliableCards /></div>
                </div>
                :
                (
                    <div >
                        <Paper className={classes.bannerPaper}>
                            <div className={classes.paper}>
                                <Paper component="form" className={classes.searchroot}>
                                    <Select
                                        disableUnderline
                                        className="search_input2"
                                        color=""
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        name="Soldmergecity"
                                        // value={Property.city}
                                        // onClick={handleChange2}
                                        className={classes.input}
                                        defaultValue="none"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem disabled value="none" className={classes.search_input}  >
                                            Search by city
                                        </MenuItem>
                                        <MenuItem value="All" className={classes.search_input} onClick={() => setSearchTerm("")} >
                                            All
                                        </MenuItem>
                                        {Cities.map((item, index) => (
                                            <MenuItem key={index} value={item} className={classes.search_input} onClick={() => setSearchTerm(item)} >
                                                {item + ", " + 'WA'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton className={classes.iconButton} aria-label="search"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </Paper>
                        <div ><AvaliableCards /></div>
                    </div>
                )}

            <Box display={{ xs: 'block', md: 'none' }} m={1}>
                <Grid container spacing={2} >
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <UpcomingCard />
                    </Grid> */}
                    {
                        user && user.email === allowedEmail ?
                            <Box display={{ xs: 'block', md: 'none' }} m={1}>
                                <Grid container spacing={2}>
                                    {
                                        listingsmobhouse.length > 0 &&
                                        // listingsmobhouse.filter((item) => {
                                        //     if (searchItem === "city") {
                                        //         return item
                                        //     } else if (item.city.toLowerCase().includes(searchItem.toLowerCase())) {
                                        //         return item
                                        //     }
                                        // })
                                        filteredClient1.map(item => {
                                            return (
                                                <Grid item key={item} xs={12} sm={6} md={4} style={{ marginTop: '1em' }}>
                                                    <House2 key={item.id} home={item} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                            :
                            <Box display={{ xs: 'block', md: 'none' }} m={1}>
                                <Grid container spacing={2}>
                                    {
                                        listingshouse.length > 0 &&
                                        // listingshouse.filter((item) => {
                                        //     if (searchItem === "city") {
                                        //         return item
                                        //     } else if (item.city.toLowerCase().includes(searchItem.toLowerCase())) {
                                        //         return item
                                        //     }
                                        // })
                                        filteredClient.map(item => {
                                            return (
                                                <Grid item key={item} xs={12} sm={6} md={4} style={{ marginTop: '1em' }}>
                                                    <House2 key={item.id} home={item} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>

                    }
                    <Grid item xs={12} sm={6} md={4}>
                        <BookConsultation />
                        {/* <Address /> */}
                    </Grid>
                </Grid>
            </Box>

            <Footer />
        </div>
    )
}


export default ListingComponent



