import React from 'react';
import { useContext, useState } from 'react';
import { PropertyContext } from '../../context';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import bannermob from '../../images/Bannermob13.png'
import SearchIcon from '@material-ui/icons/Search';
import '../Filter.css'
import { Paper, styled, Typography } from '@mui/material';;


const getUnique = (items, value) => {
  return [...new Set(items.map(item => item[value]))]
}


const StlyedPaper = styled(Paper)(({ theme }) => ({
  marginTop: "60px",
  height: '230px',
  borderRadius: "0px",
  backgroundImage: `url(${bannermob})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));


const useStyles = makeStyles((theme) =>
({
  // root: {
  //   flexGrow: 1,
  //   paddingTop: 10,
  //   marginTop: 150,
  // },

  button: {
    display: 'block',
    marginTop: '10px',
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: 900,
    borderRadius: '800px'

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 420,
    fontSize: '15px',
    width: 100,

  },

  searchroot: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      borderRadius: '4px'
    },
    margin: 'auto',
    borderRadius: '30px'
  },
  search_lable: {
    fontWeight: 900,
    fontSize: '15px',
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '80px',
      textOverflow: "ellipsis"
    },
  },
  search_input: {
    fontWeight: 500,

  },
  divpadding: {
    paddingBottom: 10,
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down("md")]: {
      minWidth: 140
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 100
    },
  },
  papersecard: {
    margin: "-8px 0px",
    height: '230px',
    borderRadius: "0px",
    backgroundImage: `url(${bannermob})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  innerpaper: {
    width: 'fit-content',
    margin: "auto",
    padding: "0px 10px",
  },
  filterPaper: {
    borderRadius: "10px"
  },
}),
);
// const useStyles2 = makeStyles((theme) =>
// ({
//   chipMob: {
//     display: 'flex',
//     // justifyContent: 'center',
//     paddingTop: "10px",
//     flexWrap: 'unset',
//     '& > *': {
//       margin: theme.spacing(0.6),
//     },
//   },
// }));

export default function Filter({ Property }) {
  const classes = useStyles();
  const context = useContext(PropertyContext);
  console.log("context", context.sortedCity)
  const {
    handleChange3,
    handleChange,
    handleChange2,
    handleChange4,
    handleChange5,
    handleChange6,
    school,
    builder,
    minPrice,
    maxPrice,
    clickCity,
    level1
  } = context;
  const price2 = '2,000,000';
  const price3 = '3,000,000';
  const price4 = '4,000,000';
  const price5 = '5,000,000';

  let citys = getUnique(Property, 'city');

  citys = [...citys]
  citys = citys.filter(item => item)
  // school
  let elementory = getUnique(context.sortedCity, 'level1');
  elementory = ['ALL', ...elementory]
  elementory = elementory.filter(item => item !== "Discovery Elementary")

  let elementoryAll = getUnique(Property, 'level1');
  elementoryAll = ['ALL', ...elementoryAll]
  elementoryAll = elementoryAll.filter(item => item !== "Discovery Elementary")

  var arr5 = elementoryAll;
  var arr6 = elementory;
  let unique5 = arr5.filter((o) => arr6.indexOf(o) === -1);
  let unique6 = arr6.filter((o) => arr5.indexOf(o) === -1);
  const elementoryDis = unique5.concat(unique6);


  // console.log("element", elementory)
  // console.log("elementAll", elementoryAll)
  // add all     
  // get unique types
  let Schools = getUnique(context.sortedCity, 'schools');
  let Scl = getUnique(context.sortedscl, 'level1');
  let Scl2 = getUnique(context.sortedscl2, 'level1');
  Scl2 = ['ALL', ...Scl2]
  Scl = ['ALL', ...Scl]
  var arr11 = elementoryAll;
  var arr12 = Scl;
  let unique11 = arr11.filter((o) => arr12.indexOf(o) === -1);
  let unique12 = arr12.filter((o) => arr11.indexOf(o) === -1);
  const SclDis = unique11.concat(unique12);
  // console.log("sscl", Scl)
  // console.log("sscl2", Scl2)
  // console.log("sscdist", context.clickDis)
  // console.log("ssct", context.schooldistrict)



  // add all
  Schools = ['ALL', ...Schools]
  Schools = Schools.filter(item => item)
  // all school data
  let SchoolsAll = getUnique(Property, 'schools');
  // add all
  SchoolsAll = ['ALL', ...SchoolsAll]
  //to filter " "
  SchoolsAll = SchoolsAll.filter(item => item)
  // Map to JSX
  // Schools = Schools.map((item, index)=> {
  //     return <MenuItem value={item} key={index}>{item}</MenuItem>
  // })

  var arr1 = SchoolsAll;
  var arr2 = Schools;
  let unique1 = arr1.filter((o) => arr2.indexOf(o) === -1);
  let unique2 = arr2.filter((o) => arr1.indexOf(o) === -1);

  const unique = unique1.concat(unique2);

  // builder filter
  let Builders = getUnique(context.sortedCity, 'builders');
  Builders = ['ALL', ...Builders]
  Builders = Builders.filter(item => item !== "Lennar")

  let BuildersAll = getUnique(Property, 'builders');
  BuildersAll = ['ALL', ...BuildersAll]
  BuildersAll = BuildersAll.filter(item => item !== "Lennar")
  // Builders = Builders.map((item, index)=> {
  //     return <MenuItem value={item} key={index}>{item}</MenuItem>
  // })
  // for  builders
  var arr1 = BuildersAll;
  var arr2 = Builders;
  let uniqueBuilderall = arr1.filter((o) => arr2.indexOf(o) === -1);
  let uniqueBuilder = arr2.filter((o) => arr1.indexOf(o) === -1);

  const grayBuilder = uniqueBuilderall.concat(uniqueBuilder);
  let Build = getUnique(context.sortedscl, 'builders');
  let Build2 = getUnique(context.sortedscl2, 'builders');
  let Build3 = getUnique(context.sortedEle, 'builders');
  Build = ['ALL', ...Build]
  Build2 = ['ALL', ...Build2]
  Build3 = ['ALL', ...Build3]

  var arr20 = BuildersAll;
  var arr21 = Build;
  let uniqueBuildall = arr20.filter((o) => arr21.indexOf(o) === -1);
  let uniqueBuild = arr21.filter((o) => arr20.indexOf(o) === -1);
  const grayBuild = uniqueBuildall.concat(uniqueBuild);

  var arr22 = BuildersAll;
  var arr23 = Build3;
  let uniqueBuild3all = arr22.filter((o) => arr23.indexOf(o) === -1);
  let uniqueBuild3 = arr23.filter((o) => arr22.indexOf(o) === -1);
  const grayBuild3 = uniqueBuild3all.concat(uniqueBuild3);

  // const menuItems = elementoryAll.map((item, index) => {
  //   return <MenuItem value={item} key={index}> {item}</MenuItem>
  // });

  // console.log("bui", Build)
  // console.log("buil2", Build2)
  // console.log("buil3", Build3)
  // console.log("buil4", Builders)
  // console.log("true", context.level1)

  // const datatest = ['hi', 'hii', 'ho', 'hoo']
  // console.log("felemm", datatest)
  return (
    <>
      <StlyedPaper >
        <div className="search_mob">
          <Grid align="center" style={{ marginBottom: '1em' }}>
            <Typography style={{ color: 'white', fontWeight: '500', fontSize: '1.4em' }} gutterBottom> New Communities In Washington State</Typography>
          </Grid>
          <Paper component="form" className={classes.searchroot}>

            <Select
              disableUnderline
              className="search_input2"
              color=""
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              name="city"
              value={context.city === "ALL" ? "none" : context.city}
              onChange={handleChange}
              onClick={handleChange3}
              className={classes.input}
              defaultValue="none"
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="none" className={classes.search_input}  >
                Search by city
              </MenuItem>
              <MenuItem value="ALL" className={classes.search_input}  >
                ALL
              </MenuItem>
              {citys.map((item, index) => (
                <MenuItem key={index} value={item} className={classes.search_input}  >
                  {item + ", " + 'WA'}
                </MenuItem>
              ))}
            </Select>
            <IconButton className={classes.iconButton} aria-label="search"
              onChange={handleChange}>
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
      </StlyedPaper>




      <Paper elevation={4} className={classes.filterPaper}>
        <div className="testContain">
          <FormControl className={classes.formControl} >
            <InputLabel id="demo-simple-select-outlined-label"
              className="search_lable" className={classes.search_lable} >School District</InputLabel>
            <Select
              disableUnderline
              color={'secondary'}
              labelId="demo-mutiple-name-label"
              id="school"
              name="school"
              value={context.clickDis ? context.school : school}
              // onClick={handleChange3}
              onClick={handleChange3}
              onChange={handleChange}
              className={classes.search_input}
              inputProps={{
                underline: {
                  "&&&:before": {
                    borderBottom: "none"
                  },
                  "&&:after": {
                    borderBottom: "none"
                  }
                }
              }}

            >
              <MenuItem disabled defaultValue value="" className={classes.search_input} >
                Schools
              </MenuItem>
              {(() => {
                if (clickCity == true)
                  return [Schools.sort().map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input} defaultValue   >
                      {item}
                    </MenuItem>
                  )),
                  unique.sort().map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                      {item}
                    </MenuItem>
                  ))]
                else {
                  return SchoolsAll.sort().map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}  >
                      {item}
                    </MenuItem>
                  ))
                }
              }
              )()}

            </Select>
          </FormControl>
          <div className="dividermob"> </div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label"
              className={classes.search_lable} >Elementary School</InputLabel>
            <Select
              disableUnderline
              color={'secondary'}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="level1"
              value={context.clickDis ? context.level1 : level1}
              // value={builder}
              onChange={handleChange}
              onClick={handleChange3}
              label="Elementary school"
              className={classes.search_input}
            >
              <MenuItem disabled value="" className={classes.search_input} >
                Elementary Schools
              </MenuItem>
              {(() => {
                if (clickCity == true) {
                  if (context.schooldistrict == true) {
                    return [Scl2.sort().map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input}  >
                        {item}
                      </MenuItem>
                    )),
                    elementoryDis.sort().map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                        {item}
                      </MenuItem>
                    ))]

                  }
                  else {
                    return [elementory.sort().map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input}  >
                        {item}
                      </MenuItem>
                    )),
                    elementoryDis.sort().map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                        {item}
                      </MenuItem>
                    ))]
                  }
                }
                else if (context.schooldistrict == true) {
                  return [Scl.sort().map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}  >
                      {item}
                    </MenuItem>
                  )),
                  SclDis.sort().map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                      {item}
                    </MenuItem>
                  ))]
                } else {
                  return elementoryAll.sort().map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}
                    >
                      {item}
                    </MenuItem>
                  ))


                }
              }
              )()}
            </Select>
          </FormControl>
          <div className="dividermob"> </div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label"
              className={classes.search_lable} >Builder</InputLabel>
            <Select
              disableUnderline
              color={'secondary'}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="builder"
              value={context.clickDis ? context.builder : builder}
              // value={builder}
              onChange={handleChange}
              onClick={handleChange3}
              label="builder"
              className={classes.search_input}
            >
              <MenuItem disabled value="" className={classes.search_input} >
                Builders
              </MenuItem>
              {(() => {
                if (clickCity == true) {
                  if (context.schooldistrict == true && context.sortedlevel1 == true) {
                    return [Build3.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input}  >
                        {item}
                      </MenuItem>
                    )),
                    grayBuild3.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                        {item}
                      </MenuItem>
                    ))]

                  }
                  else if (context.schooldistrict == true) {
                    return [Build2.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input}  >
                        {item}
                      </MenuItem>
                    )),
                    grayBuilder.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                        {item}
                      </MenuItem>
                    ))]

                  }
                  else if (context.sortedlevel1 == true) {
                    return [Build3.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input}  >
                        {item}
                      </MenuItem>
                    )),
                    grayBuilder.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                        {item}
                      </MenuItem>
                    ))]

                  }
                  else {
                    return [Builders.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input}  >
                        {item}
                      </MenuItem>
                    )),
                    grayBuilder.map((item, index) => (
                      <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                        {item}
                      </MenuItem>
                    ))]
                  }
                }
                // ClickCity == false
                else if (context.schooldistrict == true && context.sortedlevel1 == true) {
                  return [Build3.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}  >
                      {item}
                    </MenuItem>
                  )),
                  grayBuild3.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                      {item}
                    </MenuItem>
                  ))]

                }

                else if (context.schooldistrict == true) {
                  return [Build.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}  >
                      {item}
                    </MenuItem>
                  )),
                  grayBuild.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                      {item}
                    </MenuItem>
                  ))]
                } else if (context.sortedlevel1 == true) {
                  return [Build3.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}  >
                      {item}
                    </MenuItem>
                  )),
                  grayBuilder.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                      {item}
                    </MenuItem>
                  ))]

                } else {
                  return BuildersAll.map((item, index) => (
                    <MenuItem key={index} value={item} className={classes.search_input}  >
                      {item}
                    </MenuItem>
                  ))
                }
              }
              )()}
            </Select>
          </FormControl>
          <div className="dividermob"> </div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label"
              className={classes.search_lable} >Min Price</InputLabel>
            <Select
              disableUnderline
              color={'secondary'}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              name="minPrice"
              value={minPrice}
              onChange={handleChange}
              className={classes.search_input}
            >
              <MenuItem value={100000} className={classes.search_input}   >$100,000</MenuItem>
              <MenuItem value={200000} className={classes.search_input}   >$200,000</MenuItem>
              <MenuItem value={300000} className={classes.search_input}   >$300,000</MenuItem>
              <MenuItem value={400000} className={classes.search_input}   >$400,000</MenuItem>
              <MenuItem value={500000} className={classes.search_input}   >$500,000</MenuItem>
              <MenuItem value={600000} className={classes.search_input}   >$600,000</MenuItem>
              <MenuItem value={700000} className={classes.search_input}   >$700,000</MenuItem>
              <MenuItem value={800000} className={classes.search_input}   >$800,000</MenuItem>
              <MenuItem value={900000} className={classes.search_input}   >$900,000</MenuItem>
              <MenuItem value={1000000} className={classes.search_input}  >$1,000,000</MenuItem>
              <MenuItem value={1500000} className={classes.search_input}  >$1,500,000</MenuItem>
              <MenuItem value={2000000} className={classes.search_input}  >$2,000,000</MenuItem>
            </Select>
          </FormControl>
          <div className="dividermob"> </div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label"
              className={classes.search_lable} >Max Price</InputLabel>
            <Select
              disableUnderline
              color={'secondary'}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              name="maxPrice"
              value={maxPrice}
              onChange={handleChange}
              className={classes.search_input}
            >
              <MenuItem value={100000} className={classes.search_input}   >$100,000</MenuItem>
              <MenuItem value={200000} className={classes.search_input}   >$200,000</MenuItem>
              <MenuItem value={300000} className={classes.search_input}   >$300,000</MenuItem>
              <MenuItem value={400000} className={classes.search_input}   >$400,000</MenuItem>
              <MenuItem value={500000} className={classes.search_input}   >$500,000</MenuItem>
              <MenuItem value={600000} className={classes.search_input}   >$600,000</MenuItem>
              <MenuItem value={700000} className={classes.search_input}   >$700,000</MenuItem>
              <MenuItem value={800000} className={classes.search_input}   >$800,000</MenuItem>
              <MenuItem value={900000} className={classes.search_input}   >$900,000</MenuItem>
              <MenuItem value={1000000} className={classes.search_input}   >$1,000,000</MenuItem>
              <MenuItem value={1500000} className={classes.search_input}   >$1,500,000</MenuItem>
              <MenuItem value={2000000} className={classes.search_input}   >${price2}</MenuItem>
              <MenuItem value={2500000} className={classes.search_input}   >$2,500,000</MenuItem>
              <MenuItem value={3000000} className={classes.search_input}   >${price3}</MenuItem>
              <MenuItem value={3500000} className={classes.search_input}   >$3,500,000</MenuItem>
              <MenuItem value={4000000} className={classes.search_input}   >${price4}</MenuItem>
              <MenuItem value={4500000} className={classes.search_input}   >$4,500,000</MenuItem>
              <MenuItem value={5000000} className={classes.search_input}   >${price5}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Paper>

      <div className="chipMob">
        {(context.city !== "ALL" && context.city !== "") && <div className="chip_space">
          <Chip label={context.city} elevation={3} /></div>}
        {(context.school !== "ALL" && context.school !== "") && <div className="chip_space"><Chip label={context.school} elevation={3} /> </div>}
        {(context.level1 !== "ALL" && context.level1 !== "") && <div className="chip_space"><Chip label={context.level1} elevation={3} /></div>}
        {(context.builder !== "ALL" && context.builder !== "") && <div className="chip_space"><Chip label={context.builder} elevation={3} /></div>}

      </div>

    </>
  )
}
