import React from 'react'
import { Box, styled, Tab, Tabs, Typography } from '@mui/material'


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const StyledTab = styled(Tab)(({ theme }) => ({
    color: '#000',
    fontSize: " 1rem",
    fontWeight: 600,
    minWidth: 100,
    '&.Mui-selected': {
        color: '#fff',
        background: '#00aef0'
    },

}))

const AlexNavbar = (props) => {
    const [value, setValue] = React.useState(0);
    const {
        OverviewScrolldiv,
        DescriptionScrolldiv,
        FloorScrolldiv,
        PhotoGalleryScrolldiv,
        VedioGalleryScrolldiv,
        SitemapScrolldiv,
    } = props;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }}  >
            <Box sx={{
                margin: '-50px 24px',
                position: 'fixed',
                width: ' calc(100% - 48px)',
                left: 0,
                zIndex: 3,
                opacity: 1,
                background: 'rgba(255, 255, 255, 0.8)',
                color: 'rgb(52, 71, 103)',
                borderRadius: '0.75rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
                backdropFilter: 'saturate(200%) blur(30px)',
            }}>
                <Tabs value={value} onChange={handleChange} centered indicatorColor='none'>
                    <StyledTab label="Overview" {...a11yProps(0)} onClick={OverviewScrolldiv} />
                    <StyledTab label="Description" {...a11yProps(1)} onClick={DescriptionScrolldiv} />
                    <StyledTab label="Floor Plan" {...a11yProps(2)} onClick={FloorScrolldiv} />
                    <StyledTab label="Photo Gallery" {...a11yProps(3)} onClick={PhotoGalleryScrolldiv} />
                    <StyledTab label="Video Gallery" {...a11yProps(4)} onClick={VedioGalleryScrolldiv}/>
                    <StyledTab label="Site Map" {...a11yProps(5)} onClick={SitemapScrolldiv} />
                </Tabs>
            </Box>
        </Box>
    )
}

export default AlexNavbar