import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, styled, TextareaAutosize, TextField, Typography, useMediaQuery } from '@mui/material'
import { AttachFile, Cancel, Clear, Close, CloudUpload, Delete, FileUploadOutlined, Save, UploadFileOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import firebase from '../firebase/firebase'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import shortid from 'shortid';
import emailjs from 'emailjs-com';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};



const Textarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    padding: '12px',
    borderRadius: '12px 12px 0 12px',
    color: `${theme.palette.mode === 'dark' ? grey[300] : grey[900]}`,
    background: `${theme.palette.mode === 'dark' ? grey[900] : '#fff'}`,
    border: `1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]}`,
    boxShadow: `0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]}`,
    '&:hover': {
        borderColor: `${blue[400]}`,
    },
    '&:focus': {
        outline: 0,
        borderColor: `${blue[400]}`,
        boxShadow: `10 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]}`,
    },
    '&:focus-visible': {
        outline: 0,
    }
}));



const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const TodayDate = new Date().toISOString().split('T')[0];

const ScoopPannel = (props) => {
    const isMatch = useMediaQuery('(max-width:960px )')
    const [fileName, setFileName] = React.useState([]);
    const [file, setFile] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [allValues, setAllValues] = React.useState({
        newName: '',
        title: '',
        description: '',
        selectedDate: TodayDate,
        file: ''
    });


    const {
        Open,
        Close,
        addCardToFirebase,
        propertyname,
        editItem,
        wishlistData
    } = props;




    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAllValues({ ...allValues, [name]: value });
    };

    const handleClearFileName = () => {
        setFileName(''); // Clear the file name
    };

    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };


    // const handleFileChange = (e) => {
    //     if (e.target.files) {
    //         async function task() {
    //             const image = e.target.files[0];
    //             let folderName = 'PDF files';
    //             const id = shortid.generate();
    //             const metadata = {
    //                 contentType: 'application/pdf',
    //             };
    //             var storageRef = firebase.storage().ref(`${folderName}/${image.name}`)
    //             storageRef.put(file, metadata)
    //                 .then((snapshot) => {
    //                     // PDF file uploaded successfully, get its download URL
    //                     return storageRef.getDownloadURL();
    //                 })
    //                 .then((downloadURL) => {
    //                     setFile(downloadURL);
    //                     setFileName(image.name)
    //                 })
    //         }
    //         task();
    //     }
    // }
    const handleFileChange = async (e) => {
        const newFiles = e.target.files;

        if (newFiles) {
            const uploadPromises = Array.from(newFiles).map(async (file) => {
                return new Promise((resolve, reject) => {
                    let folderName = 'PDF files';
                    const id = shortid.generate();
                    const storageRef = firebase.storage().ref(`${folderName}/${id + "_pdffile_" + file.name}`);
                    const uploadTask = storageRef.put(file);

                    uploadTask.on('state_changed', (snapshot) => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setProgress(progress);
                    }, (error) => {
                        console.error(error);
                        reject(error);
                    }, async () => {
                        const downloadURL = await storageRef.getDownloadURL();
                        resolve({ downloadURL, filename: file.name });
                    });
                });
            });

            try {
                const results = await Promise.all(uploadPromises);
                const newDownloadURLs = results.map((result) => result.downloadURL);
                const newFileNames = results.map((result) => result.filename);

                setFile((prevFiles) => [...prevFiles, ...newDownloadURLs]);
                setFileName((prevFileNames) => [...prevFileNames, ...newFileNames]);
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }
    };

    // const keys = Object.keys(propertyData).filter((key) => propertyData[key].propertyname === propertyname);
    // const Prop = keys.map((id) => propertyData[id].scoop)
    // console.log('pn',  propertyname)
    // console.log('key', keys)


    // React.useEffect(() => {
    //     fireDB.child('properties').on('value', snapshot => {
    //         if (snapshot.val() != null)
    //             setPropertyData({
    //                 ...snapshot.val()
    //             })
    //         else
    //             setPropertyData({})
    //     })
    // }, [])

    const handleUpload = async (event) => {
        event.preventDefault();
        const { newName, title, description, selectedDate, } = allValues;

        if (newName && title && description && selectedDate) {
            // Create a data object with all values
            const data = {
                newName: newName,
                title: title,
                description: description,
                selectedDate: selectedDate,
                file: file, // Save the download URL
            };

            await addCardToFirebase(data);
            if (wishlistData) {
                // After adding scoop data and ensuring there is a wishlist, send the email
                sendEmail(data);
            }
            setAllValues({ ...allValues });
            setFile('');
        };
    }

    const sendEmail = (data) => {
        const { newName, title, description, selectedDate, file } = data;
        const Email = wishlistData.map((item) => item.email);
        var str = file;
        var str1 = new URL(str).pathname.split('/').pop();
        var str2 = (str.includes("_pdffile_")) ? str1.split("_pdffile_")[1] : str1

        function getFileExtension(filename) {
            // Split the filename based on the dot
            const parts = filename.split('.');

            // Take the last part of the array as the file extension
            const extension = parts[parts.length - 1];
            console.log("id2", extension.toLowerCase())
            return extension.toLowerCase();
            // Convert to lowercase for consistency
        }

        const fileExtension = getFileExtension(str2);

        const templateParams = {
            to_email: Email, // Replace with the actual recipient email
            subject: 'New Scoop Data Added',
            title: title,
            message: description,
            extension: fileExtension,
            cid: file,
            file_name: fileName,
            added_by: newName,
        };

        emailjs
            .send('service_b9v5hfs', 'template_lc2aseu', templateParams, 'user_c6L1p3GVRkDc5i4AlvRat')
            .then((response) => {
                console.log('Email sent successfully:', response);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    return (
        <BootstrapDialog
            onClose={Close}
            aria-labelledby="customized-dialog-title"
            open={Open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={Close}>
                Write Your Scoops for {propertyname}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={isMatch ? 12 : 6}>
                        <FormControl sx={{ width: 280 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Name</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={allValues.newName}
                                name="newName"
                                onChange={handleInputChange}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="Amit">Amit</MenuItem>
                                <MenuItem value="Becky">Becky</MenuItem>
                                <MenuItem value="Pradeep">Pradeep</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <TextField
                            label="Name"
                            name="newName"
                            value={allValues.newName}
                            onChange={handleInputChange}
                            fullWidth
                        /> */}
                    </Grid>
                    <Grid item xs={isMatch ? 12 : 6}>
                        <TextField
                            label="Date"
                            type="date"
                            name="selectedDate"
                            defaultValue={TodayDate}
                            value={allValues.selectedDate}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Date"
                                name="date"
                                value={allValues.selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} name="date" variant='outlined' />}
                                fullWidth
                            />
                        </LocalizationProvider> */}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            name="title"
                            value={allValues.title}
                            onChange={handleInputChange}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            label="Description"
                            name="description"
                            minRows={3}
                            placeholder="Description"
                            value={allValues.description}
                            onChange={handleInputChange}
                            sx={{ width: '100%' }} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <input
                            accept=".pdf"
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <CloudUpload style={{ marginRight: '8px' }} />
                                    ),
                                }}
                                value={file ? file.name : ''}
                                placeholder="Upload PDF file"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </label>
                    </Grid> */}
                    <Grid item xs={9}>
                        <TextField
                            label="Upload your file"
                            variant="outlined"
                            fullWidth
                            value={fileName.join(', ')}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {fileName === '' ? null :
                                            <IconButton onClick={handleClearFileName}>
                                                <Clear />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <input
                            id="fileInput"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept="*/*"
                        />
                    </Grid>
                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                        {
                            isMatch === true ?
                                <IconButton onClick={openFileDialog}
                                    sx={{
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#2e7d32',
                                            color: '#fff', // Change the background color on hover
                                        },
                                    }}>
                                    <FileUploadOutlined />
                                </IconButton>
                                :
                                <Button variant="contained" color="success" onClick={openFileDialog} startIcon={<FileUploadOutlined />}>
                                    Browse
                                </Button>
                        }
                        {/* <Button variant="contained" color="primary" onClick={openFileDialog} startIcon={<UploadFileOutlined />}>
                            Browse
                        </Button> */}
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgressWithLabel variant="determinate" value={progress} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' autoFocus onClick={handleUpload} endIcon={<Save />}>
                    Save
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default ScoopPannel