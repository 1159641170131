import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import '../components/Mobile/MobileDetail.css';
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Footer from '../components/Footer';
import white from '../images/Image_not_available.png';
import firebase from '../firebase/firebase';
import { MapRounded, StarRounded } from '@mui/icons-material';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { ArticleRounded, RoofingRounded, WorkOutlineRounded, ThreeDRotationRounded, GroupWorkRounded } from '@mui/icons-material';
import { Drawer, IconButton, MenuList, MobileStepper, Stack } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BookTour from '../contact/BookTour';
import '../components/ImagePopup.css';
import '../components/Mobile/MobileDetail.css'
import Axios from 'axios';
import BookConsultation from '../components/Cards/BookConsultation';
import nwmlslogo from '../images/nwmls_small.png';
import LicenseDrawer from './LicenseDrawer';

const useStyles = makeStyles((theme) => ({
    root: {

        flexGrow: 1,
    },
    root3: {
        paddingTop: "5px",
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 220,
        display: 'block',
        overflow: 'hidden',
        width: '100%',
    },
    mediaSmall: {
        width: "100%",
        height: "100%"
    },
    paper: {
        margin: 'auto',
    },
    paperimg: {

    },
    chip: {
        margin: theme.spacing(0.5),
    },
    section1: {
        margin: "5px 24px 16px"
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: theme.spacing(3, 1, 1),
    },
    FooterPad: {

    },
    appBar: {
        top: 'auto',
        bottom: 0,
        "backgroundColor": "#ffce34",
        width: '100%'
    },
    imageBody: {
        height: 200,
        width: 250
    },
    listitemtext: {
        fontSize: 15,
        fontWeight: 400,
        paddingRight: 9,
        paddingLeft: 9
    },
}));


function MobDetailpage(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [openFloor, setOpenFloor] = React.useState(true);
    const [currentUser, setCurrentUser] = React.useState(false);
    const [openn2, setOpenn2] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [openHm, setOpenHm] = React.useState(true);
    const [openSc, setOpenSc] = React.useState(true);
    const [elementryschooldata, setElementryschooldata] = React.useState([])
    const [middleschooldata, setMiddleschooldata] = React.useState([])
    const [highschooldata, setHighschooldata] = React.useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {
        title,
        city,
        children,
        Schools,
        Level1,
        level2,
        Level3,
        Builders,
        Details,
        Bedroom,
        Bathroom,
        builders,
        Kitchen,
        Adderss,
        Map,
        Tag,
        startprice,
        endprice,
        status,
        propertyname,
        community,
        url,
        floorUrl,
        OfferDate,
        MlsId,
        ActiveStatus,
        video,
        builtyear,
        ListingPrice,
        style,
        zip,
        index, ...other } = props;

    var pIname = propertyname.toLowerCase()
    var pname = pIname.split("by")[0];
    // var ppname = pname.charAt(0).toUpperCase() + pname.slice(1)
    var words = pname.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickHomeDetails = () => {
        setOpenHm(!openHm);
    };
    const handleClickSchoolDetails = () => {
        setOpenSc(!openSc);
    };

    const handleClickOpen = () => {
        setOpen1(true);
    };

    const handleClose = () => {
        setOpen1(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleClickOpen1 = () => {
        setOpenn2(true);
    };


    const handleClose1 = () => {
        setOpenn2(false);
    };

    useEffect(() => {

        var user = firebase.auth().currentUser;
        if (user !== null) {
            setCurrentUser(true);
        }
        else setCurrentUser(false)
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                setCurrentUser(true);
            }
            else setCurrentUser(false)
        }
        )

    }, []);


    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }
    const SiteUrl = window.location.pathname;

    useEffect(() => {
        try {
            Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?city=${props.city}&zip=${props.zip}&level_codes=e&school_type=(public or charter)&name=${props.Level1}&limit=25`,
                {
                    headers: {
                        'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
                    }
                })
                .then(data => {
                    setElementryschooldata(data.data.schools);
                    console.log("elem", data)
                })


            Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?zip=${props.zip}&city=${props.city}&level_codes=m&name=${props.level2}&limit=25`, {
                headers: {
                    'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
                }
            })
                .then(data => {
                    setMiddleschooldata(data.data.schools);
                    console.log("middle", data)
                })

            Axios.get(`https://mycorsproxy-homewastate.herokuapp.com/https://gs-api.greatschools.org/schools?city=${props.city}&zip=${props.zip}&level_codes=h&name=${props.Level3}&limit=25`, {
                headers: {
                    'X-API-KEY': `hSnOfCSZsQ1vH7aI8PhIi76VTIz8ZbbK9fVXpIm4`,
                }
            })
                .then(data => {
                    setHighschooldata(data.data.schools);
                    console.log("high", data)
                })
        }
        catch (err) {
            console.log(console.err)
        }


    }, [])

    const schoolSearch = [Level1];
    const schoolMidd = [level2];
    const schoolHigh = [Level3]

    let elemschool = schoolSearch.map(item => {
        const obj = elementryschooldata.find(o => o.name === item) || { ...item }
        return { ...item, name: obj.name, rating: obj.rating }

    });
    // console.log('elementary', elemschool)


    let middschool = schoolMidd.map(item => {
        const obj = middleschooldata.find(o => o.name === item) || { ...item }
        return { ...item, name: obj.name, rating: obj.rating }

    });

    // console.log('elementary', middschool)

    let highschool = schoolHigh.map(item => {
        const obj = highschooldata.find(o => o.name === item) || { ...item }
        return { ...item, name: obj.name, rating: obj.rating }

    });

    // console.log('elementary', highschool)


    return (
        <div>
            <Paper style={{ width: '100%', marginTop: '4em' }} elevation={0} >
                <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >{(() => {
                            if (url)
                                return url.map((item, index) => (
                                    <div key={url}>
                                        {Math.abs(activeStep - index) <= 40 ? (
                                            <img className={classes.img} src={item} alt={propertyname} />
                                        ) : null}
                                    </div>
                                ))
                            else {
                                return <div >

                                    <img className={classes.img} src={white} alt="Image" />

                                </div>
                            }

                        })()}
                        </SwipeableViews>
                    </SRLWrapper>
                </SimpleReactLightbox>
                {ActiveStatus == 'Active' && <div className="top-left_active">Active</div>}
                {ActiveStatus == 'Just Sold' && <div className="top-left_c_Sold2">Just Sold</div>}
                {ActiveStatus == 'Pending' && <div className="top-left_pending">Pending</div>}
                {ActiveStatus == 'Sold' && <div className="top-left_sold2">Sold </div>}
                {Tag == 'Recommended' && <div className="top-Right_Rec_mob_D">Recommended</div>}
                <MobileStepper
                    steps={url ? url.length : 1}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    sx={{ background: '#fafafa' }}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === (url ? url.length : 1) - 1}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                        </Button>
                    }
                />
            </Paper>

            <Paper style={{ margin: 'auto' }} elevation={0}>
                <div style={{ paddingTop: "5px", }}>
                    <div style={{ margin: "5px 24px 16px" }}>
                        <Grid container alignItems="center">
                            <Grid container spacing={2} >
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Stack direction="column" spacing={0.5}>
                                            <Typography style={{ fontSize: '15px', fontWeight: 500 }}>
                                                {Adderss}
                                            </Typography>
                                            <Typography variant="h7" style={{ color: '#565656c9', fontWeight: 400 }}>
                                                MlsId : {MlsId}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        <div className="searchResult__" >
                                            {(() => {

                                                if (ActiveStatus == 'Sold') {
                                                    return (
                                                        <p id="price_1">
                                                            <strong id="price_from">
                                                                Sold
                                                            </strong>
                                                        </p>)
                                                }
                                                if (ActiveStatus == 'Pending') {
                                                    return (
                                                        <p id="price_1">
                                                            <strong id="price_from">
                                                                Pending
                                                            </strong>
                                                        </p>)
                                                }
                                                if (ActiveStatus == 'Just Sold') {
                                                    return (
                                                        <p id="price_1">
                                                            <strong id="price_from">
                                                                Just Sold
                                                            </strong>
                                                        </p>)
                                                }
                                                if (!ListingPrice) {
                                                    return (
                                                        <p id="price_1">
                                                            <strong id="price_from">
                                                                Not Available
                                                            </strong>
                                                        </p>)
                                                }
                                                else if (!startprice) {
                                                    return (
                                                        <div>
                                                            <p id="price_1">
                                                                <div className="starting">
                                                                    Listed Price
                                                                </div>
                                                                <strong id="price_from">
                                                                    <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                                </strong>
                                                            </p>
                                                        </div>)
                                                }
                                                else {
                                                    return (<div>
                                                        <p id="price_1">
                                                            {/* from  */}
                                                            <strong id="price_from">
                                                                <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                                {' - '}
                                                                <NumberFormat value={endprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                            </strong>
                                                        </p>
                                                    </div>)
                                                }
                                            })()}
                                            <p id="price_divider"></p>
                                        </div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <div className='mobsearchResult__info2'>
                            <div className="mobsearchResult__infoTop2">
                                <h8>{Adderss}</h8>
                            </div>
                        </div> */}
                        <div className="CallButton">
                            <Grid container spacing={1} >
                                <Grid item xs>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        className={classes.button}
                                        startIcon={<CallIcon />}
                                    >
                                        <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">Call</Link>
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        style={{ "background-color": "#128C7E" }}
                                        startIcon={<WhatsAppIcon />}
                                    >
                                        <Link color="inherit" style={{ textDecoration: 'none', color: 'white' }} href={`https://api.whatsapp.com/send?phone=+14255888470&text=MlsId : ${MlsId} , Address : ${Adderss}`}>WhatsApp</Link>
                                    </Button>
                                </Grid>

                            </Grid>
                        </div>
                    </div>
                    <Divider variant="middle" />

                    <div className={classes.section2}>
                        <div className="space_around">
                            <div className="mobsearchResult__infoTop2">
                                <h5>Highlights</h5>
                            </div>
                        </div>
                        <Chip
                            icon={<OpenWithRoundedIcon />}
                            label={`Size : ${props.Size} Sq.ft`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<MapRounded />}
                            label={`Plot Size : ${props.PlotSize} Sq.ft`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<StarRounded />}
                            label={`Style : ${props.style}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<WorkOutlineRounded />}
                            label={`Built year : ${props.builtyear}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<LocalHotelIcon />}
                            label={`Beds : ${props.bedrooms}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<BathtubIcon />}
                            label={`Baths : ${props.bathrooms}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<DirectionsCarRoundedIcon />}
                            label={`Bay Garage : ${props.parking}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<MonetizationOnRoundedIcon />}
                            label={`Annual Taxes : ${props.AnnualTaxes}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<AttachMoneyRoundedIcon />}
                            label={`HOA Dues: ${props.Hoa}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<TodayRoundedIcon />}
                            label={`Tax Year : ${props.TaxYear}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<RoofingRounded />}
                            label={`Roof : ${props.Roof}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<ArticleRounded />}
                            label={`Property Type : ${props.PropertyType}`}
                            className={classes.chip}
                        />
                        <Chip
                            icon={<GroupWorkRounded />}
                            label={`Exterior : ${props.exterior}`}
                            className={classes.chip}
                        />
                    </div>
                    <Divider variant="middle" />
                </div>
                <div>
                    <ListItem button onClick={handleClickHomeDetails}>
                        <ListItemIcon>
                            {openHm ? <ExpandLess style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} /> : <ExpandMore style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} />}
                        </ListItemIcon>
                        <Typography style={{ fontSize: "1.2rem" }}>Home Details</Typography>
                    </ListItem>

                    <Collapse in={openHm} timeout="auto" unmountOnExit className={classes.section2} >
                        <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Source</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400", width: '70%' }} >NWMLS #{` ${props.MlsId}`}
                                        <IconButton disableRipple
                                            sx={{
                                                '&:hover': {
                                                    background: 'none',
                                                }
                                            }}>
                                            <img src={nwmlslogo} alt="NWMLS Logo" height="18px"
                                                style={{
                                                }} onClick={handleClickOpen1} />
                                        </IconButton>
                                    </TableCell>
                                    <Drawer
                                        anchor='right'
                                        open={openn2}
                                        onClose={handleClose1}
                                        sx={{
                                            width: '75%',
                                            flexShrink: 0,
                                            [`& .MuiDrawer-paper`]: { width: '75%', bgcolor: 'rgb(5, 30, 52)', color: 'white' },
                                        }}
                                    >
                                        <LicenseDrawer Close={handleClose1} />
                                    </Drawer>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Size</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }} >{` ${props.Size} Sq.Ft.`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Plot Size</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.PlotSize}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Beds</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.bedrooms}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Baths</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.bathrooms}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Bay Garage</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.parking}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Annual Taxes</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.AnnualTaxes}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>HOA Dues</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Hoa}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Tax Year </TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.TaxYear}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Roof</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Roof}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Property Type</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.PropertyType}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Exterior</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.exterior}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Style</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.style}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>Built year</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.builtyear}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>School District</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Schools}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }} >Elementary School</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Level1}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }} >Middle School</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.level2}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "1rem", color: "GrayText" }}>High School</TableCell>
                                    <TableCell align="right" style={{ fontSize: "1rem", fontWeight: "400" }}>{`${props.Level3}`}</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                        {
                            (ActiveStatus == 'Sold') ?
                                null
                                :
                                <div>
                                    <Divider variant="middle" />
                                    {(() => {
                                        if (video != undefined && video != "")
                                            return (
                                                <div>
                                                    <MenuList >
                                                        <MenuItem>
                                                            <ListItemIcon>
                                                                <ThreeDRotationRounded />
                                                            </ListItemIcon>
                                                            <Typography style={{ fontSize: "1.2rem" }}>360 Virtual Tour</Typography>
                                                        </MenuItem>
                                                    </MenuList>
                                                    <div style={{ padding: "30px 30px 40px " }}>
                                                        <iframe src={`${props.video}`}
                                                            width="100%"
                                                            height="500px"
                                                            frameborder="0"
                                                            allowfullscreen
                                                            allow="xr-spatial-tracking">
                                                        </iframe>
                                                    </div>
                                                </div>
                                            )
                                    })()}
                                </div>
                        }

                    </Collapse>
                </div>
                <Divider variant="middle" />
                <div className={classes.section2}>
                    <div className="space_around">
                        <div className="mobsearchResult__infoTop2">
                            <h5>Details</h5>
                        </div>
                    </div>
                    <Typography variant="body2" component="p">
                        {props.Details}
                    </Typography>
                </div>

                <Divider variant="middle" />
                <div>
                    <ListItem button onClick={handleClickSchoolDetails}>
                        <ListItemIcon>
                            {openHm ? <ExpandLess style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} /> : <ExpandMore style={{
                                "background": "rgb(255 141 0)",
                                "border-radius": "1000px",
                                "color": "white",
                                "height": "20px",
                                "width": "20px"
                            }} />}
                        </ListItemIcon>
                        <Typography style={{ fontSize: "1.2rem" }}>Area Schools</Typography>
                    </ListItem>
                    <Collapse in={openSc} timeout="auto" unmountOnExit className={classes.section2} >
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">
                                    <img src={greatschool} alt="Great School" className='great_schoolLogo' />
                                </a>
                            </Grid> */}

                            <Grid item xs={12}>
                                <div style={{ fontSize: '1rem', fontWeight: '450' }}>School District</div>
                                <h7 className="Schools_h7" style={{ marginTop: 0 }}> {props.Schools}</h7>
                            </Grid>
                            <Grid item xs={12}>
                                {(() => {
                                    if (!props.Level1 && !props.level2 && !props.Level3) {
                                        return <div>
                                            {/* <h6 className="Schools_h6"> Available Soon</h6> */}
                                            <p><strong>Available Soon.</strong>This home is within the <a rel="canonical" href="https://www.greatschools.org/WA/" target="_blank">Lake Washington School District.</a></p>
                                            <p> <strong>{props.city} enrollment policy is not based solely on geography.</strong>  Please check the school district website to see all schools serving this home.</p>
                                        </div>
                                    }
                                    else {
                                        return (
                                            <TableContainer component={Paper} elevation={2}>
                                                <Table >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell > <div className="Mob_Single_tab">Type</div></TableCell>
                                                            <TableCell> <div className="Mob_Single_tab" >Schools</div></TableCell>
                                                            {/* <TableCell> <div className="Mob_Single_tab" >Rating</div></TableCell> */}
                                                            {/* <TableCell> <div className="Single_tab" >Students</div></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" >
                                                                <h6 className="Mob_Schools_h6"> Elementary school</h6>
                                                            </TableCell>
                                                            <TableCell ><h7 className="Mob_Schools_h7">{props.Level1 ? props.Level1 : 'NA'} </h7></TableCell>
                                                            {/* <TableCell >
                                                                {elemschool.map((item) => (
                                                                    <div className="Mob_Schools_Rating2" key={item.name}>
                                                                        <h7 className="Mob_Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'} </h7>
                                                                    </div>
                                                                ))}

                                                            </TableCell> */}
                                                            {/* <TableCell ><h7 className="Schools_h7">{elementryschooldata?.enrollment ? elementryschooldata.enrollment :'NA'} </h7></TableCell> */}
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" >
                                                                <h6 className="Mob_Schools_h6">Middle schools</h6>
                                                            </TableCell>
                                                            <TableCell ><h7 className="Mob_Schools_h7">{props.level2 ? props.level2 : 'NA'}</h7> </TableCell>
                                                            {/* <TableCell >
                                                                {middschool.map((item) => (
                                                                    <div className="Mob_Schools_Rating2" key={item.name}>
                                                                        <h7 className="Mob_Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'} </h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                                                            {/* <TableCell ><h7 className="Schools_h7">{middleschooldata?.enrollment ? middleschooldata.enrollment :'NA'} </h7></TableCell> */}
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" >
                                                                <h6 className="Mob_Schools_h6">High schools</h6>
                                                            </TableCell>
                                                            <TableCell ><h7 className="Mob_Schools_h7">{props.Level3 ? props.Level3 : 'NA'}</h7> </TableCell>
                                                            {/* <TableCell >
                                                                {highschool.map((item) => (
                                                                    <div className="Mob_Schools_Rating2" key={item.name}>
                                                                        <h7 className="Mob_Schools_h7">{item.rating ? item.rating + '' + '/' + '' + '10' : 'NA'} </h7>
                                                                    </div>
                                                                ))}
                                                            </TableCell> */}
                                                            {/* <TableCell ><h7 className="Schools_h7">{highschooldata?.enrollment ? highschooldata.enrollment :'NA'} </h7></TableCell> */}
                                                        </TableRow>

                                                    </TableBody>
                                                    {/* <caption style={{ "font-size": "10px" }}> <strong>
                                                        School data provided by <>
                                                            <a href="https://www.greatschools.org/" target="_blank">GreatSchools.</a> </>
                                                        School service boundaries are intended to be used as reference only. To verify enrollment eligibility for a property, contact the school directly.
                                                    </strong></caption> */}
                                                </Table>
                                            </TableContainer>

                                        )
                                    }
                                })()}
                            </Grid>
                            <Grid xs={12} style={{ marginTop: '1.5em' }}>


                            </Grid>
                        </Grid>
                    </Collapse>

                </div>
                <div className={classes.section2}>
                    <BookConsultation />
                </div>
            </Paper>
            <Footer />
            {
                (ActiveStatus == 'Sold' || ActiveStatus == 'Pending') ?
                    null
                    :
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar style={{ "min-height": "48px" }}>
                            <Button
                                onClick={handleClickOpen}
                                fullWidth >BOOK YOUR TOUR NOW</Button>
                            <BookTour Open={open1} Close={handleClose} Address={props.Adderss} MlsId={props.MlsId} />
                        </Toolbar>
                    </AppBar>

            }
            <div className={classes.FooterPad}>
            </div>
        </div>

    );
}
export default MobDetailpage;



