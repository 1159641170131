import React, { useEffect } from 'react';
import './AvilableHome.css';
import { withPropertyConsumer } from '../context';
import Divider from '@material-ui/core/Divider';
import './Availability.css';
import defaultImage from '../images/room-1.jpeg'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Button from '@material-ui/core/Button';
import DailogForm from '../contact/DailogForm';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import DailogformContact from '../contact/DailogformContact';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import firebase from '../firebase/firebase';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pad: {
    backgroundColor: '#F6F5F5'
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    borderRadius: 15
  },

  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    borderRadius: 50
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    borderRadius: "10px"
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  divider: {
    backgroundColor: "#ffce34"
  },
  cardContent: {
    flexGrow: 1,
  },
  button: {
    borderRadius: 0
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  mediaSmall: {
    height: 150,
  },
}));




function Row(props) {
  const { card } = props;
  const [currentUser, setCurrentUser] = React.useState(false)
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const classes = useRowStyles();
  const handleClickOpen = () => {
    setOpen1(true);
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };
  
  console.log("currentuser", open2)


  useEffect(() => {

    var user = firebase.auth().currentUser;
    if (user !== null) {
      setCurrentUser(true);
    }
    else setCurrentUser(false)
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('This is the user: ', user)
        setCurrentUser(true);
      }
      else setCurrentUser(false)
    }
    )

  }, []);


  return (<div className="card_button">

    <Button variant="contained" color="primary" size="small" fullWidth className={classes.button}
      endIcon={<ChevronRightRoundedIcon />} onClick={handleClickOpen}>
      Tour Now
    </Button>
     <DailogForm Open={open1} Close={handleClose} property={card.community} Facing={card.facing} AvName={card.Activename}/> 
   
  </div>
  );
}

const Availability = ({ context, props }) => {
  const classes = useStyles();
  const { avilable } = context;

  const [openn, setOpenn] = React.useState(false);
  const {
    url, } = props;
  // const image =props.url[0]
  // console.log("urlurl", image)
  
  const handleOpen = () => {
    setOpenn(true);
  };

  const handleDone = () => {
    setOpenn(false);
  };
  let avilableproperty = avilable;
  avilableproperty = [
   ...avilableproperty.filter(house => house.community === props.community).filter(house => house.ActiveStatus === "Active"),
   ...avilableproperty.filter(house => house.community === props.community).filter(house => house.ActiveStatus !== "Active"),
   
  ]
  const Avcount = avilableproperty.length;

  if (Avcount.length === 0) {
    return (
      <div className="Schools_KNW">
        <h5 className="Schools_h6AD">Contact  Us to know current availability
          <div className="Schools_BT">
            <Button variant="contained" color="primary" size="small"
              endIcon={<ChevronRightRoundedIcon />} onClick={handleOpen}>
              Know More
            </Button></div>
          <DailogformContact Open={openn} Close={handleDone} property={props.propertyname} />
        </h5>
      </div>
    )
  }

  return (
    <div>
      <Box display={{ xs: 'none', sm: 'block' }} m={1}  >
        <CssBaseline />

        <main>

          <Container maxWidth="md" style={{ paddingBottom: 30 }}>
            {/* End hero unit */}
            <div className="AVA_value">
              <h5>
                Available Homes {Avcount !== 0 && <Chip
                  label={Avcount}
                  className={classes.chip}
                  color="primary"
                  style={{ "border-radius": "500%", "font-size": "13px" }}
                />}
              </h5>
            </div>
            {Avcount === 0 ?
              (
                <div className="Schools_KNW" style={{ padding: "15px 18px 10px" }}>
                  <h5 className="Schools_h6AD">Contact  Us to know current availability
                    <div className="Schools_BT">
                      <Button variant="contained" color="primary" size="small"
                        endIcon={<ChevronRightRoundedIcon />} onClick={handleOpen}>
                        Know More
                      </Button></div>
                    <DailogformContact Open={openn} Close={handleDone} property={props.propertyname} />
                  </h5>
                </div>

              ) :
              (<Grid container spacing={4}>
                {avilableproperty.map((card) => (
                  //   <Grid item key={card} xs={12} sm={6} md={4}>
                  <Grid item key={card} xs={12} sm={6} md={6}>
                    <Card className={classes.card} elevation={3}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={url || defaultImage} alt="single image"
                        title="Image title"
                      />
                      {card.ActiveStatus === 'Pending' && <div className="top-Availba_desk">{card.ActiveStatus}</div>}
                      {card.ActiveStatus === 'Active' && <div className="top-Availba_desk_AV">{card.ActiveStatus}</div>}
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h7" component="h6" color="primary">
                          {card.Activename}
                        </Typography>
                        <div className="cards__wrap">
                          <div className="card_facing">
                            <Typography variant="h9" component="h8" >
                              Facing : {card.facing ? card.facing : 'NA'}
                            </Typography>
                          </div>
                          <strong>| </strong>
                          <div className="card_price">
                            From :
                            {card.startprice ? <Typography gutterBottom variant="h7" component="h6" id="price_from">
                              <NumberFormat value={card.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </Typography> : " NA"}

                          </div>
                        </div>
                        <div className="card_text" >
                          <h7 className="card_text">
                            Move in : {card.movein !== '' ? card.movein : 'NA'}, Builder : {card.builders !== '' ? card.builders : 'NA'}
                            , City : {card.city !== '' ? card.city : 'NA'}
                          </h7>
                        </div>
                        {/* <Typography  variant="h9" component="h10" >
                        Move in : {card.movein != ''? card.movein : 'NA'}, Builder : {card.builders != ''? card.builders : 'NA'}
                        , City : {card.city != ''? card.city   : 'NA'}
                    </Typography> */}
                        <Divider className={classes.divider} />
                        <div className="card_bt">
                          <h7>
                            Br : {card.bedrooms !== '' ? card.bedrooms : 'NA'} <strong>| </strong>
                            Ba : {card.bathrooms !== '' ? card.bathrooms : 'NA'}<strong>  |  </strong>
                            {card.size !== '' ? card.size : 'NA'} Sq.ft
                          </h7>
                        </div>
                      </CardContent>
                      {/* <CardActions> */}
                      <Row card={card} />

                      {/* </CardActions> */}
                    </Card>
                  </Grid>
                ))}
              </Grid>)}

          </Container>
        </main>
      </Box>


{/* //////////mobile version///////////// */}


      <Box display={{ xs: 'block', sm: 'none' }} m={1} style={{ "margin": "0" }}>
        <div className="AVA_value">
          <h5>
            Available Homes {Avcount !== 0 && <Chip
              label={Avcount}
              className={classes.chip}
              color="primary"
              style={{ "border-radius": "500%", "font-size": "13px" }}
            />}
          </h5>
        </div>
        {Avcount === 0 ?
          (
            <div className="MOB_av_no">
              <Typography gutterBottom variant="body1">Contact  Us to know current availability
              </Typography>
              <div className="Schools_BT">
                <Button variant="contained" color="primary" size="small"
                  endIcon={<ChevronRightRoundedIcon />} onClick={handleOpen}>
                  Know More
                </Button>
              </div>
              <DailogformContact Open={openn} Close={handleDone} property={props.propertyname} />

            </div>
          ) :
          (<div className="AvaGrid">
            {avilableproperty.map((card) => (
              <Grid item key={card} style={{ "padding": "10px" }} >
                <Card className={classes.root} elevation={4} spacing={2}>
                  <CardActionArea>
                    {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
                    <CardMedia
                      className={classes.mediaSmall}
                      component="img"
                      image={url || defaultImage} alt="single image"
                      title="Live from space album cover"
                    />
                    {card.ActiveStatus === 'Pending' && <div className="top-Availba_deskmob">{card.ActiveStatus}</div>}
                      {card.ActiveStatus === 'Active' && <div className="top-Availba_desk_AVmob">{card.ActiveStatus}</div>}
                    <CardContent>

                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Typography className="mobav_title">
                              <h2>Lot : {card.Activename}</h2>
                            </Typography>
                            <Typography className="mobhouse_add">
                              <h3>{card.address}</h3>
                            </Typography>
                            {/* <Typography variant="body2" color="textSecondary">
                  ID: 1030114
                </Typography> */}
                          </Grid>

                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1">
                            <div className="priceContainermob">
                              {(() => {
                                if (card.startprice) {
                                  return (
                                    <div>
                                      <p id="price_2">
                                        <div className="starting">
                                          From
                                        </div>
                                        <strong id="price_from2">
                                          <NumberFormat value={card.startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </strong>
                                      </p>
                                    </div>)
                                }
                              })()}
                            </div>
                            <p id="price_divider"></p>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography className="mobhouse_dis">
                        <p>
                          Move in : {card.movein !== '' ? card.movein : 'NA'}
                        </p>
                        <p>
                          Size : {card.size !== '' ? card.size : 'NA'}
                        </p>
                        <p>
                          Facing : {card.facing !== '' ? card.facing : 'NA'}
                        </p></Typography>
                    </CardContent>
                  </CardActionArea>
                  <div className="border_top">
                    <div className="MOBButtonContain">
                      {/* <Link underline='none' component={RouterLink} to={`/${slug}`} > */}
                      {/* </Link> */}
                    </div>
                  </div>
                  <Row card={card} />
                </Card>
              </Grid>
            ))}
          </div>)}
      </Box>
    </div>
  )
}
export default withPropertyConsumer(Availability);
















