import React, { useState, useEffect } from 'react'
import { Paper, Typography, Container, Grid, Button, TextField, Box, TextareaAutosize, InputLabel, IconButton, CircularProgress, MenuItem, Select, Tabs, Tab, Autocomplete, } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import { BurstModeRounded, DescriptionRounded, HomeRounded, InsertPhotoRounded, MonetizationOn, MonetizationOnRounded, Percent, PhotoAlbumRounded, PhotoCamera } from '@mui/icons-material';
import firebase from '../../firebase/firebase';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}



const TodayDate = new Date().toISOString().split('T')[0]

const StyledForm = styled('form')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));


const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: '1em'
}));


const StyledDiv = styled('div')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    paddingBottom: 20,
}));

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={props.value} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};



const DealsDataPannel = (props) => {

    const initialValues = {
        name: '',
        dealSlug: '',
        reviewDate: '',
        shipping: '',
        price: '',
        startprice: '',
        finalprice: '',
        rating: '',
        houseImage: '',
        gallery: '',
        IsNew: null,
        IsVisible: null,
        shortDescription: '',
        description: '',
        categories: '',
        websitelink: '',
        details: '',
        productInfo: ''
    }

    var [values, setValues] = useState(initialValues);
    var [value, setValue] = useState(0);
    const [position, setPosition] = useState('')
    const [progress, setProgress] = useState(10);
    const [houseImage, setHouseImage] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [galleryProgress, setGalleryProgress] = React.useState(10);
    var fireDB = firebase.database().ref();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (e) => {
        var { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        });
    }


    useEffect(() => {
        if (props.currentId === '') {
            setValues({ ...initialValues })
            setHouseImage([...initialValues.houseImage || ''])
            setGallery([...initialValues.gallery || ''])
        }
        else {
            setValues({
                ...props.propertyObject[props.currentId]
            })
            setHouseImage([...props.propertyObject[props.currentId].houseImage || ''])
            setGallery([...props.propertyObject[props.currentId].gallery || ''])
        }
    }, [props.currentId, props.propertyObject])




    const handleImage = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'HouseImages';
                const id = new Date().getTime();
                var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
                var uploadTask = storageRef.put(image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {
                        const downloadURL = await firebase
                            .storage()
                            .ref("HouseImages")
                            .child(id + image.name)
                            .getDownloadURL();
                        setHouseImage([downloadURL]);
                    }
                )
            }
            task();
        }
    }

    const handleGallery = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Gallery';
                const id = new Date().getTime();
                var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
                var uploadTask = storageRef.put(image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setGalleryProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {
                        const downloadURL = await firebase
                            .storage()
                            .ref("Gallery")
                            .child(id + image.name)
                            .getDownloadURL();
                        setGallery(oldState => ([...oldState, downloadURL]));
                    }
                )
            }
            task();
        }
    }

    const deleteImage = (ImageUrl, setter) => {
        let image = firebase.storage().refFromURL(ImageUrl);
        image.delete().then(function () {
            if (setter === setHouseImage) {
                let newHouseImage = houseImage.filter(item => item !== ImageUrl);
                setHouseImage(newHouseImage)
                setPosition('')
                if (props.currentId !== '') {
                    fireDB.child(`Homedeals/${props.currentId}`).update({ houseImage: newHouseImage })
                }
                console.log('image deleted', houseImage, newHouseImage);
            }
            if (setter === setGallery) {
                let newGallery = gallery.filter(item => item !== ImageUrl);
                setGallery(newGallery)
                setPosition('')
                if (props.currentId !== '') {
                    fireDB.child(`Homedeals/${props.currentId}`).update({ houseImage: newGallery })
                }
                console.log('image deleted', gallery, newGallery);
            }
        })
    }

    const handleFormsubmit = (e) => {
        e.preventDefault();
        props.add({ ...values, houseImage, gallery, })
        setHouseImage('')
        setGallery('')
    }

    return (
        <>
            <StyledForm noValidate autoComplete="off" onSubmit={handleFormsubmit} style={{ marginTop: '1em' }}>
                <StyledPaper>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        style={{ margin: '1em' }}
                    >
                        <Tab label="General" icon={<HomeRounded />} {...a11yProps(0)} />
                        <Tab label="Price" icon={<MonetizationOnRounded />}{...a11yProps(1)} />
                        {/* <Tab label="Featured" icon={< InsertPhotoRounded />}{...a11yProps(2)} /> */}
                        <Tab label="Featured" icon={<InsertPhotoRounded />}{...a11yProps(2)} />
                        <Tab label="Gallery" icon={<PhotoAlbumRounded />}{...a11yProps(3)} />
                        <Tab label="Description" icon={< DescriptionRounded />}  {...a11yProps(4)} />
                    </Tabs>
                    <TabPanel value={value} index={0} sx={{ ml: '1em' }}>
                        <Typography variant='h6' sx={{ mb: '1em' }}>General</Typography>
                        <Grid container spacing={2} diaplay="flex" flexDirection="column" alignItems="flex-end">
                            <StyledGrid xs={12} align="start" sx={{ mr: '2.1em' }}>
                                <TextField
                                    type='date'
                                    label="Release Date"
                                    defaultValue={TodayDate}
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="reviewDate"
                                    value={values.reviewDate}
                                    onChange={handleInputChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </StyledGrid>
                            <StyledGrid xs={12} align="start">
                                <TextField
                                    label="Deal Name"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="name"
                                    value={values.name}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12} align="start">
                                <TextField
                                    label="Deal Slug"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="dealSlug"
                                    value={values.dealSlug}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12} align="start">
                                <TextField
                                    label="Shipping"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="shipping"
                                    value={values.shipping}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12} align="start">
                                <TextField
                                    label="Website Link"
                                    id="filled-size-small"
                                    variant="outlined"
                                    name="websitelink"
                                    value={values.websitelink}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <FormControl fullWidth sx={{ width: '190px' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.IsNew}
                                        name='IsNew'
                                        label="Status"
                                        onChange={handleInputChange}
                                        sx={{ width: 'auto' }}
                                    >
                                        <MenuItem value={'None'}>None</MenuItem>
                                        <MenuItem value={'Recommended'}>Recommended</MenuItem>
                                    </Select>
                                </FormControl>
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <FormControl fullWidth sx={{ width: '190px' }}>
                                    <InputLabel id="demo-simple-select-label">Active</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.IsVisible}
                                        name='IsVisible'
                                        label="Active"
                                        onChange={handleInputChange}
                                        sx={{ width: 'auto' }}
                                    >
                                        <MenuItem value={'Yes'}>Yes</MenuItem>
                                        <MenuItem value={'No'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <Autocomplete
                                    sx={{ width: '190px' }}
                                    id="free-solo-demo"
                                    value={values.categories} name="city"
                                    onSelect={handleInputChange}
                                    options={category.map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Categories" margin="normal" variant="outlined"
                                            value={values.categories} name="categories"
                                            onChange={handleInputChange}
                                            fullWidth
                                        />
                                    )}
                                />
                            </StyledGrid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1} sx={{ ml: '1em' }}>
                        <Typography variant='h6' sx={{ mb: '1em' }}>Price</Typography>
                        <Grid container spacing={1} alignItems="flex-end" sx={{ mt: '1em' }}>
                            <Grid item>
                                <MonetizationOn sx={{ mb: '1em' }} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="number"
                                    label="Start-Price"
                                    value={values.startprice}
                                    name="startprice"
                                    onChange={handleInputChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end" sx={{ mt: '1em' }}>
                            <Grid item>
                                <MonetizationOn sx={{ mb: '1em' }} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="number"
                                    label="Final-Price"
                                    value={values.finalprice}
                                    name="finalprice"
                                    onChange={handleInputChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="flex-end" sx={{ mt: '1em' }}>
                            <Grid item>
                                <Percent sx={{ mb: '1em' }} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="number"
                                    label="Discount"
                                    value={values.rating}
                                    name="rating"
                                    onChange={handleInputChange} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2} sx={{ ml: '1em' }}>
                        <Typography variant="h6" sx={{ mb: '1em' }}>Featured Image</Typography>
                        <StyledGrid xs={6} align="start">
                            <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file"
                                onChange={handleImage} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <div >
                                <Box width="100%" mr={1}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ width: '100%', mr: 0 }}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>

                                    </Box>

                                </Box>
                            </div>
                            <div>
                                {(() => {
                                    if (houseImage) {
                                        return (houseImage.map((url, index) => (
                                            <div key={index}>
                                                <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                <Button variant="contained" onClick={() => { deleteImage(url, setHouseImage); setPosition(index) }}>
                                                    Delete
                                                </Button>
                                                {position === index &&
                                                    <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                }
                                            </div>
                                        )))
                                    }
                                })()}
                            </div>
                        </StyledGrid>
                    </TabPanel>
                    <TabPanel value={value} index={3} sx={{ ml: '1em' }}>
                        <Typography variant="h6" sx={{ mb: '1em' }}>Gallery</Typography>
                        <StyledGrid xs={6} align="start">
                            <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file"
                                onChange={handleGallery} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <div >
                                <Box width="100%" mr={1}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ width: '100%', mr: 0 }}>
                                            <LinearProgress variant="determinate" value={galleryProgress} />
                                        </Box>

                                    </Box>

                                </Box>
                            </div>
                            <div>
                                {(() => {
                                    if (gallery) {
                                        return (gallery.map((url, index) => (
                                            <div key={index}>
                                                <img key={url} src={url} alt="Gallery" width="200" height="130" style={{ padding: 10 }} />
                                                <Button variant="contained" onClick={() => { deleteImage(url, setGallery); setPosition(index) }}>
                                                    Delete
                                                </Button>
                                                {position === index &&
                                                    <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                }
                                            </div>
                                        )))
                                    }
                                })()}
                            </div>
                        </StyledGrid>
                    </TabPanel>
                    <TabPanel value={value} index={4} sx={{ ml: '1em', width: '700px' }}>
                        <Grid container spacing={2} display="flex" justifyContent="start" alignItems='center' sx={{ width: '700px' }}>
                            <Typography variant='h6' sx={{ mb: '1em', }}>Description</Typography>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Short Description"
                                    value={values.shortDescription}
                                    name="shortDescription"
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={2}
                                    placeholder="Highlights"
                                    value={values.details}
                                    name="details"
                                    onChange={handleInputChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={10}
                                    placeholder="Description"
                                    value={values.description}
                                    name="description"
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={10}
                                    placeholder="Product Details"
                                    value={values.productInfo}
                                    name="productInfo"
                                    onChange={handleInputChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </StyledPaper>
                <Button variant="contained" color="inherit" disableElevation type="submit" >
                    {props.currentId === '' ? "Save" : "Update"}
                </Button>
            </StyledForm>
        </>
    )
}

export default DealsDataPannel

const category = [
    { title: 'Electronics' },
    { title: 'Backyard' },
    { title: 'Furniture' }, ,
]