import React from 'react';
import { useContext, useState } from 'react';
import { PropertyContext } from '../context';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Grid, InputLabel, Select, styled, Typography, useTheme } from '@mui/material';
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import logoS from '../images/logoS.5.png'
// import Banner from './Banner'
import './Filter.css';
import banner22 from '../images/Bannermob13.png';
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";

const getUnique = (items, value) => {
  return [...new Set(items.map(item => item[value]))]
}

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  height: '10px',
  fontSize: '15px',
  fontWeight: 500,
  marginLeft: '3px',
  '&:focus': {
    borderRadius: 0,
    boxShadow: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const StyledSelect2 = styled(Select)(({ theme }) => ({
  width: '100%',
  height: '30px',
  fontSize: '14px',
  fontWeight: 500,
  '&:focus': {
    borderRadius: 0,
    boxShadow: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontWeight: 900,
  fontSize: '12px',
  position: " unset",
  marginTop: "0px",
  overflow: 'hidden',
  maxWidth: '130px',
  textOverflow: "ellipsis",
  [theme.breakpoints.down("md")]: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '110px',
    textOverflow: "ellipsis"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: '13px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '110px',
    textOverflow: "ellipsis"
  },
}));


const useStyles = makeStyles((theme) =>
({
  root: {
    flexGrow: 1,
    paddingTop: 10,
    marginTop: 150,
  },

  button: {
    display: 'block',
    marginTop: '10px',
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: '10px',
    margin: 'auto',
    maxWidth: 900,
    borderRadius: '800px'

  },
  input: {
    marginLeft: '10px',
    flex: 1,
    fontWeight: 420,
    fontSize: '15px',
    width: 100,

  },

  searchroot: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: 'auto',
    borderRadius: '30px'
  },
  search_lable: {
    fontWeight: 900,
    fontSize: '15px',
    position: " unset",
    marginTop: "0px",
    overflow: 'hidden',
    maxWidth: '130px',
    textOverflow: "ellipsis",
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '110px',
      textOverflow: "ellipsis"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '110px',
      textOverflow: "ellipsis"
    },
  },
  search_input: {
    fontWeight: 500,
  },
  divpadding: {
    paddingBottom: 10,
    margin: 'auto',
  },
  formControl: {
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "1.5rem",

        }
      }
    },
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down("md")]: {
      minWidth: 140
    },
    '@media (max-width: 999px)': {
      minWidth: '130px'
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 120
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: 60
    },

  },
  papersecard: {
    margin: "auto",
    width: 'fit-content',
    borderRadius: "50px",
    borderColor: "#ff5707e0",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "4px",
    },

  }, hover: {
    "&:hover": {
      backgroundColor: 'rgb(7, 177, 77, 0.42)'
    }
  },
  bannerPaper: {
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    padding: 34,
    paddingTop: 150
  },
  gridhover: {
    "&:hover": {
      backgroundColor: ' #827f7f24',
      borderRadius: "30px"
    }
  }
}),
);
// const useStyles2 = makeStyles({
//   underline: {
//     "&&&:before": {
//       borderBottom: "none"
//     },
//     "&&:after": {
//       borderBottom: "none"
//     }
//   }
// });

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "unset",
          width: '150px',
          '@media  screen and (max-width: 1035px)': {
            width: '140px'
          },
          '@media (max-width: 999px)': {
            width: '130px'
          },
          '@media  screen and (max-width: 960px)': {
            width: '120px'
          },
          '@media  only screen and (max-width: 600px)': {
            width: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '13px'
          }

        }
      },

    },
    MuiInputLabel: {
      shrink: {
        transformOrigin: 'top left',
      },
    },
  },
});





export default function PropertyFilter({ Property }) {;
  const classes = useStyles();
  const context = useContext(PropertyContext);
  // console.log("context",context.sortedCity)
  const {
    handleChange3,
    handleChange,
    handleChange2,
    school,
    builder,
    minPrice,
    maxPrice,
    clickCity,
    level1
  } = context;
  const price2 = '2,000,000';
  const price3 = '3,000,000';
  const price4 = '4,000,000';
  const price5 = '5,000,000';

  let citys = getUnique(Property, 'city');
  citys = [...citys];
  citys = citys.filter(item => item)
  console.log("cities", citys.filter(item => item));
  // school
  let elementoryAll = getUnique(Property, 'level1');
  let elementory = getUnique(context.sortedCity, 'level1');
  let elementory1 = getUnique(context.sortedbuilder2, 'level1');
  let elementory2 = getUnique(context.sortedCity && context.sortedbuilder, 'level1');
  let elementory4 = getUnique(context.sortedCity && context.sortedscl, 'level1');
  let elementory3 = getUnique(context.sortedscl2, 'level1');
  let elementory5 = getUnique(context.sortedscl, 'level1');
  let elementory6 = getUnique(context.sortedbuilder, 'level1');
  let Scl = getUnique(context.sortedscl, 'level1');

  let SclBuild = Scl.filter((o) => elementory6.indexOf(o) === -1);
  let SclBuild1 = elementory6.filter((o) => Scl.indexOf(o) === -1);


  const SchlBuilder = SclBuild.concat(SclBuild1)

  Scl = ['ALL', ...Scl]
  elementory = ['ALL', ...elementory]
  elementory = elementory.filter(item => item !== "Discovery Elementary")
  elementoryAll = ['ALL', ...elementoryAll]
  elementoryAll = elementoryAll.filter(item => item !== "Discovery Elementary")
  elementory1 = ['ALL', ...elementory1]
  elementory1 = elementory1.filter(item => item !== "Discovery Elementary")
  elementory3 = ['ALL', ...elementory3]
  elementory3 = elementory3.filter(item => item !== "Discovery Elementary")
  elementory5 = ['ALL', ...elementory5]
  elementory5 = elementory5.filter(item => item !== "Discovery Elementary")
  elementory6 = ['ALL', ...elementory6]
  elementory6 = elementory6.filter(item => item !== "Discovery Elementary")
  // console.log("elemBuilder", elementory1)

  var arrEle = elementoryAll;
  var arrEle1 = elementory;
  var arrEle2 = elementory1;
  var arrEle3 = elementory2;
  var arrEle4 = elementory4;
  var arrEle5 = elementory5;
  var arrEle6 = elementory3;
  var arrEle7 = elementory6;

  let uniqueEle = arrEle.filter((o) => arrEle1.indexOf(o) === -1);
  let uniqueEle1 = arrEle1.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle2 = arrEle.filter((o) => arrEle2.indexOf(o) === -1);
  let uniqueEle3 = arrEle2.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle4 = arrEle.filter((o) => arrEle3.indexOf(o) === -1);
  let uniqueEle5 = arrEle3.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle6 = arrEle.filter((o) => arrEle4.indexOf(o) === -1);
  let uniqueEle7 = arrEle4.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle8 = arrEle.filter((o) => arrEle5.indexOf(o) === -1);
  let uniqueEle9 = arrEle5.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle10 = arrEle.filter((o) => arrEle6.indexOf(o) === -1);
  let uniqueEle11 = arrEle6.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle12 = arrEle.filter((o) => arrEle7.indexOf(o) === -1);
  let uniqueEle13 = arrEle7.filter((o) => arrEle.indexOf(o) === -1);
  let uniqueEle14 = arrEle.filter((o) => SchlBuilder.indexOf(o) === -1);
  let uniqueEle15 = SchlBuilder.filter((o) => arrEle.indexOf(o) === -1);

  const elementoryDis = uniqueEle.concat(uniqueEle1);
  const elementoryDis1 = uniqueEle2.concat(uniqueEle3);
  const elementoryDis2 = uniqueEle4.concat(uniqueEle5);
  const elementoryDis3 = uniqueEle6.concat(uniqueEle7);
  const elementoryDis5 = uniqueEle10.concat(uniqueEle11);
  const elementoryDis6 = uniqueEle12.concat(uniqueEle13);

  let SchoolsAll = getUnique(Property, 'schools');
  let SchoolAll = getUnique(context.sortedCity, 'schools');
  let Schools = getUnique(context.sortedCity && context.sortedbuilder, 'schools');
  let Schools1 = getUnique(context.sortedCity && context.sortedEle2, 'schools');
  let School5 = getUnique(context.sortedscl3, 'schools');
  let School6 = getUnique(context.sortedbuilder3, 'schools');
  let School7 = getUnique(context.sortedbuilder2, 'schools');
  let School4 = getUnique(context.sortedEle2, 'schools');
  // let School8 = getUnique(context.sortedEle2, 'schools');

  var arr11 = elementoryAll;
  var arr12 = Scl;
  var arr8 = SchoolAll;
  var arr1 = SchoolsAll;
  var arr2 = Schools;
  var arr3 = School5;
  var arr4 = School4;
  var arr5 = School6;
  var arr6 = School7;
  var arr7 = Schools1;
  let unique11 = arr11.filter((o) => arr12.indexOf(o) === -1);
  let unique12 = arr12.filter((o) => arr11.indexOf(o) === -1);
  let unique1 = arr1.filter((o) => arr2.indexOf(o) === -1);
  let unique2 = arr2.filter((o) => arr1.indexOf(o) === -1);
  let unique3 = arr1.filter((o) => arr3.indexOf(o) === -1);
  let unique4 = arr3.filter((o) => arr1.indexOf(o) === -1);
  let uniquebuild = arr1.filter((o) => arr5.indexOf(o) === -1);
  let uniquebuild1 = arr5.filter((o) => arr1.indexOf(o) === -1);
  let buildSchoolAll = arr1.filter((o) => arr6.indexOf(o) === -1);
  let buildSchoolAll2 = arr6.filter((o) => arr1.indexOf(o) === -1);
  let uniqueScl3all = arr1.filter((o) => arr4.indexOf(o) === -1);
  let uniqueScl3 = arr4.filter((o) => arr1.indexOf(o) === -1);
  let buildElem = arr1.filter((o) => arr7.indexOf(o) === -1);
  let buildElem1 = arr7.filter((o) => arr1.indexOf(o) === -1);
  let uniqueSchoolAll = arr1.filter((o) => arr8.indexOf(o) === -1);
  let uniqueSchoolAll1 = arr8.filter((o) => arr1.indexOf(o) === -1);

  const grayScl3 = uniqueScl3all.concat(uniqueScl3);
  const uniqueElem2 = buildElem.concat(buildElem1);
  const uniqueElem = unique3.concat(unique4);
  const uniquebuilder = uniquebuild.concat(uniquebuild1);
  const SclDis = unique11.concat(unique12);
  const Schooldisc3 = buildSchoolAll.concat(buildSchoolAll2)
  const Schooldisc2 = uniqueSchoolAll.concat(uniqueSchoolAll1);
  // let uniqueSchoolsall = arr1.filter((o) => arr2.indexOf(o) === -1);
  // let uniqueSchool = arr2.filter((o) => arr1.indexOf(o) === -1);

  // const grayschool = uniqueSchoolsall.concat(uniqueSchool);


  School4 = ['ALL', ...School4]
  School5 = ['ALL', ...School5]
  School6 = ['ALL', ...School6]
  School7 = ['ALL', ...School7]
  Schools = ['ALL', ...Schools]
  SchoolsAll = ['ALL', ...SchoolsAll]
  SchoolAll = ['ALL', ...SchoolAll]

  Schools = Schools.filter(item => item)
  SchoolsAll = SchoolsAll.filter(item => item)
  SchoolAll = SchoolAll.filter(item => item)

  // console.log("schoolsall", SchoolsAll.sort())
  // Map to JSX
  // Schools = Schools.map((item, index)=> {
  //     return <MenuItem value={item} key={index}>{item}</MenuItem>
  // })


  // console.log("unique", unique.sort())
  // builder filter
  let Builders = getUnique(context.sortedCity, 'builders');
  let BuildersAll = getUnique(Property, 'builders');
  let Build = getUnique(context.sortedscl, 'builders');
  let Build2 = getUnique(context.sortedscl2, 'builders');
  let Build3 = getUnique(context.sortedEle2, 'builders');
  let Build4 = getUnique(context.sortedEle, 'builders');

  BuildersAll = ['ALL', ...BuildersAll]
  BuildersAll = BuildersAll.filter(item => item !== "Lennar")
  Builders = ['ALL', ...Builders]
  Builders = Builders.filter(item => item !== "Lennar")
  Build = ['ALL', ...Build]
  Build = Build.filter(item => item !== "Lennar")
  Build2 = ['ALL', ...Build2]
  Build2 = Build2.filter(item => item !== "Lennar")
  Build3 = ['ALL', ...Build3]
  Build3 = Build3.filter(item => item !== "Lennar")
  Build4 = ['ALL', ...Build4]
  Build4 = Build4.filter(item => item !== "Lennar")

  var arr20 = BuildersAll;
  var arr21 = Build;
  var arr22 = BuildersAll;
  var arr23 = Build3;
  var arr1 = BuildersAll;
  var arr2 = Builders;
  var arr3 = Build2;
  var arr4 = Build4;





  let uniqueBuild3all = arr22.filter((o) => arr23.indexOf(o) === -1);
  let uniqueBuild3 = arr23.filter((o) => arr22.indexOf(o) === -1);
  let uniqueBuildall = arr20.filter((o) => arr21.indexOf(o) === -1);
  let uniqueBuild = arr21.filter((o) => arr20.indexOf(o) === -1);
  let uniqueBuilderall = arr1.filter((o) => arr2.indexOf(o) === -1);
  let uniqueBuilder = arr2.filter((o) => arr1.indexOf(o) === -1);
  let uniqueSchoolBuilder = arr1.filter((o) => arr3.indexOf(o) === -1);
  let uniqueSchoolBuilder2 = arr3.filter((o) => arr1.indexOf(o) === -1);
  let uniqueSchoolBuilder3 = arr1.filter((o) => arr4.indexOf(o) === -1);
  let uniqueSchoolBuilder4 = arr4.filter((o) => arr1.indexOf(o) === -1);

  const grayBuild3 = uniqueBuild3all.concat(uniqueBuild3);
  const grayBuild = uniqueBuildall.concat(uniqueBuild);
  const grayBuilder = uniqueBuilderall.concat(uniqueBuilder);
  const graySchoolBuilder = uniqueSchoolBuilder.concat(uniqueSchoolBuilder2);
  const grayElemBuilder = uniqueSchoolBuilder3.concat(uniqueSchoolBuilder4);

  const menuItems = elementoryAll.map((item, index) => {
    return <MenuItem value={item} key={index}> {item}</MenuItem>
  });

  //   console.log("bui",Build)
  //   console.log("buil2",Build2)
  //   console.log("buil3",Build3)
  //   console.log("buil4",Builders)
  //  console.log("true",context.level1)


  return (<div>
    <Paper className={classes.bannerPaper}>

      <Grid align="center" style={{ marginBottom: '3em' }}>
        <Typography variant="h3" style={{ color: 'white', fontWeight: '500' }} gutterBottom> New Communities In Washington State</Typography>
      </Grid>
      <Paper component="form" className={classes.searchroot}>

        <StyledSelect
          disableUnderline
          color=""
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          name="city"
          value={context.city === "ALL" ? "none" : context.city}
          onChange={handleChange}
          onClick={handleChange2}
          defaultValue="none"
          inputProps={{ 'aria-label': 'Without label' }}
          variant="outlined"
        >
          <MenuItem disabled value="none" className={classes.search_input}  >
            Search by city
          </MenuItem>
          <MenuItem value="ALL" className={classes.search_input}  >
            ALL
          </MenuItem>
          {citys.map((item, index) => (
            <MenuItem key={index} value={item} className={classes.search_input}   >
              {item + ", " + 'WA'}
            </MenuItem>
          ))}
        </StyledSelect>
        {/* <FormHelperText>Without label</FormHelperText> */}
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <div className="contain">
        <div className="BAnner_text">
          <img className="Banner_icon_desk" src={logoS} alt="HomesWaState" />
        </div>
        <MuiThemeProvider theme={theme}>
          <Paper className={classes.papersecard}>
            <Grid container spacing={0}  >
              <Grid item className={classes.gridhover} align="center">
                <FormControl className={classes.formControl} >
                  <StyledInputLabel id="demo-simple-select-outlined-label">School District</StyledInputLabel>
                  <StyledSelect2
                    disableUnderline
                    labelId="demo-mutiple-name-label"
                    id="school"
                    name="school"
                    value={context.clickDis ? context.school : school}
                    // onClick={handleChange3}
                    onClick={handleChange3}
                    onChange={handleChange}

                  >
                    <MenuItem disabled defaultValue value="" className={classes.search_input} >
                      Schools
                    </MenuItem>
                    {(function () {
                      if (clickCity == true) {
                        if (context.sortedbuilders == true && context.sortedlevel1 == true) {
                          return [
                            School4.sort()
                              .map((item, index) => (
                                <MenuItem key={index} value={item} className={classes.search_input} defaultValue>
                                  {item}
                                </MenuItem>
                              )),
                            grayScl3.sort().map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled>
                                {item}
                              </MenuItem>
                            ))
                          ];
                        }
                        else if (context.sortedlevel1 == true && context.sortedbuilders == true) {
                          return [
                            School7.sort()
                              .map((item, index) => (
                                <MenuItem key={index} value={item} className={classes.search_input} defaultValue>
                                  {item}
                                </MenuItem>
                              )),
                            Schooldisc3.sort().map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled>
                                {item}
                              </MenuItem>
                            ))
                          ];
                        }
                        else if (context.schoolbuilders === true) {
                          return [School7.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}>
                              {item}
                            </MenuItem>
                          )),
                          Schooldisc3.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled>
                              {item}
                            </MenuItem>
                          ))];

                        }
                        else if (context.sortedlevel1 === true) {
                          return [School4.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}>
                              {item}
                            </MenuItem>
                          )),
                          uniqueElem2.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled>
                              {item}
                            </MenuItem>
                          ))];

                        }
                        else {
                          return [SchoolAll.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}>
                              {item}
                            </MenuItem>
                          )),
                          Schooldisc2.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled>
                              {item}
                            </MenuItem>
                          ))];
                        }
                      }

                      // clickdisk false
                      else if (context.sortedbuilders === true && context.sortedlevel1 === true) {
                        return [
                          School5.sort()
                            .map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} defaultValue>
                                {item}
                              </MenuItem>
                            )),
                          uniqueElem.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled>
                              {item}
                            </MenuItem>
                          ))
                        ];
                      }
                      else if (context.sortedlevel1 === true && context.sortedbuilders === true) {
                        return [
                          School6.sort()
                            .map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} defaultValue>
                                {item}
                              </MenuItem>
                            )),
                          uniquebuilder.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled>
                              {item}
                            </MenuItem>
                          ))
                        ];
                      }
                      else if (context.sortedbuilders === true) {
                        return [School6.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}>
                            {item}
                          </MenuItem>
                        )),
                        uniquebuilder.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input} disabled>
                            {item}
                          </MenuItem>
                        ))];
                      }
                      else if (context.schoollevel1 === true) {
                        return [School5.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}>
                            {item}
                          </MenuItem>
                        )),
                        uniqueElem.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input} disabled>
                            {item}
                          </MenuItem>
                        ))];
                      }
                      else {
                        return SchoolsAll.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}>
                            {item}
                          </MenuItem>
                        ));
                      }
                    }
                    )()}

                  </StyledSelect2>
                </FormControl>
              </Grid>
              <Grid >
              </Grid>
              <div className="dividerpc"> </div>
              <Grid item className={classes.gridhover} align="center">
                <FormControl className={classes.formControl}>
                  <StyledInputLabel id="demo-simple-select-outlined-label">Elementary School</StyledInputLabel>
                  <StyledSelect2
                    disableUnderline
                    color={'secondary'}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="level1"
                    value={context.clickDis ? context.level1 : level1}
                    // value={builder}
                    onChange={handleChange}
                    onClick={handleChange3}
                    label="Elementary school"
                  >
                    <MenuItem disabled value="" className={classes.search_input} >
                      Elementary Schools
                    </MenuItem>
                    {(() => {
                      if (clickCity == true) {
                        if (context.schooldistrict === true && context.sortedbuilders === true) {
                          return [elementory1.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          elementoryDis1.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]

                        }
                        else if (context.sortedbuilders === true && context.schooldistrict === true) {
                          return [elementory3.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          elementoryDis5.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                        else if (context.schooldistrict === true) {
                          return [elementory3.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          elementoryDis3.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                        else if (context.sortedbuilders === true) {
                          return [elementory1.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          elementoryDis2.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                        else {
                          return [elementory.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          elementoryDis.sort().map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                      }
                      // clickDis false
                      else if (context.schooldistrict === true && context.sortedbuilders === true) {
                        return [elementory6.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}  >
                            {item}
                          </MenuItem>
                        )),
                        elementoryDis6.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                            {item}
                          </MenuItem>
                        ))]
                      }
                      else if (context.sortedbuilders === true && context.schooldistrict === true) {
                        return [Scl.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}  >
                            {item}
                          </MenuItem>
                        )),
                        SclDis.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                            {item}
                          </MenuItem>
                        ))]
                      }
                      else if (context.schooldistrict === true) {
                        return [Scl.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}  >
                            {item}
                          </MenuItem>
                        )),
                        SclDis.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                            {item}
                          </MenuItem>
                        ))]
                      }
                      else if (context.sortedbuilders === true) {
                        return [elementory6.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}  >
                            {item}
                          </MenuItem>
                        )),
                        elementoryDis6.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                            {item}
                          </MenuItem>
                        ))]
                      }
                      else {
                        return elementoryAll.sort().map((item, index) => (
                          <MenuItem key={index} value={item} className={classes.search_input}  >
                            {item}
                          </MenuItem>
                        ))
                      }
                    }
                    )()}
                  </StyledSelect2>
                </FormControl>
              </Grid>
              <Grid >
              </Grid>
              <div className="dividerpc"> </div>
              <Grid item className={classes.gridhover} align="center">
                <div className="search_center">
                  <FormControl className={classes.formControl}>
                    <StyledInputLabel id="demo-simple-select-outlined-label" >Builder</StyledInputLabel>
                    <StyledSelect2
                      disableUnderline
                      color={'secondary'}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="builder"
                      value={context.clickDis ? context.builder : builder}
                      // value={builder}
                      onChange={handleChange}
                      onClick={handleChange3}
                      label="builder"
                    >
                      <MenuItem disabled value="" className={classes.search_input} >
                        Builders
                      </MenuItem>
                      {(() => {
                        if (clickCity === true) {
                          if (context.schooldistrict === true && context.sortedlevel1 === true) {
                            return [Build3.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input}  >
                                {item}
                              </MenuItem>
                            )),
                            grayBuild3.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                                {item}
                              </MenuItem>
                            ))]

                          }
                          if (context.sortedlevel1 === true && context.schooldistrict === true) {
                            return [Build2.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input}  >
                                {item}
                              </MenuItem>
                            )),
                            graySchoolBuilder.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                                {item}
                              </MenuItem>
                            ))]

                          }
                          else if (context.schooldistrict === true) {
                            return [Build2.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input}  >
                                {item}
                              </MenuItem>
                            )),
                            graySchoolBuilder.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                                {item}
                              </MenuItem>
                            ))]

                          }
                          else if (context.sortedlevel1 === true) {
                            return [Build3.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input}  >
                                {item}
                              </MenuItem>
                            )),
                            grayBuilder.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                                {item}
                              </MenuItem>
                            ))]

                          }
                          else {
                            return [Builders.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input}  >
                                {item}
                              </MenuItem>
                            )),
                            grayBuilder.map((item, index) => (
                              <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                                {item}
                              </MenuItem>
                            ))]
                          }
                        }
                        // ClickCity == false
                        else if (context.schooldistrict === true && context.sortedlevel1 === true) {
                          return [Build4.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          grayElemBuilder.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]

                        }
                        else if (context.sortedlevel1 === true && context.schooldistrict === true) {
                          return [Build.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          grayBuild.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                        else if (context.schooldistrict === true) {
                          return [Build.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          grayBuild.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                        else if (context.sortedlevel1 === true) {
                          return [Build4.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          )),
                          grayElemBuilder.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input} disabled  >
                              {item}
                            </MenuItem>
                          ))]
                        }
                        else {
                          return BuildersAll.map((item, index) => (
                            <MenuItem key={index} value={item} className={classes.search_input}  >
                              {item}
                            </MenuItem>
                          ))
                        }
                      }
                      )()}
                    </StyledSelect2>
                  </FormControl>
                </div>
              </Grid>
              <div className="dividerpc"> </div>
              <Grid >
              </Grid>
              <Grid item className={classes.gridhover} align="center">
                <FormControl className={classes.formControl}>
                  <StyledInputLabel id="demo-controlled-open-select-label" >Min Price</StyledInputLabel>
                  <StyledSelect2
                    disableUnderline
                    color={'secondary'}
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="minPrice"
                    value={minPrice}
                    onChange={handleChange}
                  >
                    <MenuItem value={100000} className={classes.search_input}   >$100,000</MenuItem>
                    <MenuItem value={200000} className={classes.search_input}   >$200,000</MenuItem>
                    <MenuItem value={300000} className={classes.search_input}   >$300,000</MenuItem>
                    <MenuItem value={400000} className={classes.search_input}   >$400,000</MenuItem>
                    <MenuItem value={500000} className={classes.search_input}   >$500,000</MenuItem>
                    <MenuItem value={600000} className={classes.search_input}   >$600,000</MenuItem>
                    <MenuItem value={700000} className={classes.search_input}   >$700,000</MenuItem>
                    <MenuItem value={800000} className={classes.search_input}   >$800,000</MenuItem>
                    <MenuItem value={900000} className={classes.search_input}   >$900,000</MenuItem>
                    <MenuItem value={1000000} className={classes.search_input}   >$1,000,000</MenuItem>
                    <MenuItem value={1500000} className={classes.search_input}   >$1,500,000</MenuItem>
                    <MenuItem value={2000000} className={classes.search_input}   >$2,000,000</MenuItem>

                  </StyledSelect2>
                </FormControl>
              </Grid>
              <div className="dividerpc"></div>
              <Grid item className={classes.gridhover} align="center">
                <FormControl className={classes.formControl}>
                  <StyledInputLabel id="demo-controlled-open-select-label">Max Price</StyledInputLabel>
                  <StyledSelect2
                    disableUnderline
                    color={'secondary'}
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="maxPrice"
                    value={maxPrice}
                    onChange={handleChange}
                  >
                    <MenuItem value={100000} className={classes.search_input}   >$100,000</MenuItem>
                    <MenuItem value={200000} className={classes.search_input}   >$200,000</MenuItem>
                    <MenuItem value={300000} className={classes.search_input}   >$300,000</MenuItem>
                    <MenuItem value={400000} className={classes.search_input}   >$400,000</MenuItem>
                    <MenuItem value={500000} className={classes.search_input}   >$500,000</MenuItem>
                    <MenuItem value={600000} className={classes.search_input}   >$600,000</MenuItem>
                    <MenuItem value={700000} className={classes.search_input}   >$700,000</MenuItem>
                    <MenuItem value={800000} className={classes.search_input}   >$800,000</MenuItem>
                    <MenuItem value={900000} className={classes.search_input}   >$900,000</MenuItem>
                    <MenuItem value={1000000} className={classes.search_input}   >$1,000,000</MenuItem>
                    <MenuItem value={1500000} className={classes.search_input}   >$1,500,000</MenuItem>
                    <MenuItem value={2000000} className={classes.search_input}   >${price2}</MenuItem>
                    <MenuItem value={2500000} className={classes.search_input}   >$2,500,000</MenuItem>
                    <MenuItem value={3000000} className={classes.search_input}   >${price3}</MenuItem>
                    <MenuItem value={3500000} className={classes.search_input}   >$3,500,000</MenuItem>
                    <MenuItem value={4000000} className={classes.search_input}   >${price4}</MenuItem>
                    <MenuItem value={4500000} className={classes.search_input}   >$4,500,000</MenuItem>
                    <MenuItem value={5000000} className={classes.search_input}   >${price5}</MenuItem>
                  </StyledSelect2>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </MuiThemeProvider>
      </div>
    </Paper>
  </div>
  )
}












































