import React from 'react'
import { Container, styled, Grid, Box, CardContent, Typography, Stack } from '@mui/material'
import { LocationOnRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import defaultmage from '../../images/Image_not_available.png';

const CustomContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        // // minWidth:140
        paddingLeft: "24px",
        paddingRight: "24px"
    },
    [theme.breakpoints.down("md")]: {
        // minWidth:140
        padding: 0
    },
    padding: '1em 0em',
}))
const CustomDiv = styled('div')(({ theme }) => ({
    padding: 8,
}))

const CustomTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontSize: '14px',
    fontWeight: 500
}))


const CustomCard11 = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: 120,
    background: '#64b3f4',
    borderRadius: 15,
    cursor: "pointer"
}))


const PopularCommunities = (props) => {
    const history = useNavigate();
    const { Popular } = props;
    let Popularr = Popular

    const allProperty = [
        ...Popularr.filter(house => house.city === house.city)
    ]

    const Sort1 = allProperty // Filter out empty values
        .sort((a, b) => {
            if (a.Tag1 === '' && b.Tag1 !== '') {
                return 1; // Push empty values to the end
            } else if (a.Tag1 !== '' && b.Tag1 === '') {
                return -1; // Keep non-empty values before empty values
            }
            else {
                const aNum = parseInt(a.Tag1.split('#')[1]);
                const bNum = parseInt(b.Tag1.split('#')[1]);
                return aNum - bNum; // Sort based on the number values
            }
        });
    // console.log("top10", Sort1.map(obj => obj.Tag1))

    const Popularr2 = [
        ...Sort1,
        ...Popularr.filter(house => house.city === !allProperty),
    ]
    

    // const evenValues = Popularr2.filter((obj, index) => index % 2 === 0);
    // const oddValues = Popularr2.filter((obj, index) => index % 2 !== 0);

    // console.log("evenValues", evenValues);
    // console.log("oddValues", oddValues);

    return (
        <CustomContainer maxWidth="xl">
            <Grid container justify="center"
                alignItems="stretch">
                <Grid >
                    <Grid container>

                        {
                            Popularr2.map((item, index) => {
                                return (
                                    <Grid item xs={12} sm={3} key={index} >
                                        <CustomDiv>
                                            <CustomCard11 elevation={5} onClick={() => history(`${item.slug}`)} style={{backgroundColor: index % 2 === 0 ? '#64b3f4' : '#FF7F50'}}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                    }}>

                                                    </Stack>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>

                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard11>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {/* {
                            oddValues.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard1 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom color="fff">
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard1>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        } */}
                        {/* {
                            Popular3.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard11 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom color="fff">
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard11>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular4.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard1 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom color="fff">
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard1>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular5.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard1 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard1>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular6.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard11 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard11>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular7.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard1 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard1>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular8.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard11 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard11>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular9.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard11 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard11>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular10.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard1 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard1>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular11.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard11 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard11>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        }
                        {
                            Popular12.map((item) => {
                                return (
                                    <Grid item xs={12} sm={3}  >
                                        <CustomDiv>
                                            <CustomCard1 elevation={5} onClick={() => history(`${item.slug}`)}>
                                                <Box component="span" sx={{ p: 2 }}>
                                                    <img src={item.featuredUrl || item.url || defaultmage} alt="single image" width="100px" height="90px" />
                                                </Box>
                                                <CardContent>
                                                    <CustomTypography variant="h7" gutterBottom >
                                                        {item.propertyname}
                                                    </CustomTypography>
                                                    <Stack direction="row" spacing={0} sx={{
                                                        pt: '5px',
                                                        pb: '1em'
                                                    }}>
                                                        <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                lineHeight: '1.575',
                                                                textDecoration: 'none',
                                                            }}
                                                            color='text.secondary'
                                                        >
                                                            {item.city}
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CustomCard1>
                                        </CustomDiv>
                                    </Grid>
                                )
                            })
                        } */}
                    </Grid>
                </Grid>
            </Grid>
        </CustomContainer>
    )
}

export default PopularCommunities
//how to add items to array element in reactjs?