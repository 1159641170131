import React, { useState, useEffect } from 'react';
import defaultmage from '../images/Image_not_available.png';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import './House.css';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import RoomIcon from '@material-ui/icons/Room';
import firebase from '../firebase/firebase';
import NumberFormat from 'react-number-format';
import { CardActionArea } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import { styled } from '@mui/material/styles';
import Moment from 'moment';
import { IconButton, Stack, } from '@mui/material';
import { FavoriteRounded } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    "&:hover": {
      backgroundColor: ' #4240400d',

    }
  },
  typographyh7: {
    fontWeight: 400,
  },
  typographyp: {
    fontSize: '15px',
    fontWeight: 500,
    marginLeft: '10px',
    marginBottom: '0px',
    color: 'rgb(42, 41, 41)',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: "10px"
  },
  price: {
    borderBlockEndColor: '#00416A',
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: '2px',
    borderBottom: '1px solid #00416A',
    font: '11.2px / 13.44px Muli, Arial, Helvetica, sans-serif',
    margin: '0px',
    padding: '0px 0px 6px',
    display: 'flex'
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  color: 'darkgreen',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 15,
  borderRadius: '4px',
  borderColor: 'darkgreen',

}));

const Item2 = styled(Paper)(({ theme }) => ({
  color: 'brown',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 15,
  marginTop: '1em',
  borderRadius: '4px',
  borderColor: 'brown',

}));

const Item3 = styled(Paper)(({ theme }) => ({
  color: 'red',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 15,
  borderRadius: '4px',
  borderColor: 'red',
}));

export default function House({ home }) {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [propertyData, setPropertyData] = useState([]);
  var fireDB = firebase.database().ref();
  const { propertyname,
    slug,
    status,
    url,
    address,
    startprice,
    endprice,
    city,
    builders,
    featuredUrl,
    Tag,
    ActiveStatus,
    MlsId,
    OfferDate,
    ListingPrice,
    ListedDate,
    SoldDate,
    ClosedDate,
    MutualDate,
    SellerName,
    wishlist
  } = home;



  const [open, setOpen] = React.useState(false);
  const match = window.location.pathname.split('/')[1] === 'Our-Listings' ? true : false;
  const mutualDate = Moment(MutualDate).format('DD-MM-YYYY');
  const closedDate = Moment(ClosedDate).format('DD-MM-YYYY');
  // console.log("date", mutualDate);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Listen for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    fireDB.child('properties').on('value', snapshot => {
      if (snapshot.val() != null)
        setPropertyData({
          ...snapshot.val()
        })
      else
        setPropertyData({})
    })
  }, [])

  // console.log('uid', user && user.uid)

  const allowedEmail = 'dguptahomes.web@gmail.com';
  const email = user && user.email;
  const wishlistData = wishlist !== null && typeof wishlist === 'object'
    ? Object.keys(wishlist).map((id) => wishlist[id])
    : null;

  const Favourite = React.useMemo(() => {
    // Check if wishlistData is not null and has items
    if (wishlistData !== null && wishlistData.length > 0) {
      // Check if the current user's email is in the wishlistData
      const isWishlisted = wishlistData.some((item) => item.email === email);

      if (isWishlisted) {
        return (
          <IconButton sx={{
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'default', // Change this to your desired color
            },
          }}>
            <FavoriteRounded color="error" sx={{ fontSize: '1em' }} />
          </IconButton>
        );
      } else {
        return null
      }
    } else {
      // If wishlistData is null or empty, assume not wishlisted
      return null
    }
  }, [wishlistData, email,]);

  return (
    <div className="card_space" >
      <Card spacing={4}>
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={0} square="true">
            <Grid container spacing={2}>
              <Grid item  >
                <CardActionArea elevation={0} >
                  {
                    (match === true ?
                      <Link underline='none' component={RouterLink} to={`/Our-Listings/${MlsId}`}>
                        <div className='searchResultImage'>
                          <img src={featuredUrl || url || defaultmage} alt={MlsId}
                            title={address} />
                          {ActiveStatus == 'Active' && <div className="top-left_active">Active</div>}
                          {ActiveStatus == 'Just Sold' && <div className="top-left_c_Sold2">Just Sold</div>}
                          {ActiveStatus == 'Pending' && <div className="top-left_pending">Pending</div>}
                          {ActiveStatus == 'AdminOnly' && <div className="top-left_AdminOnly">Admin Only</div>}
                          {Tag == 'Recommended' && <div className="top-Right_Reclist">Recommended</div>}
                          {/* {ActiveStatus == 'Sold' &&
                            <div>
                              <div className="top-left_sold3"><img src={soldimage} width="60%" alt="single image" /></div>
                              <div className="top-left_soldstamp">Sold by Deepti </div>
                            </div>} */}
                          {ActiveStatus == 'Sold'
                            &&
                            <div >
                              <div className="top-Right_lsprice">
                                Listed Price - <NumberFormat value={ListingPrice} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                              </div>
                              <div className="top-Right_soldprice">
                                Sold Price - <NumberFormat value={startprice} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                              </div>
                            </div>
                          }
                         
                        </div>
                      </Link>
                      :
                      <Link underline='none' component={RouterLink} to={`/New-constructions/${slug}`} >
                        <div className='searchResultImage'>
                          <img src={featuredUrl || url || defaultmage} alt={address}
                            title={propertyname} />
                          {status == 'Sold Out' && <div className="top-left_sold2">Sold Out</div>}
                          {status == 'Currently Available' && <div className="top-left_avai2">Currently Available</div>}
                          {status == 'Coming Soon' && <div className="top-left_coming2">Coming Soon</div>}
                          {status == 'Currently Sold Out' && <div className="top-left_c_Sold2">Currently Sold Out</div>}
                          {Tag == 'Recommended' && <div className="top-Right_Rec">Recommended</div>}
                        </div>
                      </Link>
                    )}
                </CardActionArea>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" >
                  <Grid item>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <div className='searchResult__info2'>
                            <div className="searchResult__infoTop2">
                              {
                                (match === true ?
                                  <div className="searchResult__infoTop2">
                                    <h5>{address}</h5>
                                    <h8>MLS ID : {MlsId}</h8>
                                    {/* <h7>OfferDate: {OfferDate}</h7> */}
                                  </div>
                                  :
                                  <div className="searchResult__infoTop2">
                                    <h5>{propertyname}</h5>
                                    <h7>Community By {builders}</h7>
                                    <h8>{address}</h8>
                                  </div>)
                              }
                              <div className="searchResult__stars">
                                <RoomIcon className="searchResult__star" />
                                <p>
                                  {city}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <div className="searchResult__" >
                            {(() => {
                              if (status == 'Sold Out') {
                                return (
                                  <p id="price_1">
                                    <strong id="price_from">
                                      SOLD OUT
                                    </strong>
                                  </p>)
                              }
                              if (status == 'Currently Sold Out') {
                                return (
                                  <p id="price_1">
                                    <strong id="price_from">
                                      CURRENTLY SOLD OUT
                                    </strong>
                                  </p>)
                              }
                              if (ActiveStatus == 'Sold') {
                                return (
                                  <div>
                                    <p id="price_1">
                                      <div className="starting">
                                        Sold Price 
                                      </div>
                                      <strong id="price_from">
                                        <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </strong>
                                    </p>
                                  </div>
                                )
                              }
                              if (ActiveStatus == 'Just Sold') {
                                return (
                                  <p id="price_1">
                                    <strong id="price_from">
                                      JUST SOLD
                                    </strong>
                                  </p>)
                              }
                              if (ActiveStatus == 'Pending') {
                                return (
                                  <div>
                                    <p id="price_1">
                                      <div className="starting">
                                        Listed Price 
                                      </div>
                                      <strong id="price_from">
                                        <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </strong>
                                    </p>
                                  </div>
                                )
                              }
                              if (ActiveStatus == 'Active') {
                                return (
                                  <div>
                                    <p id="price_1">
                                      <div className="starting">
                                        Listed Price 
                                      </div>
                                      <strong id="price_from">
                                        <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </strong>
                                    </p>
                                  </div>
                                )
                              }
                              if (ActiveStatus == 'AdminOnly') {
                                return (
                                  <div>
                                    <p id="price_1">
                                      <div className="starting">
                                        Listed Price 
                                      </div>
                                      <strong id="price_from">
                                        <NumberFormat value={ListingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </strong>
                                    </p>
                                  </div>
                                )
                              }
                              if (!startprice) {
                                return (
                                  <p id="price_1">
                                    {(match === true ?
                                      <div></div>
                                      :
                                      <strong id="price_from">
                                        Not Available
                                      </strong>
                                    )}
                                  </p>)
                              }
                              else if (!endprice) {
                                return (
                                  <div>
                                    <p id="price_1">
                                      <div className="starting">
                                        Starting
                                      </div>
                                      <strong id="price_from">
                                        <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </strong>
                                    </p>
                                  </div>)
                              }
                              else {
                                return (<div>
                                  <p id="price_1">
                                    {/* from  */}
                                    <strong id="price_from">
                                      <NumberFormat value={startprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      {' - '}
                                      <NumberFormat value={endprice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </strong>
                                  </p>
                                </div>)
                              }
                            })()}
                            <p id="price_divider"></p>
                          </div>
                        </Typography>
                        {match === true ?
                          null
                          :
                          <Stack display="flex" justifyContent="end" alignItems="end"
                            sx={{ position: 'relative', height: '25px', }}>
                            {Favourite}
                          </Stack>
                        }
                        {
                          (ActiveStatus == 'Sold') ?
                            <div style={{ marginTop: '10px' }}>
                              <p id="priceDate">
                                <div className="starting">
                                  Sold Date 
                                </div>
                                <strong id="price_from">
                                  {SoldDate}
                                </strong>
                              </p>
                            </div>
                            :
                            <div>
                              {(
                                match === true ?
                                  <div style={{ marginTop: '10px' }}>
                                    <p id="price">
                                      <div className="starting">
                                        Listed Date 
                                      </div>
                                      <strong id="price_from">
                                        {ListedDate}
                                      </strong>
                                    </p>
                                  </div>
                                  :
                                  null
                              )}
                            </div>
                        }
                        {
                          user && user.email === allowedEmail && (match === true) ?
                            <Stack
                              direction="column"
                              spacing={2}
                              style={{ marginTop: '5px' }}
                            >
                              {
                                (ActiveStatus === "Active" || ActiveStatus === "AdminOnly") ?
                                  null
                                  :
                                  <Item2 variant="outlined" square={true}>Mutual Date : {mutualDate}</Item2>

                              }

                            </Stack>
                            :
                            null
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    user && user.email === allowedEmail && (match === true) ?
                      // <Stack
                      //   direction="row"
                      //   spacing={2}
                      //   style={{ marginTop: '10px', marginBottom: '1em' }}
                      // >
                      //   <Item variant="outlined" square={true}>
                      //     Seller Name : {SellerName}
                      //   </Item>
                      //   {
                      //     (ActiveStatus === "Sold") ?
                      //       <Item3 style={{marginLeft : '3em'}} variant="outlined" square={true}>Closed Date : {closedDate}</Item3>
                      //       :
                      //       null
                      //   }
                      // </Stack>
                      <Grid container spacing={2} style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <Grid item xs={7}>
                          <Item variant="outlined" square={true}>
                            Seller Name : {SellerName}
                          </Item>
                        </Grid>
                        <Grid item xs={5}>
                          {
                            (ActiveStatus === "Sold") ?
                              <Item3 variant="outlined" square={true}>Closed Date : {closedDate}</Item3>
                              :
                              null
                          }
                        </Grid>
                      </Grid>
                      :
                      null
                  }
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                      <Grid item xs>
                        <div className="searchResult__infoBottom">
                          {(match === true) ?
                            <div className="searchResult__infoBottomCall">
                              <h7>Call/Text us to know more</h7>
                              <p>(425) 588-8470</p>
                            </div>
                            :
                            // <div style={{ display: 'flex', flexDirection: 'row' }} >
                            //   <Typography variant="h7" className={classes.typographyh7}>Call/Text us to know more </Typography>
                            //   <Typography className={classes.typographyp}>
                            //     (425) 588-8470
                            //   </Typography>
                            // </div>
                            <div className="searchResult__infoBottomCall">
                              <h7>Call/Text us to know more</h7>
                              <p>(425) 588-8470</p>
                            </div>
                          }
                        </div>
                      </Grid>
                      {(match === true) ? <Grid item xs>
                        <Typography
                          style={{
                            fontSize: '.625rem',
                            color: '#999',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          Listing provided by nwmls
                        </Typography>
                      </Grid>
                        :
                        null}
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        <div className="button_know_more">
                          {
                            (match === true ?
                              <Link underline='none' component={RouterLink} to={`/Our-Listings/${MlsId}`} >
                                <Button variant="outlined" color="primary"
                                  endIcon={<ChevronRightRoundedIcon />}>
                                  Know More
                                </Button>
                              </Link>
                              :
                              <Link underline='none' component={RouterLink} to={`/New-constructions/${slug}`} >
                                <Button variant="outlined" color="primary"
                                  endIcon={<ChevronRightRoundedIcon />}>
                                  Know More
                                </Button>
                              </Link>
                            )}
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Card>
    </div>
  )
}

House.propTypes = {
  home: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    url: PropTypes.arrayOf(PropTypes.string).isRequired,
    startprice: PropTypes.number.isRequired,
    endprice: PropTypes.number.isRequired,
  })
}

















