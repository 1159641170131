import React from 'react'
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const Theme = createMuiTheme({
    palette: {
       primary: {
          main: "#2979ff" // This is an orange looking color
                 },
       secondary: {
          main: "#f50057" //Another orange-ish color
                  }
             },
 fontFamily: 'Roboto' // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
 });
 export default Theme;