import React from 'react'
import { Button, Card, CardContent, CardMedia, Chip, Container, Divider, FormControl, Grid, InputAdornment, InputLabel, Link, OutlinedInput, Paper, Slider, Stack, Step, StepLabel, Stepper, styled, Typography, useMediaQuery } from '@mui/material'
import { Calculate, CalendarMonth, CalendarViewDay, CurrencyExchange, DoubleArrow, KeyboardArrowLeft, KeyboardArrowRight, MonetizationOn, Refresh, Send } from '@mui/icons-material';
import offer from '../../images/cards/offer1.png';
import ReactApexChart from 'react-apexcharts';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <MonetizationOn />,
        2: <CurrencyExchange />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const CustomDiv = styled('div')(({ theme }) => ({
    padding: 8,
}))

const CustomCard = styled(Card)(({ theme, isMatch }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(45deg, #430089, #82ffa1)',
    borderRadius: 15,
    maxWidth: 350,
    cursor: "pointer",
    marginTop: '1em'
}))

const CustomCard1 = styled(Card)(({ theme, isMatch }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
    borderRadius: 15,
    padding: isMatch ? '8px' : '5px',
    maxWidth: 350,
    [theme.breakpoints.up("md")]: {
        height: 80,
    },
    alignItems: "center",
    cursor: "pointer"
}))


const ROI = () => {
    const isMatch = useMediaQuery('(max-width:960px )')
    const [activeStep, setActiveStep] = React.useState(0);
    const [propertyPrice, setPropertyPrice] = React.useState(315000);
    const [paey, setPaey] = React.useState(3.00);
    const [numberofYear, setNumberofYear] = React.useState(5);
    const [downPayment, setDownPayment] = React.useState(78000);
    const [profit, setProfit] = React.useState(0);
    const [roi, setROI] = React.useState(0);
    const [roipy, setROIPY] = React.useState(0);
    const steps = ['Enter Investment Details', 'Evaluate Financial Viability',];


    const maxValue = 2000000;
    const downpayment = 1000000;
    const maxpaey = 10;
    const maxYear = 40;

    const handlePropertyPrice = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setPropertyPrice(newValue);
        }
    };

    const handlePAEY = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {

        }
    };

    const handleNumberofYears = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setNumberofYear(newValue);
        }
    };

    const handleDownPayment = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setDownPayment(newValue);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({
            top: 10,
            behavior: 'smooth'
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({
            top: 10,
            behavior: 'smooth'
        });
    };


    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={isMatch ? 0 : 2} sx={{ mt: '1em', p: isMatch ? '1em' : '2em' }}>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Property Price
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Property Price</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={propertyPrice}
                                    onChange={handlePropertyPrice}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.01,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" value={typeof propertyPrice === 'number' ? propertyPrice : 0} onChange={(event, vAmt) => { setPropertyPrice(vAmt) }} max={maxValue} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Down Payment
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Down Payment</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={downPayment}
                                    onChange={handleDownPayment}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.01,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" value={typeof downPayment === 'number' ? downPayment : 0} onChange={(event, vAmt) => { setDownPayment(vAmt) }} max={downpayment} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Property appreciation every year
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Property appreciation </InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={paey}
                                    onChange={handlePAEY}
                                    startAdornment={<InputAdornment position="start"><CalendarViewDay /></InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.01,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" valueLabelDisplay="auto" value={typeof paey === 'number' ? paey : 0} onChange={(event, vAmt) => { setPaey(vAmt) }} max={maxpaey} step={0.01} />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Typography id="disabled-slider" gutterBottom sx={{ pb: '1em' }}>
                                Number of Years
                            </Typography>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Number of Years</InputLabel>
                                <OutlinedInput
                                    label="Mortgage points"
                                    id="standard-adornment-amount"
                                    size='small'
                                    value={numberofYear}
                                    onChange={handleNumberofYears}
                                    startAdornment={<InputAdornment position="start"><CalendarMonth /></InputAdornment>}
                                    inputProps={{
                                        min: 0,
                                        max: 10,
                                        step: 0.01,
                                        style: { textAlign: 'right' }, // Right-align the text
                                    }}
                                />
                            </FormControl>
                            <PrettoSlider aria-label="pretto slider" valueLabelDisplay="auto" value={typeof numberofYear === 'number' ? numberofYear : 0} onChange={(event, vAmt) => { setNumberofYear(vAmt) }} max={maxYear} />
                        </Grid>
                        {/* Add similar components for interestRate and numberOfPeriods */}
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={isMatch ? 0 : 2} display="flex" justifyContent="center" alignItems="center" sx={{ pt: isMatch ? '2em' : null, m: isMatch ? null : '1em' }}>
                        <Grid item xs={isMatch ? 10 : 12}>
                            <Stack sx={{ backgroundColor: '#f0f0f0', p: '1em', borderRadius: '5px' }}>
                                <Stack
                                    direction={isMatch ? "column" : "row"}
                                    divider={isMatch ? null : <Divider orientation="vertical" flexItem />}
                                    spacing={isMatch ? 1 : 2}
                                    sx={{ justifyContent: isMatch ? 'start' : 'center', alignItems: 'center' }}
                                >
                                    <Chip
                                        color="info"
                                        disabled={false}
                                        size="medium"
                                        variant="elevated"
                                        label={`Property Price: $ ${propertyPrice.toLocaleString('en-US')}`}
                                        sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                    />
                                    <Chip
                                        color="info"
                                        disabled={false}
                                        size="medium"
                                        variant="elevated"
                                        label={`Down Payment: $ ${downPayment.toLocaleString('en-US')}`}
                                        sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                    />
                                </Stack>
                                <Stack
                                    direction={isMatch ? "column" : "row"}
                                    divider={isMatch ? null : <Divider orientation="vertical" flexItem />}
                                    spacing={isMatch ? 1 : 2}
                                    sx={{ mt: '1em', justifyContent: isMatch ? 'start' : 'center', alignItems: 'center' }}
                                >
                                    <Chip
                                        color="info"
                                        disabled={false}
                                        size="medium"
                                        variant="elevated"
                                        label={`Property appreciation : ${paey.toLocaleString('en-US')}`}
                                        sx={{ fontSize: '1em', backgroundColor: '#00ACC1' }}
                                    />
                                    <Chip
                                        color="info"
                                        disabled={false}
                                        size="medium"
                                        variant="elevated"
                                        label={`Number of Years: ${numberofYear.toLocaleString('en-US')}`}
                                        sx={{ fontSize: '1em', backgroundColor: '#607D8B' }}
                                    />
                                </Stack>
                            </Stack>
                            <Divider sx={{ mt: '2em', mb: isMatch ?  '2em' : '1em' }} flexItem />
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6} >
                            <Card sx={{
                                m: isMatch ? null : '1em',
                                p: '8px',
                                backgroundColor: '#0288d1',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom variant="h5" component="h1">
                                    $ {downPayment.toLocaleString('en-US')} Downpayment
                                </Typography>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom>
                                    {numberofYear}  years, {paey} appreciation
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={isMatch ? 10 : 6}>
                            <Card sx={{
                                m: isMatch ? null : '1em',
                                mt: isMatch ? '1em' : null,
                                p: '8px',
                                backgroundColor: '#388e3c',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom variant="h5" component="h5">
                                    $ {profit.toLocaleString('en-US')} Profit
                                </Typography>
                                <Typography sx={{ color: '#fff' }} id="disabled-slider" gutterBottom>
                                    {roi}% returns, {roipy}% returns per year
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                );
            default:
                return 'Unknown step';
        }
    };

    React.useEffect(() => {
        if (activeStep === 1) {
            const calculatedResult = propertyPrice * Math.pow(1 + paey / 100, numberofYear);
            const result = Math.round(calculatedResult);
            const results = parseFloat(result);
            const result1 = results - propertyPrice
            setProfit(Math.round(result1))

            const results1 = parseFloat(result1);
            const result2 = downPayment;
            const ROI = results1 / result2 * 100;
            setROI(Math.round(ROI))

            const calRoipy = Math.round(ROI);
            const calRoipy1 = numberofYear;
            const calRoipy2 = calRoipy / calRoipy1;
            setROIPY(Math.round(calRoipy2));

        }
    }, [activeStep, numberofYear, paey, downPayment, propertyPrice, roi, roipy]);

    return (
        <div>
            <Card elevation={0}>
                <Container maxWidth="xl">
                    <Grid container spacing={1} sx={{ mt: '2em' }}>
                        <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography id="disabled-slider" gutterBottom variant="h4" component="h1">
                                Return on Investment
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: '1em' }} />
                </Container>
                <Grid container spacing={1} sx={{ p: isMatch ? 0 : '1em 6em' }}>
                    <Grid item xs={12} sx={{ mt: isMatch ? '1em' : null }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    <Grid item xs={isMatch ? 12 : 8}>
                        <Paper
                            sx={{
                                p: isMatch ? '0em 0em 2em 0em' : '0em 3em 2em 0em',
                                mb: '1em',
                                m: isMatch ? '1em' : null,
                                // backgroundColor: '#5faaf3', // Change the background color to your preference
                                borderRadius: '8px', // Add border-radius for a rounded look
                            }}
                        >
                            {activeStep === 1 ? (
                                <>
                                    <>
                                        {getStepContent(activeStep)}
                                    </>
                                    <Grid container spacing={2} sx={{ m: isMatch ? '1em 0em 0em 0em' : '1em' }} >
                                        <Grid item xs={isMatch ? 10 : 12} display="flex" justifyContent="center" alignItems="center">
                                            <Button
                                                variant='contained'
                                                color="success"
                                                onClick={() => setActiveStep(0)}
                                                sx={{
                                                    fontSize: '1em',
                                                    fontWeight: 400,
                                                    textTransform: 'capitalize'
                                                }}
                                                endIcon={<Refresh />}
                                            >Reset</Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid container spacing={2} sx={{ m: '1em' }}>
                                    {getStepContent(activeStep)}
                                    <>
                                        <Grid item xs={(activeStep === 0) ? isMatch ? 10 : 11 : 6} display="flex" justifyContent="end" alignItems="center">
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{
                                                    fontSize: '1em',
                                                    fontWeight: 400,
                                                    textTransform: 'capitalize',
                                                    ml: isMatch ? null : '3em'
                                                }}
                                                endIcon={activeStep === 1 ? <Send /> : <KeyboardArrowRight />}
                                            >
                                                Calculate
                                            </Button>
                                        </Grid>
                                    </>
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={isMatch ? 12 : 4}>
                        <Grid item xs={12} direction="row">
                            <CustomDiv>
                                <Link href="/Contact-US" style={{ textDecoration: "NONE" }}>
                                    <CustomCard elevation={5}>
                                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                                            <CardContent sx={{ flex: '1 0 auto', }}>
                                                <Typography component="h5" variant="h5" sx={{ color: "white" }}>
                                                    Want To Know Offer Review Dates?
                                                </Typography>
                                                <Typography variant="body1" sx={{ color: "white" }}>
                                                    Find Out Now <DoubleArrow />
                                                </Typography>
                                            </CardContent>
                                        </div>
                                        <CardMedia
                                            sx={{ width: 151, }}
                                            image={offer}
                                            title="Live from space album cover"
                                        />
                                    </CustomCard>
                                </Link>
                            </CustomDiv>
                        </Grid>
                        <Grid item xs={12} direction="row" sx={{ mb: '1em' }}>
                            <CustomDiv>
                                <Link href="/Last-Solds" style={{ textDecoration: "NONE" }}>
                                    <CustomCard1 elevation={5} isMatch={isMatch} >
                                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                                            <Typography component="h6" variant="h6" sx={{ color: "white" }}>
                                                Know what We have Sold So Far <DoubleArrow />
                                            </Typography>
                                        </div>
                                    </CustomCard1>
                                </Link>
                            </CustomDiv>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default ROI