import React, { useState, useContext } from 'react'
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Container, Divider, FormControl, Grid, InputBase, Link, ListItemIcon, ListItemText, MenuList, Paper, Skeleton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import banner22 from '../images/banner2.jpg';
import { PropertyContext } from '../context';
import Loading from '../components/Loading';
import { ContentCut, InsertEmoticonSharp, KeyboardArrowLeft, KeyboardArrowRight, LocationOnRounded } from '@mui/icons-material';
import Footer from '../components/Footer';
import '../components/House.css';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { IconButton, MenuItem, Select, InputLabel } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import BookConsultation from '../components/Cards/BookConsultation';
import firebase from '../firebase/firebase';
import '../components/Filter.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';
import '../components/Pagination.css';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import FeaturedFilter from './FeaturedFilter';
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { grey } from '@mui/material/colors';
import ListingsSearchComp from './ListingsSearchComp';
const drawerBleeding = 56;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const useStylesmob = makeStyles((theme) =>
({
    root: {
        flexGrow: 1,
        paddingTop: 10,
        marginTop: 150,
    },

    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    iconButton: {
        padding: 10,
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 900,
        borderRadius: '800px'

    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,

    },

    searchroot: {
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        [theme.breakpoints.down("sm")]: {
            width: 300,
            borderRadius: '4px'
        },
        margin: 'auto',
        borderRadius: '30px'
    },
    search_lable: {
        fontWeight: 900,
        fontSize: '15px',
        [theme.breakpoints.down("md")]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '13px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '80px',
            textOverflow: "ellipsis"
        },
    },
    search_input: {
        fontWeight: 500,

    },
    divpadding: {
        paddingBottom: 10,
        margin: 'auto',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: '1em',
        minWidth: 180,
        [theme.breakpoints.down("md")]: {
            minWidth: 140
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: 100
        },
    },
    papersecard: {
        margin: "-8px 0px",
        height: '230px',
        borderRadius: "0px",
        backgroundImage: `url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    innerpaper: {
        width: 'fit-content',
        margin: "auto",
        padding: "0px 10px",
    },
    filterPaper: {
        borderRadius: "10px"
    },
}),
);
const useStylesmob2 = makeStyles((theme) =>
({
    chipMob: {
        display: 'flex',
        // justifyContent: 'center',
        paddingTop: "10px",
        flexWrap: 'unset',
        '& > *': {
            margin: theme.spacing(0.6),
        },
    },
}));

const useStyles = makeStyles((theme) =>
({
    root: {
        flexGrow: 1,
        paddingTop: 10,
        marginTop: 150,
    },

    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    iconButton: {
        padding: 10,
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 900,
        borderRadius: '800px'

    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,

    },

    searchroot: {
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: 'auto',
        borderRadius: '30px'
    },
    search_lable: {
        fontWeight: 900,
        fontSize: '15px',
        position: " unset",
        margin: '0.5em 5em 0em 0em',
        overflow: 'hidden',
        maxWidth: '130px',
        textOverflow: "ellipsis",
        [theme.breakpoints.down("md")]: {
            fontSize: '14px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '110px',
            textOverflow: "ellipsis"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '110px',
            textOverflow: "ellipsis"
        },
    },
    search_input: {
        fontWeight: 500,

    },
    search_input1: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '0.00938em',

    },
    divpadding: {
        paddingBottom: 10,
        margin: 'auto',
    },
    formControl: {
        MuiInput: {
            formControl: {
                "label + &": {
                    marginTop: "1.5rem",

                }
            }
        },
        margin: theme.spacing(1),
        minWidth: 250,
        [theme.breakpoints.down("md")]: {
            minWidth: 140
        },
        '@media (max-width: 999px)': {
            minWidth: '130px'
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: 120
        },

        [theme.breakpoints.down("xs")]: {
            minWidth: '100%'
        },

    },
    searchroot2: {
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        [theme.breakpoints.down("sm")]: {
            width: 300,
            borderRadius: '4px'
        },
        margin: 'auto',
        borderRadius: '30px'
    },
    papersecard1: {
        margin: "-8px 0px",
        height: '230px',
        borderRadius: "0px",
        backgroundImage: `url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    papersecard: {
        margin: "auto",
        width: 'fit-content',
        borderRadius: "50px",
        borderColor: "#ff5707e0",
        [theme.breakpoints.down("xs")]: {
            borderRadius: "4px",
        },

    }, hover: {
        "&:hover": {
            backgroundColor: 'rgb(7, 177, 77, 0.42)'
        }
    },
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        padding: 34,
        paddingTop: 150
    },
    gridhover: {
        width: '150px',
        "&:hover": {
            backgroundColor: ' #827f7f24',
            borderRadius: "30px"
        }
    }
}),
);
const useStyles2 = makeStyles({
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
});

const theme1 = createMuiTheme({
    overrides: {
        MuiInput: {
            formControl: {
                "label + &": {
                    marginTop: "unset",
                    width: '150px',
                    '@media  screen and (max-width: 1035px)': {
                        width: '140px'
                    },
                    '@media (max-width: 999px)': {
                        width: '130px'
                    },
                    '@media  screen and (max-width: 960px)': {
                        width: '120px'
                    },
                    '@media  only screen and (max-width: 600px)': {
                        width: '120px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '13px'
                    }

                }
            },

        },
        MuiInputLabel: {
            shrink: {
                transformOrigin: 'top left',
            },
        },
    },
});

const StyledButton = styled(Button)(({ theme }) => ({
    margin: '1em',
    color: '#000',
    borderColor: 'rgb(224 224 224)',
    '&:hover': {
        borderColor: 'rgb(224 224 224)'
    }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 25,
    paddingTop: 150
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '0.00938em',
}));

const StyledPaper1 = styled(Paper)(({ theme }) => ({
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: 'auto',
    borderRadius: '30px'
}));

const FeaturedListings = () => {
    const context = useContext(PropertyContext);
    const { featuredListings, FeaturedListings, handlePageSelect, page, max, loading } = context;
    const [searchTerm1, setSearchTerm1] = React.useState("");
    const [searchTerm2, setSearchTerm2] = React.useState(0);
    const [searchTerm3, setSearchTerm3] = React.useState(0);
    const [searchTerm4, setSearchTerm4] = React.useState("");
    const [minPrice, setMinPrice] = React.useState(100000);
    const [maxPrice, setMaxPrice] = React.useState(5000000);
    const [serachMLS, setSearchMls] = React.useState("");
    const [newProperties, setNewProperties] = useState([]);
    const [Page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage] = useState(15);
    const classes = useStyles();
    const classesmob2 = useStylesmob2();
    const classesmob = useStylesmob();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"))
    const Property = newProperties.flat()
    const sortedProperty = Property.sort((a, b) => (b.OnMarketDate > a.OnMarketDate) ? 1 : -1)
    // console.log("height", Property.filter(item => item.County === "Snohomish"))
    const minPrice1 = '$1,00,000';
    const [open, setOpen] = React.useState(false);


    console.log('beds', searchTerm2)
    const getdata = async () => {
        firebase.firestore().collection('Property').get().then((querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setNewProperties(data.map(item => item.data2))
            console.log('prop', data)
        })
            .catch(error => {
                console.log(error);
            });
    }

    React.useEffect(() => {
        getdata();

    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page.selected + 1)
        window.scrollTo({
            top: 10,
            behavior: 'smooth'
          });
        
    }

    const filteredClient1 = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            const CityFilter = sortedProperty && sortedProperty.filter(item => item.City === searchTerm1);
            if (searchTerm4.length > 0) {
                const CityPropType = CityFilter.filter(item => item.PropertyType === searchTerm4);
                if (searchTerm2 > 0) {
                    const CityBedBath = CityPropType.filter(house => house.Bedroom === searchTerm2)
                    if (searchTerm3 > 0) {
                        const CityBedBathPrice = CityBedBath.filter(house => house.Bathroom == searchTerm3)
                        if (maxPrice && minPrice > 0) {
                            return CityBedBathPrice.filter(house => house.StartingPrice >= minPrice &&
                                house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        }
                        return CityBedBathPrice
                    }
                    else if (maxPrice && minPrice > 0) {
                        const PriceCityBedBath = CityBedBath.filter(house => house.StartingPrice >= minPrice &&
                            house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        if (searchTerm3 > 0) {
                            return PriceCityBedBath.filter(house => house.Bathroom == searchTerm3)
                        }
                        return PriceCityBedBath
                    }
                    return CityBedBath
                }
                else if (searchTerm3 > 0) {
                    const CityPropBath = CityPropType.filter(house => house.Bathroom == searchTerm3)
                    if (searchTerm2 > 0) {
                        const CityBedBathPrice = CityPropBath.filter(house => house.Bedroom === searchTerm2)
                        if (maxPrice && minPrice > 0) {
                            return CityBedBathPrice.filter(house => house.StartingPrice >= minPrice &&
                                house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        }
                        return CityBedBathPrice
                    }
                    else if (maxPrice && minPrice > 0) {
                        const PriceCityBedBath = CityPropBath.filter(house => house.StartingPrice >= minPrice &&
                            house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        if (searchTerm3 > 0) {
                            return PriceCityBedBath.filter(house => house.Bathroom == searchTerm3)
                        }
                        return PriceCityBedBath
                    }
                    return CityPropBath
                }
                else if (maxPrice && minPrice > 0) {
                    const CityPropPrice = CityPropType.filter(house => house.StartingPrice >= minPrice &&
                        house.StartingPrice <= maxPrice || house.StartingPrice == '')
                    if (searchTerm2 > 0) {
                        const PriceCityBedBath = CityPropPrice.filter(house => house.Bedroom === searchTerm2);
                        if (searchTerm3 > 0) {
                            return PriceCityBedBath.filter(house => house.Bathroom == searchTerm3)
                        }
                        return PriceCityBedBath
                    }
                    else if (searchTerm3 > 0) {
                        const PriceCityBedBath = CityPropPrice.filter(house => house.Bathroom == searchTerm3);
                        if (searchTerm3 > 0) {
                            return PriceCityBedBath.filter(house => house.Bedroom === searchTerm2)
                        }
                        return PriceCityBedBath
                    }
                    return CityPropPrice
                }
                return CityPropType
            }
            else if (searchTerm2 > 0) {
                const CityBed = CityFilter.filter(house => house.Bedroom === searchTerm2);
                if (searchTerm3 > 0) {
                    const BedBath = CityBed.filter(house => house.Bathroom === searchTerm3);
                    if (searchTerm4.length > 0) {
                        const BedBathProp = BedBath.filter(house => house.PropertyType === searchTerm4);
                        if (maxPrice && minPrice > 0) {
                            return BedBathProp.filter(house => house.StartingPrice >= minPrice &&
                                house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        }
                        return BedBathProp
                    }
                    else if (maxPrice && minPrice > 0) {
                        const BedBathPrice = BedBath.filter(house => house.StartingPrice >= minPrice &&
                            house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        if (searchTerm4.length > 0) {
                            return BedBathPrice.filter(house => house.PropertyType === searchTerm4);
                        }
                        return BedBathPrice
                    }
                    return BedBath
                }
                else if (searchTerm4.length > 0) {
                    const CityBedProp = CityBed.filter(house => house.PropertyType === searchTerm4);
                    if (searchTerm3 > 0) {
                        const CityBedBathPrice = CityBedProp.filter(house => house.Bathroom === searchTerm3);
                        if (maxPrice && minPrice > 0) {
                            CityBedBathPrice.filter(house => house.StartingPrice >= minPrice &&
                                house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        }
                        return CityBedBathPrice
                    }
                    else if (maxPrice && minPrice > 0) {
                        const BedBathPrice = CityBedProp.filter(house => house.StartingPrice >= minPrice &&
                            house.StartingPrice <= maxPrice || house.StartingPrice == '')
                        if (searchTerm3 > 0) {
                            return BedBathPrice.filter(house => house.Bathroom === searchTerm3);
                        }
                        return BedBathPrice
                    }
                    return CityBedProp
                }
                else if (maxPrice && minPrice > 0) {
                    const CityBedProp = CityBed.filter(house => house.StartingPrice >= minPrice &&
                        house.StartingPrice <= maxPrice || house.StartingPrice == '');
                    if (searchTerm3 > 0) {
                        const CityBedBathPrice = CityBedProp.filter(house => house.Bathroom === searchTerm3);
                        if (searchTerm4.length > 0) {
                            return CityBedBathPrice.filter(house => house.PropertyType === searchTerm4);
                        }
                        return CityBedBathPrice
                    }
                    else if (searchTerm4.length > 0) {
                        const CityBedBathPrice = CityBedProp.filter(house => house.PropertyType === searchTerm4);
                        if (searchTerm4.length > 0) {
                            return CityBedBathPrice.filter(house => house.Bathroom === searchTerm3);
                        }
                        return CityBedBathPrice
                    }
                    return CityBedProp
                }
                return CityBed
            }
            else if (searchTerm3 > 0) {
                const CityBath = CityFilter.filter(house => house.Bathroom === searchTerm3)
                if (searchTerm1.length > 0) {
                    return CityBath.filter(house => house.City === searchTerm1)
                }
                else if (searchTerm2 > 0) {
                    return CityBath.filter(house => house.Bedroom === searchTerm2)
                }
                else if (searchTerm4.length > 0) {
                    return CityBath.filter(house => house.PropertyType === searchTerm4)
                }
                else if (maxPrice && minPrice) {
                    return CityBath.filter(house => house.StartingPrice >= minPrice &&
                        house.StartingPrice <= maxPrice || house.StartingPrice == '')
                }
                return CityBath
            }
            else if (minPrice && maxPrice > 0) {
                return CityFilter.filter(house => house.StartingPrice >= minPrice &&
                    house.StartingPrice <= maxPrice || house.StartingPrice == '')
            }
            else {
                return sortedProperty.filter(item => item.City === searchTerm1)
            }
        }
        else if (searchTerm2 > 0) {
            const PropTypeFilter = sortedProperty && sortedProperty.filter(item => item.Bedroom === searchTerm2);
            if (searchTerm1.length > 0) {
                return PropTypeFilter.filter(item => item.City === searchTerm1)
            }
            else if (searchTerm3 > 0) {
                const BedBathroom = PropTypeFilter.filter(item => item.Bathroom === searchTerm3);
                if (searchTerm4.length > 0) {
                    const BedBathProp = BedBathroom.filter(item => item.PropertyType === searchTerm4)
                    if (minPrice && maxPrice > 0) {
                        return BedBathProp.filter(house => house.StartingPrice >= minPrice &&
                            house.StartingPrice <= maxPrice || house.StartingPrice == '')
                    }
                    return BedBathProp
                }
                return BedBathroom
            }
            else if (searchTerm4.length > 0) {
                return PropTypeFilter.filter(item => item.PropertyType === searchTerm4)
            }
            else if (minPrice && maxPrice > 0) {
                return PropTypeFilter.filter(house => house.StartingPrice >= minPrice &&
                    house.StartingPrice <= maxPrice || house.StartingPrice == '')
            }
            else {
                return sortedProperty.filter(item => item.Bedroom === searchTerm2)
            }

        }
        else if (searchTerm3 > 0) {
            if (searchTerm1.length > 0) {
                const PropTypeFilter = sortedProperty && sortedProperty.filter(item => item.Bathroom === searchTerm3);
                return PropTypeFilter.filter(item => item.City === searchTerm1)
            }
            else if (searchTerm3 > 0) {
                const PropTypeFilter = sortedProperty && sortedProperty.filter(item => item.Bathroom === searchTerm3);
                return PropTypeFilter.filter(item => item.Bathroom === searchTerm3)
            }
            else if (searchTerm4.length > 0) {
                const PropTypeFilter = sortedProperty && sortedProperty.filter(item => item.Bathroom === searchTerm3);
                return PropTypeFilter.filter(item => item.PropertyType === searchTerm4)
            }
            else if (minPrice && maxPrice > 0) {
                const PriceFilters = sortedProperty && sortedProperty.filter(item => item.Bathroom === searchTerm3);
                return PriceFilters.filter(house => house.StartingPrice >= minPrice &&
                    house.StartingPrice <= maxPrice || house.StartingPrice == '')
            }
            return sortedProperty && sortedProperty.filter(item => item.Bathroom === searchTerm3)
        }
        else if (searchTerm4.length > 0) {
            const PropTypeFilter = sortedProperty && sortedProperty.filter(item => item.PropertyType === searchTerm4);
            if (searchTerm1.length > 0) {
                return PropTypeFilter.filter(item => item.City === searchTerm1)
            }
            else if (searchTerm2 > 0) {
                const PropBed = PropTypeFilter.filter(item => item.Bedroom === searchTerm2)
                if (searchTerm3 > 0) {
                    const PropBedBath = PropBed.filter(item => item.Bathroom === searchTerm3)
                    if (minPrice && maxPrice > 0) {
                        return PropBedBath.filter(house => house.StartingPrice >= minPrice &&
                            house.StartingPrice <= maxPrice || house.StartingPrice == '')
                    }
                    return PropBedBath
                }
                return PropBed
            }
            else if (searchTerm3 > 0) {
                return PropTypeFilter.filter(item => item.Bathroom === searchTerm3)
            }
            else if (minPrice && maxPrice > 0) {
                return PropTypeFilter.filter(house => house.StartingPrice >= minPrice &&
                    house.StartingPrice <= maxPrice || house.StartingPrice == '')
            }
            return PropTypeFilter
        }
        else if (minPrice && maxPrice > 0) {
            const priceFilter1 = sortedProperty && sortedProperty.filter(house => house.StartingPrice >= minPrice &&
                house.StartingPrice <= maxPrice || house.StartingPrice == '')
            if (searchTerm1.length > 0) {
                return priceFilter1.filter(item => item.City === searchTerm1)
            }
            else if (searchTerm2 > 0) {
                return priceFilter1.filter(item => item.Bedroom === searchTerm2);
            }
            else if (searchTerm3 > 0) {
                return priceFilter1.filter(item => item.Bathroom === searchTerm3);
            }
            else if (searchTerm4.length > 0) {
                return priceFilter1.filter(item => item.PropertyType === searchTerm4);
            }
            else {
                return priceFilter1
            }
        }
        else if (searchTerm1.length && searchTerm2 && searchTerm3 > 0) {
            const CityFilter = sortedProperty && sortedProperty.filter(item => item.City === searchTerm1);
            const Citybed = CityFilter.filter(house => house.Bedroom === searchTerm2);
            return CityBed.filter(item => item.Bathroom === searchTerm3)
        }
        else {
            return sortedProperty
        }
    }, [serachMLS, searchTerm1, searchTerm2, searchTerm3, searchTerm4, searchTerm2 && searchTerm3, sortedProperty, Property]
    )
    const CityFilter = sortedProperty && sortedProperty.filter(item => item.City === searchTerm1);
    const CityBed = CityFilter.filter(house => house.Bedroom === searchTerm2)
    console.log("CityBed", CityBed.filter(item => item.Bathroom === searchTerm3))
    // const filteredClient = React.useMemo(() => {
    //     if (searchTerm2.length > 0) {
    //         return Property && Property.filter((item) => item.Bedroom === searchTerm2)
    //     }
    //     return sortedProperty
    // }, [searchTerm1, searchTerm2, searchTerm3, searchTerm4, sortedProperty, Property])

    const totalPages = Math.ceil(Property.length && filteredClient1.filter((item) => {
        if (serachMLS === "") {
            return item
        } else if (item.PropertyId.toLowerCase().includes(serachMLS.toLowerCase())) {
            return item
        }
    }).length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = filteredClient1.filter((item) => {
        if (serachMLS === "") {
            return item
        } else if (item.PropertyId.toLowerCase().includes(serachMLS.toLowerCase())) {
            return item
        }
    })
        .slice(indexOfFirstItem, indexOfLastItem)
    // console.log("filteredBed", filteredClient1.slice(indexOfFirstItem, indexOfLastItem))


    const city = Property && Property.map(item => item.City).sort((a, b) => a.localeCompare(b))
    const cities = [...new Set(city)]

    const bed = sortedProperty && sortedProperty.map(item => item.Bedroom).sort()
    const beds = [...new Set(bed)]

    // console.log('beds', beds)

    const bathroom = Property && Property.map(item => item.Bathroom).sort()
    const bathrooms = [...new Set(bathroom)]

    const propertyType = Property && Property.map(item => item.PropertyType).sort((a, b) => a.localeCompare(b))
    const propertyTypes = [...new Set(propertyType)]

    console.log("pt", sortedProperty.filter(item => item.Bedroom === searchTerm2));

    const filteredBeds = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            const bed = Property && Property.filter(item => item.City === searchTerm1).filter(item => item.Bedroom).sort()
            const beds = [...new Set(bed.map(item => item.Bedroom))]
            return beds.sort()
        }
        else if (searchTerm3 > 0) {
            const bed = Property && Property.filter(item => item.Bathroom === searchTerm3).filter(item => item.Bedroom).sort()
            const beds = [...new Set(bed.map(item => item.Bedroom))]
            return beds.sort()
        }
        else if (searchTerm4.length > 0) {
            const bed = Property && Property.filter(item => item.PropertyType === searchTerm4).filter(item => item.Bedroom).sort()
            const beds = [...new Set(bed.map(item => item.Bedroom))]
            return beds.sort()
        }
        else if (minPrice && maxPrice > 0) {
            const bed = Property && Property.filter(house => house.StartingPrice >= minPrice && house.StartingPrice <= maxPrice || house.StartingPrice == '').filter(item => item.Bedroom).sort()
            const beds = [...new Set(bed.map(item => item.Bedroom))]
            return beds.sort()
        }
        return beds

    }, [searchTerm1, searchTerm2, searchTerm3, searchTerm4, beds])

    const filteredBaths = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            const bath = sortedProperty && sortedProperty.filter(item => item.City === searchTerm1).filter(item => item.Bathroom)
            const baths = [...new Set(bath.map(item => item.Bathroom))]
            if (searchTerm2 > 0) {
                const bath = sortedProperty && sortedProperty
                    .filter(item => item.City === searchTerm1)
                    .filter(item => item.Bedroom === searchTerm2)
                const bath1 = bath.filter(item => item.Bathroom)
                const baths = [...new Set(bath1.map(item => item.Bathroom))]
                return baths.sort()
            }
            return baths.sort()
        }
        else if (searchTerm2 > 0) {
            const propType = sortedProperty && sortedProperty.filter(item => item.Bedroom === searchTerm2).filter(item => item.Bathroom)
            const propTypes = [...new Set(propType.map(item => item.Bathroom))]
            return propTypes.sort()
        }
        else if (searchTerm4.length > 0) {
            const propType = sortedProperty && sortedProperty.filter(item => item.PropertyType === searchTerm4).filter(item => item.Bathroom)
            const propTypes = [...new Set(propType.map(item => item.Bathroom))]
            if (searchTerm2 > 0) {
                const bath = sortedProperty && sortedProperty
                    .filter(item => item.PropertyType === searchTerm4)
                    .filter(item => item.Bedroom === searchTerm2)
                const bath1 = bath.filter(item => item.Bathroom)
                const baths = [...new Set(bath1.map(item => item.Bathroom))]
                return baths.sort()
            }
            return propTypes.sort()
        }
        else if (minPrice && maxPrice > 0) {
            const bed = sortedProperty && sortedProperty.filter(house => house.StartingPrice >= minPrice && house.StartingPrice <= maxPrice || house.StartingPrice == '').filter(item => item.Bathroom)
            const beds = [...new Set(bed.map(item => item.Bathroom))]
            if (searchTerm2 > 0) {
                const bath = sortedProperty && sortedProperty
                    .filter(house => house.StartingPrice >= minPrice && house.StartingPrice <= maxPrice || house.StartingPrice == '')
                    .filter(item => item.Bedroom === searchTerm2)
                const bath1 = bath.filter(item => item.Bathroom)
                const baths = [...new Set(bath1.map(item => item.Bathroom))]
                return baths.sort()
            }
            return beds.sort()
        }
        return bathrooms

    }, [searchTerm1, searchTerm2, searchTerm3, searchTerm4, bathrooms])

    const filteredPropertyType = React.useMemo(() => {
        const propertyType = sortedProperty && sortedProperty.map(item => item.PropertyType).sort((a, b) => a.localeCompare(b))
        const propertyTypes = [...new Set(propertyType)]
        if (searchTerm1.length > 0) {
            const propType = Property && Property.filter(item => item.City === searchTerm1).filter(item => item.PropertyType)
            const propTypes = [...new Set(propType.map(item => item.PropertyType))]
            return propTypes.sort()
        }
        else if (searchTerm2 > 0) {
            const propType = Property && Property.filter(item => item.Bedroom === searchTerm2).filter(item => item.PropertyType)
            const propTypes = [...new Set(propType.map(item => item.PropertyType))]
            return propTypes.sort()
        }
        else if (searchTerm3 > 0) {
            const propType = Property && Property.filter(item => item.Bedroom === searchTerm3).filter(item => item.PropertyType)
            const propTypes = [...new Set(propType.map(item => item.PropertyType))]
            return propTypes.sort()
        }
        else if (minPrice && maxPrice > 0) {
            const bed = Property && Property.filter(house => house.StartingPrice >= minPrice && house.StartingPrice <= maxPrice || house.StartingPrice == '').filter(item => item.PropertyType).sort()
            const beds = [...new Set(bed.map(item => item.Bathroom))]
            return beds.sort()
        }
        return propertyTypes

    }, [propertyTypes])

    // const filteredClient = React.useMemo(() => {
    //     if (searchTerm1.length > 0) {
    //         if (searchTerm4.length > 0) {
    //             const PropTypeFilter = CurrentCommunities && CurrentCommunities.filter(item => item.City === searchTerm1);
    //             return PropTypeFilter.filter(item => item.PropertySubType === searchTerm4)
    //         }
    //         else if (searchTerm2 > 0) {
    //             const PriceFilter = CurrentCommunities && CurrentCommunities.filter(item => item.City === searchTerm1);
    //             return PriceFilter.filter(house => house.Bedroom === searchTerm2)
    //         }
    //         else if (searchTerm3 > 0) {
    //             const PriceFilter = CurrentCommunities && CurrentCommunities.filter(item => item.City === searchTerm1);
    //             return PriceFilter.filter(house => house.Bathroom === searchTerm3)
    //         }
    //         else if (minPrice && maxPrice > 0) {
    //             const PriceFilters = CurrentCommunities && CurrentCommunities.filter(item => item.City === searchTerm1);
    //             return PriceFilters.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //         }
    //         // else if (searchTerm2 && searchTerm3 > 0) {
    //         //     const PriceFilter = Property && Property.filter(item => item.City === searchTerm1);
    //         //     return PriceFilter.filter(house => house.Bedroom === searchTerm2) && PriceFilter.filter(house => house.Bedroom === searchTerm3)
    //         // }
    //         else {
    //             return CurrentCommunities && CurrentCommunities.filter(item => item.City === searchTerm1)
    //         }

    //     }
    //     else if (searchTerm2 > 0) {
    //         if (searchTerm1.length > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bedroom === searchTerm2);
    //             return bedFilter.filter(item => item.City === searchTerm1)
    //         }
    //         else if (searchTerm3 > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bedroom === searchTerm2);
    //             return bedFilter.filter(item => item.Bathroom === searchTerm3)
    //         }
    //         else if (searchTerm4 > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bedroom === searchTerm2);
    //             return bedFilter.filter(item => item.PropertySubType === searchTerm4)
    //         }
    //         else if (minPrice && maxPrice > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bedroom === searchTerm2);
    //             return bedFilter.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //         }
    //         else {
    //             return CurrentCommunities && CurrentCommunities.filter(item => item.Bedroom === searchTerm2)
    //         }
    //     }
    //     else if (searchTerm3 > 0) {
    //         if (searchTerm1.length > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bathroom === searchTerm3);
    //             return bedFilter.filter(item => item.City === searchTerm1)
    //         }
    //         else if (searchTerm2 > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bathroom === searchTerm3);
    //             return bedFilter.filter(item => item.Bedroom === searchTerm3)
    //         }
    //         else if (searchTerm4 > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bathroom === searchTerm3);
    //             return bedFilter.filter(item => item.PropertySubType === searchTerm4)
    //         }
    //         else if (minPrice && maxPrice > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.Bathroom === searchTerm3);
    //             return bedFilter.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //         }
    //         else {
    //             return CurrentCommunities && CurrentCommunities.filter(item => item.Bathroom === searchTerm3)
    //         }
    //     }
    //     else if (searchTerm4.length > 0) {
    //         if (searchTerm1.length > 0) {
    //             const PropTypeFilter = CurrentCommunities && CurrentCommunities.filter(item => item.PropertySubType === searchTerm4);
    //             return PropTypeFilter.filter(item => item.City === searchTerm1)
    //         }
    //         else if (searchTerm2 > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.PropertySubType === searchTerm4);
    //             return bedFilter.filter(item => item.Bedroom === searchTerm2)
    //         }
    //         else if (searchTerm3 > 0) {
    //             const bedFilter = CurrentCommunities && CurrentCommunities.filter(item => item.PropertySubType === searchTerm4);
    //             return bedFilter.filter(item => item.Bathroom === searchTerm3)
    //         }
    //         else if (minPrice && maxPrice > 0) {
    //             const PriceFilter = CurrentCommunities && CurrentCommunities.filter(item => item.PropertySubType === searchTerm4);
    //             return PriceFilter.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //         }
    //         else {
    //             return CurrentCommunities && CurrentCommunities.filter(item => item.PropertySubType === searchTerm4)
    //         }

    //     }
    //     else if (minPrice && maxPrice > 0) {
    //         if (searchTerm1.length > 0) {
    //             const priceFilter1 = CurrentCommunities && CurrentCommunities.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //             return priceFilter1.filter(item => item.City === searchTerm1)
    //         }
    //         else if (searchTerm2 > 0) {
    //             const priceFilter1 = CurrentCommunities && CurrentCommunities.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //             return priceFilter1.filter(item => item.Bedroom === searchTerm2)
    //         }
    //         else if (searchTerm3 > 0) {
    //             const priceFilter1 = CurrentCommunities && CurrentCommunities.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //             return priceFilter1.filter(item => item.Bathroom === searchTerm3)
    //         }
    //         else if (searchTerm4.length > 0) {
    //             const priceFilter = CurrentCommunities && CurrentCommunities.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //             return priceFilter.filter(item => item.PropertySubType === searchTerm4)
    //         }
    //         else {
    //             return CurrentCommunities && CurrentCommunities.filter(house => house.StartingPrice >= minPrice &&
    //                 house.StartingPrice <= maxPrice || house.StartingPrice == '')
    //         }

    //     }

    //     return CurrentCommunities


    // }, [searchTerm1, searchTerm2, searchTerm3, searchTerm4, maxPrice, minPrice, CurrentCommunities]  )
    // console.log("Bed", Property && Property.filter(item => item.Bedroom === searchTerm2))
    // console.log("cityprop", Property && Property.filter(item => item.City === searchTerm1).filter(item => item.PropertySubType === searchTerm4))



    // console.log('beds', searchTerm2)
    const StyledTypography = styled(Typography)(({ theme }) => ({
        font: 'inherit',
        fontSize: isMatch ? '15px' : '17px',
        fontWeight: 400
    }));

    const StyledTypography1 = styled(Typography)(({ theme }) => ({
        font: 'inherit',
        fontSize: isMatch ? '15px' : '17px',
        fontWeight: 400
    }));

    React.useEffect(() => {
        if (featuredListings.length === 0) {
            FeaturedListings()
        }

    }, [])

    // const getdata = async () => {
    //     const usersRef = firebase.firestore().collection('Properties');
    //     const alovelaceRef = usersRef.doc();
    //     alovelaceRef.onSnapshot(function (doc) {
    //         setNewProperties(doc.data())
    //         console.log("Current data: ", doc.data());
    //     });
    // }



    if (featuredListings.length === 0) {
        return <Loading />
    }



    if (sortedProperty.length === 0) {
        return <Loading />;
    }

    return (
        <>
            {
                isMatch ?
                    <>
                        <ListingsSearchComp
                            citys={cities}
                            beds={filteredBeds}
                            baths={filteredBaths}
                            propertyType={filteredPropertyType}
                            Price={Price}
                            Price2={Price2}
                            searchTerm1={setSearchTerm1}
                            searchTerm2={setSearchTerm2}
                            searchTerm3={setSearchTerm3}
                            searchTerm4={setSearchTerm4}
                            minPrice={minPrice}
                            MinPrice={setMinPrice}
                            maxPrice={maxPrice}
                            MaxPrice={setMaxPrice}
                            searchMLS={setSearchMls}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {(searchTerm1 !== "ALL" && searchTerm1 !== "") && <div style={{ padding: '7px 5px 0px 5px' }}>
                                <Chip label={`City : ${searchTerm1.slice(", " + 'WA')}`} elevation={3} /></div>}
                            {(searchTerm2 !== "ALL" && searchTerm2 !== "") && <div style={{ padding: '7px 5px 0px 5px' }}>{searchTerm2 === 0 ? null : <Chip label={`Beds : ${searchTerm2}`} elevation={3} />} </div>}
                            {(searchTerm3 !== "ALL" && searchTerm3 !== "") && <div style={{ padding: '7px 5px 0px 5px' }}>{searchTerm2 === 0 ? null : <Chip label={`Baths : ${searchTerm3}`} elevation={3} />}</div>}
                            {(searchTerm4 !== "ALL" && searchTerm4 !== "") && <div style={{ padding: '7px 5px 0px 5px' }}><Chip label={`Home Type : ${searchTerm4}`} elevation={3} /></div>}
                            {(minPrice !== "ALL" && minPrice !== "") && <div style={{ padding: '7px 5px 0px 5px' }}>{minPrice === 100000 ? null : <Chip label={<NumberFormat value={minPrice} thousandSeparator={true} displayType={'text'} prefix={'Min Price : $'} />} elevation={3} />}</div>}
                            {(maxPrice !== "ALL" && minPrice !== "") && <div style={{ padding: '7px 5px 0px 5px' }}>{maxPrice === 5000000 ? null : <Chip label={<NumberFormat value={maxPrice} thousandSeparator={true} displayType={'text'} prefix={'Max Price : $'} />} elevation={3} />}</div>}
                        </div>
                    </>
                    :
                    <StyledPaper>
                        <Grid align="center" style={{ marginBottom: '3em' }}>
                            <Typography variant="h2" sx={{ color: 'white', fontWeight: '500' }} gutterBottom> Featured Listings</Typography>
                            {/* <Typography variant="h2" sx={{ color: 'white', fontWeight: '500' }} gutterBottom> Number of Properties {Property.length}</Typography> */}
                        </Grid>
                        <div style={{
                            marginBottom: '8rem'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Paper
                                    sx={{
                                        paddingTop: '2px 10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: isMatch ? 320 : 400,
                                        margin: 'auto',
                                        borderRadius: '30px',
                                        ml: '20em'
                                    }}>
                                    <Select
                                        disableUnderline
                                        color=""
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        name="Soldmergecity"
                                        style={{
                                            marginLeft: '1em',
                                            flex: 1,
                                            fontWeight: 420,
                                            fontSize: '15px',
                                            width: 100,
                                        }}
                                        defaultValue="none"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem disabled value="none"
                                            style={{
                                                fontWeight: 500,
                                                fontSize: 15,
                                                letterSpacing: '0.00938em',
                                            }}
                                        >
                                            Search by city
                                        </MenuItem>
                                        <MenuItem value="All"
                                            style={{
                                                fontWeight: 500,
                                                fontSize: 15,
                                                letterSpacing: '0.00938em',
                                            }}
                                            onClick={() => setSearchTerm1("")} >
                                            All
                                        </MenuItem>
                                        {cities.map((item, index) => (
                                            <MenuItem key={index} value={item}
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: 15,
                                                    letterSpacing: '0.00938em',
                                                }}
                                                onClick={() => setSearchTerm1(item)} >
                                                {item + ", " + 'WA'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton aria-label="search"
                                    >
                                        <Search />
                                    </IconButton>
                                </Paper>
                                <Divider orientation="vertical" sx={{ background: '#ff810052', width: '3px', }} flexItem />
                                <Paper
                                    sx={{
                                        paddingTop: '2px 10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: isMatch ? 320 : 400,
                                        margin: 'auto',
                                        borderRadius: '30px',
                                        mr: '20em'
                                    }}>
                                    <InputBase
                                        sx={{
                                            ml: 1,
                                            flex: 1,
                                            fontWeight: 420,
                                            fontSize: '15px',
                                            width: 100
                                        }}
                                        placeholder="Enter MLS#"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                        onChange={(event) => setSearchMls(event.target.value)}
                                    />
                                    <IconButton aria-label="search"
                                    >
                                        <Search />
                                    </IconButton>
                                </Paper>
                            </div>

                            <div className="contain">
                                <MuiThemeProvider theme={theme1}>
                                    <Paper className={classes.papersecard}>
                                        <Grid container spacing={0}>
                                            <Grid item className={classes.gridhover} align="center">
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                        className={classes.search_lable}
                                                    >
                                                        Beds
                                                    </InputLabel>
                                                    <Select
                                                        disableUnderline
                                                        color={'secondary'}
                                                        labelId="demo-mutiple-name-label"
                                                        id="school"
                                                        name="school"
                                                        style={{
                                                            // marginLeft: '1em',
                                                            flex: 1,
                                                            fontWeight: 420,
                                                            fontSize: '15px',
                                                            width: 120,
                                                        }}
                                                        defaultValue="All"
                                                    >
                                                        <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                                            Beds
                                                        </MenuItem>
                                                        <MenuItem value="All"
                                                            style={{
                                                                fontWeight: 500,
                                                                fontSize: 15,
                                                                letterSpacing: '0.00938em',
                                                            }}
                                                            onClick={() => setSearchTerm2("")} >
                                                            All
                                                        </MenuItem>
                                                        {filteredBeds.map((item, index) => (
                                                            <MenuItem key={index} value={item} className={classes.search_input} defaultValue
                                                                onClick={() => setSearchTerm2(item)} >
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid >
                                            </Grid>
                                            <div className="dividerpc"> </div>
                                            <Grid item className={classes.gridhover} align="center">
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                        className={classes.search_lable}
                                                    >
                                                        Baths
                                                    </InputLabel>
                                                    <Select
                                                        disableUnderline
                                                        color={'secondary'}
                                                        labelId="demo-mutiple-name-label"
                                                        id="school"
                                                        name="school"
                                                        style={{
                                                            // marginLeft: '1em',
                                                            flex: 1,
                                                            fontWeight: 420,
                                                            fontSize: '15px',
                                                            width: 120,
                                                        }}
                                                        defaultValue="All"
                                                    >
                                                        <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                                            Baths
                                                        </MenuItem>
                                                        <MenuItem value="All"
                                                            style={{
                                                                fontWeight: 500,
                                                                fontSize: 15,
                                                                letterSpacing: '0.00938em',
                                                            }}
                                                            onClick={() => setSearchTerm3("")} >
                                                            All
                                                        </MenuItem>
                                                        {filteredBaths.map((item, index) => (
                                                            <MenuItem key={index} value={item} className={classes.search_input} defaultValue
                                                                onClick={() => setSearchTerm3(item)} >
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid >
                                            </Grid>
                                            <div className="dividerpc"> </div>
                                            <Grid item className={classes.gridhover} align="center" sx={{ ml: '2em', mr: '2em' }}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                        className={classes.search_lable}
                                                    >
                                                        Home Type
                                                    </InputLabel>
                                                    <Select
                                                        disableUnderline
                                                        color={'secondary'}
                                                        labelId="demo-mutiple-name-label"
                                                        id="school"
                                                        name="school"
                                                        style={{
                                                            // marginLeft: '1em',
                                                            flex: 1,
                                                            fontWeight: 420,
                                                            fontSize: '15px',
                                                            width: 155,
                                                        }}
                                                        defaultValue="none"
                                                    >
                                                        <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                                            Home Type
                                                        </MenuItem>
                                                        <MenuItem value="none"
                                                            style={{
                                                                fontWeight: 500,
                                                                fontSize: 15,
                                                                letterSpacing: '0.00938em',
                                                            }}
                                                            onClick={() => setSearchTerm4("")} >
                                                            All
                                                        </MenuItem>
                                                        {propertyTypes.map((item, index) => (
                                                            <MenuItem key={index} value={item} className={classes.search_input} defaultValue
                                                                onClick={() => setSearchTerm4(item)} >
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid >
                                            </Grid>
                                            <div className="dividerpc"> </div>
                                            <Grid item className={classes.gridhover} align="center" sx={{ ml: '2em' }}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                        className={classes.search_lable}
                                                    >
                                                        Min Price
                                                    </InputLabel>
                                                    <Select
                                                        disableUnderline
                                                        color={'secondary'}
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        name="minPrice"
                                                        value={minPrice}
                                                        style={{
                                                            // marginLeft: '1em',
                                                            flex: 1,
                                                            fontWeight: 420,
                                                            fontSize: '15px',
                                                            width: 120,
                                                        }}
                                                    >
                                                        <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                                            Min Price
                                                        </MenuItem>
                                                        {Price.map((item, index) => (
                                                            <MenuItem key={index} value={item}
                                                                style={{
                                                                    fontWeight: 500,
                                                                    fontSize: 15,
                                                                    letterSpacing: '0.00938em',
                                                                }}
                                                                onClick={() => setMinPrice(item)} >
                                                                <NumberFormat value={item} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid >
                                            </Grid>
                                            <div className="dividerpc"> </div>
                                            <Grid item className={classes.gridhover} align="center" sx={{ ml: '2em' }}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                        className={classes.search_lable}
                                                    >
                                                        Max Price
                                                    </InputLabel>
                                                    <Select
                                                        disableUnderline
                                                        color={'secondary'}
                                                        labelId="demo-mutiple-name-label"
                                                        id="school"
                                                        name="school"
                                                        style={{
                                                            // marginLeft: '1em',
                                                            flex: 1,
                                                            fontWeight: 420,
                                                            fontSize: '15px',
                                                            width: 120,
                                                        }}
                                                        value={maxPrice}
                                                    >
                                                        <MenuItem disabled defaultValue value="" className={classes.search_input} >
                                                            Max Price
                                                        </MenuItem>
                                                        {Price2.map((item, index) => (
                                                            <MenuItem key={index} value={item}
                                                                style={{
                                                                    fontWeight: 500,
                                                                    fontSize: 15,
                                                                    letterSpacing: '0.00938em',
                                                                }}
                                                                onClick={() => setMaxPrice(item)} >
                                                                <NumberFormat value={item} thousandSeparator={true} displayType={'text'} prefix={'$'} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </MuiThemeProvider>
                            </div>

                        </div>

                    </StyledPaper>
            }
            <FeaturedFilter
                CurrentData={currentData}
                isMatch={isMatch}
                SearchTerm={serachMLS}
            />
            <Stack spacing={2} sx={{ p: isMatch ? '1em 2em 1em 0em' : '1em' }}>
                <ReactPaginate
                    previousLabel={<KeyboardArrowLeft />}
                    nextLabel={<KeyboardArrowRight />}
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    containerClassName={"navigationButtons"}
                    previousLinkClassName={"previousButton"}
                    nextLinkClassName={"nextButton"}
                    //   disabledClassName={"navigationDisabled"}
                    activeClassName={"navigationActive"}
                    marginPagesDisplayed={isMatch ? 1 : 3}
                    pageRangeDisplayed={isMatch ? 1 : 3}
                />
            </Stack >
            <div>
                <Footer />
            </div>
        </>
    )
}

export default FeaturedListings
// /how to use number in usestate using reactjs?

const Beds = [0, 1, 2, 3, 4, 5, 6, 7]


const Baths = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
]

const Price = [
    '100000',
    '200000',
    '300000',
    '400000',
    '500000',
    '600000',
    '700000',
    '800000',
    '900000',
    '1000000',
    '1100000',
    '1200000',
    '1300000',
    '1400000',
    '1500000',
    '1600000',
    '1700000',
    '1800000',
    '1900000',
    '2000000',
]


const Price2 = [
    '100000',
    '200000',
    '300000',
    '400000',
    '500000',
    '600000',
    '700000',
    '800000',
    '900000',
    '1000000',
    '1100000',
    '1200000',
    '1300000',
    '1400000',
    '1500000',
    '1600000',
    '1700000',
    '1800000',
    '1900000',
    '2000000',
    '2100000',
    '2200000',
    '2300000',
    '2400000',
    '2500000',
    '2600000',
    '2700000',
    '2800000',
    '2900000',
    '3000000',
    '3100000',
    '3200000',
    '3300000',
    '3400000',
    '3500000',
    '3600000',
    '3700000',
    '3800000',
    '3900000',
    '4000000',
    '4100000',
    '4200000',
    '4300000',
    '4400000',
    '4500000',
    '4600000',
    '4700000',
    '4800000',
    '4900000',
    '5000000',
]

