import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Divider from '@material-ui/core/Divider';
import Chart from 'react-apexcharts';

import offer from '../../images/cards/offer1.png';

// const PrettoSlider = withStyles({
//     root: {
//       color: '#52af77',
//       height: 6,
//     },
//     thumb: {
//       height: 20,
//       width: 20,
//       backgroundColor: '#fff',
//       border: '2px solid currentColor',
//       marginTop: -8,
//       marginLeft: -12,
//       '&:focus, &:hover, &$active': {
//         boxShadow: 'inherit',
//       },
//     },
//     active: {},
//     valueLabel: {
//       left: 'calc(-50% + 4px)',
//     },
//     track: {
//       height: 6,
//       borderRadius: 4,
//     },
//     rail: {
//       height: 6,
//       borderRadius: 4,
//     },
//   })(Slider);

const Pretto2Slider = withStyles({
  root: {
    color: '#0074e4',
    height: 4,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#2979ffc2'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    margin: 'auto',
    alignItems: "center",
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  card2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#0066ff'
  },
  field: {
    padding: '10px 0px 10px ',
  },
  card3: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: "white",
    background: '#01398e'
  },
  chart: {
    width: '250px',
    margin: 'auto',
    alignItems: "center",
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  heading: {
    paddingBottom: "10px",
    // textAlign:"center",
    alignItems: "center",
    paddingTop: "30px"
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  // wid:{
  //   minWidth:"900px"
  // },
  main: {
    // padding:"30px",
  },
  cardbody: {
    padding: 30,
    textAlign: "center",
    background: "#6d6dca",
    color: "white",
    borderRadius: 10
  },
  root: {
    display: 'flex',
    background: 'linear-gradient(45deg, #430089, #82ffa1)',
    borderRadius: 15,
    padding: "30px 10px",
    maxWidth: 500
  },
  holder: {
    [theme.breakpoints.up("md")]: {
      // minWidth:140
      display: 'flex'
    },

  },
  containmd: {
    [theme.breakpoints.up("md")]: {
      // minWidth:140
      paddingLeft: "24px",
      paddingRight: "24px"
    },
    [theme.breakpoints.down("md")]: {
      // minWidth:140
      padding: 0
    },


  },
  root2: {
    display: 'flex',
    background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
    borderRadius: 15,
    padding: 10,
    maxWidth: 500,

    cursor: "pointer"
  },
  root3: {
    display: 'flex',
    background: 'linear-gradient(45deg, #f59301fc, #f70000)',
    borderRadius: 15,
    padding: 10,
    maxWidth: 500,

    cursor: "pointer"
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',

  },
  content2: {
    flex: '1 '
  },
  cover: {
    width: 151,

  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  hold: {
    padding: 8
  },
  textsecond: {
    color: "white"
  },
  textOne: {
    color: "white"
  },
  texttwo: {
    color: "white",
    fontSize: "1.2rem",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {

      fontSize: "0.99rem",
    },
  },
  pad: {
    padding: 8
  },
  graph: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    borderRadius: 30
  },
  cardpad: {
    [theme.breakpoints.up("md")]: {
      padding: 8,
    },

  },
  heading2: {
    paddingTop: 20,
    paddingBottom: 10
  }
}));



function Mortgage(props) {
  const classes = useStyles();
  // prenciple amount
  const [pamount, setPamount] = useState(250000)
  const [interest, setInterest] = useState(7);
  const [duration, setDuration] = useState(10);
  const [downPayment, setDownPayment] = useState(50000);
  const maxValue = 2000000;
  const maxValue2 = 2000000;
  const intMax = 20;
  const maxDuration = 30;

  const principle = pamount - downPayment;
  const monthlyInterest = interest / 100 / 12;
  const numberOfPayments = duration * 12;
  let monthlyPrice = ((
    principle *
    [monthlyInterest * (1 + monthlyInterest) ** numberOfPayments]) /
    [(1 + monthlyInterest) ** numberOfPayments - 1]).toFixed(2)

  const totall = Math.round(monthlyPrice)
  // const intr = interest / 1200;
  // const emi = duration? Math.round(pamount * intr / (1-(Math.pow(1 /( 1+ intr), duration)))) : 0;
  // const totalAmt = duration * emi;
  // const TotalAmountOfCredit = Math.round((emi / intr) * (1 - Math.pow( (1 + intr), (-duration))));
  // var TotalAmountOfInterest = Math.round(totalAmt - TotalAmountOfCredit);


  var options = {
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        size: 200,
        donut: {
          size: '85%',
          labels: {
            show: true,
            name: {
              show: true,

            },
            value: {
              show: true,

            },
            total: {
              showAlways: true,
              show: true,
              label: "Interest",

              formatter: function (w) {
                return w.globals.series.reduce((a, b, c) => {
                  return `${a}`
                }

                )
              }
            }
          }
        }
      }
    },
    total: 22,
    labels: ['Monthly Payment', 'Down Payment', 'Home Price']
  };
  var series = [totall, downPayment, pamount,]


  return (
    <div>
      <Card elevation={0} className={classes.main}>
        <Container maxWidth="lg">
          <div className={classes.heading}>

            <Typography id="disabled-slider" gutterBottom variant="h4" component="h1">
              Mortgage Calculator
            </Typography>
            <div className={classes.heading2}>
              <Typography gutterBottom colour="secondaryText" variant="subtitle1" component="h1" >
                Use our home loan calculator to estimate your mortgage payment, with taxes and insurance. Simply enter the price of the home, your down payment, and details about the home loan to calculate your mortgage payment breakdown, schedule, and more.
              </Typography>
            </div>
          </div>
          <Divider />
        </Container>
        <p></p>

        <Container maxWidth="lg">
          <Grid container spacing={3} >
            <Grid Item md={8}>

              <div>
                <Grid container >
                  <Grid item xs={12} sm={6}>
                    <Card elevation={0} style={{ padding: 20 }}>
                      <div>
                        <Typography id="disabled-slider" gutterBottom>
                          Home price
                        </Typography>
                        <div className={classes.field}>
                          <CurrencyTextField gutterBottom
                            id="standard-basic"
                            variant="outlined"
                            fullWidth
                            decimalPlaces="0"
                            cur rencySymbol="$"
                            size="small" label="Home price"
                            value={pamount} onChange={(event, value) => setPamount(value)} />
                        </div>
                        <Pretto2Slider aria-label="pretto slider" value={typeof pamount === 'number' ? pamount : 0} onChange={(event, vAmt) => { setPamount(vAmt) }} max={maxValue} />
                      </div>
                      <div>
                        <Typography id="disabled-slider" gutterBottom>
                          Down Payment
                        </Typography>
                        <div className={classes.field}>
                          <CurrencyTextField gutterBottom
                            id="standard-basic" variant="outlined" currencySymbol="$" decimalPlaces="0" fullWidth
                            size="small" label="Down Payment" value={downPayment} onChange={(event, vInt) => setDownPayment(vInt)} />
                        </div>
                        <Pretto2Slider aria-label="pretto slider" value={typeof downPayment === 'number' ? downPayment : 0} onChange={(event, vDnt) => { setDownPayment(vDnt) }} defaultValue={downPayment} max={maxValue2} />
                      </div>
                      <div>
                        <Typography id="disabled-slider" gutterBottom>
                          Interest Rate
                        </Typography>
                        <div className={classes.field}>
                          <TextField id="standard-basic" variant="outlined" align="right" fullWidth type="number"
                            size="small" label="Interest rate" value={interest} onChange={(event, vInt) => { setInterest(event.target.value === '' ? '' : Number(event.target.value)) }} defaultValue={interest} max={intMax} />
                        </div>
                        <Pretto2Slider valueLabelDisplay="auto" aria-label="pretto slider" value={typeof interest === 'number' ? interest : 0} onChange={(event, vInt) => { setInterest(vInt) }} defaultValue={interest} max={intMax} />
                      </div>
                      <div>
                        <Typography id="disabled-slider" gutterBottom>
                          Tenure (Years)
                        </Typography>
                        <Pretto2Slider valueLabelDisplay="auto" aria-label="pretto slider" value={typeof duration === 'number' ? duration : 0} onChange={(event, vDur) => { setDuration(vDur) }} defaultValue={duration} max={maxDuration} />
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card elevation={0} className={classes.cardpad}>
                      <div className={classes.pad}>
                        <Card className={classes.cardbody}>

                          <Typography id="disabled-slider" gutterBottom variant="h4" component="h1">
                            <NumberFormat value={monthlyPrice} displayType={'text'} thousandSeparator={true} prefix={'$ '} /> per month
                          </Typography>

                          <Typography id="disabled-slider" gutterBottom>
                            {duration} Year Fixed, {interest}% Interest
                          </Typography>
                        </Card>
                      </div>
                      <div className={classes.pad}>
                        <Card elevation={2} className={classes.graph}>
                          <Chart options={options} series={series} type="donut" />

                        </Card>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </div>

            </Grid>
            <Grid Item xs={12} md={4} direction="row">
              <div className={classes.hold}  >
                <Link to="/Contact-US" style={{ textDecoration: "NONE" }}>
                  <Card className={classes.root} elevation={5}>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5" className={classes.textOne}>
                          Want To Know Offer Review Dates?
                        </Typography>
                        <Typography variant="body1" className={classes.textsecond}>
                          Find Out Now <DoubleArrowIcon />
                        </Typography>
                      </CardContent>
                    </div>
                    <CardMedia
                      className={classes.cover}
                      image={offer}
                      title="Live from space album cover"
                    />
                  </Card>
                </Link>
              </div>

              <div className={classes.hold}  >
                <Link to="/Last-Solds" style={{ textDecoration: "NONE" }}>
                  <Card className={classes.root2} elevation={5}>
                    <div className={classes.details}>
                      <Typography component="h5" variant="h5" className={classes.texttwo}>
                        Know what We have Sold So Far <DoubleArrowIcon />
                      </Typography>
                    </div>
                  </Card>
                </Link>
              </div>

            </Grid>
          </Grid>
        </Container>
        <Card style={{ paddingBottom: 80 }} >
        </Card>
        <React.Fragment>
          <CssBaseline />
        </React.Fragment>
      </Card>
    </div>
    // </Container>
    // </Dialog>
  )
}

export default Mortgage;




























